import * as React from 'react';
import { createBrowserHistory } from 'history';

import IntakeStrategySidebar from './IntakeStrategySidebar';
import { CreateIntakeStrategy } from '../../../../actions/ISActions';
import ISStore from '../../../../stores/IntakeStrategyStore';

const customHistory = createBrowserHistory();

class IntakeStrategyNew extends React.Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      newISName: '',
      hasError: false,
      currentError: '',
    };

    this.handleCreate = this.handleCreate.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.onISChange = this.onISChange.bind(this);
  }

  componentDidMount() {
    ISStore.addChangeListener(this.onISChange);
  }

  componentWillUnmount() {
    ISStore.removeChangeListener(this.onISChange);
  }

  handleNameChange(e) {
    this.setState({ newISName: e.target.value });
  }

  onISChange() {
    let lastErr = ISStore.getLastError();

    if (lastErr !== '') {
      this.setState({ hasError: true, currentError: lastErr });
      // we have an error.  show it.
    } else {
      let _currentIS = ISStore.getCurrentIS();
      this.setState({ hasError: false });
      if (_currentIS !== undefined) {
        customHistory.push(
          `/Admin/IntakeStrategies/${_currentIS.id.toString()}`
        );
        window.location.reload();
        //LoadIntakeStrategies();
      }
    }
  }

  handleCreate() {
    if (this.state.newISName === '') {
      this.setState({
        hasError: true,
        currentError: 'The name of the intake strategy is required',
      });
    } else {
      // create new BP
      if (this.state.hasError) {
        this.setState({ hasError: false });
      }
      CreateIntakeStrategy(this.state.newISName);
    }
  }

  render() {
    return (
      <div className='container-fluid' style={{ height: '100%' }}>
        <div className='row flex-nowrap' style={{ height: '100%' }}>
          <IntakeStrategySidebar activePage='newIS' />
          <div className='flex-fill' style={{ backgroundColor: '#efefef' }}>
            <div
              className='card'
              style={{
                width: '50%',
                marginTop: '20px',
                marginLeft: '30px',
              }}
            >
              <div className='card-header'>New Intake Strategy</div>
              <div className='card-body'>
                <div className='mb-3'>
                  <label className='form-label'>Intake Strategy Name</label>
                  <input
                    className='form-control'
                    id='isName'
                    placeholder='Name'
                    value={this.state.newISName}
                    onChange={this.handleNameChange}
                  />
                </div>

                {this.state.hasError && (
                  <div
                    className='alert alert-danger'
                    role='alert'
                    style={{ marginTop: '10px' }}
                  >
                    <p>{this.state.currentError}</p>
                  </div>
                )}
                <button className='btn btn-primary' onClick={this.handleCreate}>
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default IntakeStrategyNew;
