import dispatcher from '../Dispatcher';
import actionTypes from './ActionTypes';
import axios from 'axios';
import * as Globals from '../Globals';
import { Logout } from './LoginActions';

export function ProcessAction(encAction) {
  let url = `${Globals.baseUrl}/DocumentActions/ProcessAction`;
  console.log('processAction');

  axios
    .post(
      url,
      {
        encryptedAction: encAction,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.DocActionProcess,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let errorMsg = res.toString();
      if (errorMsg.includes('401')) {
        console.log('Unauthorized');
        Logout();
      }
    });
}
