import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';

const CHANGE_EVENT = 'scheduleChange';
let _lastChange = '';
let _currentSchedules = [];
let _currentSchedule = undefined;
let _currentScheduleData = [];
let _deleteResult = undefined;

class ScheduleStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getSchedule() {
    return _currentSchedule;
  }

  getScheduleList() {
    return _currentSchedules;
  }

  getScheduleDataList() {
    return _currentScheduleData;
  }

  getDeleteResult() {
    return _deleteResult;
  }

  getLastChange() {
    return _lastChange;
  }

}

const store = new ScheduleStore();

Dispatcher.register((action) => {
  _lastChange = action.actionType;
  switch (action.actionType) {
    case actionTypes.SchedulesGetList:
      _currentSchedules = action.Result;
      store.emitChange();
      break;
    case actionTypes.SchedulesGetOne:
      _currentSchedule = action.Result;
      store.emitChange();
      break;
    case actionTypes.ScheduleUpdateOne:
      _currentSchedule = action.Result;
      store.emitChange();
      break;
    case actionTypes.ScheduleDelete:
      _deleteResult = action.Result;
      store.emitChange();
      break;
    default:
  }
});

export default store;
