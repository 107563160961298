import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import actionTypes from '../../../actions/ActionTypes';
import { GetCategories, GetLogs } from '../../../actions/LogActions';
import LoggingStore from '../../../stores/LoggingStore';

class LogsView extends React.Component {
  constructor() {
    // state
    super();

    this.state = {
      toDate: '',
      fromDate: '',
      toTime: '',
      fromTime: '',
      categories: [],
      subCategories: [],
      logs: [],
      cat1: 'All',
      cat2: 'All',
      sev: -1,
      showPopup: false,
      showMessageText: '',
    };

    this.onLogUpdate = this.onLogUpdate.bind(this);
    this.onEditFromDate = this.onEditFromDate.bind(this);
    this.onEditToDate = this.onEditToDate.bind(this);
    this.onEditCategory = this.onEditCategory.bind(this);
    this.onEditSev = this.onEditSev.bind(this);
    this.refresh = this.refresh.bind(this);
    this.toggleShowMessage = this.toggleShowMessage.bind(this);
    this.showNewMessage = this.showNewMessage.bind(this);
    this.onEditToTime = this.onEditToTime.bind(this);
    this.onEditFromTime = this.onEditFromTime.bind(this);
  }

  componentDidMount() {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    let todayDate = yyyy + '-' + mm + '-' + dd;
    this.setState({ toDate: todayDate, fromDate: todayDate });
    LoggingStore.addChangeListener(this.onLogUpdate);
    document.title = 'TrinDocs - Logging'

    GetCategories();
    let query = this.buildQuery();
    GetLogs(query);
  }

  componentWillUnmount() {
    LoggingStore.removeChangeListener(this.onLogUpdate);
  }

  componentDidUpdate() {}

  refresh() {
    let query = this.buildQuery();
    GetLogs(query);
  }

  onLogUpdate() {
    let change = LoggingStore.getLastChange();
    switch (change) {
      case actionTypes.LogGetCategories:
        let cat = LoggingStore.getCategories();
        this.setState({ categories: cat });
        break;
      case actionTypes.LogGetLogs:
        let logs = LoggingStore.getLogs();
        this.setState({ logs: logs });
        break;
      default:
    }
  }

  buildQuery() {
    let query = {
      fetchSize: 500,
      category: this.state.cat1,
      subCategory: this.state.cat2,
      severity: this.state.sev,
      ts: '',
    };
    if (this.state.fromDate !== '') {
      query.startTime = `${this.state.fromDate}`;
      if (this.state.fromTime !== '') {
        let startTime = `${this.state.fromDate} ${this.state.fromTime}`;
        let startDate = new Date(startTime);

        query.startTime = `${
          this.state.fromDate
        } ${startDate.getUTCHours()}:${startDate.getUTCMinutes()}`;
      }
    }
    if (this.state.toDate !== '') {
      query.endTime = `${this.state.toDate}`;
      if (this.state.toTime !== '') {
        let endTime = `${this.state.toDate} ${this.state.toTime}`;
        let endDate = new Date(endTime);
        query.endTime = `${
          this.state.toDate
        } ${endDate.getUTCHours()}:${endDate.getUTCMinutes()}`;
      }
    }

    return query;
  }

  onEditFromDate(e) {
    //console.log(fromDateStr);
    this.setState({ fromDate: e.target.value });
  }

  onEditToDate(e) {
    this.setState({ toDate: e.target.value });
  }

  onEditCategory(e) {
    if (e.target.value !== this.state.cat1) {
      this.setState({ cat1: e.target.value }, () => {
        let query = this.buildQuery();
        GetLogs(query);
      });
      let cat = this.state.categories.find(
        (f) => f.category === e.target.value
      );
      if (cat) {
        this.setState({ subCategories: cat.subCategories });
      }
    }
  }

  toggleShowMessage() {
    let popup = this.state.showPopup;
    this.setState({ showPopup: !popup });
  }

  showNewMessage(message) {
    console.log(message);
    this.setState({ showPopup: true, showMessageText: message });
  }

  onEditToTime(e) {
    this.setState({ toTime: e.target.value });
  }

  onEditFromTime(e) {
    console.log(e.target.value);
    this.setState({ fromTime: e.target.value });
  }

  onEditSev(e) {
    this.setState({ sev: parseInt(e.target.value, 10) }, () => {
      let query = this.buildQuery();
      GetLogs(query);
    });
  }

  convertDate(dateString) {
    let d = new Date(dateString + 'Z');
    let h = d.getHours();
    let mrdn = 'AM';
    if (h > 11) {
      mrdn = 'PM';
    }
    if (h === 24) {
      mrdn = 'AM';
    }
    if (h > 12) {
      h = h - 12;
    }
    if (h === 0) {
      h = 12;
    }
    let m = d.getMonth() + 1;
    //let m2 = m.toString().padStart(2, '0');
    let d2 = d.getDate().toString();
    let m3 = d.getMinutes().toString().padStart(2, '0');

    return `${m}/${d2}/${d.getFullYear()} ${h}:${m3} ${mrdn}`;
  }

  render() {
    return (
      <div className='d-flex flex-row flex-fill'>
        <div className='flex-fill'>
          <div className='card' style={{ margin: '16px', maxWidth: '900px' }}>
            <div className='card-header'>TrinDocs Logging</div>
            <div className='card-body'>
              <div className='row'>
                <div className='col-3'>
                  <div>
                    <strong>Category:</strong>
                  </div>
                  <select
                    value={this.state.cat1}
                    onChange={this.onEditCategory}
                    className='form-select mt-2'
                  >
                    <option>All</option>
                    {this.state.categories.map((cat1) => (
                      <option key={cat1.categoryId}>{cat1.category}</option>
                    ))}
                  </select>
                  <select className='form-select mt-2'>
                    <option>All</option>
                    {this.state.subCategories.map((cat2) => (
                      <option key={cat2.subCategoryId}>
                        {cat2.subCategory}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='col-3'>
                  <div>
                    <strong>Severity:</strong>
                  </div>
                  <select
                    value={this.state.sev}
                    onChange={this.onEditSev}
                    className='form-select mt-2'
                  >
                    <option value='-1'>All</option>
                    <option value='0'>Debug</option>
                    <option value='1'>Informational</option>
                    <option value='2'>Warning</option>
                    <option value='3'>Error</option>
                  </select>
                </div>
                <div className='col-3'>
                  <div>
                    <strong>From:</strong>
                  </div>
                  <div>
                    <input
                      value={this.state.fromDate}
                      onChange={this.onEditFromDate}
                      className='form-control mt-2'
                      placeholder=''
                      type='date'
                    />
                    <input
                      className='form-control mt-2'
                      type='time'
                      value={this.state.fromTime}
                      onChange={this.onEditFromTime}
                    />
                  </div>
                </div>
                <div className='col-3'>
                  <div>
                    <strong>To:</strong>
                  </div>
                  <div>
                    <input
                      value={this.state.toDate}
                      onChange={this.onEditToDate}
                      className='form-control mt-2'
                      type='date'
                    />
                    <input
                      className='form-control mt-2'
                      type='time'
                      value={this.state.toTime}
                      onChange={this.onEditToTime}
                    />
                  </div>
                </div>
              </div>
              <button className='btn btn-primary mt-4' onClick={this.refresh}>
                <FontAwesomeIcon icon={solid('redo')} /> Refresh
              </button>
            </div>
          </div>
          <table className='table table-striped'>
            <thead>
              <tr>
                <th>Severity</th>
                <th>Message</th>
                <th>...</th>
                <th>Time</th>
                <th>Category</th>
              </tr>
            </thead>
            <tbody>
              {this.state.logs.map((log) => (
                <tr key={log.logId} style={{ maxHeight: '20px' }}>
                  <td>
                    {log.sevLevel === 1 && (
                      <span style={{ color: '#44C0EF' }}>
                        <FontAwesomeIcon icon={solid('info-circle')} />
                      </span>
                    )}
                    {log.sevLevel === 0 && (
                      <span style={{ color: '#5F5F5F' }}>
                        <FontAwesomeIcon icon={solid('bug')} />
                      </span>
                    )}
                    {log.sevLevel === 2 && (
                      <span style={{ color: '#E1CA22' }}>
                        <FontAwesomeIcon icon={solid('exclamation-triangle')} />
                      </span>
                    )}
                    {log.sevLevel === 3 && (
                      <span style={{ color: '#BD2E2E' }}>
                        <FontAwesomeIcon icon={solid('exclamation-circle')} />
                      </span>
                    )}
                  </td>
                  <td
                    style={{
                      maxWidth: '600px',
                      maxHeight: '20px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {log.message}
                  </td>
                  <td>
                    <button
                      className='btn btn-sm btn-info'
                      onClick={() => this.showNewMessage(log.message)}
                    >
                      <FontAwesomeIcon icon={solid('ellipsis')} />
                    </button>
                  </td>
                  <td>{this.convertDate(log.messageTime)}</td>
                  <td>
                    {log.category}:{log.subCategory}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {this.state.showMoveAssign && (
          <div className='td-dialogue-container'>
            <div className='td-dialogue card' style={{ width: '720px' }}>
              <div className='card-header'>
                Move and Assign
                <button
                  type='button'
                  className='btn-close'
                  onClick={this.toggleShowMoveAssign}
                />
              </div>
              <div
                className='card-body'
                style={{ height: '500px', padding: '40px', overflow: 'auto' }}
              >
                <label className='form-label'>Workflow step</label>
                <select
                  className='form-select'
                  value={this.state.moveAssignStepId}
                  onChange={this.editMoveAssignStep}
                >
                  <option value='-1'></option>
                  {this.state.currentDoc.destinations.map((dest) => (
                    <option key={dest.stepId} value={dest.stepId}>
                      {dest.stepName}
                    </option>
                  ))}
                </select>
                <label className='form-label mt-4'>Assign User(s)</label>
                <div className='d-flex flex-row'>
                  {this.state.moveAssignSelectedUsers.map((user) => (
                    <div key={user.userId} className='queueAssignOther mt-2'>
                      {user.userName}{' '}
                      <FontAwesomeIcon
                        style={{ cursor: 'pointer' }}
                        onClick={(e) =>
                          this.onClickRemoveMoveAssignUser(e, user)
                        }
                        icon={solid('times')}
                      />
                    </div>
                  ))}
                </div>
                <div id='moveContainerAssignUserText' className='autocomplete2'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Assign User...'
                    onFocus={this.editMoveAssignUser}
                    value={this.state.moveAssignUserSearch}
                    onBlur={this.moveAssignUserBlur}
                    onChange={this.editMoveAssignUser}
                  ></input>
                </div>
                {this.state.moveAssignStepId !== -1 && (
                  <button
                    className='btn btn-success mt-3'
                    onClick={this.moveAssignDoc}
                  >
                    <FontAwesomeIcon icon={solid('check')} /> OK
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
        {this.state.showPopup && (
          <div className='td-dialogue-container'>
            <div className='td-dialogue card' style={{ width: '720px' }}>
              <div className='card-header'>
                <button
                  type='button'
                  className='btn-close'
                  onClick={this.toggleShowMessage}
                />
              </div>
              <div
                className='card-body'
                style={{ height: '500px', padding: '40px', overflow: 'auto' }}
              >
                <p>{this.state.showMessageText}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default LogsView;
