import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';

const CHANGE_EVENT = 'docActionChange';
let _lastAction = '';
let _lastError = '';

class DocActionStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getLastAction() {
    return _lastAction;
  }

  getLastError() {
    return _lastError;
  }
}

const store = new DocActionStore();

Dispatcher.register((action) => {
  switch (action.actionType) {
    case actionTypes.DocActionProcess:
      _lastAction = action.Result.status;
      _lastError = action.Result.message;

      store.emitChange();
      break;

    default:
  }
});

export default store;
