import dispatcher from '../Dispatcher';
import actionTypes from './ActionTypes';
import axios from 'axios';
import * as Globals from '../Globals';

export function GetUploads() {
  axios
    .get(`${Globals.baseUrl}/UploadTracker/Get50Uploads`, {
      headers: { 'content-type': 'application/json; charset=utf-8' },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.UploadTrackerGet,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}

export function GetMoreUploads(maxId) {
  axios
    .post(
      `${Globals.baseUrl}/UploadTracker/Get50MoreUploads`,
      { uploadId: maxId },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.UploadTrackerGetMore,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}

export function GetOneUpload(uploadId) {
  axios
    .post(
      `${Globals.baseUrl}/UploadTracker/GetOneUpload`,
      {
        uploadId: uploadId,
      },
      {
        headers: { 'content-type': 'application/json; charset=utf-8' },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.UploadTrackerUpdateOne,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}

export function QueryUpload(query) {
  axios
    .post(`${Globals.baseUrl}/UploadTracker/QueryUploads`, query, {
      headers: { 'content-type': 'application/json; charset=utf-8' },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.UploadTrackerSearch,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}

export function QueryUploadGetMore(query) {
  
  axios
    .post(`${Globals.baseUrl}/UploadTracker/QueryUploadGetMore`, query, {
      headers: { 'content-type': 'application/json; charset=utf-8' },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.UploadTrackerGetMore,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}

export function AbortUpload(uploadId) {
  axios
    .post(`${Globals.baseUrl}/UploadTracker/AbortUpload`, uploadId, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.UploadTrackerUpdateOne,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}

export function GetUploadDetail(uploadId) {
  axios
    .post(
      `${Globals.baseUrl}/UploadTracker/GetUploadDetail`,

      uploadId,

      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.UploadTrackerGetDetails,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}

export function ReprocessDoc(uploadId) {
  axios
    .post(`${Globals.baseUrl}/UploadTracker/ReprocessDoc`, uploadId, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        console.log('reprocessed');
        console.log(res);
        dispatcher.dispatch({
          actionType: actionTypes.UploadTrackerReprocess,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    });
}

export function DownloadFail(uploadId, originalName) {
  axios
    .get(
      `${Globals.baseUrl}/UploadTracker/DownloadFail/${uploadId.toString()}`,

      //uploadId,

      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
        responseType: 'blob',
      }
    )
    .then((res) => {
      if (res.status === 200) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', originalName);
        document.body.appendChild(link);
        link.click();
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}
