import * as React from 'react';
import { createBrowserHistory } from 'history';
import ActiveDocumentSidebar from './ActiveDocumentSidebar';
import DocumentList from '../DocumentList/DocumentList';
import ADStore from '../../../stores/ActiveDocumentStore';
import { GetWorkflowFromStep } from '../../../actions/WorkflowActions';
import {
  GetADStep,
  GetBP,
  GetBPPermissions,
} from '../../../actions/ActiveDocumentActions';
import actionTypes from '../../../actions/ActionTypes';

const customHistory = createBrowserHistory();

class ActiveDocuments extends React.Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      currentDocs: [],
      docCount: 0,
      columns: [],
      currentFormType: 0,
      scrollWidth: window.innerWidth - 320,
      scrollHeight: window.innerHeight - 58,
      //colSizes: [180, 150, 150, 150, 150, 150],
      //colOffsets: [0, 180, 330, 480, 630, 780],
      downloadConfirmation: false,
      formatDocumentIdAsTDLink: false,
      currentWfName: '',
      checkAll: false,
      activeStep: null,
      activeBP: null,
      currentOffset: 0,
      showComplete: false,
      lastCheckedDocId: 0,
    };

    this.LoadStep = this.LoadStep.bind(this);
    this.LoadBP = this.LoadBP.bind(this);
    this.onDocChange = this.onDocChange.bind(this);
    //this.onWfChange = this.onWfChange.bind(this);
    this.checkParams = this.checkParams.bind(this);
    //this.toggleDownloadConfirmation = this.toggleDownloadConfirmation.bind(this);
    //this.onExcelDownloadConfirmation = this.onExcelDownloadConfirmation.bind(this);
    this.toggleCheckAll = this.toggleCheckAll.bind(this);
    this.toggleDocument = this.toggleDocument.bind(this);
    this.toggleCheckAll2 = this.toggleCheckAll2.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);
    this.ChangeComplete = this.ChangeComplete.bind(this);
  }

  componentDidMount() {
    ADStore.addChangeListener(this.onDocChange);
    window.onresize = this.onWindowResize;
    this.checkParams();
    document.title = 'TrinDocs - Active Documents'
  }

  componentWillUnmount() {
    ADStore.removeChangeListener(this.onDocChange);
  }

  LoadStep(id, bpId, pushHist) {

    if(pushHist === undefined || pushHist !== false)
    {
       customHistory.push(
       `/ActiveDocuments?Step=${id.toString()}&BP=${bpId.toString()}`
     );
    }

    let defaultPages = localStorage.getItem('DefaultParams');
    if (defaultPages === null) {
      defaultPages = {
        ActiveDocuments: { BP: null, Step: null },
        Inbox: { BP: null, Step: null },
        Search: { BP: null, Step: null },
      };
    } else defaultPages = JSON.parse(localStorage.getItem('DefaultParams'));

    defaultPages.ActiveDocuments.BP = bpId;
    defaultPages.ActiveDocuments.Step = id;
    localStorage.setItem('DefaultParams', JSON.stringify(defaultPages));
    GetBPPermissions(bpId);
    GetADStep(id.toString(), bpId.toString(), 'user', 'asc', 0, this.state.showComplete);
    GetWorkflowFromStep(id);
  }

  LoadBP(id, completed) {
    customHistory.push(`/ActiveDocuments?BP=${id.toString()}`);
    let defaultPages = localStorage.getItem('DefaultParams');
    if (defaultPages === null) {
      defaultPages = {
        ActiveDocuments: { BP: null, Step: null },
        Inbox: { BP: null, Step: null },
        Search: { BP: null, Step: null },
      };
    } else defaultPages = JSON.parse(localStorage.getItem('DefaultParams'));

    defaultPages.ActiveDocuments.BP = id;
    defaultPages.ActiveDocuments.Step = null;
    localStorage.setItem('DefaultParams', JSON.stringify(defaultPages));
    var completedDocsInput = document.getElementById('showCompletedDocs');
    GetBPPermissions(id);
    console.log('GetBP');
    GetBP(id, 'user', 'asc', this.state.showComplete, 0);
  }

  ChangeComplete(show){
    this.setState({showComplete: show});
  }

  onDocChange() {
    var lastChange = ADStore.lastChange();

    if (lastChange === actionTypes.ADGetStep) {
      let docs = ADStore.getDocs();
      let count = ADStore.getDocCount();
      let cols = ADStore.getColumns();
      let ft = ADStore.getFormTypeId();
      let bp = ADStore.getBPName();
      let step = ADStore.getStepName();
      let offset = ADStore.getOffset();
      let mode = 'ACTIVE_DOCUMENTS';

      if (docs.length > 0 && docs[0].status === 5) {
        mode = 'IMAGE_REVIEW';
      }

      let loadingMore = localStorage.getItem('loadingMore');

      if (loadingMore === 'true') {
        let currentDocArray = this.state.currentDocs;
        let toReturn = currentDocArray.concat(docs);
        console.log(`FormTypeId: ${ft}`);
        this.setState({
          currentDocs: toReturn,
          docCount: count,
          columns: cols,
          currentFormType: ft,
          gridMode: mode,
          activeStep: step,
          activeBP: bp,
          currentOffset: offset,
        });

        localStorage.setItem('loadingMore', false);
      } else {
        console.log(`FormTypeId: ${ft}`);
        this.setState({
          currentDocs: docs,
          docCount: count,
          columns: cols,
          currentFormType: ft,
          gridMode: mode,
          activeStep: step,
          activeBP: bp,
          currentOffset: offset,
        });
      }
    }
  }

  onWindowResize(e) {
    this.setState({
      scrollWidth: window.innerWidth - 320,
      scrollHeight: window.innerHeight - 58,
    });
  }

  checkParams() {
    let params = new URLSearchParams(window.location.search);
    var stepParam = params.get('Step');
    var bpParam = params.get('BP');

    if (bpParam) {
      GetBPPermissions(parseInt(bpParam, 10));
    }

    if (stepParam) {
      this.LoadStep(stepParam, bpParam, false);
    }
    //needs a load bp
    if (!stepParam && bpParam) {
      this.LoadBP(bpParam);
    }
  }

  toggleCheckAll() {
    //dont do anything
    return;
    let tempCheckAll = null;
    if (this.state.checkAll === null) {
      tempCheckAll = true;
      this.setState({ checkAll: true });
    } else {
      tempCheckAll = !this.state.checkAll;
      this.setState({ checkAll: tempCheckAll });
    }
    let checkCount = 0;
    let docs = this.state.currentDocs;
    docs.forEach((d) => {
      d.isChecked = tempCheckAll;
      if (tempCheckAll) checkCount++;
    });
    this.setState({ currentDocs: docs, checkCount: checkCount });

    // set same check to all others.
  }

  toggleCheckAll2() {
    let tempCheckAll = null;
    if (this.state.checkAll === false) {
      tempCheckAll = true;
      this.setState({ checkAll: true });
    } else {
      tempCheckAll = !this.state.checkAll;
      this.setState({ checkAll: tempCheckAll });
    }
    let checkCount = 0;
    let docs = this.state.currentDocs;
    docs.forEach((d) => {
      d.isChecked = tempCheckAll;
      if (tempCheckAll) checkCount++;
    });
    this.setState({ currentDocs: docs, checkCount: checkCount });

    // set same check to all others.
  }
  toggleDocument(docId, e) {



    let docs = this.state.currentDocs;
    let doc = docs.find((d) => d.documentId === docId);
    if (doc) {

      if(e.nativeEvent.shiftKey && this.state.lastCheckedDocId !== 0 && !doc.isChecked)
      {
        let doc1 = docs.find((d) => d.documentId === this.state.lastCheckedDocId);
        let doc2 = docs.find((d) => d.documentId == docId);
        let idx1 = docs.indexOf(doc1);
        let idx2 = docs.indexOf(doc2);
        let a = Math.min(idx1, idx2);
        let b = Math.max(idx1, idx2);
        for(let i = a; i <= b; i++)
        {
          docs[i].isChecked = true;
        }
      } else {
      doc.isChecked = !doc.isChecked;
      if(doc.isChecked && !e.nativeEvent.shiftKey)
      {
        this.setState({lastCheckedDocId: docId});
      }
      let checkCount = 0;


      if (!doc.isChecked) {
        this.setState({ checkAll: false });
      }
    }
    let checkCount = 0;
    docs.forEach((d) => {
      if (d.isChecked) checkCount++;
    });

      this.setState({ currentDocs: docs, checkCount: checkCount });
    }
  }


  render() {
    return (
      <div className='sidebarLayout d-flex flex-row flex-fill'>
        <ActiveDocumentSidebar
          LoadStepClick={this.LoadStep}
          LoadBPClick={this.LoadBP}
          ChangeComplete={this.ChangeComplete}
        />
        <DocumentList
          mode='ACTIVE_DOCUMENTS'
          documents={this.state.currentDocs}
          FormTypeId={this.state.currentFormType}
          Reload={this.checkParams}
          scrollWidth={this.state.scrollWidth}
          scrollHeight={this.state.scrollHeight}
          toggleCheckAll2={this.toggleCheckAll2}
          toggleCheckAll={this.toggleCheckAll}
          bpName={this.state.activeBP}
          stepName={this.state.activeStep}
          docCount={this.state.docCount}
          toggleDocument={this.toggleDocument}
          checkAll={this.state.checkAll}
          ExportToExcel={this.onExcelDownloadConfirmation}
          offset={this.state.currentOffset}
          checkCount={this.state.checkCount}
        />
      </div>
    );
  }
}

export default ActiveDocuments;
