import dispatcher from '../Dispatcher';
import actionTypes from './ActionTypes';
import axios from 'axios';
import * as Globals from '../Globals';
import { Logout } from './LoginActions';
//import { CompanionRecord } from '../Models/Admin/CompanionSchema';
import { PostAlert } from './AlertActions';
export function GetWorkflowList() {
  axios
    .get(`${Globals.baseUrl}/Workflow/GetWorkflows`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.WorkflowGetWorkflows,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        Logout();
      }
    })
    .catch((res) => {
      if (res.response.status === 401) {
        console.log('unauthorized');
        Logout();
      }
      // Logout();
    });
}

export function GetAllowedFormTypes(id) {
  axios
    .get(`${Globals.baseUrl}/Workflow/GetAllowedFormTypes/${id}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.WorkflowAllowedFormTypes,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        Logout();
      }
    })
    .catch((res) => {
      console.log('error');
      console.log(res);
      // Logout();
    });
}

export function GetFormTypesAndTemplatesForWorkflow(id) {
  axios
    .get(
      `${Globals.baseUrl}/Workflow/GetFormTypesAndTemplatesForWorkflow/${id}`,
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.WorkflowFTAndTemp,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        Logout();
      }
    })
    .catch((res) => {
      console.log('error');
      console.log(res);
      // Logout();
    });
}

export function UpdateWorkflow(wf, layout) {
  console.log(wf);
  wf.routes.forEach((r) => {
    r.fieldChoices = [];
    r.templateChoices = [];
    r.formTypeChoices = [];
  });
  wf.steps.forEach((s) => {
    s.possibleAssignees = [];
  });
  axios
    .post(
      `${Globals.baseUrl}/Workflow/UpdateWorkflow`,
      { update: wf, layout: layout },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.WorkflowUpdate,
          Result: res.data,
        });
        PostAlert('success', 'Workflow updated');
      } else if (res.status === 400 || res.status === 401) {
        Logout();
      }
    })
    .catch((res) => {
      console.log('error');
      console.log(res);
      // Logout();
    });
}

export function GetWorkflow(wfId) {
  axios
    .get(`${Globals.baseUrl}/Workflow/GetWorkflow/${wfId}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.WorkflowGetOne,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        Logout();
      }
    })
    .catch((res) => {
      console.log('error');
      console.log(res);
      // Logout();
    });
}

export function GetWorkflowFromStep(stepId) {
  axios
    .get(`${Globals.baseUrl}/Workflow/GetWorkflowFromStep/${stepId}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.WorkflowGetFromStep,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        Logout();
      }
    })
    .catch((res) => {
      console.log('error');
      console.log(res);
      // Logout();
    });
}
