import dispatcher from '../Dispatcher';
import actionTypes from './ActionTypes';
import axios from 'axios';
import * as Globals from '../Globals';
import { Logout } from './LoginActions';

export function GetUserInRoleForUser(userId) {
   axios
      .get(`${Globals.baseUrl}/UserInRole/GetUserInRoleForUser/${userId}`, {
         headers: { 'content-type': 'application/json; charset=utf-8', 'Authorization': `Bearer ${Globals.getJwtToken()}`  },
      })
      .then((res) => {

         if (res.status === 200) {
            dispatcher.dispatch({

               actionType: actionTypes.UserInRoleGetList,
               Result: res.data,
            });
         } else if (res.status === 400 || res.status === 401) {
            Logout();
         }
      })
      .catch((res) => {
        //Logout();
      });
}

