import dispatcher from '../Dispatcher';
import actionTypes from './ActionTypes';
import axios from 'axios';
import * as Globals from '../Globals';
import { Logout } from './LoginActions';
import { PostAlert } from './AlertActions';
//import { CompanionRecord } from '../Models/Admin/CompanionSchema';

export function GetBPList() {
  axios
    .get(`${Globals.baseUrl}/BusinessProcess/GetBPList`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.BPGetList,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        Logout();
      }
    })
    .catch((res) => {
      console.log('error');
      console.log(res.response);
      // Logout();
    });
}

export function GetBP(bpId) {
  axios
    .get(`${Globals.baseUrl}/BusinessProcess/GetBP/${bpId}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.BPGetOne,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        Logout();
      }
    })
    .catch((res) => {
      console.log('error');
      console.log(res);
      // Logout();
    });
}

export function UpdateBP(bpId, bpName, orgUnit) {
  axios
    .post(
      `${Globals.baseUrl}/BusinessProcess/UpdateBP`,
      { bpId: bpId, bpName: bpName, orgUnit: orgUnit },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.BPUpdate,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        Logout();
      }
    })
    .catch((res) => {
      console.log('error');
      console.log(res);
      // Logout();
    });
}

export function GetBPListByFormType(formTypeId) {
  axios
    .get(
      `${Globals.baseUrl}/BusinessProcess/GetBPListByFormType/${formTypeId}`,
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.BPGetListByFormType,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        Logout();
      }
    })
    .catch((res) => {
      console.log('error');
      console.log(res);
      // Logout();
    });
}

export function ExportBusinessProcess(id, name) {
  axios
    .get(`${Globals.baseUrl}/BusinessProcess/ExportBusinessProcess/${id}`,  {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.ExportBP,
          Result: res.data,
        });
        
        var link = document.createElement('a');
        link.setAttribute('download', '');
        link.href =  URL.createObjectURL(
          new Blob([JSON.stringify(res.data)], {type:"application/json"})
        );
        link.download = 'TrinDocs Bp - ' + name + '.json';
        document.body.appendChild(link);
        link.click();
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
      dispatcher.dispatch({
        actionType: actionTypes.ExportBP,
        Result: result,
      });
    });
}

export function ImportBP(name, json) {
  axios
    .post(
      `${Globals.baseUrl}/BusinessProcess/ImportBP`,
      { name: name, json: json },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        if (res.data.status === 'success') {
          PostAlert('success', res.data.updateMessage);
        }
        if (res.data.status === 'fail') {
          PostAlert('error', res.data.updateMessage);
        }
        dispatcher.dispatch({
          actionType: actionTypes.ImportBP,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        Logout();
      }
    })
    .catch((res) => {
      console.log('error');
      console.log(res);
      // Logout();
    });
}