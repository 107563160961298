import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';


const CHANGE_EVENT = 'uploadTrackerChange';
let _currentUploads = [];
let _currentUploadInfo = undefined;
let _currentTracker = undefined;
let _currentOCRInfo = undefined;
let _lastAction = '';

class UploadTrackerStore extends EventEmitter {
   addChangeListener(callback) {
      this.on(CHANGE_EVENT, callback);
   }

   removeChangeListener(callback) {
      this.removeListener(CHANGE_EVENT, callback);
   }

   emitChange() {
      this.emit(CHANGE_EVENT);
   }

   getCurrentUploads() {
      return _currentUploads;
   }

   getCurrentUploadInfo() {
      return _currentUploadInfo;
   }

   getCurrentTracker() {
      return _currentTracker;
   }

   getLastAction() {
      return _lastAction;
   }

   getCurrentOCRInfo() {
      return _currentOCRInfo;
   }
}

const store = new UploadTrackerStore();

Dispatcher.register((action) => {
   switch (action.actionType) {
      case actionTypes.UploadTrackerGet:
        _currentUploads = action.Result.uploads;
        _lastAction = 'bulk';
         store.emitChange();
         break;
      case actionTypes.UploadTrackerSearch:
         _lastAction = 'bulk';


         _currentUploads = action.Result.uploads;
         store.emitChange();
         break;
      case actionTypes.UploadTrackerGetMore:
         _lastAction = 'bulk';
         _currentUploads = _currentUploads.concat(action.Result.uploads);
         store.emitChange();
          break;
      case actionTypes.UploadTrackerUpdateOne:
         _lastAction = 'bulk';
          let idx = _currentUploads.findIndex(f => f.uploadId === action.Result.uploadId);
          if (idx !== -1) {
              _currentUploads[idx] = action.Result;
              store.emitChange();


          }
          break;
      case actionTypes.UploadTrackerGetDetails:
         _lastAction = 'detail';
         _currentUploadInfo = action.Result.info;
         _currentTracker = action.Result.tracker;
         let ocrInfo = action.Result.ocrInfo;
         let newInfo = {};
         console.log(ocrInfo);
         if(ocrInfo !== null && ocrInfo !== undefined && ocrInfo.length > 0)
         {
            var iis = ocrInfo.find((o) => o.propName === 'IntakeStrategy');
            if(iis !== undefined) newInfo.intakeStrategy = iis.value;
            else newInfo.intakeStrategy = 'None';

            var ocrType = ocrInfo.find((o) => o.propName === 'OCRType');
            if(ocrType !== undefined) newInfo.ocrType = ocrType.value;
            else newInfo.ocrType = 'N/A';

            var ocrQuality = ocrInfo.find((o) => o.propName === 'OCRQuality');
            if(ocrQuality !== undefined) newInfo.ocrQuality = ocrQuality.value;
            else newInfo.ocrQuality = 'N/A';

            var barcodes = ocrInfo.find((o) => o.propName === 'Barcodes');
            if(barcodes !== undefined) newInfo.barcodes = barcodes.value;
            else newInfo.barcodes = 'No';
         }
         console.log(newInfo);
         _currentOCRInfo = newInfo;

         store.emitChange();
         break;
      case actionTypes.UploadTrackerReprocess:
         _lastAction = 'reprocess';
         store.emitChange();
          break;
      default:
   }
});

export default store;
