import { Component } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './App.scss';
import './styles/activeDocuments.css';
import './styles/templateEditor.css';

import LoginView from './Views/Login';
import AppRoot from './Views/Root';
import LoginStore from './stores/LoginStore';
import { LoginViaTd6Token } from './actions/LoginActions';
import { RaiseHotKeyEvent } from './actions/HotKeyActions';

/*



              ##########################
             ##########################################################
            #################################################################
           ###################################################################
           #########
           #########                                             #####################
           #########                 ###################################################
           #########     ################################################################
           #########    ##################################################################
           #########    ##########                                               #########
           #########    #####                                             ####   #########
           #########    #####                                             ####   #########
           #########    #####                                             ####   #########
           #########    #####                                             ####   #########
           #########    #####     ###################################     ####   #########
           #########    #####     ###################################     ####   #########
           #########    #####     ###################################     ####   #########
           #########    #####                                             ####   #########
           #########    #####     ###################################     #####  #########
           #########    #####     ###################################     #####  #########
           #########    #####     ###################################     #####  #########
           #########    #####                                             #####  #########
           #########    #####     ########################                #####  #########
           #########    #####     ########################                #####  #########
           #########    #####     ########################                #####  #########
           #########    #####                                             #####  #########
           #########    #####                                             #####  #########
           #########    #####                                             #####  #########
           #########    ######                                           ######  #########
           #########    #######          #####################################   #########
           #########     ####################################################    #########
           #########      ########################################               #########
           ##########        #########                                           #########
            ############                                                         #########
            ##########################                                           #########
              ###########################################                        #########
                    ########################################################     #########
                                   #######################################################
                                                  ########################################
                                                                 ########################
                                                                               ######

*/
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userLoggedIn: false,
      userName: '',
      userLicenseLevel: '',
    };

    this.onLoginStoreChange = this.onLoginStoreChange.bind(this);
    this.checkForLogin = this.checkForLogin.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
  }

  componentDidMount() {
    LoginStore.addChangeListener(this.onLoginStoreChange);
    this.checkForLogin();
    document.addEventListener('keyup', this.onKeyPress, true);
  }

  componentWillUnmount() {
    LoginStore.removeChangeListener(this.onLoginStoreChange);
    document.removeEventListener('keyup', this.onKeyPress);
  }

  checkForLogin() {
    let token = window.localStorage.getItem('td6Token');
    if (token) {
      LoginViaTd6Token(token);
    }
  }

  onKeyPress(e)
  {
    if(e.key === 'F2')
    {
      //console.log('F2');
      RaiseHotKeyEvent('F2');

    }
    if(e.key === 'F4')
    {
      //console.log('F4');
      RaiseHotKeyEvent('F4');

    }
    if(e.key === 'F8')
    {
      //console.log('F8');

      RaiseHotKeyEvent('F8');
    }
  }

  onLoginStoreChange() {
    let action = LoginStore.getLastAction();
    switch (action) {
      case 'Login':
      case 'Logout':
        let isLoggedIn = LoginStore.getLoggedInStatus();
        if (isLoggedIn === true) {
          // route to app page
          this.setState({ userLoggedIn: true });
        } else {
          if (action === 'Logout') {
            let link = document.getElementById('logOutLink');
            link.click();
          }
          this.setState({ userLoggedIn: false });
        }
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <div className='App Light' >
        <Router>
          {this.state.userLoggedIn && (
            <Routes>
              <Route path='*' element={<AppRoot />} />
            </Routes>
          )}
          {!this.state.userLoggedIn && (
            <Routes>
              <Route path='*' element={<LoginView />} />
            </Routes>
          )}
        </Router>
      </div>
    );
  }
}

export default App;
