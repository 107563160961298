import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  GetDocuments,
  GetMoreDocuments,
} from '../../../actions/DocumentHistoryActions';
import { Link } from 'react-router-dom';
import { GetUploadDetail } from '../../../actions/UploadTrackerActions';
import { GetBPList } from '../../../actions/BPActions';
import { GetTemplateList } from '../../../actions/TemplateActions';
import DocumentUploadHistStore from '../../../stores/DocumentUploadHistStore';
import UploadTrackerStore from '../../../stores/UploadTrackerStore';
import LegacyBPStore from '../../../stores/BPStore';
import TemplateStore from '../../../stores/TemplateStore';

class DocumentUploadHistoryView extends React.Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      selectedSource: 'ALL',
      selectedStatus: 'ALL',
      selectedBP: -1,
      selectedTemplate: -1,
      showBusinessProcess: false,
      showUploadTime: false,
      showFileName: false,
      searchText: '',
      showDialog: false,
      currentInfo: undefined,
      docs: [],
      legacyBPs: [],
      allTemplates: [],
      filteredTemplates: [],
      showUpTimeCol: true,
      showPagesCol: true,
      showSplitCol: true,
      showBPCol: false,
      showTemplateCol: true,
      showRecvCol: false,
      showDocSrcCol: false,
      showFieldSelect: false,
      colCount: 11,
      lastSearch: undefined,
      maxFetch: 0,
      filterStartDate: '',
      filterEndDate: '',
      filterStartTime: '',
      filterEndTime: '',
      linkPath: '',
      bottomHeight: window.innerHeight - 400,
    };

    // bindings
    this.onChangeSourceFilter = this.onChangeSourceFilter.bind(this);
    this.onChangeStatusFilter = this.onChangeStatusFilter.bind(this);
    this.onChangeShowBusinessProcess =
      this.onChangeShowBusinessProcess.bind(this);
    this.onChangeShowUploadTime = this.onChangeShowUploadTime.bind(this);
    this.onChangeShowFileName = this.onChangeShowFileName.bind(this);
    this.onChangeBPFilter = this.onChangeBPFilter.bind(this);
    this.onQuerySearch = this.onQuerySearch.bind(this);
    this.onRefresh = this.onRefresh.bind(this);
    this.onChangeSearchText = this.onChangeSearchText.bind(this);
    this.onDocStoreChange = this.onDocStoreChange.bind(this);
    this.onUploadLink = this.onUploadLink.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.onCloseDialog = this.onCloseDialog.bind(this);
    this.onUploadUpdate = this.onUploadUpdate.bind(this);
    this.onLegacyBPUpdate = this.onLegacyBPUpdate.bind(this);
    this.onTemplateStoreUpdate = this.onTemplateStoreUpdate.bind(this);
    this.toggleCol = this.toggleCol.bind(this);
    this.toggleFieldSelect = this.toggleFieldSelect.bind(this);
    this.filterTemplates = this.filterTemplates.bind(this);
    this.onChangeTemplateFilter = this.onChangeTemplateFilter.bind(this);
    this.showMore = this.showMore.bind(this);
    this.updateTimeFilters = this.updateTimeFilters.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);
  }

  componentDidMount() {
    DocumentUploadHistStore.addChangeListener(this.onDocStoreChange);
    UploadTrackerStore.addChangeListener(this.onUploadUpdate);
    LegacyBPStore.addChangeListener(this.onLegacyBPUpdate);
    TemplateStore.addChangeListener(this.onTemplateStoreUpdate);
    window.onresize = this.onWindowResize;
    GetBPList();
    GetTemplateList();
    this.onQuerySearch();
    this.onWindowResize();
    document.title = 'TrinDocs - Document Uploads'
  }

  componentWillUnmount() {
    DocumentUploadHistStore.removeChangeListener(this.onDocStoreChange);
    UploadTrackerStore.removeChangeListener(this.onUploadUpdate);
    LegacyBPStore.removeChangeListener(this.onLegacyBPUpdate);
    TemplateStore.removeChangeListener(this.onTemplateStoreUpdate);
  }

  onWindowResize(e) {

    let bh = window.innerHeight - 400;

    let top = document.getElementById('docHistorySearchPanel');

    let topHeight = top.offsetHeight;
    bh = window.innerHeight - topHeight - 140;
    window.removeEventListener('resize', this.onWindowResize);
    this.setState({bottomHeight: bh});
    console.log(window.innerHeight);
    console.log(topHeight);
  }

  onUploadUpdate() {
    let lastAction = UploadTrackerStore.getLastAction();
    if (lastAction === 'detail') {
      let detail = UploadTrackerStore.getCurrentUploadInfo();
      this.setState({ currentInfo: detail, showDialog: true });
    }
  }

  onLegacyBPUpdate() {
    console.log('log');
    let bps = LegacyBPStore.getBPList();
    this.setState({ legacyBPs: bps });
  }

  filterTemplates() {
    let filter = [];
    let selectBP = this.state.legacyBPs.find(
      (f) => f.bpId === this.state.selectedBP
    );

    let temps = this.state.allTemplates;

    temps.forEach((ft) => {
      if (ft.formTypeId === selectBP.formTypeId) {
        console.log(ft);
        console.log(ft.templates);
        ft.templates.forEach((t) => {
          filter.push(t);
        });
      }
    });
    this.setState({ filteredTemplates: filter, selectedTemplate: -1 });
  }

  onTemplateStoreUpdate() {
    let templates = TemplateStore.getTemplateList();
    this.setState({ allTemplates: templates }, this.updateBPNames);
  }

  onChangeSourceFilter(e) {
    console.log(e.target.value);
    this.setState({ selectedSource: e.target.value });
  }

  onChangeStatusFilter(e) {
    console.log(e.target.value);
    this.setState({ selectedStatus: e.target.value });
  }

  // Store updates
  onDocStoreChange() {
    console.log('Documents');
    let newDocs = DocumentUploadHistStore.getCurrentDocuments();
    console.log(newDocs);
    this.setState({ docs: newDocs }, () => {
      this.updateBPNames();
    });
  }

  onChangeShowBusinessProcess(e) {
    this.setState({ showBusinessProcess: e.target.checked }, this.onWindowResize);
  }

  onChangeShowUploadTime(e) {
    this.setState({ showUploadTime: e.target.checked }, this.onWindowResize);
  }

  onChangeShowFileName(e) {
    this.setState({ showFileName: e.target.checked }, this.onWindowResize);
  }

  onChangeBPFilter(e) {
    console.log('change bp filter');
    this.setState(
      { selectedBP: parseInt(e.target.value, 10) },
      this.filterTemplates
    );
  }

  onChangeTemplateFilter(e) {
    console.log('change template');
    console.log(e.target.value);
    this.setState({ selectedTemplate: parseInt(e.target.value, 10) });
  }

  onChangeSearchText(e) {
    this.setState({ searchText: e.target.value });
  }

  onSearch() {}

  onQuerySearch() {
    console.log('Search');
    let search = {};
    search.source = this.state.selectedSource;
    search.status = this.state.selectedStatus;
    if (this.state.showBusinessProcess) {
      search.ftpId = this.state.selectedBP;
      search.templateId = this.state.selectedTemplate;
    } else {
      search.ftpId = -1;
      search.templateId = -1;
    }
    if (this.state.showUploadTime) {
      search.fromDate = this.state.filterStartDate;
      search.toDate = this.state.filterEndDate;
      search.fromTime = this.state.filterStartTime;
      search.toTime = this.state.filterEndTime;
    }

    search.fetchSize = 150;
    if (this.state.showFileName) {
      search.searchText = this.state.searchText;
    }
    GetDocuments(search);
    this.setState({ lastSearch: search, maxFetch: 50 });
  }

  toggleFieldSelect() {
    let newState = !this.state.showFieldSelect;
    this.setState({ showFieldSelect: newState });
  }

  /*
   updateColCount() {
      let colCount = 4;
      if (this.state.showBPCol) colCount++;
      if (this.state.showUpTimeCol) colCount++;
      if (this.state.showTemplateCol) colCount++;
      if (this.state.showPagesCol) colCount++;
      if (this.state.showSplitCol) colCount++;

   }
*/
  onRefresh() {
    let search = this.state.lastSearch;
    search.fetchSize = this.state.maxFetch;
    search.startIndex = 0;
    GetDocuments(search);
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      console.log(' you pressed enter');
      this.onQuerySearch();
    }
  }
  // yes
  onDocLink(e, docId) {
    console.log('docLink');
    console.log(docId);
    try {
      if (e.ctrlKey) {
        window.open('/Document/' + docId.toString());
      } else {
        this.routeTo('/Document/' + docId.toString());
      }
    } catch {}
  }

  onUploadLink(e, uploadId) {
    console.log(uploadId);
    GetUploadDetail(uploadId);
  }

  RouteToDoc(docId) {
    this.routeTo('/Document/' + docId.toString());
  }

  routeTo(path) {
    this.setState({ linkPath: path }, () => {
      var link = document.getElementById('nextDocLink');
      console.log(this.state.linkPath);
      link.click();
    });
  }

  onCloseDialog() {
    this.setState({ showDialog: false });
  }

  showMore() {
    if (this.state.lastSearch) {
      let search = this.state.lastSearch;
      search.startIndex = Math.min.apply(
        Math,
        this.state.docs.map((d) => d.docHistId)
      );
      search.fetchSize = 150;
      GetMoreDocuments(search);
      this.setState({ maxFetch: this.state.maxFetch + 50 });
    }
  }

  toggleCol(colId, e) {
    switch (colId) {
      case 'Up':
        this.setState({ showUpTimeCol: e.target.checked });
        break;
      case 'Pages':
        this.setState({ showPagesCol: e.target.checked });
        break;
      case 'Split':
        this.setState({ showSplitCol: e.target.checked });
        break;
      case 'BP':
        this.setState({ showBPCol: e.target.checked });
        break;
      case 'Template':
        this.setState({ showTemplateCol: e.target.checked });
        break;
      case 'Recv':
        this.setState({ showRecvCol: e.target.checked });
        break;
      case 'DocSrc':
        this.setState({ showDocSrcCol: e.target.checked });
        break;
      default:
    }
  }

  render2() {
    return (
      <div
        className='h-100 d-flex flex-column'
        style={{ backgroundColor: '#efefef' }}
      >
        <div className='container' style={{ backgroundColor: 'blue' }}>
          Hello
        </div>
        <div className='flex-fill' style={{ backgroundColor: 'yellow' }}>
          TrinDocs
        </div>
      </div>
    );
  }

  updateTimeFilters(e, set) {
    let text = e.target.value;
    if (set === 'startDate') {
      this.setState({ filterStartDate: text });
    }
    if (set === 'startTime') {
      this.setState({ filterStartTime: text });
    }
    if (set === 'endDate') {
      this.setState({ filterEndDate: text });
    }
    if (set === 'endTime') {
      this.setState({ filterEndTime: text });
    }
  }

  updateBPNames() {
    let uploads = this.state.docs;
    console.log('updateBPNames');
    uploads.forEach((d) => {
      if (d.ftpName === null || d.ftpName === undefined || d.ftpName === '') {
        let lookup = this.state.legacyBPs.find(
          (f) => f.formTypeProductId === d.ftpId
        );
        if (lookup) {
          d.ftpName = lookup.formTypeName + ' - ' + lookup.workflowName;
        }
      }

      if (
        d.templateName == null ||
        d.templateName === undefined ||
        d.templateName === ''
      ) {
        let lookup = this.state.allTemplates.find(
          (t) => t.templateId === d.templateId
        );
        if (lookup) {
          d.templateName = lookup.name;
        }
      }
      //d.ftpName = this.state.legacyBPs.find(f => f.formTypeProductId === d.ftpId).
    });
    this.setState({ docs: uploads });
  }

  render() {
    return (
      <div
        className='h-100  d-flex flex-column'
        style={{ backgroundColor: '#efefef', width: '100%' }}

      >
        <div
          className='container'
          style={{
            backgroundColor: '#efefef',
            padding: '10px',
          }}

        >
          <div
            className='card'
            style={{
              maxWidth: '750px',
            }}
            id='docHistorySearchPanel'
          >
            <div className='card-header'>
              <h4>Document Upload History</h4>
            </div>
            <div className='card-body'>
              <div className='row' style={{ marginBottom: '8px' }}>
                <div className='col'>
                  <label className='form-label'>Source</label>
                  <select
                    className='form-select'
                    value={this.state.selectedSource}
                    onChange={this.onChangeSourceFilter}
                  >
                    <option value='ALL'>All</option>
                    <option value='IA'>InboundAutomation</option>
                    <option value='WEB'>Web Upload</option>
                    <option value='DIR'>File Watch</option>
                    <option value='IR'>Image Review</option>
                    <option value='FEED'>Direct feed</option>
                  </select>
                </div>
                <div className='col'>
                  <label className='form-label'>Status</label>
                  <select
                    className='form-select'
                    value={this.state.selectedStatus}
                    onChange={this.onChangeStatusFilter}
                  >
                    <option value='ALL'>All</option>
                    <option value='REG'>Registered</option>
                    <option value='REJ'>Rejected</option>
                  </select>
                </div>
              </div>
              <div>
                <label className='form-label' style={{ marginRight: '12px' }}>
                  Search By:
                </label>
                <div
                  className='form-check form-switch form-check-inline'
                  style={{ marginTop: '10px' }}
                >
                  <input
                    className='form-check-input'
                    type='checkbox'
                    id='switchShowBP'
                    onChange={this.onChangeShowBusinessProcess}
                    checked={this.state.showBusinessProcess}
                  />
                  <label className='form-check-label' htmlFor='switchShowBP'>
                    Business Process
                  </label>
                </div>

                <div
                  className='form-check form-switch form-check-inline'
                  style={{ marginTop: '10px' }}
                >
                  <input
                    className='form-check-input'
                    type='checkbox'
                    id='switchShowUploadDtime'
                    onChange={this.onChangeShowUploadTime}
                    checked={this.state.showUploadTime}
                  />
                  <label
                    className='form-check-label'
                    htmlFor='switchShowUploadDtime'
                  >
                    Upload Date/Time
                  </label>
                </div>

                <div
                  className='form-check form-switch form-check-inline'
                  style={{ marginTop: '10px' }}
                >
                  <input
                    className='form-check-input'
                    type='checkbox'
                    id='switchShowFileName'
                    onChange={this.onChangeShowFileName}
                    checked={this.state.showFileName}
                  />
                  <label
                    className='form-check-label'
                    htmlFor='switchShowFileName'
                  >
                    Search Text
                  </label>
                </div>
              </div>
              <hr />
              {this.state.showBusinessProcess && (
                <div className='row' style={{ marginBottom: '8px' }}>
                  <div className='col'>
                    <label className='form-label'>Business Process</label>
                    <select
                      className='form-select'
                      value={this.state.selectedBP}
                      onChange={this.onChangeBPFilter}
                    >
                      <option value='-1'>All</option>
                      {this.state.legacyBPs.map((bp) => (
                        <option key={bp.bpId} value={bp.bpId.toString()}>
                          {bp.bpName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='col'>
                    <label className='form-label'>Template</label>
                    <select
                      className='form-select'
                      value={this.state.selectedTemplate}
                      onChange={this.onChangeTemplateFilter}
                    >
                      <option value='-1'>All</option>
                      {this.state.filteredTemplates.map((template) => (
                        <option
                          key={template.templateId}
                          value={template.templateId.toString()}
                        >
                          {template.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              {this.state.showUploadTime && (
                <div className='row' style={{ marginBottom: '8px' }}>
                  <div className='col'>
                    <div className='row'>
                      <div className='col'>
                        <label className='form-label'>Start Date</label>
                        <input
                          className='form-control'
                          value={this.state.filterStartDate}
                          type='date'
                          onChange={(e) =>
                            this.updateTimeFilters(e, 'startDate')
                          }
                        ></input>
                      </div>
                      <div className='col'>
                        <label className='form-label'>Start Time</label>
                        <input
                          className='form-control'
                          type='time'
                          value={this.state.filterStartTime}
                          onChange={(e) =>
                            this.updateTimeFilters(e, 'startTime')
                          }
                        ></input>
                      </div>
                    </div>
                  </div>
                  <div className='col'>
                    <div className='row'>
                      <div className='col'>
                        <label className='form-label'>End Date</label>
                        <input
                          className='form-control'
                          value={this.state.filterEndDate}
                          type='date'
                          onChange={(e) => this.updateTimeFilters(e, 'endDate')}
                        />
                      </div>
                      <div className='col'>
                        <label className='form-label'>End Time</label>
                        <input
                          className='form-control'
                          value={this.state.filterEndTime}
                          type='time'
                          onChange={(e) => this.updateTimeFilters(e, 'endTime')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {this.state.showFileName && (
                <div className='row' style={{ marginBottom: '8px' }}>
                  <div className='col'>
                    <label className='form-label'>Search Text:</label>
                    <input
                      className='form-control'
                      value={this.state.searchText}
                      onChange={this.onChangeSearchText}
                      onKeyPress={this.handleKeyPress}
                    />
                  </div>
                </div>
              )}
              <div className='clearfix'>
                <button
                  type='button'
                  className='btn btn-primary btn-lg float-start'
                  onClick={this.onQuerySearch}
                >
                  Search
                </button>
                <button
                  type='button'
                  className='btn btn-info float-end'
                  style={{ marginLeft: '6px' }}
                  onClick={this.onRefresh}
                >
                  <FontAwesomeIcon icon={solid('sync')} /> Refresh
                </button>
                <button
                  type='button'
                  className='btn btn-primary float-end'
                  style={{ marginLeft: '6px' }}
                  onClick={this.toggleFieldSelect}
                >
                  <FontAwesomeIcon icon={solid('list-ul')} /> Fields
                </button>
              </div>
            </div>
          </div>
        </div>
        {this.state.showFieldSelect && (
          <div className='container'>
            <div
              className='card'
              style={{
                maxWidth: '750px',
              }}
            >
              <div className='card-body'>
                <div className='form-check form-check-inline'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    onChange={(e) => {
                      this.toggleCol('Up', e);
                    }}
                    checked={this.state.showUpTimeCol}
                  />
                  <label className='form-check-label'>Upload Time</label>
                </div>
                <div className='form-check form-check-inline'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    onChange={(e) => {
                      this.toggleCol('Pages', e);
                    }}
                    checked={this.state.showPagesCol}
                  />
                  <label className='form-check-label'>Pages</label>
                </div>
                <div className='form-check form-check-inline'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    onChange={(e) => {
                      this.toggleCol('Split', e);
                    }}
                    checked={this.state.showSplitCol}
                  />
                  <label className='form-check-label'>Split</label>
                </div>
                <div className='form-check form-check-inline'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    onChange={(e) => {
                      this.toggleCol('BP', e);
                    }}
                    checked={this.state.showBPCol}
                  />
                  <label className='form-check-label'>Business Process</label>
                </div>
                <div className='form-check form-check-inline'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    onChange={(e) => {
                      this.toggleCol('Template', e);
                    }}
                    checked={this.state.showTemplateCol}
                  />
                  <label className='form-check-label'>Template</label>
                </div>
                <div className='form-check form-check-inline'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    onChange={(e) => {
                      this.toggleCol('Recv', e);
                    }}
                    checked={this.state.showRecvCol}
                  />
                  <label className='form-check-label'>Received from</label>
                </div>
                <div className='form-check form-check-inline'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    onChange={(e) => {
                      this.toggleCol('DocSrc', e);
                    }}
                    checked={this.state.showDocSrcCol}
                  />
                  <label className='form-check-label'>Doc Source</label>
                </div>
              </div>
            </div>
          </div>
        )}

          <div className='flex-fill' style={{ margin: '8px' }}>
          <div
            className='card'
            style={{
              marginTop: '20px',
              marginLeft: '30px',
              marginRight: '30px',
              maxHeight: `${this.state.bottomHeight}px`,
            }}
          >
            <div className='card-header'>
              <h4>Uploaded Documents</h4>
            </div>
            <div
              className='card-body'
              style={{
                overflowY: 'auto',
                overflow: 'scroll',
                maxHeight: '20%',
              }}
            >
            <table className='tdGrid'>
              <tbody>
                <tr>
                  <th>Links</th>
                  <th>Source</th>
                  <th>Status</th>
                  <th style={{ maxWidth: '300px' }}>Original Name</th>
                  {this.state.showUpTimeCol && <th>Upload Time</th>}
                  {this.state.showPagesCol && <th>Pages</th>}
                  {this.state.showSplitCol && <th>Split</th>}
                  {this.state.showBPCol && <th>Business Process</th>}
                  {this.state.showTemplateCol && <th>Template</th>}
                  {this.state.showRecvCol && <th>Received from</th>}
                  {this.state.showDocSrcCol && <th>Doc Source</th>}
                </tr>

                {this.state.docs.map((doc) => (
                  <tr key={doc.docHistId}>
                    <td>
                      {doc.documentId !== 0 && (
                        <span
                          style={{
                            cursor: 'pointer',
                            color: '#324CDA',
                          }}
                          onClick={(e) => this.onDocLink(e, doc.documentId)}
                        >
                          <FontAwesomeIcon icon={solid('file-alt')} />{' '}
                          {doc.documentId}
                        </span>
                      )}
                      {'  '}
                      {doc.status === 'Registered' && doc.uploadId !== 0 && (
                        <span
                          style={{
                            cursor: 'pointer',
                            color: '#279028',
                            marginLeft: '9px',
                          }}
                          onClick={(e) => this.onUploadLink(e, doc.uploadId)}
                        >
                          <FontAwesomeIcon icon={solid('upload')} />{' '}
                        </span>
                      )}
                      {doc.status === 'Rejected' &&
                        doc.dupUploadId !== null && doc.dupDocId !== null && (
                          <span
                            style={{
                              cursor: 'pointer',
                              color: '#9B272B',
                            }}
                            //onClick={() => this.onUploadLink(doc.dupUploadId)}
                            onClick={(e) => this.onDocLink(e, doc.dupDocId)}
                          >
                            <FontAwesomeIcon icon={solid('file-alt')} />{' '}
                             {doc.dupDocId !== undefined && <span>{doc.dupDocId}</span>}
                          </span>
                        )}{' '}
                         {doc.status === 'Rejected' &&
                        doc.dupUploadId !== null && doc.dupDocId === null && (
                          <span
                            style={{
                              cursor: 'pointer',
                              color: '#9B272B',
                            }}
                            //onClick={() => this.onUploadLink(doc.dupUploadId)}

                          >
                            <FontAwesomeIcon icon={solid('file-alt')} />{' '}
                            {' (multiple/split)'}
                          </span>
                        )}{' '}
                      {doc.status === 'Rejected' && doc.dupUploadId !== 0 && (
                        <span
                          style={{
                            cursor: 'pointer',
                            color: '#9B272B',
                            marginLeft: '9px',
                          }}
                          onClick={(e) => this.onUploadLink(e, doc.dupUploadId)}
                        >
                          <FontAwesomeIcon icon={solid('upload')} />{' '}
                        </span>
                      )}
                    </td>
                    <td>
                      {doc.source === 'InboundAutomation' && (
                        <FontAwesomeIcon icon={solid('envelope-open')} />
                      )}
                      {doc.source === 'Web Upload' && (
                        <FontAwesomeIcon icon={solid('upload')} />
                      )}
                      {doc.source === 'Direct feed' && (
                        <FontAwesomeIcon icon={solid('angle-double-right')} />
                      )}
                      {doc.source === 'File Watch' && (
                        <FontAwesomeIcon icon={solid('folder-open')} />
                      )}
                      {doc.source === 'Image Review' && (
                        <FontAwesomeIcon icon={solid('image')} />
                      )}{' '}
                      {doc.source === 'Mobile App' && (
                        <FontAwesomeIcon icon={solid('mobile-screen-button')} />
                      )}{' '}
                      {doc.source === 'Fillable Form' && (
                        <FontAwesomeIcon icon={solid('edit')} />
                      )}{' '}
                      {doc.source}
                    </td>
                    <td>
                      {doc.status === 'Registered' && (
                        <span style={{ color: '#28B156' }}>
                          <FontAwesomeIcon icon={solid('file-alt')} />{' '}
                          Registered
                        </span>
                      )}
                      {doc.status === 'Rejected' && (
                        <span style={{ color: '#B12828' }}>
                          <FontAwesomeIcon icon={regular('copy')} /> Rejected
                        </span>
                      )}
                    </td>
                    <td style={{ maxWidth: '300px' }}>{doc.originalName}</td>
                    {this.state.showUpTimeCol && (
                      <td>{this.convertDate(doc.uploadTime)}</td>
                    )}
                    {this.state.showPagesCol && <td>{doc.pageCount}</td>}
                    {this.state.showSplitCol && <td>{doc.pageSplice}</td>}
                    {this.state.showBPCol && <td>{doc.bpName}</td>}
                    {this.state.showTemplateCol && <td>{doc.templateName}</td>}
                    {this.state.showRecvCol && <td>{doc.recvFrom}</td>}
                    {this.state.showDocSrcCol && <td>{doc.docSource}</td>}
                  </tr>
                ))}
                <tr>
                  <td
                    colSpan={this.state.colCount}
                    style={{
                      textAlign: 'center',
                      background: '#DCE9F7',
                    }}
                  >
                    {' '}
                    <span className='link' onClick={this.showMore}>
                      Show More
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            </div>
            </div>
          </div>


        {this.state.showDialog && this.state.currentInfo !== undefined && (
          <div className='tdDialog'>
            <div className='tdDialogContent card'>
              <div
                className='card-header'
                style={{ backgroundColor: '#469FA2', color: '#FFF' }}
              >
                Upload Information
              </div>
              <div className='card-body'>
                <div className='row' style={{ marginBottom: '5px' }}>
                  <div className='col-6'>
                    Upload Time:{' '}
                    {this.convertDate(this.state.currentInfo.uploadDtime)}
                  </div>
                  <div className='col-6'>
                    Page Count: {this.state.currentInfo.pageCount}
                  </div>
                </div>
                <div className='row' style={{ marginBottom: '5px' }}>
                  <div className='col-6'>
                    Status: {this.state.currentInfo.uploadStatus}
                  </div>
                  <div className='col-6'>
                    Documents: {this.state.currentInfo.docs.length}
                  </div>
                </div>
                <div className='row' style={{ marginBottom: '5px' }}>
                  <div className='col-6'>
                    {this.state.currentInfo.hasError && (
                      <span style={{ color: 'red' }}>Has Errors</span>
                    )}
                  </div>
                </div>
                <hr />
                <table className='table'>
                  <thead>
                    <tr className='table-dark'>
                      <th scope='col'>Doc Id:</th>
                      <th scope='col'>Pages</th>
                      <th scope='col'>Split</th>
                      <th scope='col'>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.currentInfo.docs.map((doc) => (
                      <tr key={doc.uploadGuid}>
                        <td>
                          <span
                            className='link'
                            onClick={() => this.RouteToDoc(doc.documentId)}
                          >
                            {doc.documentId}
                          </span>
                        </td>
                        <td>{doc.pageCount}</td>
                        <td>{doc.pageRange}</td>
                        <td>{doc.documentStatus}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <button
                  className='btn btn-secondary'
                  style={{ marginRight: '4px' }}
                  onClick={this.onCloseDialog}
                >
                  <i className='fas fa-times'></i> Close
                </button>
              </div>
            </div>
          </div>
        )}
        <Link
          id='nextDocLink'
          to={this.state.linkPath}
          style={{ display: 'none' }}
        >
          HiddenLink
        </Link>
      </div>
    );
  }

  convertDate(strDate) {
    let strDate2 = strDate.toString();
    if (!strDate2.endsWith('Z')) {
      strDate2 = strDate2 + 'Z';
    }
    let date = new Date(strDate2);
    let d1 = date.getDate();
    let day = d1.toString();
    let m1 = date.getMonth();
    m1 = m1 + 1;
    let mon = m1.toString();
    let year = date.getFullYear().toString();
    let h1 = date.getHours();

    let mer = 'AM';
    if (h1 >= 12) mer = 'PM';
    if (h1 > 12) {
      h1 = h1 - 12;
    }
    if (h1 === 0) h1 = 12;
    let h = h1.toString();
    let m = date.getUTCMinutes().toString();
    if (m.length === 1) {
      m = '0' + m;
    }

    let fulldtime =
      mon + '/' + day + '/' + year + ' ' + h + ':' + m + ' ' + mer;
    return fulldtime;
  }
}

export default DocumentUploadHistoryView;
