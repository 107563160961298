import * as React from 'react';
import { GetAllUserList } from '../../../actions/UserActions';
import { ExportLicenseAllocation } from '../../../actions/LicenseActions';
import UserStore from '../../../stores/UserStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
//import * as Globals from '../Globals';

class LicenseAllocation extends React.Component {
  constructor(props) {
    // state
    super(props);

    this.state = {
      sysMgrs: [],
      admins: [],
      approvers: [],
      viewers: [],
    };

    this.onUsersUpdate = this.onUsersUpdate.bind(this);
  }

  componentDidMount() {
    UserStore.addChangeListener(this.onUsersUpdate);
    GetAllUserList();
  }

  componentWillUnmount() {
    UserStore.removeChangeListener(this.onUsersUpdate);
  }

  onUsersUpdate() {
    let users = UserStore.getUserList();
    let sysMgrs = [];
    let admins = [];
    let viewers = [];
    let approvers = [];

    users.sort(function (a, b) {
      return a.userName.localeCompare(b.userName);
    });

    users.forEach((user) => {
      if (user.isActive){
        if (user.licenseLevel === 5) {
          sysMgrs.push(user);
        }
        if (user.licenseLevel === 4) {
          sysMgrs.push(user);
        }
        if (user.licenseLevel === 3) {
          admins.push(user);
        }
        if (user.licenseLevel === 2) {
          approvers.push(user);
        }
        if (user.licenseLevel === 1) {
          viewers.push(user);
        }
      }
    });

    this.setState({
      sysMgrs: sysMgrs,
      admins: admins,
      approvers: approvers,
      viewers: viewers,
    });
  }

  onExportClick(e) {
    ExportLicenseAllocation();
  }

  render() {
    return (
      <div className='container'>
        {true && (
          <div
            style={{ maxWidth: '100%', margin: '12px' }}
            id='userInfo'
            className='card'
          >
            <div className='card-header'>
              <FontAwesomeIcon icon={solid('id-card')} />
              {'  '}
              License Allocation
              <span style={{ float: 'right' }}>
                <button
                  className='btn btn-success'
                  onClick={this.onExportClick}
                >
                  <FontAwesomeIcon icon={solid('file-arrow-down')} /> Export to
                  Excel
                </button>
              </span>
            </div>
            <div className='card-body'>
              <div className='row'>
                <div className='col-3 p-1'>
                  <div className='card'>
                    <div className='card-header'>System Manager</div>
                    <div className='card-body'>
                      {this.state.sysMgrs.map((user) => (
                        <div className='' key={user.userId}>
                          {user.firstName} {user.lastName}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className='col-3 p-1'>
                  <div className='card'>
                    <div className='card-header'>Admin</div>
                    <div className='card-body'>
                      {this.state.admins.map((user) => (
                        <div className='' key={user.userId}>
                          {user.firstName} {user.lastName}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className='col-3 p-1'>
                  <div className='card'>
                    <div className='card-header'>Approver</div>
                    <div className='card-body'>
                      {this.state.approvers.map((user) => (
                        <div className='' key={user.userId}>
                          {user.firstName} {user.lastName}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className='col-3 p-1'>
                  <div className='card'>
                    <div className='card-header'>Viewer</div>
                    <div className='card-body'>
                      {this.state.viewers.map((user) => (
                        <div className='' key={user.userId}>
                          {user.firstName} {user.lastName}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default LicenseAllocation;
