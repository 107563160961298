import * as React from 'react';
import { Link } from 'react-router-dom';
import { GetCompanionList } from '../../../actions/TableEditorActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import TableEditorStore from '../../../stores/TableEditorStore';

class TableEditorSidebar extends React.Component {
  constructor() {
    super();
    this.state = {
      compList: [],
    };

    this.onReceiveCompanionChange = this.onReceiveCompanionChange.bind(this);
  }

  componentDidMount() {
    TableEditorStore.addChangeListener(this.onReceiveCompanionChange);
    GetCompanionList();
  }

  componentWillUnmount() {
    TableEditorStore.removeChangeListener(this.onReceiveCompanionChange);
  }

  onReceiveCompanionChange() {
    let comps = TableEditorStore.getCompanionList();
    this.setState({ compList: comps });
  }

  render() {
    let listOfISs;
    if (this.state.compList !== undefined) {
      listOfISs = this.state.compList.map((comp) => (
        <Link
          to={'/Admin/TableEditorView/' + comp.id}
          key={comp.id}
          style={{ cursor: 'Pointer' }}
        >
          {comp.companionName}
        </Link>
      ));
    } else {
      listOfISs = <div />;
    }
    return (
      <div
        className='d-flex flex-column p-3 text-white bg-dark'
        style={{ height: '100%', width: '245px' }}
      >
        <Link
          to='/Admin/CompanionTables'
          className='d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none'
        >
          <span className='fs-4'>
            <FontAwesomeIcon icon={solid('table')} />
            {'   '}
            Configuration Tables
          </span>
        </Link>
        <hr />
        {listOfISs}
        <hr />
      </div>
    );
  }
}

export default TableEditorSidebar;
