import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';

const CHANGE_EVENT = 'inboxChange';
let _queueTree = undefined;
let _filterTree = undefined;
let _errorTree = undefined;
let _deletedTree = undefined;
let _imageReviewTree = undefined;
let _adNode = undefined;
let _lastChange = '';
let _currentDocs = [];
let _columns = [];
let _docCount = 0;
let _workflowSteps = [];
let _fields = [];
let _headerFields = [];
let _filters = [];
let _filterData = undefined;
let _formTypeId = 0;
let _inboxType = '';
let _filterId = 0;
let _bpName = '';
let _stepName = '';
let _offset = 0;
let _recentQuery = '';

class InboxStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getQueueTree() {
    return _queueTree;
  }

  getFilterTree() {
    return _filterTree;
  }

  getErrorTree() {
    return _errorTree;
  }

  getDeletedTree() {
    return _deletedTree;
  }

  getImageReviewTree() {
    return _imageReviewTree;
  }


  getDocCount() {
    return _docCount;
  }

  getColumns() {
    return _columns;
  }

  getDocs() {
    return _currentDocs;
  }

  getLastNodeChange() {
    return _adNode;
  }

  getWorkflowSteps() {
    return _workflowSteps;
  }

  getFields() {
    return _fields;
  }

  getHeaderFields() {
    return _headerFields;
  }

  getFilters() {
    return _filters;
  }

  getFilterData() {
    return _filterData;
  }

  getFormTypeId() {
    return _formTypeId;
  }

  getFilterId() {
    return _filterId;
  }

  getInboxType() {
    return _inboxType;
  }

  getBPName() {
    return _bpName;
  }

  getStepName() {
    return _stepName;
  }

  getOffset() {
    return _offset;
  }

  getRecentQuery() {
    return _recentQuery;
  }

  lastChange() {
    return _lastChange;
  }
}

const store = new InboxStore();

Dispatcher.register((action) => {
  _lastChange = action.actionType;
  switch (action.actionType) {
    case actionTypes.InboxGetQueueTree:
      _lastChange = actionTypes.InboxGetQueueTree;
      _queueTree = action.Result;
      store.emitChange();
      break;

    case actionTypes.InboxGetFilterTree:
      _lastChange = actionTypes.InboxGetFilterTree;
      _filterTree = action.Result;
      store.emitChange();
      break;

    case actionTypes.InboxGetErrorTree:
        _lastChange = actionTypes.InboxGetErrorTree;
        _errorTree = action.Result;
        store.emitChange();
        break;

    case actionTypes.InboxGetDeletedTree:
        _lastChange = actionTypes.InboxGetDeletedTree;
        _deletedTree = action.Result;
        store.emitChange();
        break;

    case actionTypes.InboxGetImageReviewTree:
        _lastChange = actionTypes.InboxGetImageReviewTree;
        _imageReviewTree = action.Result;
        store.emitChange();
        break;

    case actionTypes.InboxGetStep:
      _lastChange = actionTypes.InboxGetStep;
      _currentDocs = action.Result.docData;
      _docCount = action.Result.docCount;
      _columns = action.Result.columns;
      _formTypeId = action.Result.formTypeId;
      _inboxType = action.Result.type;
      _bpName = action.Result.bpName;
      _stepName = action.Result.step;
      _offset = action.Result.offset;
      _recentQuery = action.Result.fullQuery;
      store.emitChange();
      break;

    case actionTypes.InboxGetBp:
      _lastChange = actionTypes.InboxGetBp;
      _currentDocs = action.Result.docData;
      _docCount = action.Result.docCount;
      _columns = action.Result.columns;
      _formTypeId = action.Result.formTypeId;
      _inboxType = action.Result.type;
      _bpName = action.Result.bpName;
      _stepName = action.Result.step;
      _offset = action.Result.offset;
      _recentQuery = action.Result.fullQuery;
      store.emitChange();
      break;

    case actionTypes.GetWorkflowStepsForBPs:
        _workflowSteps = action.Result;
        _lastChange = action.actionType;

        store.emitChange();
        break;

    case actionTypes.GetFieldsByFormType:
        _fields = action.Result;
        _lastChange = action.actionType;
        store.emitChange();
        break;

    case actionTypes.GetHeaderFieldsByFormType:
        _headerFields = action.Result;
        _lastChange = action.actionType;
        store.emitChange();
        break;
    
    case actionTypes.InboxGetFilters:
        _filters = action.Result;
        _lastChange = action.actionType;
        store.emitChange();
        break; 
        
        
    case actionTypes.GetFilterData:
        _filterData = action.Result.data;
        _lastChange = action.actionType;
        store.emitChange();
        break; 


    case actionTypes.InboxUpdateFilter:
        _lastChange = action.actionType;
        _filterId = action.Result.data.id;
        store.emitChange();
        break; 

    case actionTypes.InboxDeleteFilter:
      _lastChange = action.actionType;
      store.emitChange();
      break; 
    default:
  }
});

export default store;