import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';

const CHANGE_EVENT = 'moduleChange';
let _lastChange = '';
let _currentModules = [];
let _upload = undefined;
let _currentModule = undefined;
let _currentModuleData = [];

class ModuleStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getModule() {
    return _currentModule;
  }

  getModuleList() {
    return _currentModules;
  }

  getModuleDataList() {
    return _currentModuleData;
  }

  getLastChange() {
    return _lastChange;
  }

  getUploadResult() {
    return _upload;
  }
}

const store = new ModuleStore();

Dispatcher.register((action) => {
  _lastChange = action.actionType;
  switch (action.actionType) {
    case actionTypes.ModulesGetList:
      _currentModules = action.Result;
      store.emitChange();
      break;
    case actionTypes.ModulesUpload:
      _upload = action.Result;
      store.emitChange();
      break;
    case actionTypes.ModulesGetOne:
      _currentModule = action.Result;
      store.emitChange();
      break;
    case actionTypes.ModulesUpdate:
      store.emitChange();
      break;
    case actionTypes.ModuleDataGetList:
      _currentModuleData = action.Result;
      store.emitChange();
      break;
    case actionTypes.ModuleDelete:
      _upload = action.Result;
      store.emitChange();
      break;
    case actionTypes.ModuleAnalyze:
      _upload = action.Result;
      store.emitChange();
      break;
      case actionTypes.ModulesRename:
        store.emitChange();
        break;
    default:
  }
});

export default store;
