import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';

const CHANGE_EVENT = 'hotKeyEvent';

let _lastKey = '';
let _lastChange = '';

class HotKeyStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getLastKey() {
    return _lastKey;
  }
}

const store = new HotKeyStore();

Dispatcher.register((action) => {
  _lastChange = action.actionType;
  switch (action.actionType) {
    // loading the full list of Companion schemas
    case actionTypes.HotKey:
        console.log('hotKeyStore');
        console.log(action.hotkey);
      _lastChange = actionTypes.HotKey;
      _lastKey = action.hotkey;
      store.emitChange();
      break;
    default:
  }
});

export default store;
