import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';
//import { Field } from '../Models/admin/Field';

const CHANGE_EVENT = 'fieldChange';

//let _lastError: string = '';
let _currentField = undefined;
let _lastAction = { actionType: '', status: '', message: '' };
let _allFields = [];
let _fieldsOtherFT = [];
let _allFormTypes = [];

class FieldStore extends EventEmitter {
   addChangeListener(callback) {
      this.on(CHANGE_EVENT, callback);
   }

   removeChangeListener(callback) {
      this.removeListener(CHANGE_EVENT, callback);
   }

   emitChange() {
      this.emit(CHANGE_EVENT);
   }

   getCurrentField() {
      return _currentField;
   }

   getAllFormTypes() {
      return _allFormTypes;
   }


   getLastAction() {
      return _lastAction;
   }

   getAllFieldsForFormType() {
      return _allFields;
   }

   getFieldsOtherFT() {
      return _fieldsOtherFT;
   }
}

const store = new FieldStore();

Dispatcher.register((action) => {
   switch (action.actionType) {
      case actionTypes.FieldGetAllForFormType:
         _lastAction = {
            actionType: actionTypes.FieldGetAllForFormType,
            status: action.Result.status,
            message: action.Result.updateMessage,
         };
         _allFields = action.Result;
         store.emitChange();
         break;

      case actionTypes.GetFieldsOtherFormType:
         _lastAction = {
            actionType: actionTypes.GetFieldsOtherFormType,
            status: action.Result.status,
            message: action.Result.updateMessage,
         };
         _fieldsOtherFT = action.Result;
         store.emitChange();
         break;


      case actionTypes.GetAllFormTypes:
         _lastAction = {
            actionType: actionTypes.GetAllFormTypes,
            status: action.Result.status,
            message: action.Result.updateMessage,
         };
         _allFormTypes = action.Result;
         store.emitChange();
         break;
      // case actionTypes.FTLoad:
      //    console.log('hmm ' + action.Result);
      //    console.log(action.Result);
      //    _lastAction = {
      //       actionType: 'FTLoad',
      //       status: action.Result.status,
      //       message: action.Result.updateMessage,
      //    };
      //    _currentFt = action.Result;
      //    console.log(_currentFt);
      //    store.emitChange();
      //    break;
      // case actionTypes.FTUpdate:
      //    console.log(action.Result);
      //    _lastAction = {
      //       actionType: 'Update',
      //       status: action.Result.status,
      //       message: action.Result.updateMessage,
      //    };
      //    console.log(_lastAction);
      //    store.emitChange();
      //    break;
      default:
   }
});

export default store;

