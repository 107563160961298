import dispatcher from '../Dispatcher';
import actionTypes from './ActionTypes';
import axios from 'axios';
import * as Globals from '../Globals';

//import { CompanionRecord } from '../Models/Admin/CompanionSchema';

export function GetIAList() {
  axios
    .get(`${Globals.baseUrl}/IA/GetIAs`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.IAList,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      //let result = {
      //   updateStatus: 'Error',
      //   errorMessage: '401: Bad request',
      //};
      //dispatcher.dispatch({
      //   actionType: actionTypes.IAList,
      //   Result: result,
      //});
      console.log('Error: GetIAs');
    });
}

export function LoadIA(id) {
  axios
    .post(
      `${Globals.baseUrl}/IA/GetIA`,
      { IAId: id },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.IALoadOne,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(`Error: LoadIA: ${id.toString()}`);
    });
}

export function CreateIA(mailboxId, mailboxName) {
  axios
    .post(
      `${Globals.baseUrl}/IA/CreateIA`,
      { mbId: mailboxId, mbName: mailboxName },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.IACreate,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}

export function UpdateIA(id, update) {
  console.log(update);
  axios
    .post(
      `${Globals.baseUrl}/IA/UpdateIA`,
      { inboundAutomationId: id, update: update },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.IAUpdateOne,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}

export function DeleteIA(inboundAutoId) {
  axios
    .post(
      `${Globals.baseUrl}/IA/DeleteIA`,
      { IAId: inboundAutoId },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.IADelete,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}
