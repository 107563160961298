import dispatcher from '../Dispatcher';
import actionTypes from './ActionTypes';
import axios from 'axios';
import { getNewUrl } from '../Globals';
//import { FTModel } from '../Models/FTModel';
import { FieldList } from '../models/admin/Field';
import * as Globals from '../Globals';

export function LoadFormType(id) {
   let newUrl = getNewUrl();
   axios
      .post(
         `${newUrl}/FormType/Lookup`,
         { ftId: id },
         {
            headers: { 'content-type': 'application/json; charset=utf-8' },
         }
      )
      .then((res) => {
         if (res.status === 200) {
            dispatcher.dispatch({
               actionType: actionTypes.FTLoad,
               Result: res.data,
            });
         } else if (res.status === 400 || res.status === 401) {
            console.log('error: 401');
         }
      })
      .catch((res) => {
         console.log('error');
      });
}


// export function GetAllFieldsForFormType(id) {
//    let newUrl = getNewUrl();
//    axios
//       .get(
//          `${newUrl}/Field/GetAllForFormType/${id}`,
//          {
//             headers: { 'content-type': 'application/json; charset=utf-8' },
//          }
//       )
//       .then((res) => {
//          if (res.status === 200) {
//             dispatcher.dispatch({
//                actionType: actionTypes.FieldGetAllForFormType,
//                Result: res.data,
//             });
//          } else if (res.status === 400 || res.status === 401) {
//             console.log('error: 401');
//          }
//       })
//       .catch((res) => {
//          console.log('error');
//       });
// }

export function GetAllFieldsForFormType(formTypeId) {
   axios
     .get(`${Globals.baseUrl}/Field/GetAllForFormType/${formTypeId}`, {
       headers: {
         'content-type': 'application/json; charset=utf-8',
         Authorization: `Bearer ${Globals.getJwtToken()}`,
       },
     })
     .then((res) => {
       if (res.status === 200) {
         dispatcher.dispatch({
           actionType: actionTypes.FieldGetAllForFormType,
           Result: res.data,
         });
       } else if (res.status === 400 || res.status === 401) {
         console.log('error: 401');
       }
     })
     .catch((res) => {
       let result = {
         updateStatus: 'Error',
         errorMessage: '401: Bad request',
       };
       dispatcher.dispatch({
         actionType: actionTypes.FieldGetAllForFormType,
         Result: result,
       });
     });
 }

 export function GetFieldsForOtherFormType(formTypeId, fieldId) {
   axios
     .get(`${Globals.baseUrl}/Field/GetFieldsForOtherFormType/${formTypeId}/${fieldId}`, {
       headers: {
         'content-type': 'application/json; charset=utf-8',
         Authorization: `Bearer ${Globals.getJwtToken()}`,
       },
     })
     .then((res) => {
       if (res.status === 200) {
         dispatcher.dispatch({
           actionType: actionTypes.GetFieldsOtherFormType,
           Result: res.data,
         });
       } else if (res.status === 400 || res.status === 401) {
         console.log('error: 401');
       }
     })
     .catch((res) => {
       let result = {
         updateStatus: 'Error',
         errorMessage: '401: Bad request',
       };
       dispatcher.dispatch({
         actionType: actionTypes.GetFieldsOtherFormType,
         Result: result,
       });
     });
 }

 export function GetAllFormTypes() {
   axios
     .get(`${Globals.baseUrl}/Field/GetAllFormTypes`, {
       headers: {
         'content-type': 'application/json; charset=utf-8',
         Authorization: `Bearer ${Globals.getJwtToken()}`,
       },
     })
     .then((res) => {
       if (res.status === 200) {
         dispatcher.dispatch({
           actionType: actionTypes.GetAllFormTypes,
           Result: res.data,
         });
       } else if (res.status === 400 || res.status === 401) {
         console.log('error: 401');
       }
     })
     .catch((res) => {
       let result = {
         updateStatus: 'Error',
         errorMessage: '401: Bad request',
       };
       dispatcher.dispatch({
         actionType: actionTypes.GetAllFormTypes,
         Result: result,
       });
     });
 }

export function UpdateFields(fields) {
    let newUrl = getNewUrl();
    console.log('update fields');
    console.log(fields);
    let x = new FieldList();
    x.fields = fields;
    x.formTypeId = 1;
    axios
       .post(`${newUrl}/Field/UpdateFields`, { formTypeId: 1, fields: fields } , {
          headers: { 'content-type': 'application/json; charset=utf-8' },
       })
       .then((res) => {
          if (res.status === 200) {
             dispatcher.dispatch({
                actionType: actionTypes.FieldsUpdate,
                Result: res.data,
             });
          }
       })
       .catch((res) => {
          dispatcher.dispatch({
             actionType: actionTypes.FieldsUpdate,
             Result: {
                status: 'Error',
                updateMessage: 'Unable to reach API service',
             },
          });
       });
 }

