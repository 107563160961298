import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';

const CHANGE_EVENT = 'SystemAuditLoggingChange';
let _loggingCategories = undefined;
let _logs = [];
let _lastChange = '';

class SystemAuditLoggingStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getCategories() {
    return _loggingCategories;
  }

  getLogs() {
    return _logs;
  }

  getLastChange() {
    return _lastChange;
  }
}

const store = new SystemAuditLoggingStore();

Dispatcher.register((action) => {
  switch (action.actionType) {
    case actionTypes.SystemAuditLogGetCategories:
      _loggingCategories = action.Result;
      _lastChange = actionTypes.SystemAuditLogGetCategories;
      store.emitChange();
      break;
    case actionTypes.SystemAuditLogGetLogs:
      _logs = action.Result;
      _lastChange = actionTypes.SystemAuditLogGetLogs;
      store.emitChange();
      break;
    default:
  }
});

export default store;
