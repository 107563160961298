// @flow
import * as React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import SecretStore from '../../../../stores/SecretStore';
import { GetSecretList } from '../../../../actions/SecretActions';

class SecretSidebar extends React.Component {
  //isList: Array<BPModel>;
  constructor(props) {
    super(props);
    this.state = {
      secretList: [],
      secretsLoaded: false,
    };

    this.onReceiveSecretChange = this.onReceiveSecretChange.bind(this);
  }

  componentDidMount() {
    SecretStore.addChangeListener(this.onReceiveSecretChange);
    GetSecretList();
  }

  componentWillUnmount() {
    SecretStore.removeChangeListener(this.onReceiveSecretChange);
  }

  onReceiveSecretChange() {
    let lastChange = SecretStore.lastChange();
    if (lastChange === 'SecretList') {
      let secrets = SecretStore.getSecrets();
      this.setState({
        secretList: secrets,
        secretsLoaded: true,
      });
    }
  }

  render() {
    let listOfSecrets;
    if (this.state.secretList !== undefined) {
      listOfSecrets = this.state.secretList.map((secret) => (
        <div style={{ marginBottom: '8px' }} key={secret.id}>
          <Link
            className='sidebar-link'
            to={'/Admin/SecretView/' + secret.id}
            //onClick={() => this.handleBPClick(bp)}
            style={{ cursor: 'Pointer', wordWrap: 'break-word' }}
          >
            {secret.isEncrypted ? <FontAwesomeIcon style={{ color: '#FFD700' }} icon={solid('key')} title='Encrypted' /> : <FontAwesomeIcon title='UnEncrypted' style={{ color: '#86AFC0' }} icon={solid('key')} />}
            {' '}{secret.name} 
          </Link>
        </div>
      ));
    } else {
      listOfSecrets = <div>No secrets defined</div>;
    }
    return (
      <div
        className='d-flex flex-column p-3 text-white bg-dark'
        style={{ height: '100%', width: '245px' }}
      >
        <Link
          to='/Admin/Secret'
          className='d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none'
        >
          <span className='fs-4'>
            <FontAwesomeIcon style={{ color: '#FFCA48' }} icon={solid('key')} />
            {'   '}
            Secrets
          </span>
        </Link>
        <Link
                      to='/Admin/SystemAuditLogs/Admin/Secrets'
                      style={{ textDecoration: 'none', color: 'White', display: 'inline-block', target: '_blank' }}
                    >
                      <div className='navSubHeader'>
                        <span style={{ color: '#BE4EE8' }}>
                          <FontAwesomeIcon icon={solid('table')} />
                        </span>{' '}
                        Audit Page
                      </div>
                    </Link>
        <hr />

        <div style={{ maxHeight: '50%', overflow: 'auto' }}>
          {listOfSecrets}
        </div>

        <hr />
        <Link className='sidebar-link' to='/Admin/SecretView/0'>
          New Secret
        </Link>
      </div>
    );
  }
}

export default SecretSidebar;
