import * as React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import { LoadIntakeStrategies } from '../../../../actions/ISActions';
import IntakeStrategyStore from '../../../../stores/IntakeStrategyStore';

class IntakeStrategySidebar extends React.Component {
  //isList: Array<BPModel>;
  constructor(props) {
    super(props);

    this.state = {
      isList: [],
      currentIs: undefined,
    };
    this.LoadStrategies = this.LoadStrategies.bind(this);
    this.onISChange = this.onISChange.bind(this);
  }

  LoadStrategies() {}

  componentDidMount() {
    IntakeStrategyStore.addChangeListener(this.onISChange);
    let isList = IntakeStrategyStore.getIntakeStrategies();
    if (isList === undefined) {
      LoadIntakeStrategies();
    } else {
      this.setState({ isList: IntakeStrategyStore.getIntakeStrategies() });
    }
  }

  componentWillUnmount() {
    IntakeStrategyStore.removeChangeListener(this.onISChange);
  }

  onISChange() {
    let list = IntakeStrategyStore.getIntakeStrategies();
    this.setState({ isList: list });
    //this.setState({ currentIs: IntakeStrategyStore.getCurrentIS() });
  }

  handleBPClick(bp) {}

  handleImportLink() {}

  render() {
    let bpSection;
    if (this.state.currentIs !== undefined) {
      bpSection = (
        <div>
          <span className='fs-3' style={{ marginBottom: '10px' }}>
            {this.state.currentIs.isName}{' '}
            {/*<i className='fas fa-cog' style={{ color: '#5E629C' }}></i>*/}
          </span>

          <hr />
        </div>
      );
    }
    let listOfISs;
    if (this.state.isList !== undefined) {
      listOfISs = this.state.isList.map((intake) => (
        <Link
          className='sidebar-link'
          to={'/Admin/IntakeStrategies/' + intake.id}
          key={intake.id}
          //onClick={() => this.handleBPClick(bp)}
          style={{ cursor: 'Pointer' }}
        >
          {intake.isName}
        </Link>
      ));
    } else {
      listOfISs = <div />;
    }
    return (
      <div
        className='d-flex flex-column p-3 text-white bg-dark'
        style={{ height: '100%', width: '245px' }}
      >
        <Link
          to='/v/administration/intakeStrategies'
          className='d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none'
        >
          <span className='fs-4'>
            <FontAwesomeIcon
              icon={regular('paper-plane')}
              style={{ color: '#4EE8E8' }}
            />
            {'   '}
            Intake Strategies
          </span>
        </Link>
        <hr />
        {bpSection}
        {listOfISs}
        <hr />

        <Link to='/Admin/IntakeStrategies/new' className='sidebar-link'>
          New Intake Strategy
        </Link>
      </div>
    );
  }
}

export default IntakeStrategySidebar;
