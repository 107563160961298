import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';

const CHANGE_EVENT = 'workflowStepRolePermissionChange';
let _wfStepRPList = [];
let _currentRole = {};
let _lastChange = '';

class WorkflowStepRolePermissionStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getWfStepRpList() {
    return _wfStepRPList;
  }

  getLastChange() {
    return _lastChange;
  }

  getCurrentRole() {
    return _currentRole;
  }
}

const store = new WorkflowStepRolePermissionStore();

Dispatcher.register((action) => {
  switch (action.actionType) {
    case actionTypes.WorkflowStepRolePermissionGetList:
      _wfStepRPList = action.Result;
      _lastChange = action.actionType;

      store.emitChange();
      break;
    default:
  }
});

export default store;
