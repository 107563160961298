import * as React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

class SysPanel extends React.Component {
  constructor() {
    // state
    super();
    this.state = {};
  }

  componentDidMount() {}
  componentWillUnmount() {}
  componentDidUpdate() {}

  render() {
    return (
      <div
        className='d-flex flex-column flex-fill p-4'
        style={{ background: '#2A2A2A' }}
      >
        <div>
          <h2 className='sys-title'>System Control Panel</h2>
        </div>
        <hr style={{ color: '#fff' }} />
        <div className='sys-link-group'>
          <div className='sys-link-header'>Business Process</div>
          <div className='sys-link-list d-flex flex-wrap'>
            <div className='sys-link'>
              <Link to='/Admin/OrgUnits'>
                <span style={{ color: '#8090FF' }}>
                  <FontAwesomeIcon icon={solid('th-list')} size='2x' />
                </span>
                <div>Organizational Units</div>
              </Link>
            </div>
            <div className='sys-link'>
              <Link to='/Admin/BusinessProcess'>
                <span style={{ color: '#8090FF' }}>
                  <FontAwesomeIcon icon={solid('building')} size='2x' />
                </span>
                <div>Business Processes</div>
              </Link>
            </div>
            <div className='sys-link'>
              <Link to='/Admin/FormTypes'>
                <span style={{ color: '#8090FF' }}>
                  <FontAwesomeIcon icon={solid('file-invoice')} size='2x' />
                </span>
                <div>Form Types</div>
              </Link>
            </div>
            <div className='sys-link'>
              <Link to='/Admin/Workflows'>
                <span style={{ color: '#8090FF' }}>
                  <FontAwesomeIcon icon={solid('sitemap')} size='2x' />
                </span>
                <div>Workflows</div>
              </Link>
            </div>
          </div>
        </div>
        <div className='sys-link-group'>
          <div className='sys-link-header'>Integrations</div>
          <div className='sys-link-list d-flex flex-wrap'>
            <div className='sys-link'>
              <Link to='/Admin/Modules'>
                <span style={{ color: '#6CE84E' }}>
                  <FontAwesomeIcon icon={solid('box')} size='2x' />
                </span>
                <div>Modules</div>
              </Link>
            </div>

            <div className='sys-link'>
              <Link to='/Admin/Connections'>
                <span style={{ color: '#6CE84E' }}>
                  <FontAwesomeIcon icon={solid('database')} size='2x' />
                </span>
                <div>Connections</div>
              </Link>
            </div>

            <div className='sys-link'>
              <Link to='/Admin/Secrets'>
                <span style={{ color: '#6CE84E' }}>
                  <FontAwesomeIcon icon={solid('key')} size='2x' />
                </span>
                <div>Secrets</div>
              </Link>
            </div>
            <div className='sys-link'>
              <Link to='/Admin/Schedule'>
                <span style={{ color: '#6CE84E' }}>
                  <FontAwesomeIcon icon={solid('calendar-check')} size='2x' />
                </span>
                <div>Schedules</div>
              </Link>
            </div>
          </div>
        </div>

        <div className='sys-link-group'>
          <div className='sys-link-header'>Permissions</div>
          <div className='sys-link-list d-flex flex-wrap'>
            <div className='sys-link'>
              <Link to='/Admin/Users'>
                <span style={{ color: '#E8C54E' }}>
                  <FontAwesomeIcon icon={solid('user')} size='2x' />
                </span>
                <div>Users</div>
              </Link>
            </div>
            <div className='sys-link'>
              <Link to='/Admin/Modules'>
                <span style={{ color: '#E8C54E' }}>
                  <FontAwesomeIcon icon={solid('user-friends')} size='2x' />
                </span>
                <div>Roles</div>
              </Link>
            </div>
            <div className='sys-link'>
              <Link to='/Admin/LicenseAllocation'>
                <span style={{ color: '#E8C54E' }}>
                  <FontAwesomeIcon icon={solid('id-card')} size='2x' />
                </span>
                <div>License Allocation</div>
              </Link>
            </div>
            <div className='sys-link'>
              <Link to='/Admin/LoginSettings'>
                <span style={{ color: '#E8C54E' }}>
                  <FontAwesomeIcon icon={solid('sign-in-alt')} size='2x' />
                </span>
                <div>AD/OAuth</div>
              </Link>
            </div>
          </div>
        </div>
        <div className='sys-link-group'>
          <div className='sys-link-header'>Intake</div>
          <div className='sys-link-list d-flex flex-wrap'>
            <div className='sys-link'>
              <Link to='/Admin/UploadTracker'>
                <span style={{ color: '#4EE8E8' }}>
                  <FontAwesomeIcon icon={solid('upload')} size='2x' />
                </span>
                <div>Upload Tracker</div>
              </Link>
            </div>
            <div className='sys-link'>
              <Link to='/Admin/Directories'>
                <span style={{ color: '#4EE8E8' }}>
                  <FontAwesomeIcon icon={solid('folder-open')} size='2x' />
                </span>
                <div>Directories</div>
              </Link>
            </div>
            <div className='sys-link'>
              <Link to='/Admin/InboundAutomation'>
                <span style={{ color: '#4EE8E8' }}>
                  <FontAwesomeIcon icon={regular('envelope-open')} size='2x' />
                </span>
                <div>InboundAutomation</div>
              </Link>
            </div>
            <div className='sys-link'>
              <Link to='/Admin/IntakeStrategies'>
                <span style={{ color: '#4EE8E8' }}>
                  <FontAwesomeIcon icon={regular('paper-plane')} size='2x' />
                </span>
                <div>Intake Strategies</div>
              </Link>
            </div>
            <div className='sys-link'>
              <Link to='/Admin/IntakeSettings'>
                <span style={{ color: '#4EE8E8' }}>
                  <FontAwesomeIcon icon={solid('cog')} size='2x' />
                </span>
                <div>Intake Settings</div>
              </Link>
            </div>
          </div>
        </div>
        <div className='sys-link-group'>
          <div className='sys-link-header'>Configuration</div>
          <div className='sys-link-list d-flex flex-wrap'>
            <div className='sys-link'>
              <Link to='/Admin/UploadTracker'>
                <span style={{ color: '#BE4EE8' }}>
                  <FontAwesomeIcon icon={solid('archive')} size='2x' />
                </span>
                <div>Document Storage</div>
              </Link>
            </div>
            <div className='sys-link'>
              <Link to='/Admin/Directories'>
                <span style={{ color: '#BE4EE8' }}>
                  <FontAwesomeIcon icon={solid('envelope-square')} size='2x' />
                </span>
                <div>Mailboxes</div>
              </Link>
            </div>
            <div className='sys-link'>
              <Link to='/Admin/License'>
                <span style={{ color: '#BE4EE8' }}>
                  <FontAwesomeIcon icon={solid('file-signature')} size='2x' />
                </span>
                <div>License</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SysPanel;
