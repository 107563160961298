import * as React from 'react';
import DirectorySidebar from './DirectorySidebar';
import { Directory } from '../../../../models/admin/Directory';
import { createBrowserHistory } from 'history';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

// import {
//    LoadCompanionSchema,
//    GetCompanionData,
//    UpdateCompanionData,
// } from '../../../Actions/DirectoriesActions';
import {
  CreateDirectory,
  GetDirectoryList,
  LoadDirectory,
  UpdateSectionOne,
  UpdateSectionTwo,
  DeleteDirectory,
} from '../../../../actions/DirectoriesActions';
import { GetBPList } from '../../../../actions/BPActions';
import { LoadIntakeStrategies } from '../../../../actions/ISActions';
import DirectoryStore from '../../../../stores/DirectoryStore';
import BPStore from '../../../../stores/BPStore';
import ISStore from '../../../../stores/IntakeStrategyStore';

const customHistory = createBrowserHistory();

class DirectoryView extends React.Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      currentDirectory: undefined,
      directoryId: 0,
      uploadPath: '',
      isActive: false,
      isSecOneDirty: false,
      isSecOneSaving: false,
      errorOne: '',
      isSecTwoDirty: false,
      isSecTwoSaving: false,
      errorTwo: '',
      routingType: 0,
      defaultFtp: 0,
      legacyBPs: [],
      ftps: [],
      intakeStrats: [],
      selectedIs: '',
      isNewDirectory: false,
      deleteStatus: 0,
    };
    this.updateDirectory = this.updateDirectory.bind(this);
    this.onStoreUpdate = this.onStoreUpdate.bind(this);
    this.isActiveChange = this.isActiveChange.bind(this);
    this.handleUploadPathChange = this.handleUploadPathChange.bind(this);
    this.handleSaveOneClick = this.handleSaveOneClick.bind(this);
    this.handleChangeRouting = this.handleChangeRouting.bind(this);
    this.onLegacyBPUpdate = this.onLegacyBPUpdate.bind(this);
    this.handleDefaultFtpChange = this.handleDefaultFtpChange.bind(this);
    this.handleSaveTwoClick = this.handleSaveTwoClick.bind(this);
    this.ftpChange = this.ftpChange.bind(this);
    this.onISUpdate = this.onISUpdate.bind(this);
    this.handleIsChange = this.handleIsChange.bind(this);
    this.startDelete = this.startDelete.bind(this);
    this.deleteDirectory = this.deleteDirectory.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
  }

  componentDidMount() {
    DirectoryStore.addChangeListener(this.onStoreUpdate);
    BPStore.addChangeListener(this.onLegacyBPUpdate);
    ISStore.addChangeListener(this.onISUpdate);
    this.updateDirectory();
    GetBPList();
    LoadIntakeStrategies();
  }

  componentWillUnmount() {
    DirectoryStore.removeChangeListener(this.onStoreUpdate);
    BPStore.removeChangeListener(this.onLegacyBPUpdate);
    ISStore.removeChangeListener(this.onISUpdate);
  }

  componentDidUpdate() {
    this.updateDirectory();
  }

  updateDirectory() {
    let loc = window.location.pathname;
    let pathParts = loc.split('/');
    let dirId = parseInt(pathParts[pathParts.length - 1], 10);
    if (this.state.directoryId !== dirId) {
      this.setState({ directoryId: dirId });
      LoadDirectory(dirId);
    }
    if (this.state.currentDirectory === undefined) {
      if (dirId !== 0) {
        LoadDirectory(dirId);
      }
      if (dirId === 0) {
        let currentDir = new Directory();
        currentDir.uploadPath = 'New Directory';
        currentDir.uploadId = 0;
        this.setState({
          currentDirectory: currentDir,
          isNewDirectory: true,
          isActive: true,
          uploadPath: '',
        });
      }
    }
  }

  isActiveChange(e) {
    this.setState({ isActive: e.target.checked, isSecOneDirty: true });
  }

  onStoreUpdate() {
    let lastAction = DirectoryStore.lastChange();

    if (lastAction === 'DirectoriesLoadOne') {
      let dir = DirectoryStore.getCurrentDirectory();

      this.setState({
        currentDirectory: dir,
        uploadPath: dir.uploadPath,
        isActive: dir.isActive,
        isSecOneDirty: false,
        isSecOneSaving: false,
        routingType: dir.intakeRoutingType,
        defaultFtp: dir.defaultBPId,
        ftps: dir.bPs,
        selectedIs: dir.intakeStrategyId,
        isSecTwoDirty: false,
        isSecTwoSaving: false,
        isNewDirectory: false,
      });
    }
    if (lastAction === 'DirectoriesUpdateOne') {
      let lastError = DirectoryStore.getLastError();
      if (lastError === '') {
        let newDir = DirectoryStore.getCurrentDirectory();
        if (newDir.uploadId !== this.state.currentDirectory.uploadId) {
          //routeTo(`administration/directoryView/${newDir.uploadId}`);
          customHistory.push(`/Admin/DirectoryView/${newDir.uploadId}`);
          //window.location.href = `${baseUrl}/v/administration/directoryView/${newDir.uploadId}`;
        }

        this.setState({
          errorOne: '',
          currentDirectory: newDir,
          uploadPath: newDir.uploadPath,
          isActive: newDir.isActive,
          ftps: newDir.bPs,
          routingType: newDir.intakeRoutingType,
          selectedIs: newDir.intakeStrategyId,
          isSecOneSaving: false,
          isSecTwoDirty: false,
          isSecOneDirty: false,
          isSecTwoSaving: false,
        });
        GetDirectoryList();
      } else {
        this.setState({ isSecOneSaving: false, errorOne: lastError });
      }
    }
    if (lastAction === 'DirectoriesDelete') {
      let lastError = DirectoryStore.getLastError();
      if (lastError === '') {
        customHistory.push('Admin/Directories');
      } else {
        this.setState({ isSecOneSaving: false, errorOne: lastError });
      }
    }
  }

  onLegacyBPUpdate() {
    let bps = BPStore.getBPList();
    this.setState({ legacyBPs: bps });
  }

  onISUpdate() {
    let intakes = ISStore.getIntakeStrategies();
    this.setState({ intakeStrats: intakes });
  }

  handleUploadPathChange(e) {
    this.setState({ uploadPath: e.target.value, isSecOneDirty: true });
  }

  handleSaveOneClick(e) {
    this.setState({ isSecOneSaving: true });
    if (this.state.isNewDirectory) {
      CreateDirectory(this.state.uploadPath, this.state.isActive);
    } else {
      UpdateSectionOne(
        this.state.currentDirectory.uploadId,
        this.state.uploadPath,
        this.state.isActive
      );
    }
  }

  handleSaveTwoClick(e) {
    this.setState({ isSecTwoSaving: true });
    UpdateSectionTwo(
      this.state.currentDirectory.uploadId,
      this.state.routingType,
      this.state.ftps,
      this.state.defaultFtp,
      this.state.selectedIs
    );
  }

  handleChangeRouting(e) {
    this.setState({
      isSecTwoDirty: true,
      routingType: parseInt(e.target.value, 10),
    });
  }

  handleIsChange(e) {
    this.setState({
      isSecTwoDirty: true,
      selectedIs: e.target.value,
    });
  }

  ftpChange(e) {
    let ftps = this.state.ftps;
    let targetBp = parseInt(e.target.value, 10);
    if (e.target.checked && ftps.indexOf(targetBp) === -1) {
      // add to ftps
      ftps.push(targetBp);
    }
    if (!e.target.checked && ftps.indexOf(targetBp) !== -1) {
      // remove from ftps
      let idx = ftps.indexOf(targetBp);
      ftps.splice(idx, 1);
    }

    this.setState({ isSecTwoDirty: true, ftps: ftps });
    //this.setState({ isActive: e.target.checked, isSecOneDirty: true });
  }

  handleDefaultFtpChange(e) {
    this.setState({
      isSecTwoDirty: true,
      defaultFtp: parseInt(e.target.value, 10),
    });
  }

  startDelete() {
    this.setState({ deleteStatus: 1 });
  }

  deleteDirectory() {
    DeleteDirectory(this.state.currentDirectory.uploadId);
    this.setState({ deleteStatus: 0 });
  }

  cancelDelete() {
    this.setState({ deleteStatus: 0 });
  }

  render() {
    return (
      <div className='container-fluid' style={{ height: '100%' }}>
        <div className='row flex-nowrap' style={{ height: '100%' }}>
          <DirectorySidebar />
          {this.state.currentDirectory !== undefined && (
            <div
              className='flex-fill'
              style={{ backgroundColor: '#efefef', overflow: 'scroll' }}
            >
              <div
                className='card'
                style={{
                  marginTop: '20px',
                  marginLeft: '30px',
                  marginRight: '30px',
                  maxHeight: '620px',
                }}
              >
                <div className='card-header'>
                  <h4>
                    <FontAwesomeIcon icon={regular('folder')} />
                    {'  '}
                    {this.state.currentDirectory.uploadPath}
                  </h4>
                </div>
                <div className='card-body'>
                  <div className='mb-3'>
                    <label htmlFor='uploadPathTxt' className='form-label'>
                      Upload Path:
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      id='uploadPathTxt'
                      value={this.state.uploadPath}
                      onChange={this.handleUploadPathChange}
                    />
                  </div>

                  <div
                    className='form-check form-switch'
                    style={{ marginTop: '10px' }}
                  >
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='flexSwitchCheckChecked'
                      onChange={this.isActiveChange}
                      checked={this.state.isActive}
                    />
                    <label
                      className='form-check-label'
                      htmlFor='flexSwitchCheckChecked'
                    >
                      {this.state.isActive && <span>Active</span>}
                      {!this.state.isActive && <span>Inactive</span>}
                    </label>
                  </div>
                  <div className='mb-3' style={{ marginTop: '8px' }}>
                    {this.state.isSecOneDirty && !this.state.isSecOneSaving && (
                      <button
                        className='btn btn-primary'
                        onClick={this.handleSaveOneClick}
                      >
                        Save
                      </button>
                    )}
                    {!this.state.isSecOneDirty &&
                      !this.state.isSecOneSaving && (
                        <div>
                          <button className='btn btn-primary disabled'>
                            Save
                          </button>
                          <button
                            className='btn btn-danger'
                            style={{ marginLeft: '6px' }}
                            onClick={this.startDelete}
                          >
                            Delete
                          </button>
                        </div>
                      )}
                    {this.state.isSecOneSaving && (
                      <i className='fas fa-spinner fa-spin fa-2x' />
                    )}
                    {this.state.deleteStatus === 1 && (
                      <div
                        className='alert alert-danger'
                        style={{ marginTop: '6px' }}
                        role='alert'
                      >
                        <p>Are you sure you want to delete this directory?</p>

                        <button
                          className='btn btn-danger'
                          onClick={this.deleteDirectory}
                        >
                          Yes - Delete
                        </button>
                        <button
                          className='btn btn-secondary'
                          style={{ marginLeft: '6px' }}
                          onClick={this.cancelDelete}
                        >
                          Cancel
                        </button>
                      </div>
                    )}

                    {this.state.errorOne !== '' && (
                      <div
                        className='alert alert-danger'
                        style={{ marginTop: '6px' }}
                        role='alert'
                      >
                        {this.state.errorOne}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {!this.state.isNewDirectory && (
                <div
                  className='card'
                  style={{
                    marginTop: '20px',
                    marginLeft: '30px',
                    marginRight: '30px',
                    maxHeight: '620px',
                  }}
                >
                  <div className='card-header'>
                    <h4>Intake Routing</h4>
                  </div>
                  <div className='card-body'>
                    <div className='row'>
                      <div className='col'>
                        <div className='form-check'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='stdRoutingRadio'
                            id='stdRoutingRdo'
                            value='1'
                            checked={this.state.routingType === 1}
                            onChange={this.handleChangeRouting}
                          />
                          <label
                            className='form-check-label'
                            htmlFor='stdRoutingRdo'
                          >
                            Legacy Routing
                          </label>
                        </div>
                      </div>
                      <div className='col'>
                        <div className='form-check'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='intakeStrategyRadio'
                            id='intakeStrategyRdo'
                            value='2'
                            checked={this.state.routingType === 2}
                            onChange={this.handleChangeRouting}
                          />
                          <label
                            className='form-check-label'
                            htmlFor='intakeStrategyRdo'
                          >
                            Intake Strategy
                          </label>
                        </div>
                      </div>
                    </div>
                    {this.state.routingType === 1 && (
                      <div >
                        <div style={{ marginTop: '10px' }}>
                          Try these business processes:
                        </div>
                        <div style={{maxHeight: '280px', overflowY: 'auto'}}>
                        {this.state.legacyBPs.map((bp) => (
                          <div
                            className='form-check form-switch'
                            style={{ marginTop: '5px' }}
                            key={bp.bpId}
                          >
                            <input
                              className='form-check-input'
                              type='checkbox'
                              value={bp.bpId}
                              onChange={this.ftpChange}
                              //onChange={this.onFastPassChange}
                              checked={this.state.ftps.indexOf(bp.bpId) !== -1}
                            />
                            <label
                              className='form-check-label'
                              htmlFor='flexSwitchCheckChecked'
                            >
                              {bp.bpName}
                            </label>
                          </div>
                        ))}
                        </div>
                        <div style={{ marginTop: '10px' }}>
                          If none match, use this:
                        </div>
                        <div className='row'>
                          <div className='col-6'>
                            <select
                              className='form-select'
                              aria-label='Select default business process'
                              value={this.state.defaultFtp}
                              onChange={this.handleDefaultFtpChange}
                            >
                              <option value='0'>
                                Select a Business Process
                              </option>
                              {this.state.legacyBPs.map((bp) => (
                                <option key={bp.bpId} value={bp.bpId}>
                                  {bp.bpName}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className='col-6'>
                            <select
                              className='form-select'
                              aria-label='Select default template'
                            >
                              <option value='0'>Select default Template</option>
                              <option value='1'>Template1</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    )}
                    {this.state.routingType === 2 && (
                      <div>
                        <div className='h5' style={{ marginTop: '10px' }}>
                          Select an Intake Strategy:
                        </div>
                        <hr />
                        <select
                          className='form-select'
                          value={this.state.selectedIs}
                          onChange={this.handleIsChange}
                        >
                          <option value='0'>Select an intake strategy</option>
                          {this.state.intakeStrats.map((intake) => (
                            <option key={intake.id} value={intake.objectId}>
                              {intake.isName}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    <div className='mb-3' style={{ marginTop: '8px' }}>
                      {this.state.isSecTwoDirty &&
                        !this.state.isSecTwoSaving && (
                          <button
                            className='btn btn-primary'
                            onClick={this.handleSaveTwoClick}
                          >
                            Save
                          </button>
                        )}
                      {!this.state.isSecTwoDirty &&
                        !this.state.isSecTwoSaving && (
                          <button className='btn btn-primary disabled'>
                            Save
                          </button>
                        )}
                      {this.state.isSecTwoSaving && (
                        <i className='fas fa-spinner fa-spin fa-2x' />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default DirectoryView;
