import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';

const CHANGE_EVENT = 'roleChange';
let _roleList = [];
let _usersInRole = [];
let _currentRole = {};
let _lastChange = '';
let _usersForRole = [];
let _bps = [];

class RoleStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getRoleList() {
    return _roleList;
  }

  getUsersInRole() {
    return _usersInRole;
  }

  getLastChange() {
    return _lastChange;
  }

  getCurrentRole() {
    return _currentRole;
  }

  getUsersForRole() {
    return _usersForRole;
  }

  getAllBPs() {
    return _bps;
  }
}

const store = new RoleStore();

Dispatcher.register((action) => {
  switch (action.actionType) {
    case actionTypes.RolesGetRoles:
      _roleList = action.Result;
      _lastChange = action.actionType;

      store.emitChange();
      break;
    case actionTypes.RolesGetOne:
      _currentRole = action.Result;
      _lastChange = action.actionType;
      store.emitChange();
      break;
    case actionTypes.RolesUpdateOne:
      _currentRole = action.Result;
      console.log(action.Result);
      _lastChange = action.actionType;
      store.emitChange();
      break;
    case actionTypes.RolesDeleteOne:
      _lastChange = action.actionType;
      console.log('emit');
      store.emitChange();
      break;
    case actionTypes.RolesGetUsers:
      _usersInRole = action.Result;
      _lastChange = action.actionType;
      store.emitChange();
      break;
    case actionTypes.RolesUsersFor:
      _usersForRole = action.Result;
      _lastChange = action.actionType;
      store.emitChange();
      break;
    case actionTypes.RolesUpdateUsers:
      _lastChange = action.actionType;
      console.log('store');
      store.emitChange();
      break;
    case actionTypes.RoleBPGetList:
      _lastChange = action.actionType;
      _bps = action.Result;
      store.emitChange();
      break;
    default:
  }
});

export default store;
