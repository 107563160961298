import dispatcher from '../Dispatcher';
import actionTypes from './ActionTypes';
import axios from 'axios';
import * as Globals from '../Globals';
import { Logout } from './LoginActions';
import { PostAlert } from './AlertActions';

export function GetSystemSettings() {
  axios
    .get(`${Globals.baseUrl}/SystemSettings/GetSystemSettings`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.SystemGetSettings,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        Logout();
      }
    })
    .catch((res) => {
      //Logout();
    });
}

export function UpdateSystemSettings(settings) {
  axios
    .post(`${Globals.baseUrl}/SystemSettings/UpdateSystemSettings`, settings, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.SystemUpdateSettings,
          Result: res.data,
        });
        PostAlert('success', 'System settings updated');
      } else if (res.status === 400 || res.status === 401) {
        Logout();
      }
    })
    .catch((res) => {
      //Logout();
    });
}
