import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import SecretSidebar from './SecretSidebar';

class SecretHome extends React.Component {
  constructor(props) {
    super(props);
    // state
    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <div className='container-fluid' style={{ height: '100%' }}>
        <div className='row flex-nowrap' style={{ height: '100%' }}>
          <SecretSidebar />
          <div className='h-100 d-flex align-items-center'>
            <div
              className='p-2 col-example'
              style={{
                textAlign: 'center',
                marginLeft: '100px',
                marginRight: '100px',
                width: '60%',
              }}
            >
              <FontAwesomeIcon icon={solid('key')} size='6x' />

              <h2 style={{ fontWeight: 'bolder', fontStyle: 'italic' }}>
                Secrets
              </h2>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SecretHome;
