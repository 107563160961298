import dispatcher from '../Dispatcher';
import actionTypes from './ActionTypes';
import axios from 'axios';
import * as Globals from '../Globals';
import { Logout } from './LoginActions';
import { PostAlert } from './AlertActions';
//import { CompanionRecord } from '../Models/Admin/CompanionSchema';

export function GetOUList() {
  axios
    .get(`${Globals.baseUrl}/OrgUnit/GetOUs`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.GetOUList,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        Logout();
      }
    })
    .catch((res) => {
      console.log('error');
      console.log(res);
      // Logout();
    });
}

export function GetOU(ouId) {
  console.log('getOU');
  axios
    .get(`${Globals.baseUrl}/OrgUnit/GetOU/${ouId}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.OUGetOne,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        Logout();
      }
    })
    .catch((res) => {
      console.log('error');
      console.log(res);
      // Logout();
    });
}

export function UpdateOU(id, name) {
  axios
    .post(
      `${Globals.baseUrl}/OrgUnit/UpdateOU`,
      { ouName: name, ouId: id },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.data.status === 'Failure'){
        PostAlert('error', 'Error Saving: ' + res.data.updateMessage);
      }
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.OUUpdate,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        Logout();
      }
    })
    .catch((res) => {
      console.log('error');
      console.log(res);
      // Logout();
    });
}

export function DeleteOU(id, name) {
  axios
    .post(
      `${Globals.baseUrl}/OrgUnit/DeleteOU`,
      { ouName: name, ouId: id },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      console.log(res);
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.OUDelete,
          Result: res.data,
        });
        PostAlert('success', 'Organizational Unit deleted');
      } else if (res.status === 400 || res.status === 401) {
        Logout();
      }
    })
    .catch((res) => {
      console.log('error');
      console.log(res);
      // Logout();
    });
}
