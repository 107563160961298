import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';

const CHANGE_EVENT = 'navChange';
let _navDestination = '';
let _navPath = '';
let _lastChange = '';
let _listenCount = 0;
let _element = '';

class NavStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
    _listenCount++;
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);



    _listenCount--;
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getNavDestination() {
    return _navDestination;
  }

  getNavPath() {
    return _navPath;
  }

  getListenCount() {
    return _listenCount;
  }

  getElement() {
    return _element;
  }
}

const store = new NavStore();

Dispatcher.register((action) => {
  _lastChange = action.actionType;
  switch (action.actionType) {
    // loading the full list of Companion schemas
    case actionTypes.NavRequest:
      _lastChange = actionTypes.NavRequest;
      _navDestination = action.Result.dest;
      _navPath = action.Result.path;
      _element = action.Result.el;
      store.emitChange();
      break;
    default:
  }
});

export default store;
