import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';

const CHANGE_EVENT = 'userChange';
let _userList = [];
let _currentUser = {};
let _lastChange = '';
let _lastError = '';
let _userSettings = [];

class UserStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getUserList() {
    return _userList;
  }

  getLastChange() {
    return _lastChange;
  }

  getCurrentUser() {
    return _currentUser;
  }

  getLastError() {
    return _lastError;
  }
  getUserSetting(setting) {
    let s = _userSettings.find((u) => u.settingName === setting);
    if (s) {
      return s.value;
    }
    return null;
  }
}

const store = new UserStore();

Dispatcher.register((action) => {
  switch (action.actionType) {
    case actionTypes.UsersGetUsers:
      _userList = action.Result;
      _lastChange = action.actionType;

      store.emitChange();
      break;
    case actionTypes.UserChangePassword:
      _lastChange = action.actionType;
      store.emitChange();
      break;
    case actionTypes.UsersGetOne:
      _currentUser = action.Result;
      _lastChange = action.actionType;
      store.emitChange();
      break;
    case actionTypes.UsersUpdateUser:
      var split = action.Result.split(':');
      console.log(split);
      if (split[0] === 'User Created') {
        var newUserId = parseInt(split[1], 10);
        console.log(newUserId);
        _currentUser.userId = newUserId;
        //this.props.history.push(`/Admin/UsersView/${newUserId}`);
        _lastChange = 'UserCreateUser';
        store.emitChange();
      }
      if (split[0] === 'Success') {
        _lastChange = action.actionType;
      }
      if (split[0] === 'Error') {
        _lastChange = 'UserUpdateError';
        _lastError = action.Result;
      }
      //_currentUser = action.Result;
      store.emitChange();
      break;
    case actionTypes.UserChangeSetting:
      let update = action.Result;
      let s = _userSettings.find((u) => u.settingName === update.setting);
      if (s) {
        s.value = update.value;
      } else {
        _userSettings.push({
          setting: update.setting,
          value: update.value,
        });
      }
      store.emitChange();
      break;
    default:
  }
});

export default store;
