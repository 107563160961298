import * as React from 'react';
import { createBrowserHistory } from 'history';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
//import { PostAlert } from '../../../../actions/AlertActions';
import WorkflowSidebar from './WorkflowSidebar';
import WorkflowStore from '../../../../stores/WorkflowStore';
import {
  UpdateWorkflow,
  GetWorkflow,
  GetWorkflowList,
  GetAllowedFormTypes,
  GetFormTypesAndTemplatesForWorkflow,
} from '../../../../actions/WorkflowActions';
import actionTypes from '../../../../actions/ActionTypes';
import AdvWorkflowEdit from './advWorkflow';
import NavStore from '../../../../stores/NavStore';
import { AcceptNav } from '../../../../actions/NavActions';
import AutoComplete from '../../../../components/AutoComplete';

const colorScheme = [
  { scheme: 'User', foreground: '#000', background: '#FDDD5B' },
  { scheme: 'Intg', foreground: '#fff', background: '#626262' },
  { scheme: 'Auto', foreground: '#000', background: '#FFAB20' },
  { scheme: 'End', foreground: '#fff', background: '#973838' },
  { scheme: 'Start', foreground: '#fff', background: '#50A41D' },
  { scheme: 'OCR', foreground: '#000', background: '#DFDFDF' },
];

var formTypes = [];

const customHistory = createBrowserHistory();
var lastNewConditionId = -1;
var lastNewAssigneeId = -1;
class WorkflowView extends React.Component {
  dragStep = {};
  dragTarget = {};
  dragRoute = {};
  dragRouteTarget = {};
  dragRouteDir = '';

  constructor(props) {
    // state
    super(props);

    this.state = {
      currentWorkflow: {
        name: '',
        workflowId: 0,
        workflowName: 'system',
        hasBP: false,
        bpName: '',
        steps: [],
        routes: [],
        isNew: false,
      },
      currentEditStep: {
        name: '',
        stepType: '',
        description: '',
        notes: '',
        reqiredFields: [],
      },
      newRoute: {
        toStepId: 0,
        fromStepId: 0,
      },
      currentRouteId: -1,
      currentRouteGroup: 0,
      routeGroups: [],
      toFromRouteGroups: [],
      fromRouteGroups: [],
      routeFilterType: 'none',
      stepFilterText: '',
      showNewRoute: false,
      showNewCondition: false,
      currentView: 'steps',
      isDirty: false,
      hasError: false,
      errorMessage: '',
      addingStep: false,
      newStep: { name: '', stepType: 'User', description: '' },
      newCondition: { ruleType: '1', value: '', fileId: -1 },
      showStepEditor: false,
      isSaving: false,
      activeRoute: undefined,
      cardHeight: 200,
      filteredSteps: [],
      routeToFilter: 0,
      routeFromFilter: 0,
      filteredRoutes: [],
      allowedFormTypes: [],
      filteredFields: [],
      reqFieldFormTypeId: 0,
      reqField: '0',
      showReqFields: false,
      showFlow: false,
      layoutViewWidth: (window.innerWidth - 330) / 2,
      layoutViewHeight: window.innerHeight - 350,
      layoutViewWidth2: window.innerWidth - 305,
      layoutViewHeight2: window.innerHeight - 80,
      fullScreenFlow: false,
      hasRoutesCopied: false,

      availablePlugins: [],
      layout: {
        tasks: [
          {
            id: 1,
            x: 100,
            y: 100,
            height: 50,
            width: 50,
            taskType: 'start',
            name: 'Start',
          },
        ],
        routes: [],
        canvasWidth: 700,
        canvasHeight: 700,
        showNav: false,
        navDestination: '',
      },
      showFormTypes: true,
      showTemplates: true,
      showConditions: true,
      showAssignees: true,

      routeCopy: '',
      bottomHeight: window.innerHeight - 260,
    };

    this.componentDidUpdate = this.componentDidUpdate.bind(this);
    this.onWorkflowUpdate = this.onWorkflowUpdate.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.showNewStep = this.showNewStep.bind(this);
    this.hideNewStep = this.hideNewStep.bind(this);
    this.editNewStepName = this.editNewStepName.bind(this);
    this.editNewStepDesc = this.editNewStepDesc.bind(this);
    this.onChangeNewStepType = this.onChangeNewStepType.bind(this);
    this.addStep = this.addStep.bind(this);
    this.onDescKeyDown = this.onDescKeyDown.bind(this);
    this.onSave = this.onSave.bind(this);
    this.editStep = this.editStep.bind(this);
    this.closeStepEditor = this.closeStepEditor.bind(this);
    this.editStepValue = this.editStepValue.bind(this);
    this.onAddRouteClick = this.onAddRouteClick.bind(this);
    this.onCloseNewRoute = this.onCloseNewRoute.bind(this);
    this.editNewRouteFrom = this.editNewRouteFrom.bind(this);
    this.editNewRouteTo = this.editNewRouteTo.bind(this);
    this.addRoute = this.addRoute.bind(this);
    this.addQuickRoute = this.addQuickRoute.bind(this);
    // this.toggleShowNewCondition = this.toggleShowNewCondition.bind(this);
    //this.switchtoRoute = this.switchtoRoute.bind(this);
    this.addCondition = this.addCondition.bind(this);
    this.toggleEditCondition = this.toggleEditCondition.bind(this);
    this.editStepFilterText = this.editStepFilterText.bind(this);
    this.removeRoute = this.removeRoute.bind(this);
    this.editFromFilter = this.editFromFilter.bind(this);
    this.editToFilter = this.editToFilter.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.toggleFlow = this.toggleFlow.bind(this);
    this.setSelectedTask = this.setSelectedTask.bind(this);
    this.setSelectedRoute = this.setSelectedRoute.bind(this);
    this.onUpdateTree = this.onUpdateTree.bind(this);
    this.createDefalutLayout = this.createDefalutLayout.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);
    this.toggleFullScreen = this.toggleFullScreen.bind(this);
    this.createRouteGroupsByStart = this.createRouteGroupsByStart.bind(this);
    this.onChangeReqFormTypeId = this.onChangeReqFormTypeId.bind(this);
    this.toggleReqFields = this.toggleReqFields.bind(this);
    this.onChangeReqField = this.onChangeReqField.bind(this);
    this.addReqField = this.addReqField.bind(this);
    this.deleteReq = this.deleteReq.bind(this);
    this.removeCondition = this.removeCondition.bind(this);
    //this.toggleRearrange = this.toggleRearrange.bind(this);

    this.deleteRoute = this.deleteRoute.bind(this);
    this.setSetDragging = this.setSetDragging.bind(this);
    this.onStepDragOver = this.onStepDragOver.bind(this);
    this.onStepDrop = this.onStepDrop.bind(this);
    this.setCurrentRoute = this.setCurrentRoute.bind(this);
    this.onRouteGroupKeyUp = this.onRouteGroupKeyUp.bind(this);
    this.addAssigneeToRoute = this.addAssigneeToRoute.bind(this);
    this.onRouteHotKey = this.onRouteHotKey.bind(this);
    this.updateRouteCode = this.updateRouteCode.bind(this);
    this.onEditCondValue = this.onEditCondValue.bind(this);
    this.addSubRoute = this.addSubRoute.bind(this);
    this.updateFormType = this.updateFormType.bind(this);
    this.updateCondition = this.updateCondition.bind(this);
    this.deleteCondition = this.deleteCondition.bind(this);
    this.deleteAssignment = this.deleteAssignment.bind(this);
    this.copyRoute = this.copyRoute.bind(this);
    this.adjustWFLayout = this.adjustWFLayout.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.editRouteAssignee = this.editRouteAssignee.bind(this);
    this.onEditToStep = this.onEditToStep.bind(this);
    this.onRouteDragOver = this.onRouteDragOver.bind(this);
    this.setRouteDragging = this.setRouteDragging.bind(this);
    this.onRouteDragLeave = this.onRouteDragLeave.bind(this);
    this.onRouteDrop = this.onRouteDrop.bind(this);
    this.onRouteClick = this.onRouteClick.bind(this);
    this.validateWorkflow = this.validateWorkflow.bind(this);
    this.updateTemplate = this.updateTemplate.bind(this);
    this.toggleRowCheck = this.toggleRowCheck.bind(this);
    this.copyCheckedRoutes = this.copyCheckedRoutes.bind(this);
    this.pasteRoutes = this.pasteRoutes.bind(this);
    this.onNavRequest = this.onNavRequest.bind(this);
    this.onSaveAndNav = this.onSaveAndNav.bind(this);
    this.onStay = this.onStay.bind(this);
    this.onDiscardAndNav = this.onDiscardAndNav.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
  }

  componentDidMount() {
    WorkflowStore.addChangeListener(this.onWorkflowUpdate);
    NavStore.addChangeListener(this.onNavRequest);
    window.onresize = this.onWindowResize;
    this.loadWorkflowFromDB();
  }

  componentWillUnmount() {
    WorkflowStore.removeChangeListener(this.onWorkflowUpdate);
    NavStore.removeChangeListener(this.onNavRequest);
  }

  componentDidUpdate() {
    this.loadWorkflowFromDB();
  }

  onNavRequest() {
    if (this.state.isDirty) {
      let dest = NavStore.getNavDestination();
      this.setState({ showNav: true, navDestination: dest });
    } else {
      AcceptNav();
    }
  }

  onWorkflowUpdate() {
    let lastChange = WorkflowStore.getLastAction();
    if (lastChange === actionTypes.WorkflowGetWorkflow) {
      let newWorkflow = WorkflowStore.getCurrentWorkflow();

      GetAllowedFormTypes(newWorkflow.workflowId);
      this.setState(
        {
          currentWorkflow: newWorkflow,
          hasError: false,
          isDirty: false,
          isSaving: false,
          filteredSteps: newWorkflow.steps,
          filteredRoutes: newWorkflow.routes,
        },
        () => {
          this.adjustWFLayout();
        }
      );

      if (newWorkflow.layout === undefined || newWorkflow.layout === null) {
        // no layout
        this.createDefalutLayout();
      }
    }
    if (lastChange === actionTypes.WorkflowGetOne) {
      let newWorkflow = WorkflowStore.getCurrentWorkflow();
      let newLayout = WorkflowStore.getCurrentLayout();
      let plugins = WorkflowStore.getPlugins();
      //newWorkflow = this.adjustColors(newWorkflow);

      GetAllowedFormTypes(newWorkflow.workflowId);

      // add possibleAssignees to routes
      newWorkflow.routes.forEach((r) => {
        r.possibleAssignees = [];
        r.checked = false;
        let toStep = newWorkflow.steps.find(
          (s) => s.workflowStepId === r.toStepId
        );

        r.formTypeChoices = formTypes;

        let ft = formTypes.find((ftId) => ftId.formTypeId === r.formTypeId);
        if (ft) {
          r.templateChoices = ft.templateChoices;
          r.fieldChoices = ft.fieldChoices;
        }


        toStep.possibleAssignees.forEach((pa) => {
          r.possibleAssignees.push({
            userId: pa.userId,
            userName: pa.userName,
          });
        });



        if(ft) {

        ft.fieldChoices.forEach((fc) => {
          let id = parseInt(fc.fieldId,10) * -1;

          r.possibleAssignees.push({
            userId: id,
            userName: '$' + fc.fieldName
          });
        });
        }

      });

      this.setState(
        {
          currentWorkflow: newWorkflow,
          //layout: newLayout,
          hasError: false,
          isDirty: false,
          filteredSteps: newWorkflow.steps,
          filteredRoutes: newWorkflow.routes,
          availablePlugins: plugins,
        },
        () => {
          this.adjustWFLayout();
        }
      );
      console.log(newWorkflow.steps);

      if (newLayout === undefined || newLayout === null) {
        // no layout
        this.createDefalutLayout();
      } else {
        this.setState({ layout: newLayout });
      }

      this.createRouteGroupsByStart(newWorkflow, 0, 0);
    }
    if (lastChange === actionTypes.WorkflowFTAndTemp) {
      formTypes = WorkflowStore.getFormTypes();
    }
    if (lastChange === actionTypes.WorkflowUpdate) {
      if (
        this.state.currentWorkflow !== undefined &&
        this.state.currentWorkflow.workflowId === 0
      ) {
        let newWorkflow = WorkflowStore.getCurrentWorkflow();
        //newWorkflow = this.adjustColors(newWorkflow);
console.log('update');
        newWorkflow.routes.forEach((r) => {
          r.possibleAssignees = [];
          r.checked = false;
          let toStep = newWorkflow.steps.find(
            (s) => s.workflowStepId === r.toStepId
          );
          r.formTypeChoices = formTypes;

          let ft = formTypes.find((ftId) => ftId.formTypeId === r.formTypeId);
          if (ft) {
            r.templateChoices = ft.templateChoices;
            r.fieldChoices = ft.fieldChoices;
          }

          toStep.possibleAssignees.forEach((pa) => {
            r.possibleAssignees.push({
              userId: pa.userId,
              userName: pa.userName,
            });
          });

          ft.fieldChoices.forEach((fc) => {
            let id = parseInt(fc.fieldId,10) * -1;

            r.possibleAssignees.push({
              userId: id,
              userName: '$' + fc.fieldName
            });
          });
        });

        customHistory.push(`/Admin/WorkflowView/${newWorkflow.workflowId}`);
        this.setState(
          {
            currentWorkflow: newWorkflow,
            isDirty: false,
            hasError: false,
            isSaving: false,
          },
          () => {
            let to = this.state.routeToFilter;
            let from = this.state.routeFromFilter;
            this.applyFilter(to, from);
          }
        );
        GetWorkflowList();
        this.createRouteGroupsByStart(newWorkflow, 0, 0);
        // new workflow - go there
      } else {
        let updatedWorkflow = WorkflowStore.getCurrentWorkflow();

        updatedWorkflow.routes.forEach((r) => {
          r.possibleAssignees = [];
          r.checked = false;
          let toStep = updatedWorkflow.steps.find(
            (s) => s.workflowStepId === r.toStepId
          );
          if (r.templateId === null) r.templateId = 0;
          r.formTypeChoices = formTypes;

          let ft = formTypes.find((ftId) => ftId.formTypeId === r.formTypeId);
          if (ft) {
            r.templateChoices = ft.templateChoices;
            r.fieldChoices = ft.fieldChoices;
          }

          toStep.possibleAssignees.forEach((pa) => {
            r.possibleAssignees.push({
              userId: pa.userId,
              userName: pa.userName,
            });
          });

          ft.fieldChoices.forEach((fc) => {
            let id = parseInt(fc.fieldId,10) * -1;

            r.possibleAssignees.push({
              userId: id,
              userName: '$' + fc.fieldName
            });
          });


        });

        this.setState(
          { currentWorkflow: updatedWorkflow, filteredSteps: updatedWorkflow.steps, isDirty: false, isSaving: false },
          () => {
            let to = this.state.routeToFilter;
            let from = this.state.routeFromFilter;
            this.applyFilter(to, from);
          }
        );
      }
    }
    if (lastChange === actionTypes.WorkflowAllowedFormTypes) {
      let allowedFTs = WorkflowStore.getAllowedFormTypes();
      let defaultFt = allowedFTs[0];
      this.setState({
        allowedFormTypes: allowedFTs,
        reqFieldFormTypeId: defaultFt.formTypeId,
      });

      if (allowedFTs.length === 1) {
        let fields = allowedFTs[0].fieldSets[0].fields;
        this.setState({ filteredFields: fields });
      }
    }
  }

  createDefalutLayout() {
    var y = 0;

    let layout = this.state.layout;
    layout.tasks = [];
    let cw = this.state.currentWorkflow;
    let x = 0;
    // find the start step
    var remainingSteps = [...cw.steps];
    var layoutSteps = [];
    var layers = [];
    remainingSteps.forEach((step) => {
      if (step.stepType === 'Start') {
        // create layer zero
        var layerZero = [];
        layerZero.push(step);
        layoutSteps.push(step.workflowStepId);
        remainingSteps.splice(remainingSteps.indexOf(step), 1);
        layers.push(layerZero);
      }
    });
    let currentLayer = 0;

    let stepsAdded = 1;
    if (layers.length === 1) {
      while (stepsAdded > 0) {
        stepsAdded = 0;
        layers.push([]);
        currentLayer++;
        //let nextLayer = [];
        layers[currentLayer - 1].forEach((task) => {
          let outRoutes = cw.routes.filter(
            (r) => r.fromStepId === task.workflowStepId
          );
          outRoutes.forEach((outRoute) => {
            if (layers[currentLayer].length > 6) {
              layers.push([]);
              currentLayer++;
            }
            let stepToAdd = remainingSteps.find(
              (rs) => rs.workflowStepId === outRoute.toStepId
            );
            if (stepToAdd) {
              layers[currentLayer].push(stepToAdd);
              remainingSteps.splice(remainingSteps.indexOf(stepToAdd), 1);
              stepsAdded++;
            }
          });
        });
      }

      // add remaining, unrouted steps to a separate layer
      if (remainingSteps.length > 0) {
        layers.push([...remainingSteps]);
      }
    }

    layers.forEach((layer) => {
      layer.forEach((step) => {
        var newTask = {
          id: step.workflowStepId,
        };
        if (step.stepType === 'Start') {
          newTask.x = x + 106;
          newTask.y = y + 50;
          newTask.height = 50;
          newTask.width = 50;
          newTask.taskType = 'start';
          newTask.name = step.Name;
        }
        if (step.stepType === 'User') {
          newTask.x = x + 20;
          newTask.y = y + 20;
          newTask.width = 180;
          newTask.height = 60;
          newTask.name = step.name;
          newTask.taskType = 'user';
        }
        if (step.stepType === 'OCR') {
          newTask.x = x + 20;
          newTask.y = y + 20;
          newTask.width = 180;
          newTask.height = 60;
          newTask.name = step.name;
          newTask.taskType = 'auto';
        }
        if (step.stepType === 'Intg') {
          newTask.x = x + 20;
          newTask.y = y + 20;
          newTask.width = 180;
          newTask.height = 60;
          newTask.name = step.name;
          newTask.taskType = 'auto';
        }
        if (step.stepType === 'End') {
          newTask.x = x + 20;
          newTask.y = y + 20;
          newTask.width = 180;
          newTask.height = 60;
          newTask.name = step.name;
          newTask.taskType = 'end';
        }

        layout.tasks.push(newTask);
        x = x + 200;
      });
      y = y + 100;
      x = 0;
    });

    cw.routes.forEach((r) => {
      var newRoute = {
        endTaskId: r.toStepId,
        startTaskId: r.fromStepId,
      };
      var fromStep = layout.tasks.find((s) => s.id === r.fromStepId);
      var toStep = layout.tasks.find((s) => s.id === r.toStepId);
      if (fromStep.taskType === 'start') {
        newRoute.start = {
          x: fromStep.x,
          y: fromStep.y + 24,
          d: 'b:50',
        };
        newRoute.end = {
          x: toStep.x + toStep.width / 2,
          y: toStep.y,
          d: 't:50',
        };
      } else if (fromStep.y > toStep.y) {
        newRoute.start = {
          x: fromStep.x + (fromStep.width * 8) / 10,
          y: fromStep.y,
          d: 't:80',
        };
        newRoute.end = {
          x: toStep.x + toStep.width / 2,
          y: toStep.y + toStep.width,
          d: 'b:80',
        };
      } else if (fromStep.y === toStep.y && fromStep.x < toStep.x) {
        newRoute.start = {
          x: fromStep.x + fromStep.width,
          y: fromStep.y + (fromStep.height * 2) / 10,
          d: 'r:20',
        };
        newRoute.end = {
          x: toStep.x,
          y: toStep.y + (toStep.height * 2) / 10,
          d: 'l:20',
        };
      } else if (fromStep.y === toStep.y && fromStep.x > toStep.x) {
        newRoute.start = {
          x: fromStep.x,
          y: fromStep.y + (fromStep.height * 8) / 10,
          d: 'l:80',
        };
        newRoute.end = {
          x: toStep.x + toStep.width,
          y: toStep.y + (toStep.height * 8) / 10,
          d: 'r:80',
        };
      } else if (fromStep.y < toStep.y) {
        newRoute.start = {
          x: fromStep.x + (fromStep.width * 5) / 10,
          y: fromStep.y + fromStep.height,
          d: 'b:50',
        };
        newRoute.end = {
          x: fromStep.x + (fromStep.width * 5) / 10,
          y: fromStep.y,
          d: 't:50',
        };
      } else if (fromStep.id === toStep.id) {
        newRoute.start = {
          x: toStep.x + toStep.width,
          y: toStep.y + (toStep.height * 2) / 10,
          d: 'r:20',
        };
        newRoute.end = {
          x: toStep.x + toStep.width,
          y: toStep.y + (toStep.height * 8) / 10,
          d: 'r:80',
        };
      }

      layout.routes.push(newRoute);
    });

    this.setState({ layout: layout });
  }

  createRouteGroupsByStart(workflow, fromFilter, toFilter) {
    var routeGroups = [];
    var nextRgId = 1;
    var groupType = 'startOnly';
    if (toFilter !== 0) groupType = 'startEnd';

    //let fromFilter = this.state.routeFromFilter;
    workflow.routes.forEach((r) => {
      if (
        (fromFilter === 0 || fromFilter === r.fromStepId) &&
        (toFilter === 0 || toFilter === r.toStepId)
      ) {
        r.editConditions = false;
        r.editAssignments = false;

        var rg = routeGroups.find((f) => f.fromId === r.fromStepId); // && f.toId === r.endTaskId);
        if (toFilter > 0)
          rg = routeGroups.find(
            (f) => f.fromId === r.fromStepId && f.toId === r.toStepId
          );

        if (rg) {
          rg.routes.push(r);
        }
        if (!rg) {
          //let startTask = workflow.steps.find(t => t.workflowStepId === r.fromStepId);
          //let endTask = workflow.tasks.find(t => t.workflowStepId === r.endTaskId);
          rg = {
            fromId: r.fromStepId,
            toId: -1, //endTask.workflowStepId,
            fromName: r.fromStepName,
            id: nextRgId,
            toName: '',
            routes: [],
            layout: groupType,
            showRearrange: false,
          };
          if (toFilter !== 0) {
            rg.toId = r.toStepId;
            rg.toName = r.toStepName;
          }
          nextRgId++;
          rg.routes.push(r);
          routeGroups.push(rg);
        }

        //r.possibleAssignees = [];
        let toStep = workflow.steps.find(
          (s) => s.workflowStepId === r.toStepId
        );

        // if (toStep) {
        //   r.possibleAssignees = [...toStep.possibleAssignees];
        //   r.possibleAssignees.push({
        //     userId: 0,
        //     userName: 'ricky'
        //   })
        // }
      }
    });

    // normalize display order
    routeGroups.forEach((rg) => {
      rg.routes.sort((a, b) => a.executionOrder - b.executionOrder);
      let previousOrder = 0;
      rg.routes.forEach((rr) => {
        rr.executionOrder = previousOrder + 1;
        previousOrder = rr.executionOrder;
      });
    });
    this.setState({ routeGroups: routeGroups });
  }

  onWindowResize(e) {
    //setViewWidth
    this.setState({ layoutViewWidth: (window.innerWidth - 330) / 2 });
    this.setState({ layoutViewHeight: window.innerHeight - 350 });
    this.setState({
      layoutViewWidth2: window.innerWidth - 305,
      layoutViewHeight2: window.innerHeight - 80,
    });
    this.setState({bottomHeight: window.innerHeight - 260});
  }

  loadWorkflowFromDB() {
    let loc = window.location.pathname;
    let pathParts = loc.split('/');
    let workflowId = parseInt(pathParts[pathParts.length - 1], 10);

    if (this.state.currentWorkflow.workflowId !== 0 && workflowId === 0) {
      let newWorkflow = {
        workflowId: 0,
        name: '',
        steps: [], // to do,  add default steps
        routes: [],
        isNew: true,
      };

      this.setState({ currentWorkflow: newWorkflow, isDirty: true });
    } else if (this.state.currentWorkflow.workflowId !== workflowId) {
      let wf = WorkflowStore.getCurrentWorkflow();

      //if (wf.steps !== undefined) {
      wf.workflowId = workflowId;
      //wf = this.adjustColors(wf);
      //this.setState({ currentWorkflow: wf, isDirty: false });
      GetWorkflow(workflowId);
      GetFormTypesAndTemplatesForWorkflow(workflowId);
      //}
    }
  }

  editStepFilterText(e) {
    let filter = e.target.value;
    if (filter === '') {
      // full list
      let filteredSteps = this.state.currentWorkflow.steps;
      this.setState({
        stepFilterText: e.target.value,
        filteredSteps: filteredSteps,
      });
    } else {
      let filteredSteps = this.state.currentWorkflow.steps.filter((s) =>
        s.name.toLowerCase().includes(filter.toLowerCase())
      );
      this.setState({
        stepFilterText: e.target.value,
        filteredSteps: filteredSteps,
      });
    }
  }

  onChangeUserName(e) {
    let cu = this.state.currentWorkflow;
    cu.userName = e.target.value;
    this.setState({ currentWorkflow: cu, isDirty: true });
  }

  onChangeName(e) {
    let wf = this.state.currentWorkflow;
    wf.name = e.target.value;
    this.setState({ currentWorkflow: wf, isDirty: true });
  }

  switchView(newView) {
    if (newView === 'routes') {
      this.setState({ showFlow: false });
    }
    this.setState({ currentView: newView }, () => {
      this.adjustWFLayout();
    });
  }

  showNewStep() {
    let newStep = { name: '', stepType: 'User', description: '' };
    this.setState({ newStep: newStep, addingStep: true }, () => {
      document.getElementById('new-step-name').focus();
    });
  }

  hideNewStep() {
    this.setState({ addingStep: false });
  }

  onChangeNewStepType(e) {
    let newStep = this.state.newStep;
    newStep.stepType = e.target.value;

    this.setState({ newStep: newStep });
  }

  addStep() {
    let newStep = {};
    var nextId = -1;

    newStep.name = this.state.newStep.name;
    newStep.stepType = this.state.newStep.stepType;
    newStep.description = this.state.newStep.description;
    newStep.requiredFields = [];
    newStep.isActive = true;
    let wf = this.state.currentWorkflow;
    newStep.useStamps = false;
    newStep.customColor = '#FDDD5B';
    let scheme = colorScheme.find((cs) => cs.scheme === newStep.stepType);
    if (scheme !== undefined) {
      newStep.customColor = scheme.background;
      newStep.textColor = scheme.foreground;
    }
    if (wf.steps.length === 0) {
      newStep.displayOrder = 1;
      newStep.workflowStepId = nextId;
    } else {
      let maxDisplay = 1;
      this.state.currentWorkflow.steps.forEach((s) => {
        if (s.displayOrder >= maxDisplay) {
          maxDisplay = s.displayOrder + 1;
        }
        if (s.workflowStepId <= nextId) {
          nextId = s.workflowStepId - 1;
        }
      });
      newStep.displayOrder = maxDisplay;
      newStep.workflowStepId = nextId;
    }
    wf.steps.push(newStep);
    this.setState({ currentWorkflow: wf, isDirty: true });
    let cleanStep = {
      name: '',
      stepType: 'User',
      description: '',
    };
    this.setState({ newStep: cleanStep }, () => {
      document.getElementById('new-step-name').focus();
    });

    // add step to layout
    let layout = this.state.layout;
    var maxY = 0;

    layout.tasks.forEach((t) => {
      if (t.y + t.height > maxY) {
        maxY = t.y + t.height;
      }
    });

    var newTask = {
      id: nextId,
      x: 80,
      y: maxY + 40,
      height: 70,
      width: 180,
      name: this.state.newStep.name,
    };
    if (this.state.newStep.stepType === 'User') newTask.taskType = 'user';
    if (this.state.newStep.stepType === 'Intg') newTask.taskType = 'auto';
    if (this.state.newStep.stepType === 'OCR') newTask.taskType = 'auto';
    if (this.state.newStep.stepType === 'End') newTask.taskType = 'end';
    if (this.state.newStep.stepType === 'Start') newTask.taskType = 'start';
    layout.tasks.push(newTask);
    if (newTask.y + newTask.height + 20 > layout.canvasHeight) {
      layout.canvasHeight = newTask.y + newTask.height + 100;
    }
    this.setState({ layout: layout });
  }

  onDescKeyDown(e) {
    if (e.code === 'Enter') {
      this.addStep();
    }
  }

  onSave() {
    let valid = this.validateWorkflow();
    if (valid) {
      this.setState({ isSaving: true });

      let storeLayout = {
        canvasHeight: this.state.layout.canvasHeight,
        canvasWidth: this.state.layout.canvasWidth,
        tasks: [...this.state.layout.tasks],
        routes: [...this.state.layout.routes],
      };

      storeLayout.tasks.forEach((t) => {
        if (t.name === undefined) t.name = t.taskType;
        delete t.isSelected;
        delete t.hover;
      });
      storeLayout.routes.forEach((r) => {
        delete r.hasProximity;
        delete r.isSelected;
        r.start.x = Math.round(r.start.x);
        r.start.y = Math.round(r.start.y);
        r.end.x = Math.round(r.end.x);
        r.end.y = Math.round(r.end.y);

        if (r.objectId) delete r.objectId;
        if (r.to) delete r.to;
        if (r.from) delete r.from;
        if (r.id) delete r.id;
      });
      console.log(this.state.currentWorkflow);
      UpdateWorkflow(this.state.currentWorkflow, storeLayout);
    }
  }
  editNewStepName(e) {
    let ns = this.state.newStep;
    ns.name = e.target.value;
    this.setState({ newStep: ns });
  }

  editNewStepDesc(e) {
    let ns = this.state.newStep;
    ns.description = e.target.value;
    this.setState({ newStep: ns });
  }

  editStep(step) {
    this.setState({ showStepEditor: true, currentEditStep: step });
  }

  closeStepEditor() {
    this.setState({ showStepEditor: false });
  }

  editStepValue(e, property) {
    let step = this.state.currentEditStep;
    let layout = this.state.layout;
    let task = layout.tasks.find((t) => t.id === step.workflowStepId);
    switch (property) {
      case 'description':
        step.description = e.target.value;
        break;
      case 'name':
        step.name = e.target.value;
        // also update the name in the layout
        if (task !== null && task !== undefined) {
          task.name = e.target.value;
        }
        break;
      case 'notes':
        step.notes = e.target.value;
        break;
      case 'stepType':
        step.stepType = e.target.value;
        if (step.stepType === 'User') task.taskType = 'user';
        if (step.stepType === 'Intg') task.taskType = 'auto';
        if (step.stepType === 'OCR') task.taskType = 'auto';
        if (step.stepType === 'End') task.taskType = 'end';
        if (step.stepType === 'Start') task.taskType = 'start';
        break;
      case 'hasWorker':
        step.hasWorker = e.target.checked;
        break;
      case 'workflowPlugin':
        step.workerId = parseInt(e.target.value);
        if (step.workerId !== 0) {
          let plugin = this.state.availablePlugins.find(
            (p) => p.integrationId === step.workerId
          );
          if (plugin) {
            step.workerName = plugin.pluginName;
            step.workerGen = plugin.moduleGen;
            step.workerConfig = '';
          }
        }
        break;
      case 'workerConfig':
        step.workerConfig = e.target.value;
        break;
      case 'useStamps':
        step.useStamps = e.target.checked;
        break;
      case 'hidden':
        step.isActive = !e.target.checked;
        break;
      default:
        break;
    }
    let cw = this.state.currentWorkflow;
    let cwStep = cw.steps.find((s) => s.workflowStepId === step.workflowStepId);

    if (cwStep) {
      console.log('update mainstep');
      cwStep.name = step.name;
      cwStep.notes = step.notes;
      cwStep.workerConfig = step.workerConfig;
      cwStep.useStamps = step.useStamps;
      cwStep.isActive = step.isActive;
      cwStep.workerName = step.workerName;
      cwStep.workerGen = step.workerGen;
      cwStep.workerId = step.workerId;
      cwStep.hasWorker = step.hasWorker;
      cwStep.stepType = step.stepType;
    }

    this.setState({
      currentEditStep: step,
      currentWorkflow: cw,
      layout: layout,
      isDirty: true,
    });
  }

  onChangeReqFormTypeId(e) {
    let id = parseInt(e.target.value, 10);
    console.log(id);
    this.setState({ reqFieldFormTypeId: e.target.value });
    console.log(this.state.allowedFormTypes);
    let ft = this.state.allowedFormTypes.find((f) => f.formTypeId === id);
    console.log(ft);
    if (ft) {
      let fields = ft.fieldSets[0].fields;
      this.setState({ filteredFields: fields });
    }
  }

  toggleReqFields() {
    let toggle = this.state.showReqFields;
    this.setState({ showReqFields: !toggle });

    if (this.state.reqFieldFormTypeId !== 0) {
      let ft = this.state.allowedFormTypes.find(
        (f) => f.formTypeId === this.state.reqFieldFormTypeId
      );
      if (ft) {
        let fields = ft.fieldSets[0].fields;
        this.setState({ filteredFields: fields });
      }
    }
  }

  onChangeReqField(e) {
    let newReqField = e.target.value;
    this.setState({ reqField: newReqField });
  }

  addReqField() {
    let step = this.state.currentEditStep;
    let reqFieldId = parseInt(this.state.reqField, 10);
    console.log(reqFieldId);
    if (reqFieldId !== 0 && reqFieldId !== undefined && reqFieldId !== '0') {
      let reqFT = this.state.allowedFormTypes.find(
        (f) => f.formTypeId === parseInt(this.state.reqFieldFormTypeId, 10)
      );
      console.log(reqFT);
      if (reqFT) {
        let reqFieldSet = reqFT.fieldSets[0];
        let reqField = reqFieldSet.fields.find(
          (f) => f.fieldId === parseInt(reqFieldId, 10)
        );

        let existReq = step.requiredFields.find(
          (f) => f.fieldId === parseInt(reqFieldId, 10)
        );
        if (!existReq) {
          step.requiredFields.push({
            formTypeId: reqFT.formTypeId,
            fieldId: parseInt(reqFieldId, 10),
            fieldName: reqField.name,
          });

          this.setState({ currentEditStep: step, isDirty: true });
        }
      }
    }
  }

  deleteReq(fieldId) {
    let step = this.state.currentEditStep;
    let reqField = step.requiredFields.find((f) => f.fieldId === fieldId);
    if (reqField) {
      let idx = step.requiredFields.indexOf(reqField);
      if (idx >= 0) {
        step.requiredFields.splice(idx, 1);
      }
      this.setState({ currentEditStep: step, isDirty: true });
    }
  }

  onAddRouteClick() {
    let allSteps = this.state.currentWorkflow.steps;
    this.setState(
      { showNewRoute: true, fromStepId: allSteps[0].workflowStepId },
      () => {
        let fromField = document.getElementById('rt_new_from_text');
        fromField.select();
        fromField.focus();
      }
    );
  }

  onCloseNewRoute() {
    this.setState({ showNewRoute: false });
  }

  editNewRouteFrom(fromValue) {
    let newRt = this.state.newRoute;
    newRt.fromStepId = fromValue;
    this.setState({ newRoute: newRt });
  }

  editNewRouteTo(toValue) {
    let newRt = this.state.newRoute;
    newRt.toStepId = toValue;
    this.setState({ newRoute: newRt });
  }

  addRoute() {
    let cw = this.state.currentWorkflow;
    let newRt = this.state.newRoute;
    let nextRouteId = -1;
    let nextExeOrder = 1;

    cw.routes.forEach((r) => {
      if (r.routeId <= nextRouteId) {
        nextRouteId = nextRouteId - 1;
      }
      if (
        r.fromStepId === newRt.fromStepId &&
        r.executionOrder >= nextExeOrder
      ) {
        nextExeOrder = r.executionOrder + 1;
      }
    });
    newRt.routeId = nextRouteId;
    newRt.toStepId = parseInt(newRt.toStepId);
    newRt.routeCode = 'APPROVE';
    newRt.assignees = [];
    if (formTypes.length > 0) {
      let ft = formTypes[0];
      newRt.fieldChoices = ft.fieldChoices;
      newRt.templateChoices = ft.templateChoices;
      newRt.formTypeId = ft.formTypeId;
      newRt.formTypeName = ft.formTypeName;
    }
    newRt.possibleAssignees = [];
    let toStep = cw.steps.find((s) => s.workflowStepId === newRt.toStepId);

    if (toStep) {
      newRt.toStepName = toStep.name;
      newRt.possibleAssignees = [...toStep.possibleAssignees];
    }
    newRt.fromStepId = parseInt(newRt.fromStepId);
    newRt.executionOrder = nextExeOrder;
    var fromStep = cw.steps.find((s) => s.workflowStepId === newRt.fromStepId);
    if (fromStep) {
      newRt.fromStepName = fromStep.name;
    }
    newRt.conditions = [];
    newRt.assignees = [];
    newRt.fieldChoices = [];
    newRt.templateChoices = [];

    if (formTypes.length === 2) {
      let ft = formTypes[0];
      newRt.fieldChoices = ft.fieldChoices;
      newRt.templateChoices = ft.templateChoices;
      newRt.formTypeId = ft.formTypeId;
      newRt.formTypeName = ft.formTypeName;

      ft.fieldChoices.forEach((fc) => {
        let id = parseInt(fc.fieldId,10) * -1;

        newRt.possibleAssignees.push({
          userId: id,
          userName: '$' + fc.fieldName
        });
      });
    }

    newRt.templateName = 'ANY';
    newRt.templateId = 0;

    cw.routes.push(newRt);
    //var filter = this.state.filteredRoutes;
    //filter.push(newRt);

    let groups = this.state.routeGroups;
    let rg = groups.find((g) => g.fromId === newRt.fromStepId);

    if (rg) {
      rg.routes.push(newRt);
    } else {
      let newRgId = 0;
      groups.forEach((g) => {
        if (g.id >= newRgId) {
          newRgId = g.id + 1;
        }
      });
      let newRg = {
        fromId: newRt.fromStepId,
        fromName: newRt.fromStepName,
        id: newRgId,
        layout: 'startOnly',
        routes: [],
        showRearrange: true,
        toId: -1,
        toName: '',
      };
      newRg.routes.push(newRt);
      groups.push(newRg);
    }

    let cl = this.state.layout;
    let startTask = cl.tasks.find((t) => t.name === newRt.fromStepName);
    let endTask = cl.tasks.find((t) => t.name === newRt.toStepName);
    let layoutRoute = {
      endTaskId: endTask.id,
      startTaskId: startTask.id,
      start: {
        x: startTask.x + startTask.width / 2,
        y: startTask.y + startTask.height,
        d: 'b:50',
      },
      end: {
        x: endTask.x + endTask.width / 2,
        y: endTask.y,
        d: 't:50',
      },
    };
    cl.routes.push(layoutRoute);
    newRt = { fromStepId: 0, toStepId: 0 };
    this.setState(
      {
        currentWorkflow: cw,
        newRoute: newRt,
        activeRoute: undefined,
        routeGroups: groups,
        //filteredRoutes: filter,
        isDirty: true,
        layout: cl,
      },
      () => {
        document.getElementById('rt_new_from_text').focus();
      }
      //() => this.switchtoRoute(nextRouteId)
    );
  }

  addSubRoute(rg) {
    let cw = this.state.currentWorkflow;

    var nextRouteId = -1;
    var nextExeOrder = 1;
    cw.routes.forEach((r) => {
      if (r.routeId <= nextRouteId) {
        nextRouteId = nextRouteId - 1;
      }
      if (r.fromStepId === rg.fromId && r.executionOrder >= nextExeOrder) {
        nextExeOrder = r.executionOrder + 1;
      }
    });
    var newRt = {};
    newRt.routeId = nextRouteId;
    newRt.toStepId = '';
    newRt.routeCode = 'APPROVE';
    newRt.assignees = [];
    newRt.templateId = 0;
    newRt.formTypeId = cw.defaultFormTypeId;
    newRt.formTypeName = cw.defaultFormTypeName;
    newRt.possibleAssignees = [];
    newRt.fromStepId = rg.fromId;
    newRt.fromStepName = rg.fromName;
    newRt.fieldChoices = [];
    let ft = null;

    if (formTypes.length === 2) {
      let ft = formTypes[0];
      newRt.fieldChoices = ft.fieldChoices;
      newRt.templateChoices = ft.templateChoices;
      newRt.formTypeId = ft.formTypeId;
      newRt.formTypeName = ft.formTypeName;
    }



    var fromStep = cw.steps.find((s) => s.workflowStepId === newRt.fromStepId);
    if (fromStep) {
      newRt.toStepName = fromStep.name;
    }

    newRt.executionOrder = nextExeOrder;

    newRt.conditions = [];
    newRt.assignees = [];
    cw.routes.push(newRt);
    //var filter = this.state.filteredRoutes;
    //filter.push(newRt);

    if (rg) {
      rg.routes.push(newRt);
    }

    this.setState(
      {
        currentWorkflow: cw,
        activeRoute: undefined,
        currentRouteId: newRt.Id,
        isDirty: true,
      },
      () => {
        document.getElementById(`rt_routeCode_${newRt.routeId}_text`).focus();
        //let n = 0;
      }
      //() => this.switchtoRoute(nextRouteId)
    );
  }

  addQuickRoute(toStepId, fromStepId) {
    let cw = this.state.currentWorkflow;
    let newRt = {};
    var nextRouteId = -1;
    var nextExeOrder = 1;
    cw.routes.forEach((r) => {
      if (r.routeId <= nextRouteId) {
        nextRouteId = nextRouteId - 1;
      }
      if (
        r.toStepId === toStepId &&
        r.fromStepId === fromStepId &&
        r.executionOrder >= nextExeOrder
      ) {
        nextExeOrder = r.executionOrder + 1;
      }
    });
    newRt.routeId = nextRouteId;
    newRt.routeCode = 'APPROVE';

    newRt.toStepId = toStepId;
    var toStep = cw.steps.find((s) => s.workflowStepId === toStepId);
    if (toStep) {
      newRt.toStepName = toStep.name;
    }

    newRt.fromStepId = fromStepId;
    var fromStep = cw.steps.find((s) => s.workflowStepId === fromStepId);
    if (fromStep) {
      newRt.fromStepName = fromStep.name;
    }

    newRt.executionOrder = nextExeOrder;
    newRt.conditions = [];
    newRt.assignees = [];
    cw.routes.push(newRt);
    //var filter = this.state.filteredRoutes;
    //filter.push(newRt);

    newRt = { toStepId: '0', fromStepId: '0' };

    if (formTypes.length === 1) {
      let ft = formTypes[0];
      newRt.fieldChoices = ft.fieldChoices;
      newRt.templateChoices = ft.templateChoices;
      newRt.formTypeId = ft.formTypeId;
      newRt.formTypeName = ft.formTypeName;
    }

    newRt.templateId = 0;
    newRt.templateName = 'ANY';

    this.setState({
      currentWorkflow: cw,
      newRoute: newRt,
      activeRoute: undefined,
      //filteredRoutes: filter,
      isDirty: true,
    });

    this.createRouteGroupsByStart(cw, fromStepId, toStepId);
    //document.getElementById('newRouteFromSelect').focus();
  }

  removeRoute() {
    let cw = this.state.currentWorkflow;
    let routes = cw.routes;
    let routeToRemove = routes.find((r) => r === this.state.activeRoute);
    if (routeToRemove) {
      var idx = routes.indexOf(routeToRemove);
      routes.splice(idx, 1);
    }
    this.setState({ currentWorkflow: cw, activeRoute: undefined });
  }

  // switchtoRoute(rtId) {
  //   let routes = this.state.currentWorkflow.routes;
  //   let rt = routes.find((f) => f.routeId === rtId);
  //   if (rt) {
  //     this.setState({ activeRoute: rt, showNewCondition: true });

  //     let nc = {
  //       ruleType: 1,
  //       formTypeId: this.state.allowedFormTypes[0].formTypeId,
  //       operator: '=',
  //       fieldId: 0,
  //       value: '',
  //     };

  //     this.setState({ newCondition: nc });
  //   }
  // }

  calculateRuleDesc(routingRule) {
    if (routingRule.ruleType === 1) {
      // RouteCode
      return `RouteCode = '${routingRule.value}'`;
    }
    if (routingRule.ruleType === 2) {
      // Tempalte
      return 'TempalteRule';
    }
    if (routingRule.ruleType === 3) {
      let ft = this.state.allowedFormTypes.find(
        (f) => f.formTypeId === routingRule.formTypeId
      );
      if (ft) {
        return `{FormType} = ${ft.name}`;
      } else {
        return `{FormType} = unknown`;
      }
    }
    if (routingRule.ruleType === 4) {
      let field = this.state.filteredFields.find(
        (f) => f.fieldId === routingRule.fieldId
      );
      if (field) {
        return `[${field.name}] ${routingRule.operator} ${routingRule.value}`;
      } else {
        return `[unknown] ${routingRule.operator} ${routingRule.value}`;
      }
    }
  }

  // toggleShowNewCondition() {
  //   let show = this.state.showNewCondition;
  //   if (!show) {
  //     let nc = {
  //       ruleType: 1,
  //       formTypeId: this.state.allowedFormTypes[0].formTypeId,
  //       operator: '=',
  //       fieldId: 0,
  //       value: '',
  //     };
  //     try {
  //       let fields = this.state.allowedFormTypes[0].fieldSets[0].fields;
  //       this.setState({ filteredFields: fields });
  //     } catch {}
  //     this.setState({ newCondition: nc });
  //   }
  //   this.setState({ showNewCondition: !show });
  // }

  addCondition(routingRule) {
    // let condIds = [];
    // routingRule.conditions.forEach((c) => {
    //   condIds.push(c.routeConditionId);
    // });
    //var minIfd = Math.min(...condIds);
    let minId = lastNewConditionId - 1;

    let nc = {
      ruleType: 4,
      formTypeId: this.state.allowedFormTypes[0].formTypeId,
      routeConditionId: minId,
      operator: '=',
      routeId: routingRule.routeId,
      templateId: 0,
      fieldId: 0,
      value: '',
      ruleDesc: 'New Condition',
    };
    //routingRule.conditions.push(nc);
    //routingRule.hasConditions = true;
    let cw = this.state.currentWorkflow;
    let rt = cw.routes.find((r) => r.routeId === routingRule.routeId);
    rt.conditions.push(nc);
    rt.hasConditions = true;
    //rt = routingRule;
    this.setState({ currentWorkflow: cw, isDirty: true }, () => {
      let newFieldBox = document.getElementById(
        `rt_condField_${minId}_${routingRule.routeId}_text`
      );
      if (newFieldBox) {
        newFieldBox.select();
        newFieldBox.focus();
      }
    });
    lastNewConditionId = minId;
  }

  removeCondition(route, condition) {
    var idx = route.conditions.indexOf(condition);

    route.conditions.splice(idx, 1);
    this.setState({ isDirty: true });
  }

  // toggleEditAssignments(route) {
  //   let rg = this.state.routeGroups;
  //   route.editAssignments = !route.editAssignments;
  //   route.newAssignmentId = '0';
  //   this.setState({ routeGroups: rg });
  // }

  // editAssignment(e, route) {
  //   let rg = this.state.routeGroups;
  //   route.newAssignmentId = e.target.value;
  //   this.setState({ routeGroups: rg });
  // }

  deleteAssignee(route, userId) {
    let assignee = route.assignees.find((a) => a.userId === userId);
    if (assignee) {
      let idx = route.assignees.indexOf(assignee);
      route.assignees.splice(idx, 1);
    }
  }

  toggleEditCondition(route) {
    let rg = this.state.routeGroups;
    route.editConditions = !route.editConditions;
    this.setState({ routeGroups: rg });
  }

  deleteRoute(route) {
    let cw = this.state.currentWorkflow;
    let allRoutes = this.state.currentWorkflow.routes;
    let idx = allRoutes.indexOf(route);
    cw.routes.splice(idx, 1);
    this.setState({ currentWorkflow: cw, isDirty: true });
    let to = this.state.routeToFilter;
    let from = this.state.routeFromFilter;

    // are all routes gone
    let deleteLine = true;
    cw.routes.forEach((r) => {
      if (r.fromStepId === route.fromStepId && r.toStepId === route.toStepId) {
        deleteLine = false;
      }
    });
    if (deleteLine) {
      let layout = this.state.layout;
      let toTask = layout.tasks.find((t) => t.name === route.toStepName);
      let fromTask = layout.tasks.find((t) => t.name === route.fromStepName);
      let deleteRoute = layout.routes.find(
        (f) => f.startTaskId === fromTask.id && f.endTaskId === toTask.id
      );
      if (deleteRoute) {
        let idx = layout.routes.indexOf(deleteRoute);
        layout.routes.splice(idx, 1);
        this.setState({ layout: layout });
      }
    }
    this.applyFilter(to, from);

    // clear layoutRoute if all routes to and from are gone.
    // let layout = this.state.layout;
    // let toTask = layout.tasks.find((t) => t.name === route.toStepName);
    // let fromTask = layout.tasks.find((t) => t.name === route.fromStepName);
    // if(toTask !== null && fromTask != null)
    // {
    //   // do any common routes still exist?

    // }
  }

  /*editCondition(e, condition, editType) {
    let nc = this.state.newCondition;
    if (editType === 'ruleType') {
      condition.ruleType = parseInt(e.target.value);
      condition.ruleDesc = this.calculateRuleDesc(condition);
      //nc.ruleType = parseInt(e.target.value);
      //this.setState({ newCondition: nc });
    }

    if (editType === 'value') {
      condition.value = e.target.value;
      condition.ruleDesc = this.calculateRuleDesc(condition);
      this.setState({ newCondition: nc });
    }

    if (editType === 'formType') {
      let selectedValue = parseInt(e.target.value);
      nc.formTypeId = selectedValue;
      condition.ruleDesc = this.calculateRuleDesc(condition);

      if (nc.ruleType === 4) {
        // apply filter
        let ft = this.state.allowedFormTypes.find(
          (f) => f.formTypeId === selectedValue
        );
        if (ft) {
          let fields = ft.fieldSets[0].fields;
          this.setState({ filteredFields: fields });
        }
      }
      this.setState({ newCondition: nc });
    }

    if (editType === 'field') {
      let nc = this.state.newCondition;
      let selectedValue = parseInt(e.target.value);
      condition.fieldId = selectedValue;
      condition.ruleDesc = this.calculateRuleDesc(condition);
      this.setState({ newCondition: nc });
    }

    if (editType === 'operator') {
      let nc = this.state.newCondition;
      condition.operator = e.target.value;
      condition.ruleDesc = this.calculateRuleDesc(condition);
      this.setState({ newCondition: nc });
    }

    this.setState({ isDirty: true });
  }
  */

  // toggleRearrange(rg) {
  //   let cw = this.state.currentWorkflow;
  //   rg.showRearrange = !rg.showRearrange;
  //   this.setState({ currentWorkflow: cw });
  // }

  editToFilter(e) {
    let to = e;
    if (e === '') {
      to = 0;
    }
    let from = this.state.routeFromFilter;
    this.setState({ routeToFilter: to });
    this.applyFilter(to, from);
  }

  editFromFilter(e) {
    let to = this.state.routeToFilter;
    let from = e;
    if (from === '') {
      from = 0;
    }
    this.setState({ routeFromFilter: from });
    this.applyFilter(to, from);
  }

  applyFilter(to, from) {

    if(to === '') to = 0;
    if(from === '') from = 0;
    let filter = [];
    if (to === 0 && from === 0) {
      filter = this.state.currentWorkflow.routes;
    } else if (to === 0 && from !== 0) {
      filter = this.state.currentWorkflow.routes.filter(
        (r) => r.fromStepId === from
      );
    } else if (to !== 0 && from === 0) {
      filter = this.state.currentWorkflow.routes.filter(
        (r) => r.toStepId === to
      );
    } else if (to !== 0 && from !== 0) {
      filter = this.state.currentWorkflow.routes.filter(
        (r) => r.toStepId === to && r.fromStepId === from
      );
    }
    this.setState({ filteredRoutes: filter }, () => {
      this.adjustWFLayout();
    });

    //update route groups;
    this.createRouteGroupsByStart(this.state.currentWorkflow, from, to);
  }

  toggleFlow(e) {
    this.setState({ showFlow: e.target.checked });
  }

  setSelectedTask(task) {
    if (task === undefined) {
      if (this.state.currentView === 'routes') {
        this.setState({ routeToFilter: 0, routeFromFilter: 0 });
        this.createRouteGroupsByStart(this.state.currentWorkflow, 0, 0);
      }
      if (this.state.currentView === 'steps') {
        this.setState({ showStepEditor: false });
      }
    }

    if (task !== undefined) {
      this.state.currentWorkflow.steps.forEach((s) => {
        if (s.name === task.name) {
          this.editStep(s);
        }
      });

      if (this.state.currentView === 'routes') {
        // update from filter
        this.setState({
          routeFromFilter: task.id,
          routeToFilter: 0,
        });
        this.createRouteGroupsByStart(this.state.currentWorkflow, task.id, 0);
      }
    }
  }

  setSelectedRoute(route) {
    this.setState({
      routeFromFilter: route.startTaskId,
      routeToFilter: route.endTaskId,
      currentView: 'routes',
    });

    this.createRouteGroupsByStart(
      this.state.currentWorkflow,
      route.startTaskId,
      route.endTaskId
    );

    this.applyFilter(route.endTaskId, route.startTaskId);
  }

  onUpdateTree(tasks, routes) {
    let layout = this.state.layout;
    layout.tasks = tasks;
    layout.routes = routes;

    // check for boundry expansion
    tasks.forEach((t) => {
      if (t.x + t.width + 50 > layout.canvasWidth) {
        layout.canvasWidth = t.x + t.width + 100;
      }
      if (t.y + t.height + 50 > layout.canvasHeight) {
        layout.canvasHeight = t.y + t.height + 100;
      }
    });

    // any new routes
    var routeExists = false;
    routes.forEach((r) => {
      routeExists = false;
      this.state.currentWorkflow.routes.forEach((r2) => {
        if (r.startTaskId === r2.fromStepId && r.endTaskId === r2.toStepId) {
          routeExists = true;
        }
      });
      if (!routeExists) {
        // create the route.
        var nextRouteId = -1;
        this.state.currentWorkflow.routes.forEach((r) => {
          if (r.routeId <= nextRouteId) {
            nextRouteId = nextRouteId - 1;
          }
        });
        var fromStep = this.state.currentWorkflow.steps.find(
          (s) => s.workflowStepId === r.startTaskId
        );
        var toStep = this.state.currentWorkflow.steps.find(
          (s) => s.workflowStepId === r.endTaskId
        );
        if (fromStep && toStep) {
          var newRoute = {
            conditions: [],
            assignees: [],

            fromStepId: r.startTaskId,
            fromStepName: fromStep.name,
            routeId: nextRouteId,
            toStepId: r.endTaskId,
            toStepName: toStep.name,
          };
          newRoute.routeCode = 'APPROVE';
          if (formTypes.length === 1) {
            let ft = formTypes[0];
            newRoute.fieldChoices = ft.fieldChoices;
            newRoute.templateChoices = ft.templateChoices;
            newRoute.formTypeId = ft.formTypeId;
            newRoute.formTypeName = ft.formTypeName;
          }
          newRoute.templateId = 0;
          newRoute.templateName = 'ANY';
          let cw = this.state.currentWorkflow;
          cw.routes.push(newRoute);
          this.setState({ currentWorkflow: cw }, () => {
            this.applyFilter(r.endTaskId, r.startTaskId);
          });
          //
        }
      }
    });

    this.setState({ layout: layout, isDirty: true });
  }

  toggleFullScreen() {
    let f = this.state.fullScreenFlow;
    this.setState({ fullScreenFlow: !f });
  }

  setSetDragging(step) {
    this.dragStep = step;
  }

  onStepDragOver(e, step) {
    if (
      this.dragStep !== undefined &&
      this.dragStep.workflowStepId !== step.workflowStepId &&
      this.dragTarget.workflowStepId !== step.workflowStepId
    ) {
      let fs = this.state.filteredSteps;
      this.dragTarget = step;
      this.setState({ filteredSteps: fs });
    }
  }

  toggleRowCheck(rr, rg) {
    let rgs = this.state.routeGroups;
    if (rr.checked === undefined) {
      rr.checked = true;
    } else {
      rr.checked = !rr.checked;
    }
    let rCount = 0;
    rg.routes.forEach((r) => {
      if (r.checked === true) rCount++;
    });
    rg.checkCount = rCount;
    this.setState({ routeGroups: rgs });
  }

  onStepDrop(step) {
    let fs = this.state.filteredSteps;
    this.setState({ filteredSteps: fs });
    let currentSteps = this.state.currentWorkflow.steps;
    currentSteps.sort((a, b) => a.displayOrder - b.displayOrder);
    let idx = currentSteps.indexOf(this.dragStep);

    currentSteps.splice(idx, 1);
    let idx2 = currentSteps.indexOf(this.dragTarget);
    currentSteps.splice(idx2, 0, this.dragStep);

    let displayIdx = 1;
    currentSteps.forEach((s) => {
      s.displayOrder = displayIdx;
      displayIdx++;
    });

    let filter = this.state.stepFilterText;
    let filteredSteps = this.state.currentWorkflow.steps;
    if (filter !== '') {
      filteredSteps = this.state.currentWorkflow.steps.filter((s) =>
        s.name.toLowerCase().includes(filter.toLowerCase())
      );
    }
    this.dragTarget = {};
    filteredSteps.sort((a, b) => a.displayOrder - b.displayOrder);
    this.setState({ filteredSteps: filteredSteps, isDirty: true });
  }

  setCurrentRoute(tagName) {
    let idx = tagName.lastIndexOf('_');
    let routeId = tagName.substring(idx + 1);
    //let cw = this.state.currentWorkflow;
    //let rtUpdate = cw.routes.find((r) => r.routeId === parseInt(routeId, 10));

    let rid = parseInt(routeId, 10);
    let rgs = this.state.routeGroups;
    let currentRg = 0;
    rgs.forEach((rg) => {
      let r = rg.routes.find((f) => f.routeId === rid);
      if (r) {
        currentRg = rg.id;
      }
    });
    this.setState({ currentRouteId: rid, currentRouteGroup: currentRg });
  }

  onRouteGroupKeyUp(e) {
    if (e.keyCode === 115) {
      // F4
      //this.addSubRoute(this.state.currentRouteGroup);
      e.preventDefault();
    }
    if (e.keyCode === 119) {
      // F8
      let rid = this.state.currentRouteId;
      let route = this.state.currentWorkflow.routes.find(
        (r) => r.routeId === rid
      );
      this.addCondition(route);
      e.preventDefault();
    }
    if (e.keyCode === 120) {
      // F9
      this.addAssigneeToRoute();
      e.preventDefault();
    }
  }

  onRouteHotKey(e) {
    if (e.keyCode === 115) {
      let rgs = this.state.routeGroups;
      let rg = rgs.find((r) => r.id === this.state.currentRouteGroup);
      this.addSubRoute(rg);
      e.preventDefault();
    }
    // if (e.keyCode === 119) {
    //   let rid = this.state.currentRouteId;
    //   let route = this.state.currentWorkflow.routes.find(
    //     (r) => r.routeId === rid
    //   );
    //   this.addCondition(route);
    //   e.preventDefault();
    // }
    // if (e.keyCode === 119) {
    //   e.preventDefault();
    //   this.addAssigneeToRoute();
    // }
    if (e.keyCode === 120) {
      // F9
      e.preventDefault();
    }
    if (e.keyCode === 113) {
      // F2
      this.onAddRouteClick();
      e.preventDefault();
    }
  }

  addAssigneeToRoute() {
    let routeId = this.state.currentRouteId;
    let cw = this.state.currentWorkflow;
    let allRoutes = this.state.currentWorkflow.routes;
    let route = allRoutes.find((r) => r.routeId === routeId);
    let minId = lastNewAssigneeId - 1;
    lastNewAssigneeId = minId;
    if (route) {
      let newAssignee = {
        routeQueueId: minId,
        userId: '',
        userName: '',
      };
      route.assignees.push(newAssignee);
    }
    this.setState({ currentWorkflow: cw }, () => {
      document
        .getElementById(`rt_assignment_${minId}_${route.routeId}_text`)
        .focus();
    });
    this.adjustWFLayout();
  }

  updateRouteCode(route, routeCode) {
    let cw = this.state.currentWorkflow;
    let rt = cw.routes.find((r) => r.routeId === route.routeId);
    rt.routeCode = routeCode;
    //rt.routeCode = routeCode.value;
    this.setState({ currentWorkflow: cw, isDirty: true });
  }

  updateFormType(route, ft) {
    let cw = this.state.currentWorkflow;
    let rt = cw.routes.find((r) => r.routeId === route.routeId);
    console.log(rt);
    rt.formTypeId = parseInt(ft, 10);

    let ftChoice = formTypes.find((f) => f.formTypeId === parseInt(ft, 10));
    console.log(ftChoice);
    if (ftChoice) {
      rt.templateChoices = ftChoice.templateChoices;
      rt.fieldChoices = [...ftChoice.fieldChoices];
      rt.conditions.forEach((rtc) => {
        let field = rt.fieldChoices.find((f) => f.fieldId === rtc.fieldId);
        if (field === undefined) {
          console.log('field not there');
          rtc.fieldId = null;
        }
      });
    }
    console.log(rt);

    //rt.routeCode = routeCode.value;
    this.setState({ currentWorkflow: cw, isDirty: true });
  }

  onEditCondValue(e, route, condition) {
    let cw = this.state.currentWorkflow;
    let rt = cw.routes.find((r) => r.routeId === route.routeId);
    let cond = rt.conditions.find(
      (c) => c.routeConditionId === condition.routeConditionId
    );
    cond.value = e.target.value;
    this.setState({ currentWorkflow: cw, isDirty: true });
  }

  updateCondition(route, condition, val, changeType) {
    let cw = this.state.currentWorkflow;
    let rt = cw.routes.find((r) => r.routeId === route.routeId);
    let cond = rt.conditions.find(
      (c) => c.routeConditionId === condition.routeConditionId
    );

    if (changeType === 'field' && !isNaN(val) && val !== '') {
      cond.fieldId = parseInt(val);
    }
    if (changeType === 'op') {
      cond.operator = val;
    }
    this.setState({ currentWorkflow: cw, isDirty: true }, () => {});
  }

  deleteCondition(route, condition) {
    let cw = this.state.currentWorkflow;
    let rt = cw.routes.find((r) => r.routeId === route.routeId);
    let cond = rt.conditions.find(
      (c) => c.routeConditionId === condition.routeConditionId
    );
    let idx = rt.conditions.indexOf(cond);

    if (idx >= 0) {
      rt.conditions.splice(idx, 1);
    }

    this.setState({ currentWorkflow: cw, isDirty: true });
  }

  deleteAssignment(route, assignment) {
    let cw = this.state.currentWorkflow;
    let rt = cw.routes.find((r) => r.routeId === route.routeId);
    let idx = rt.assignees.indexOf(assignment);
    if (idx >= 0) {
      rt.assignees.splice(idx, 1);
    }
    this.setState({ currentWorkflow: cw, isDirty: true });
  }

  copyRoute(rg, route) {
    let cw = this.state.currentWorkflow;

    var nextRouteId = -1;
    var nextExeOrder = 1;
    cw.routes.forEach((r) => {
      if (r.routeId <= nextRouteId) {
        nextRouteId = nextRouteId - 1;
      }
      if (r.fromStepId === rg.fromId && r.executionOrder >= nextExeOrder) {
        nextExeOrder = r.executionOrder + 1;
      }
    });
    var newRt = {};
    newRt.routeId = nextRouteId;
    newRt.toStepId = route.toStepId;
    newRt.toStepName = route.toStepName;
    newRt.routeCode = route.routeCode;
    newRt.assignees = [];
    newRt.formTypeId = route.formTypeId;
    newRt.formTypeName = route.formTypeName;
    newRt.possibleAssignees = [];
    newRt.fromStepId = route.fromStepId;
    newRt.fromStepName = route.fromStepName;
    newRt.fieldChoices = [];
    let ft = cw.formTypeChoices.find((f) => f.formTypeId === route.formTypeId);
    if (ft) {
      newRt.fieldChoices = [...route.fieldChoices];
    }

    newRt.executionOrder = nextExeOrder;

    newRt.conditions = [];
    newRt.assignees = [];
    let minId = lastNewConditionId - 1;
    route.conditions.forEach((cond) => {
      newRt.conditions.push({
        routeId: nextRouteId,
        formTypeId: route.formTypeId,
        routeConditionId: minId,
        fieldId: cond.fieldId,
        ruleType: cond.ruleType,
        templateId: 0,
        releDesc: route.ruleDesc,
        operator: cond.operator,
        value: cond.value,
      });
      minId--;
    });

    newRt.templateChoices = [...route.templateChoices];
    newRt.templateId = route.templateId;
    newRt.templateName = route.templateName;
    lastNewConditionId = minId;

    let minAssId = lastNewAssigneeId - 1;
    route.assignees.forEach((ass) => {
      newRt.assignees.push({
        routeQueueId: minAssId,
        userId: ass.userId,
        userName: ass.userName,
      });
      minAssId--;
    });
    route.possibleAssignees.forEach((ass2) => {
      newRt.possibleAssignees.push({
        userId: ass2.userId,
        userName: ass2.userName,
      });
    });

    route.fieldChoices.forEach((fc) => {
      let id = parseInt(fc.fieldId,10) * -1;

      newRt.possibleAssignees.push({
        userId: id,
        userName: '$' + fc.fieldName
      });
    });

    cw.routes.push(newRt);
    if (rg) {
      rg.routes.push(newRt);
    }

    this.setState(
      {
        currentWorkflow: cw,
        currentRouteId: newRt.Id,
        isDirty: true,
      },
      () => {
        this.adjustWFLayout();
      }
    );
  }

  adjustWFLayout() {
    let routeDiv = document.getElementById('wf_routeView');
    if (routeDiv) {
      let wfViewHeight = routeDiv.clientHeight - 250;
      this.setState({ layoutViewHeight: wfViewHeight });
    }
  }

  clearFilter() {
    this.setState({ routeToFilter: '', routeFromFilter: '' });
    this.applyFilter(0, 0);
  }

  editRouteAssignee(val, assignment, route) {
    let currentWf = this.state.currentWorkflow;
    let assignee = route.possibleAssignees.find((a) => a.userId === val);
    if (assignee) {
      assignment.userId = val;
      assignment.userName = assignee.userName;

      assignment = val;

      this.setState({ currentWorkflow: currentWf, isDirty: true }, () => {});
    }
  }

  onEditToStep(val, route) {
    let currentWf = this.state.currentWorkflow;
    route.toStepId = val;
    let toStep = currentWf.steps.find((s) => s.workflowStepId === val);
    if (toStep !== undefined) {
      route.toStepName = toStep.name;
      route.possibleAssignees = [...toStep.possibleAssignees];
      if(route.fieldChoices) {
      route.fieldChoices.forEach((fc) => {
        let id = parseInt(fc.fieldId,10) * -1;

        route.possibleAssignees.push({
          userId: id,
          userName: '$' + fc.fieldName
        });
      });
      }
      this.setState({ currentWorkflow: currentWf, isDirty: true }, () => {});
    } else {
      route.toStepName = '';
      route.toStepId = '';
      this.setState({ currentWorkflow: currentWf });
    }
  }

  setRouteDragging(e, route) {
    e.dataTransfer.setData('Route', route);
    e.dataTransfer.dropEffect = 'copy';
    this.dragRoute = route;
  }

  onRouteDragLeave(e, route) {
    //this.dragRoute = {};
    //this.dragRouteTarget = {};
  }

  onRouteDragOver(e, route) {
    if (route.routeId === undefined && this.dragRoute !== undefined) {
      let rg = this.state.routeGroups;
      this.dragRouteTarget = route.routes[route.routes.length - 1];
      this.dragRouteDir = 'below';
      this.setState({ routeGroups: rg });
      e.preventDefault();
      e.dataTransfer.dropEffect = 'move';
    } else if (
      this.dragRoute !== undefined &&
      this.dragRoute.routeId !== route.routeId &&
      this.dragRouteTarget.routeId !== route.routeId &&
      this.dragRoute.fromStepId === route.fromStepId
    ) {
      let rg = this.state.routeGroups;
      this.dragRouteTarget = route;
      this.dragRouteDir = 'above';
      this.setState({ routeGroups: rg });
      e.preventDefault();
      e.dataTransfer.dropEffect = 'move';
    }
  }

  onRouteDrop(route) {
    let routeGroups = this.state.routeGroups;
    let targetRg = [];
    routeGroups.forEach((rg) => {
      rg.routes.forEach((r) => {
        if (r.routeId === this.dragRouteTarget.routeId) {
          targetRg = rg;
        }
      });
    });
    if (targetRg.routes.length > 0) {
      let movingRoute = targetRg.routes.find(
        (r) => r.routeId === route.routeId
      );
      let idx1 = targetRg.routes.indexOf(movingRoute);
      targetRg.routes.splice(idx1, 1);
      let newNeighbor = targetRg.routes.find(
        (r) => r.routeId === this.dragRouteTarget.routeId
      );
      let idx2 = targetRg.routes.indexOf(newNeighbor);
      targetRg.routes.splice(idx2, 0, movingRoute);

      let exeOrder = 1;
      targetRg.routes.forEach((rg) => {
        rg.executionOrder = exeOrder;
        exeOrder++;
      });
    }
    this.dragRoute = {};
    this.dragRouteTarget = {};
    this.setState({ routeGroups: routeGroups, isDirty: true });
  }

  onRouteClick(route) {
    let rid = route.routeId;
    let rgs = this.state.routeGroups;
    let currentRg = 0;
    rgs.forEach((rg) => {
      let r = rg.routes.find((f) => f.routeId === rid);
      if (r) {
        currentRg = rg.id;
      }
    });
    this.setState({ currentRouteId: rid, currentRouteGroup: currentRg });
  }

  validateWorkflow() {
    let cw = this.state.currentWorkflow;
    let hasError = false;
    cw.routes.forEach((r) => {
      if (r.toStepId === '') {
        this.setState({
          errorMessage: `Can't save. At least one route is missing To Step. Source: '${r.fromStepName}'`,
        });
        hasError = true;
      }

      if (r.routeCode === '' || r.routeCode === null) {
        this.setState({
          errorMessage:
            "Can't save.  One or more routes are missing route code",
        });
        hasError = true;
      }
    });
    if (!hasError) {
      this.setState({ errorMessage: '' });
    }
    return !hasError;
  }

  updateTemplate(route, templateId) {
    let currentWf = this.state.currentWorkflow;
    route.templateId = templateId;
    this.setState({ currentWf: currentWf, isDirty: true });
  }

  copyCheckedRoutes(rg) {
    let routesToCopy = [];
    rg.routes.forEach((r) => {
      if (r.checked) {
        let newR = { ...r };
        newR.templateChoices = [];
        newR.fieldChoices = [];
        newR.formTypeChoices = [];
        //newR.possibleAssignees = [];
        routesToCopy.push(newR);
      }
    });
    let json = JSON.stringify(routesToCopy);
    console.log(navigator.clipboard);
    console.log(json);
    if(navigator.clipboard) {
      navigator.clipboard.writeText(json);
    } else {
      this.setState({routeCopy: json});
    }

    this.setState({ hasRoutesCopied: true });
  }

  async pasteRoutes(rg) {
    let rgs = this.state.routeGroups;
    let cw = this.state.currentWorkflow;
    let clipText = '';
    console.log('paste');
    if(navigator.clipboard) {
      console.log('readingText');
      await navigator.clipboard.readText().then((ct) => {
        clipText = ct;
        console.log(ct);
      });

      console.log(clipText);
    } else {

      clipText = this.state.routeCopy;
    }
    console.log(clipText);
    let newRoutes = JSON.parse(clipText);

    if (newRoutes) {
      newRoutes.forEach((route) => {
        var nextRouteId = -1;
        var nextExeOrder = 1;
        cw.routes.forEach((r) => {
          if (r.routeId <= nextRouteId) {
            nextRouteId = nextRouteId - 1;
          }
          if (
            r.fromStepId === rg.fromId &&
            r.executionOrder >= nextExeOrder
          ) {
            nextExeOrder = r.executionOrder + 1;
          }
        });
        let newRt = {};
        newRt.routeId = nextRouteId;
        newRt.toStepId = route.toStepId;
        newRt.toStepName = route.toStepName;
        newRt.routeCode = route.routeCode;
        newRt.assignees = [];
        newRt.formTypeId = route.formTypeId;
        newRt.formTypeName = route.formTypeName;
        newRt.possibleAssignees = [];
        newRt.fromStepId = rg.fromId;
        newRt.fromStepName = rg.fromName;
        newRt.fieldChoices = [];

        let ft = formTypes.find((f) => f.formTypeId === route.formTypeId);
        if (ft) {
          newRt.fieldChoices = [...ft.fieldChoices];
          newRt.templateChoices = [...ft.templateChoices];
        }
        newRt.executionOrder = nextExeOrder;
        newRt.conditions = [];
        newRt.assignees = [];
        let minId = lastNewConditionId - 1;
        route.conditions.forEach((cond) => {
          newRt.conditions.push({
            routeId: nextRouteId,
            formTypeId: route.formTypeId,
            routeConditionId: minId,
            fieldId: cond.fieldId,
            ruleType: cond.ruleType,
            templateId: 0,
            releDesc: route.ruleDesc,
            operator: cond.operator,
            value: cond.value,
          });
          minId--;
        });

        newRt.templateId = route.templateId;
        newRt.templateName = route.templateName;
        lastNewConditionId = minId;
        cw.routes.push(newRt);
        let minAssId = lastNewAssigneeId - 1;
        route.assignees.forEach((ass) => {
          newRt.assignees.push({
            routeQueueId: minAssId,
            userId: ass.userId,
            userName: ass.userName,
          });
          minAssId--;
        });
        console.log(route.possibleAssignees);
        route.possibleAssignees.forEach((ass2) => {
          newRt.possibleAssignees.push({
            userId: ass2.userId,
            userName: ass2.userName,
          });
        });
        if (rg) {
          rg.routes.push(newRt);
        }
      });

      this.setState(
        {
          currentWorkflow: cw,
          //currentRouteId: newRt.Id,
          isDirty: true,
        },
        () => {
          this.adjustWFLayout();
        }
      );
    }

    this.setState({ hasRoutesCopied: false });


  }

  onSaveAndNav() {
    this.onSave();
    AcceptNav();
  }

  onStay() {
    this.setState({ showNav: false });
  }

  onDiscardAndNav() {
    AcceptNav();
  }

  toggleShow(e, showVar) {
    if (showVar === 'formTypes') {
      this.setState({ showFormTypes: e.target.checked });
    }
    if (showVar === 'templates') {
      this.setState({ showTemplates: e.target.checked });
    }
    if (showVar === 'conditions') {
      this.setState({ showConditions: e.target.checked });
    }
    if (showVar === 'assignees') {
      this.setState({ showAssignees: e.target.checked });
    }
  }

  render() {
    return (
      <div className='sidebarLayout d-flex flex-row flex-fill'>
        <div className='sidebar-container'>
          <WorkflowSidebar />
        </div>
        {!this.state.fullScreenFlow && (
          <div
            className='flex-fill'
            style={{
              display: 'grid',
              gridTemplateColumns: '650px 1fr',
              gridTemplateRows: '65px 40px 1fr 15px',
            }}
          >

            <div className='row mb-3 mt-3 ms-3'>
                  <label
                    className='col-sm-2 col-form-label'
                    htmlFor='txtUserName'
                  >
                    Workflow:
                  </label>
                  <div className='col-sm-10'>
                    <input
                      type='text'
                      className='form-control'
                      id='txtUserName'
                      value={this.state.currentWorkflow.name}
                      onChange={this.onChangeName}
                      placeholder='Workflow Name'
                    />
                  </div>
                </div>
            <div
              id='userPills'
              style={{
                gridColumnStart: '1',
                gridColumnEnd: '3',
                gridRow: '2 / 2',
                marginLeft: '16px',
              }}
            >

              <ul className='nav nav-pills'>

                <li className='nav-item'>
                  <span
                    className={`nav-link ${
                      this.state.currentView === 'steps' ? 'active' : ''
                    }`}
                    style={{ cursor: 'pointer' }}
                    onClick={() => this.switchView('steps')}
                  >
                    Steps
                  </span>
                </li>
                <li className='nav-item'>
                  <span
                    className={`nav-link ${
                      this.state.currentView === 'routes' ? 'active' : ''
                    }`}
                    style={{ cursor: 'pointer' }}
                    onClick={() => this.switchView('routes')}
                  >
                    Routes
                  </span>
                </li>
                <li className='nav-item'>
                  <span
                    className={`nav-link ${
                      this.state.currentView === 'flow' ? 'active' : ''
                    }`}
                    style={{ cursor: 'pointer' }}
                    onClick={() => this.switchView('flow')}
                  >
                    Layout
                  </span>
                </li>
                {/*} <li className='nav-item'>
                <span
                  className={`nav-link ${
                    this.state.showFlow ? 'active' : ''
                  }`}
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.toggleFlow()}
                >
                  Flow
                </span>
                </li>*/}

                {this.state.isDirty &&
                  this.state.currentWorkflow.name !== '' &&
                  !this.state.isSaving && (
                    <li className='nav_item'>
                      <button
                        className='btn btn-success'
                        onClick={this.onSave}
                        style={{ marginLeft: '30px' }}
                      >
                        <FontAwesomeIcon icon={solid('save')} /> Save Changes
                      </button>
                    </li>
                  )}
                {this.state.isSaving && (
                  <li className='nav_item'>
                    <button
                      className='btn btn-success'
                      style={{ marginLeft: '30px', width: '100px' }}
                    >
                      <FontAwesomeIcon icon={solid('spinner')} spin />
                    </button>
                  </li>
                )}
                {this.state.errorMessage !== '' && (
                  <li className='nav_item ms-2 mb-2'>
                    <div className='alert alert-sm alert-danger'>
                      {this.state.errorMessage}
                    </div>
                  </li>
                )}
              </ul>
            </div>

            {this.state.currentView === 'steps' && (
              <div
                className='row'
                style={{
                  gridColumnStart: '1',
                  gridColumnEnd: '3',
                  gridRow: '3 / 3',
                  margin: '8px',
                  position: 'relative',
                }}
              >
                <div
                  id='wfSteps'
                  className='card p-0 col-6 mr-2'
                >
                  <div className='card-header d-flex'>
                    <div className='me-auto'>Steps</div>
                    <div className='ms-auto'>
                      <FontAwesomeIcon
                        icon={solid('filter')}
                        className='me-2'
                      />
                      <input
                        type='text'
                        value={this.state.stepFilterText}
                        onChange={this.editStepFilterText}
                      />
                    </div>
                  </div>
                  <div
                    className='card-body'
                    style={{
                      height: this.state.cardHeight,
                      overflow: 'auto',
                      padding: '0px',
                    }}
                  >

                      <div
                        className='row'
                        style={{
                          marginLeft: '0px',
                          marginRight: '0px',
                          maxHeight: '100%',
                          overflow: 'auto',
                          padding: '6px',
                        }}
                      >
                        {this.state.filteredSteps.map((step) => (
                          <React.Fragment key={step.workflowStepId}>
                            {step.workflowStepId ===
                              this.dragTarget.workflowStepId && (
                              <div>
                                <FontAwesomeIcon
                                  style={{ color: '#23B221' }}
                                  icon={solid('share')}
                                />
                              </div>
                            )}
                            <div
                              className={`mt-2 ${
                                !step.isActive
                                  ? 'workflowStep-InActive'
                                  : step.stepType === 'Start'
                                  ? 'workflowStep-Start'
                                  : step.stepType === 'User'
                                  ? 'workflowStep-User'
                                  : 'workflowStep-Auto'
                              }`}
                              draggable='true'
                              onDragStart={() => this.setSetDragging(step)}
                              onDragOver={(e) => this.onStepDragOver(e, step)}
                              onDragEnd={() => this.onStepDrop(step)}
                            >
                              <div style={{ float: 'left' }}>
                                <div
                                  style={{
                                    fontSize: '14pt',
                                    fontWeight: '700',
                                  }}
                                >
                                  {step.stepType === 'User' && (
                                    <span style={{ color: '#1F8F8F' }}>
                                      <FontAwesomeIcon icon={solid('user')} />{' '}
                                    </span>
                                  )}
                                  {step.stepType === 'Start' && (
                                    <span style={{ color: '#39B02F' }}>
                                      <FontAwesomeIcon icon={solid('play')} />{' '}
                                    </span>
                                  )}
                                  {(step.stepType === 'Intg' ||
                                    step.stepType === 'OCR') && (
                                    <span style={{ color: '#777' }}>
                                      <FontAwesomeIcon icon={solid('gears')} />{' '}
                                    </span>
                                  )}
                                  {step.stepType === 'End' && (
                                    <span style={{ color: '#777' }}>
                                      <FontAwesomeIcon icon={solid('stop')} />{' '}
                                    </span>
                                  )}
                                  {step.name}
                                </div>
                                <div
                                  style={{
                                    fontSize: '9pt',
                                    fontStyle: 'italic',
                                  }}
                                >
                                  {step.description}
                                </div>
                              </div>
                              <div style={{ float: 'right' }}>
                                <button
                                  className='btn btn-primary btn-sm btn-circle mt-2'
                                  onClick={() => this.editStep(step)}
                                >
                                  <FontAwesomeIcon icon={solid('pencil')} />
                                </button>
                              </div>
                            </div>
                            {step.dragHover === 'under' && <div>Under</div>}
                          </React.Fragment>
                        ))}

                        {!this.state.addingStep && (
                          <div style={{ marginTop: '8px' }}>
                            <button
                              className='btn btn-success me-2'
                              onClick={this.showNewStep}
                            >
                              <FontAwesomeIcon icon={solid('plus')} /> Add
                              workflow step
                            </button>
                          </div>
                        )}
                        {this.state.addingStep && (
                          <div>
                            <div>
                              <div>
                                <strong>New Step</strong>
                                <div className='row'>
                                  <div className='col-6'>
                                    <label className='form-label'>Name:</label>
                                    <input
                                      type='text'
                                      id='new-step-name'
                                      className='form-control'
                                      value={this.state.newStep.name}
                                      onChange={this.editNewStepName}
                                      placeholder='Step Name'
                                    />
                                  </div>
                                  <div className='col-6'>
                                    <label className='form-label'>Type:</label>
                                    <select
                                      className='form-select'
                                      value={this.state.newStep.stepType}
                                      onChange={this.onChangeNewStepType}
                                    >
                                      <option value='Start'>Start</option>
                                      <option value='OCR'>OCR</option>
                                      <option value='User'>User</option>
                                      <option value='Intg'>Integration</option>
                                      <option value='End'>End</option>
                                    </select>
                                  </div>
                                  <div className='col-6'>
                                    <label className='form-label'>
                                      Description:
                                    </label>
                                    <input
                                      type='text'
                                      className='form-control'
                                      value={this.state.newStep.description}
                                      onChange={this.editNewStepDesc}
                                      onKeyDown={this.onDescKeyDown}
                                      placeholder='Short description'
                                    />
                                  </div>
                                </div>
                                <button
                                  style={{ marginTop: '4px' }}
                                  className='btn btn-success'
                                  onClick={this.addStep}
                                >
                                  Add Step
                                </button>
                                <button
                                  style={{
                                    marginTop: '4px',
                                    marginLeft: '4px',
                                  }}
                                  className='btn btn-secondary'
                                  onClick={this.hideNewStep}
                                >
                                  Close
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>


                  </div>
                </div>
                {this.state.showStepEditor && (

                     <div
                  id='wfEditStep'
                  className='card col-6  p-0 ml-2'
                >
                  <div className='card-header d-flex'>
                    <div className='me-auto'>Step</div>
                    <div className='ms-auto'>
                      <FontAwesomeIcon
                        icon={solid('filter')}
                        className='me-2'
                      />
                      <input
                        type='text'
                        value={this.state.stepFilterText}
                        onChange={this.editStepFilterText}
                      />
                    </div>
                  </div>
                    <div className='card-body row'>
                        <div className='col-8'>
                          <div className='row'>
                            <div className='col-6'>
                              <div className='mb-3'>
                                <label
                                  htmlFor='txtStepName'
                                  className='form-label'
                                >
                                  Name:
                                </label>
                                <input
                                  type='text'
                                  className='form-control'
                                  id='txtStepName'
                                  value={this.state.currentEditStep.name}
                                  onChange={(event) =>
                                    this.editStepValue(event, 'name')
                                  }
                                />
                              </div>
                              <div className='mb-3'>
                                <label
                                  htmlFor='selStepType'
                                  className='form-label'
                                >
                                  Step Type:
                                </label>
                                <select
                                  className='form-select'
                                  value={this.state.currentEditStep.stepType}
                                  onChange={(event) =>
                                    this.editStepValue(event, 'stepType')
                                  }
                                >
                                  <option value='Start'>Start</option>
                                  <option value='OCR'>OCR</option>
                                  <option value='User'>User</option>
                                  <option value='Intg'>Integration</option>
                                  <option value='End'>End</option>
                                </select>
                              </div>
                              <div className='mb-3'>
                                <div className='from-check'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    checked={
                                      !this.state.currentEditStep.isActive
                                    }
                                    onChange={(event) =>
                                      this.editStepValue(event, 'hidden')
                                    }
                                  />{' '}
                                  <label className='form-check-label'>
                                    Hidden
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className='col-6'>
                              <div className='mb-3'>
                                <label
                                  htmlFor='txtDescription'
                                  className='form-label'
                                >
                                  Description:
                                </label>
                                <input
                                  type='text'
                                  className='form-control'
                                  id='txtDescription'
                                  value={this.state.currentEditStep.description}
                                  onChange={(event) =>
                                    this.editStepValue(event, 'description')
                                  }
                                />
                              </div>
                              {this.state.currentEditStep.stepType ===
                                'User' && (
                                <div className='from-check'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    checked={
                                      this.state.currentEditStep.useStamps
                                    }
                                    onChange={(event) =>
                                      this.editStepValue(event, 'useStamps')
                                    }
                                  />{' '}
                                  <label className='form-check-label'>
                                    Use Stamps
                                  </label>
                                </div>
                              )}
                              {this.state.currentEditStep.stepType ===
                                'Intg' && (
                                <div
                                  className='mb-3 p-1'
                                  style={{
                                    backgroundColor: '#F2F2F2',
                                    border: 'solid 1px #D1D1D1',
                                    borderRadius: '6px',
                                  }}
                                >
                                  <div className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      checked={
                                        this.state.currentEditStep.hasWorker
                                      }
                                      onChange={(event) =>
                                        this.editStepValue(event, 'hasWorker')
                                      }
                                    />
                                    <label className='form-check-label'>
                                      Use workflow plugin
                                    </label>
                                  </div>
                                  {this.state.currentEditStep.hasWorker && (
                                    <div>
                                      <select
                                        className='form-select'
                                        value={
                                          this.state.currentEditStep.workerId
                                        }
                                        onChange={(event) =>
                                          this.editStepValue(
                                            event,
                                            'workflowPlugin'
                                          )
                                        }
                                      >
                                        <option value='0'></option>
                                        {this.state.availablePlugins.map(
                                          (plug) => (
                                            <option
                                              key={plug.integrationId}
                                              value={plug.integrationId}
                                            >
                                              {plug.moduleName}:{' '}
                                              {plug.pluginName}
                                            </option>
                                          )
                                        )}
                                      </select>
                                      {this.state.currentEditStep.hasWorker &&
                                        this.state.currentEditStep.workerGen ===
                                          'Gen2' && (
                                          <div>
                                            <label
                                              htmlFor='txtNote'
                                              className='form-label'
                                            >
                                              Config:
                                            </label>
                                            <textarea
                                              style={{
                                                backgroundColor: '#E8E8E8',
                                              }}
                                              type='text'
                                              className='form-control'
                                              spellCheck='false'
                                              id='txtConfig'
                                              rows='3'
                                              value={
                                                this.state.currentEditStep
                                                  .workerConfig
                                              }
                                              onChange={(event) =>
                                                this.editStepValue(
                                                  event,
                                                  'workerConfig'
                                                )
                                              }
                                            />
                                          </div>
                                        )}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='mb-3'>
                            <label htmlFor='txtNote' className='form-label'>
                              Notes:
                            </label>
                            <textarea
                              style={{ backgroundColor: '#FFECA9' }}
                              type='text'
                              className='form-control'
                              id='txtNote'
                              rows='7'
                              value={this.state.currentEditStep.notes}
                              onChange={(event) =>
                                this.editStepValue(event, 'notes')
                              }
                            />
                          </div>
                          <button
                            className='btn btn-success'
                            onClick={this.closeStepEditor}
                          >
                            Close
                          </button>
                        </div>
                        <div className='col-4'>
                          {this.state.currentEditStep.stepType === 'User' && (
                            <div className='mb-3'>
                              <label
                                htmlFor='lstReqFields'
                                className='form-label'
                              >
                                Required Fields:
                              </label>
                              <ul className='list-group'>
                                {this.state.currentEditStep.requiredFields.map(
                                  (req) => (
                                    <li
                                      className='wf-reqField-item'
                                      key={req.fieldId}
                                    >
                                      {req.fieldName}{' '}
                                      <FontAwesomeIcon
                                        style={{
                                          color: 'red',
                                          float: 'right',
                                          marginTop: '4px',
                                          marginRight: '6px',
                                          cursor: 'pointer',
                                        }}
                                        onClick={() =>
                                          this.deleteReq(req.fieldId)
                                        }
                                        icon={solid('times')}
                                      />
                                    </li>
                                  )
                                )}
                              </ul>
                              {!this.state.showReqFields && (
                                <button
                                  onClick={this.toggleReqFields}
                                  className='btn btn-sm mt-1 btn-success'
                                >
                                  Add
                                </button>
                              )}
                              {this.state.showReqFields && (
                                <div>
                                  <div className='mini-label mt-4'>
                                    Form Type:
                                  </div>
                                  <select
                                    className='form-control '
                                    value={this.state.reqFieldFormTypeId}
                                    onChange={this.onChangeReqFormTypeId}
                                  >
                                    {this.state.allowedFormTypes.map((ft) => (
                                      <option
                                        key={ft.formTypeId}
                                        value={ft.formTypeId}
                                      >
                                        {ft.name}
                                      </option>
                                    ))}
                                  </select>
                                  <div className='mini-label mt-1'>Field:</div>
                                  <select
                                    className='form-control '
                                    value={this.state.reqField}
                                    onChange={this.onChangeReqField}
                                  >
                                    <option style={{ color: '#CCC' }} value='0'>
                                      Select a field
                                    </option>
                                    {this.state.filteredFields.map((field) => (
                                      <option
                                        key={field.fieldId}
                                        value={field.fieldId}
                                      >
                                        {field.name}
                                      </option>
                                    ))}
                                  </select>
                                  <button
                                    onClick={this.addReqField}
                                    className={`btn btn-sm mt-1 btn-success ${
                                      this.state.reqField !== '0'
                                        ? ''
                                        : 'disabled'
                                    }`}
                                  >
                                    Add
                                  </button>
                                  <button
                                    onClick={this.toggleReqFields}
                                    className='btn btn-sm mt-1 ms-1 btn-secondary'
                                  >
                                    Close
                                  </button>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                )}
              </div>
            )}

            {this.state.currentView === 'routes' && (
              <div
                className='row'
                id='wf_routeView'
                style={{
                  gridColumnStart: '1',
                  gridColumnEnd: '3',
                  gridRow: '3 / 3',
                  margin: '8px',
                  position: 'relative',
                }}
                onKeyUp={this.onRouteHotKey}
              >
                <div className={`${this.state.showFlow ? 'col-6' : ''}`}>
                  <div id='crdRoutes' className='card'

                  >
                    <div className='card-header d-flex'>Routes</div>
                    <div className='card-body p-2'
                    style={{
                      height: this.state.bottomHeight,
                      overflow: 'auto',

                    }}
                    >
                      <div className='row'>
                        <div className='col-12'>
                          {/*  Route Filter */}
                          <div className='mb-2'>
                            <FontAwesomeIcon icon={solid('filter')} /> Route
                            filter:{' '}
                          </div>

                          <div className='row'>
                            <div className='col-auto'>
                              <label className='col-form-label'>From:</label>
                            </div>
                            <div className='col-4'>
                              <AutoComplete
                                id='route-from-ac'
                                value={this.state.routeFromFilter}
                                allowedValues={this.state.currentWorkflow.steps.filter((f) => f.isActive)}
                                valueField='workflowStepId'
                                displayField='name'
                                onChange={this.editFromFilter}
                              />
                            </div>

                            <div className='col-auto'>
                              <label className='col-form-label'>To:</label>
                            </div>
                            <div className='col-4'>
                              <AutoComplete
                                id='route-to-ac'
                                value={this.state.routeToFilter}
                                allowedValues={this.state.currentWorkflow.steps.filter((f) => f.isActive)}
                                valueField='workflowStepId'
                                displayField='name'
                                onChange={this.editToFilter}
                              />
                            </div>
                            <div className='col-auto'>
                              <button
                                className='btn btn-sm btn-primary'
                                onClick={this.clearFilter}
                              >
                                Clear
                              </button>
                            </div>
                          </div>
                          <div className='row mt-2'>
                            <div className='form-check col-auto ms-4 '>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                checked={this.state.showFormTypes}
                                onChange={(e) =>
                                  this.toggleShow(e, 'formTypes')
                                }
                              />
                              <label className='form-check-label'>
                                Show FormTypes
                              </label>
                            </div>
                            <div className='form-check col-auto'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                checked={this.state.showTemplates}
                                onChange={(e) =>
                                  this.toggleShow(e, 'templates')
                                }
                              />
                              <label className='form-check-label'>
                                Show Templates
                              </label>
                            </div>
                            <div className='form-check col-auto'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                checked={this.state.showConditions}
                                onChange={(e) =>
                                  this.toggleShow(e, 'conditions')
                                }
                              />
                              <label className='form-check-label'>
                                Show Conditions
                              </label>
                            </div>
                            <div className='form-check col-auto'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                checked={this.state.showAssignees}
                                onChange={(e) =>
                                  this.toggleShow(e, 'assignees')
                                }
                              />
                              <label className='form-check-label'>
                                Show Assignees
                              </label>
                            </div>
                          </div>

                          <hr className='mt-3 mb-1' />

                          {this.state.routeGroups.map((rg) => (
                            <div className='mt-2' key={rg.id}>
                              <div
                                style={{ fontSize: '16pt', fontWeight: 650 }}
                              >
                                <span className='me-2'>{rg.fromName}</span>
                                <FontAwesomeIcon
                                  style={{ color: '#248924' }}
                                  icon={solid('arrow-right')}
                                />
                                {rg.layout === 'startEnd' && (
                                  <span> {rg.toName}</span>
                                )}
                              </div>
                              <hr className='mt-1 mb-1' />
                              <table onKeyUp={this.onRouteGroupKeyUp}>
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th></th>
                                    <th className='pe-2'>From:</th>
                                    <th className='pe-2'>Route Code</th>
                                    {this.state.showFormTypes && (
                                      <th className='pe-2'>Form Type</th>
                                    )}
                                    {this.state.showTemplates && (
                                      <th className='pe-2'>Template</th>
                                    )}
                                    {this.state.showConditions && (
                                      <th className='pe-2'>Conditions</th>
                                    )}
                                    <th className='pe-2'>To:</th>
                                    {this.state.showAssignees && (
                                      <th className='pe-2'>Assignees</th>
                                    )}
                                  </tr>
                                </thead>
                                <tbody>
                                  {rg.routes.map((rr) => (
                                    <tr
                                      key={rr.routeId}
                                      className='p-1'
                                      onDragOver={(e) =>
                                        this.onRouteDragOver(e, rr)
                                      }
                                      onDragLeave={(e) =>
                                        this.onRouteDragLeave(e, rr)
                                      }
                                      onClick={() => this.onRouteClick(rr)}
                                      style={{
                                        borderTop:
                                          rr.routeId ===
                                            this.dragRouteTarget.routeId &&
                                          this.dragRouteDir === 'above'
                                            ? 'solid 3px #000'
                                            : '',
                                        borderBottom:
                                          rr.routeId ===
                                            this.dragRouteTarget.routeId &&
                                          this.dragRouteDir === 'below'
                                            ? 'solid 3px #000'
                                            : '',
                                      }}
                                    >
                                      <td
                                        style={{
                                          verticalAlign: 'top',
                                          paddingTop: '8px',
                                          paddingRight: '3px',
                                        }}
                                      >
                                        <input
                                          type='checkbox'
                                          className='form-check-input'
                                          checked={rr.checked}
                                          onChange={() =>
                                            this.toggleRowCheck(rr, rg)
                                          }
                                        />
                                      </td>
                                      <td
                                        style={{
                                          verticalAlign: 'top',
                                          cursor: 'all-scroll',
                                        }}
                                      >
                                        <h4
                                          draggable='true'
                                          onDragStart={(e) =>
                                            this.setRouteDragging(e, rr)
                                          }
                                          onDragEnd={() => this.onRouteDrop(rr)}
                                        >
                                          <span className='badge bg-info'>
                                            {rr.executionOrder}{' '}
                                            <FontAwesomeIcon
                                              icon={solid('up-down-left-right')}
                                            />
                                          </span>
                                        </h4>
                                      </td>
                                      <td
                                        className='pe-2'
                                        style={{ verticalAlign: 'top' }}
                                      >
                                        <AutoComplete
                                          id={`rt_fromStep_${rr.routeId}`}
                                          allowedValues={
                                            this.state.currentWorkflow.steps
                                          }
                                          disabled='true'
                                          value={rr.fromStepId}
                                          valueField='workflowStepId'
                                          displayField='name'
                                          setFocus={this.setCurrentRoute}
                                        />
                                      </td>
                                      <td
                                        className='pe-2'
                                        style={{ verticalAlign: 'top' }}
                                      >
                                        <AutoComplete
                                          id={`rt_routeCode_${rr.routeId}`}
                                          allowedValues={[
                                            {
                                              id: 'APPROVE',
                                              value: 'APPROVE',
                                            },
                                            { id: 'REJECT', value: 'REJECT' },
                                          ]}
                                          value={rr.routeCode}
                                          valueField='id'
                                          displayField='value'
                                          enforce='false'
                                          setFocus={this.setCurrentRoute}
                                          onChange={(val) =>
                                            this.updateRouteCode(rr, val)
                                          }
                                        />
                                      </td>
                                      {this.state.showFormTypes && (
                                        <td
                                          className='pe-2'
                                          style={{ verticalAlign: 'top' }}
                                        >
                                          <AutoComplete
                                            id={`rt_formType_${rr.routeId}`}
                                            allowedValues={formTypes}
                                            value={rr.formTypeId}
                                            valueField='formTypeId'
                                            displayField='formTypeName'
                                            setFocus={this.setCurrentRoute}
                                            enforce='true'
                                            onChange={(val) =>
                                              this.updateFormType(rr, val)
                                            }
                                          />
                                        </td>
                                      )}
                                      {this.state.showTemplates && (
                                        <td
                                          className='pe-2'
                                          style={{ verticalAlign: 'top' }}
                                        >
                                          <AutoComplete
                                            id={`rt_template_${rr.routeId}`}
                                            allowedValues={rr.templateChoices}
                                            value={rr.templateId}
                                            valueField='templateId'
                                            displayField='templateName'
                                            setFocus={this.setCurrentRoute}
                                            enforce='true'
                                            onChange={(val) =>
                                              this.updateTemplate(rr, val)
                                            }
                                          />
                                        </td>
                                      )}
                                      {this.state.showConditions && (
                                        <td
                                          className='pe-2'
                                          style={{ verticalAlign: 'top' }}
                                        >
                                          {rr.conditions.length > 0 && (
                                            <table>
                                              <tbody>
                                                {rr.conditions.map((cond) => (
                                                  <tr
                                                    key={cond.routeConditionId}
                                                  >
                                                    <td>
                                                      <AutoComplete
                                                        id={`rt_condField_${cond.routeConditionId}_${rr.routeId}`}
                                                        allowedValues={
                                                          rr.fieldChoices
                                                        }
                                                        value={cond.fieldId}
                                                        valueField='fieldId'
                                                        displayField='fieldDisplay'
                                                        setFocus={
                                                          this.setCurrentRoute
                                                        }
                                                        onChange={(val) =>
                                                          this.updateCondition(
                                                            rr,
                                                            cond,
                                                            val,
                                                            'field'
                                                          )
                                                        }
                                                      />
                                                    </td>
                                                    <td>
                                                      <AutoComplete
                                                        id={`rt_condOp_${cond.routeConditionId}_${rr.routeId}`}
                                                        allowedValues={[
                                                          {
                                                            id: '=',
                                                            value: '=',
                                                          },
                                                          {
                                                            id: '!=',
                                                            value: '≠',
                                                          },
                                                          {
                                                            id: '>',
                                                            value: '>',
                                                          },
                                                          {
                                                            id: '>=',
                                                            value: '>=',
                                                          },
                                                          {
                                                            id: '<=',
                                                            value: '<=',
                                                          },
                                                          {
                                                            id: '<',
                                                            value: '<',
                                                          },
                                                        ]}
                                                        value={cond.operator}
                                                        valueField='id'
                                                        displayField='value'
                                                        onChange={(val) =>
                                                          this.updateCondition(
                                                            rr,
                                                            cond,
                                                            val,
                                                            'op'
                                                          )
                                                        }
                                                        setFocus={
                                                          this.setCurrentRoute
                                                        }
                                                      />
                                                    </td>
                                                    <td>
                                                      <input
                                                        id={`rt_cond_value_${cond.routeConditionId}_${rr.routeId}`}
                                                        className='form-control'
                                                        value={cond.value}
                                                        onChange={(e) =>
                                                          this.onEditCondValue(
                                                            e,
                                                            rr,
                                                            cond
                                                          )
                                                        }
                                                        onFocus={() =>
                                                          this.setCurrentRoute(
                                                            `rt_cond_value_${cond.routeConditionId}_${rr.routeId}`
                                                          )
                                                        }
                                                      />
                                                    </td>
                                                    <td>
                                                      <button
                                                        className='btn btn-danger btn-small'
                                                        onClick={() =>
                                                          this.deleteCondition(
                                                            rr,
                                                            cond
                                                          )
                                                        }
                                                      >
                                                        <FontAwesomeIcon
                                                          icon={solid('times')}
                                                        />
                                                      </button>
                                                    </td>
                                                  </tr>
                                                ))}
                                              </tbody>
                                            </table>
                                          )}

                                          <div>
                                            <button
                                              className='btn btn-primary btn-sm'
                                              onClick={() =>
                                                this.addCondition(rr)
                                              }
                                            >
                                              Add conditions
                                            </button>
                                          </div>
                                        </td>
                                      )}
                                      <td
                                        className='pe-2'
                                        style={{ verticalAlign: 'top' }}
                                      >
                                        <AutoComplete
                                          id={`rt_toStep_${rr.routeId}`}
                                          allowedValues={
                                            this.state.currentWorkflow.steps
                                          }
                                          value={rr.toStepId}
                                          valueField='workflowStepId'
                                          displayField='name'
                                          setFocus={this.setCurrentRoute}
                                          onChange={(val) =>
                                            this.onEditToStep(val, rr)
                                          }
                                          title={rr.toStepName}
                                        />
                                      </td>
                                      {this.state.showAssignees && (
                                        <td
                                          className='pe-2'
                                          style={{ verticalAlign: 'top' }}
                                        >
                                          <table>
                                            <tbody>
                                              {rr.assignees.map((ass) => (
                                                <tr key={ass.routeQueueId}>
                                                  <td>
                                                    <div>
                                                      <AutoComplete
                                                        id={`rt_assignment_${ass.routeQueueId}_${rr.routeId}`}
                                                        allowedValues={
                                                          rr.possibleAssignees
                                                        }
                                                        value={ass.userId}
                                                        valueField='userId'
                                                        displayField='userName'
                                                        setFocus={
                                                          this.setCurrentRoute
                                                        }
                                                        onChange={(v) =>
                                                          this.editRouteAssignee(
                                                            v,
                                                            ass,
                                                            rr
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <button
                                                      className='btn btn-danger btn-sm'
                                                      onClick={() =>
                                                        this.deleteAssignment(
                                                          rr,
                                                          ass
                                                        )
                                                      }
                                                    >
                                                      <FontAwesomeIcon
                                                        icon={solid('times')}
                                                      />
                                                    </button>
                                                  </td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                          {rr.routeId ===
                                            this.state.currentRouteId && (
                                            <div>
                                              <button
                                                className='btn btn-secondary btn-sm'
                                                onClick={
                                                  this.addAssigneeToRoute
                                                }
                                              >
                                                Add Assignee -F9
                                              </button>
                                            </div>
                                          )}
                                        </td>
                                      )}
                                      <td style={{ verticalAlign: 'top' }}>
                                        <button
                                          className='btn btn-sm btn-circle btn-success'
                                          onClick={() => this.copyRoute(rg, rr)}
                                          title='Copy route'
                                        >
                                          <FontAwesomeIcon
                                            icon={regular('clone')}
                                          />
                                        </button>
                                      </td>
                                      <td style={{ verticalAlign: 'top' }}>
                                        <button
                                          className='btn btn-sm btn-circle btn-danger'
                                          onClick={() => this.deleteRoute(rr)}
                                          title='Delete route'
                                        >
                                          <FontAwesomeIcon
                                            icon={solid('times')}
                                          />
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                  <tr
                                    onDragOver={(e) =>
                                      this.onRouteDragOver(e, rg)
                                    }
                                    onDragLeave={(e) =>
                                      this.onRouteDragLeave(e, rg)
                                    }
                                  >
                                    <td
                                      style={{
                                        height: '12px',
                                      }}
                                    ></td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              </table>

                              {rg.id === this.state.currentRouteGroup && (
                                <div className='btn-group'>
                                  <button
                                    className='btn btn-success btn-sm'
                                    onClick={() => this.addSubRoute(rg)}
                                  >
                                    Add Route to '{rg.fromName}'
                                  </button>
                                  {rg.checkCount !== undefined &&
                                    rg.checkCount > 0 && (
                                      <button
                                        className='btn btn-primary btn-sm ms-2'
                                        onClick={() =>
                                          this.copyCheckedRoutes(rg)
                                        }
                                      >
                                        <FontAwesomeIcon icon={solid('copy')} />{' '}
                                        Copy {rg.checkCount} routes
                                      </button>
                                    )}
                                  {this.state.hasRoutesCopied && (
                                    <button
                                      className='btn btn-dark btn-sm ms-2'
                                      onClick={() => this.pasteRoutes(rg)}
                                    >
                                      <FontAwesomeIcon icon={solid('paste')} />{' '}
                                      Paste routes
                                    </button>
                                  )}
                                </div>
                              )}
                            </div>
                          ))}
                          <div className='btn-group'>
                            {!this.state.showNewRoute && (
                              <button
                                className='btn btn-success btn-sm mt-2'
                                onClick={this.onAddRouteClick}
                              >
                                <FontAwesomeIcon icon={solid('plus')} /> New
                                Route
                              </button>
                            )}
                          </div>
                          {this.state.showNewRoute && (
                            <div>
                              <strong>New Route</strong>
                              <div className='row'>
                                <div className='col-6'>
                                  <label className='form-label'>
                                    From Step
                                  </label>
                                  <AutoComplete
                                    id={`rt_new_from`}
                                    allowedValues={
                                      this.state.currentWorkflow.steps
                                    }
                                    value={this.state.newRoute.fromStepId}
                                    valueField='workflowStepId'
                                    displayField='name'
                                    onChange={this.editNewRouteFrom}
                                    //setFocus={this.setCurrentRoute}
                                  />
                                  {/*<select
                                    className='form-select'
                                    value={this.state.newRoute.fromStepId}
                                    onChange={(e) =>
                                      this.editNewRoute(e, 'from')
                                    }
                                    id='newRouteFromSelect'
                                  >
                                    <option value='0'></option>
                                    {this.state.currentWorkflow.steps.map(
                                      (step) => (
                                        <option
                                          value={step.workflowStepId}
                                          key={step.workflowStepId}
                                        >
                                          {step.name}
                                        </option>
                                      )
                                    )}
                                      </select>*/}
                                </div>
                                <div className='col-6'>
                                  <label className='form-label'>To Step</label>
                                  <AutoComplete
                                    id={`rt_new_from`}
                                    allowedValues={
                                      this.state.currentWorkflow.steps
                                    }
                                    value={this.state.newRoute.toStepId}
                                    valueField='workflowStepId'
                                    displayField='name'
                                    onChange={this.editNewRouteTo}
                                    //setFocus={this.setCurrentRoute}
                                  />
                                  {/*<select
                                    className='form-select'
                                    value={this.state.newRoute.toStepId}
                                    onChange={(e) => this.editNewRoute(e, 'to')}
                                  >
                                    <option value='0'></option>
                                    {this.state.currentWorkflow.steps.map(
                                      (step) => (
                                        <option
                                          value={step.workflowStepId}
                                          key={step.workflowStepId}
                                        >
                                          {step.name}
                                        </option>
                                      )
                                    )}
                                      </select>*/}
                                </div>
                              </div>
                              <button
                                className='btn btn-success btn-sm mt-2 me-2'
                                onClick={this.addRoute}
                              >
                                Add
                              </button>
                              <button
                                className='btn btn-secondary btn-sm mt-2 me-2'
                                onClick={this.onCloseNewRoute}
                              >
                                Close
                              </button>
                            </div>
                          )}
                          <div style={{ height: '120px' }}> </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.showFlow && (
                  <div className='col-6 d-flex'>
                    <div className='p-2'>
                      <AdvWorkflowEdit
                        tasks={this.state.layout.tasks}
                        routes={this.state.layout.routes}
                        SetSelectedTask={this.setSelectedTask}
                        onUpdateTree={this.onUpdateTree}
                        SetSelectedRoute={this.setSelectedRoute}
                        viewWidth={this.state.layoutViewWidth}
                        viewHeight={this.state.layoutViewHeight}
                        canvasHeight={this.state.layout.canvasHeight}
                        canvasWidth={this.state.layout.canvasWidth}
                        toggleFullScreen={this.toggleFullScreen}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
            {this.state.currentView === 'flow' && (
              <div
                id='Flow'
                className='card'
                style={{
                  gridColumnStart: '1',
                  gridColumnEnd: '3',
                  gridRow: '3 / 3',
                  margin: '16px',
                }}
              >
                <div className='card-header'>Flow</div>
                <div className='card-body'>
                    <AdvWorkflowEdit
                      tasks={this.state.layout.tasks}
                      routes={this.state.layout.routes}
                      SetSelectedTask={this.setSelectedTask}
                      onUpdateTree={this.onUpdateTree}
                      SetSelectedRoute={this.setSelectedRoute}
                      viewWidth={this.state.layoutViewWidth2}
                      viewHeight={this.state.layoutViewHeight2}
                      canvasHeight={this.state.layout.canvasHeight}
                      canvasWidth={this.state.layout.canvasWidth}
                      toggleFullScreen={this.toggleFullScreen}
                    />
                </div>
              </div>
            )}
          </div>
        )}
        {this.state.fullScreenFlow && (
          <AdvWorkflowEdit
            tasks={this.state.layout.tasks}
            routes={this.state.layout.routes}
            SetSelectedTask={this.setSelectedTask}
            onUpdateTree={this.onUpdateTree}
            SetSelectedRoute={this.setSelectedRoute}
            viewWidth={this.state.layoutViewWidth2}
            viewHeight={this.state.layoutViewHeight2}
            canvasHeight={this.state.layout.canvasHeight}
            canvasWidth={this.state.layout.canvasWidth}
            toggleFullScreen={this.toggleFullScreen}
          />
        )}
        {this.state.showNav && (
          <div className='td-dialogue-container'>
            <div
              className='td-dialogue card bg-light'
              style={{ height: '259px', width: '400px' }}
            >
              <div className='card-header'>Unsaved changes</div>
              <div className='card-body' style={{ display: 'block' }}>
                <div>
                  <FontAwesomeIcon icon={solid('warning')} /> You have unsaved
                  changes
                </div>

                <button
                  className='btn btn-success btn-block mt-2'
                  style={{ width: '360px' }}
                  onClick={this.onSaveAndNav}
                >
                  Save changes and go to {this.state.navDestination}
                </button>
                <button
                  className='btn btn-info btn-block mt-2'
                  style={{ width: '360px' }}
                  onClick={this.onStay}
                >
                  Stay in Workflow Editor
                </button>
                <button
                  className='btn btn-secondary btn-block mt-2'
                  style={{ width: '360px' }}
                  onClick={this.onDiscardAndNav}
                >
                  Discard changes and go to {this.state.navDestination}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default WorkflowView;
