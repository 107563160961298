import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';

const CHANGE_EVENT = 'docListChange';
let _userSettings = undefined;
let _columnSetting = undefined;
let _lastChange = '';

class DocumentListStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getUserSettings() {
    return _userSettings;
  }

  getColumnSettings() {
    return _columnSetting;
  }

  lastChange() {
    return _lastChange;
  }
}

const store = new DocumentListStore();

Dispatcher.register((action) => {
  _lastChange = action.actionType;
  switch (action.actionType) {
    case actionTypes.DocListSetting:
      _userSettings = action.Result;
      store.emitChange();
      break;
    case actionTypes.DocListColumns:
      _columnSetting = action.Result;
      store.emitChange();
      break;
    default:
  }
});

export default store;
