import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import WorkflowSidebar from './WorkflowSidebar';

/*import { UpdateName } from '../../../Actions/BPActions';
import ISStore from '../../../Stores/IntakeStrategyStore';
import { IntakeStrategyModel } from '../../../Models/Admin/IntakeStrategy';*/
/*import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
   faCog,
   faSitemap,
   faFileInvoice,
} from '@fortawesome/free-solid-svg-icons';*/

class WorkflowsHome extends React.Component {
  constructor() {
    // state
    super();
    this.state = {};
  }

  componentDidMount() {}
  componentWillUnmount() {}
  componentDidUpdate() {}

  render() {
    return (
      <div className='sidebarLayout d-flex flex-row flex-fill'>
        <div className='sidebar-container'>
          <WorkflowSidebar />
        </div>
        <div className='flex-fill'>
          <div className='h-100 d-flex align-items-center'>
            <div
              className='p-2 col-example'
              style={{
                textAlign: 'center',
                marginLeft: '100px',
                marginRight: '100px',
                width: '60%',
              }}
            >
              <FontAwesomeIcon icon={solid('sitemap')} size='6x' />
              <h2
                style={{
                  fontWeight: 'bolder',
                  fontStyle: 'italic',
                  margin: '16px',
                }}
              >
                Workflows
              </h2>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WorkflowsHome;
