import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import SystemSettingsStore from '../../../stores/SystemSettingsStore';
import {
  GetSystemSettings,
  UpdateSystemSettings,
} from '../../../actions/SystemSettingsActions';

class SystemSettings extends React.Component {
  constructor(props) {
    // state
    super(props);

    this.state = {
      currentSettings: {
        forceRejectReason: true,
        multipleRolesCombinedBehavior: 'OR'
      },
    };

    this.onSystemSettingsUpdate = this.onSystemSettingsUpdate.bind(this);
    this.toggleRejectReason = this.toggleRejectReason.bind(this);
    this.saveSystemSettings = this.saveSystemSettings.bind(this);
    this.onChangeMultipleRolesCombinedBehavior = this.onChangeMultipleRolesCombinedBehavior.bind(this);
  }

  componentDidMount() {
    SystemSettingsStore.addChangeListener(this.onSystemSettingsUpdate);
    GetSystemSettings();
  }

  componentWillUnmount() {
    SystemSettingsStore.removeChangeListener(this.onSystemSettingsUpdate);
  }

  onSystemSettingsUpdate() {
    let settings = SystemSettingsStore.getSystemSettings();
    this.setState({ currentSettings: settings });
  }

  toggleRejectReason() {
    let cs = this.state.currentSettings;
    cs.forceRejectReason = !cs.forceRejectReason;
    this.setState({ currentSettings: cs });
  }

  onChangeMultipleRolesCombinedBehavior() {
    let cs = this.state.currentSettings;
    if (cs.multipleRolesCombinedBehavior === 'OR'){
      cs.multipleRolesCombinedBehavior = 'AND';
    }
    else{
      cs.multipleRolesCombinedBehavior = 'OR';
    }
    this.setState({ currentSettings: cs });
  }

  saveSystemSettings() {
    //UpdateADSettings(this.state.currentSettings);
    UpdateSystemSettings(this.state.currentSettings);
  }

  render() {
    return (
      <div className='container'>
        {this.state.currentSettings !== undefined && (
          <div
            style={{ maxWidth: '60%', margin: '12px' }}
            id='intakeInfo'
            className='card'
          >
            <div className='card-header'>
              <FontAwesomeIcon icon={solid('gear')} /> System Settings
            </div>
            <div className='card-body'>
              <div className='form-check form-switch'>
                <label className='form-check-label' htmlFor='rejectDupeswitch'>
                  Force Reject Reason
                </label>
                <input
                  className='form-check-input'
                  type='checkbox'
                  checked={this.state.currentSettings.forceRejectReason}
                  onChange={this.toggleRejectReason}
                  id='rejectDupeswitch'
                />
              </div>

              <div>
                <label className='form-check-label' htmlFor='multipleRolesCombinedBehavior'>
                  Multiple Roles Combined Behavior
                </label>
                <select
                  className='form-select'
                  aria-label='Multiple Roles Combined Behavior'
                  value={this.state.currentSettings.multipleRolesCombinedBehavior}
                  onChange={this.onChangeMultipleRolesCombinedBehavior}
                 >
                  <option value='OR'>OR</option>
                  <option value='AND'>AND</option>
                </select>
              </div>
    
    
                <button
                    className='btn btn-success btn-sm mt-3'
                    onClick={this.saveSystemSettings}
                >
                    Save
                </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default SystemSettings;
