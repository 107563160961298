import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { createBrowserHistory } from 'history';
import { Link } from 'react-router-dom';
import SearchSidebar from './SearchSidebar';
import SearchStore from '../../../stores/SearchStore';
import UserStore from '../../../stores/UserStore';
import DocumentList from '../DocumentList/DocumentList';
import { GetWorkflowFromStep } from '../../../actions/WorkflowActions';
import { GetUserList } from '../../../actions/UserActions';
import actionTypes from '../../../actions/ActionTypes';
import { Form } from 'react-bootstrap';
import {
  SearchByDocumentId,
  SearchByValues,
  GetSearchStep,
  GetIndexFields,
  SearchByFieldValue,
  GetWorkflowUserSteps,
  SearchByUserAudit,
  SearchByUserQueue,
  SearchByNotes,
  SearchByProperties,
  GetSearchBp,
} from '../../../actions/SearchActions';
import { GetBPPermissions } from '../../../actions/ActiveDocumentActions';

const customHistory = createBrowserHistory();

class Search extends React.Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      currentDocs: [],
      docCount: 0,
      columns: [],
      showField: false,
      currentFormType: 0,
      scrollWidth: window.innerWidth - 320,
      scrollHeight: window.innerHeight - 170,
      downloadConfirmation: false,
      formatDocumentIdAsTDLink: false,
      currentWfName: '',
      currentSearchType: 'values',
      searchText: '',
      displaySearchOptions: true,
      results: false,
      allIndexFields: [],
      indexFieldsForFormType: [],
      selectedIndexField: {
        Id: 0,
        colName: '',
        FieldId: 0,
        FormTypeName: '',
        DataType: 0,
      },
      formTypes: [],
      selectedFormTypeId: 0,
      selectedOperator: 0,
      showFields: false,
      sidebarLoading: false,
      fieldValueRows: [
        {
          id: 1,
          fieldId: 0,
          operatorValue: '',
          search: '',
          dataType: 0,
        },
      ],
      fieldValueRow: {
        id: 0,
        fieldId: 0,
        operatorValue: '',
        search: '',
        dataType: 0,
      },
      fieldValueRowCount: 1,
      users: [],
      steps: [],
      userAudit: {
        userId: '-1',
        stepId: '-1',
        startDate: '',
        endDate: '',
        action: 'approve',
      },
      selectedQueueUser: '',
      noMatchesFound: false,
      lastSearch: undefined,
      activeStep: null,
      activeBP: null,
      currentOffset: 0,
      checkAll: false,
      getActiveDocuments: true,
      getCompletedDocuments: true,
      getDeletedDocuments: false
    };

    this.LoadStep = this.LoadStep.bind(this);
    this.LoadBP = this.LoadBP.bind(this);
    this.checkParams = this.checkParams.bind(this);
    this.goToDoc = this.goToDoc.bind(this);
    this.toggleDownloadConfirmation =
      this.toggleDownloadConfirmation.bind(this);
    this.toggleFormatDocIdasTDLink = this.toggleFormatDocIdasTDLink.bind(this);
    this.changeSearchType = this.changeSearchType.bind(this);
    this.editSearchText = this.editSearchText.bind(this);
    this.onSearchKeyDown = this.onSearchKeyDown.bind(this);
    this.onSearchButton = this.onSearchButton.bind(this);
    this.onSearchUpdate = this.onSearchUpdate.bind(this);
    this.editSelectedFormType = this.editSelectedFormType.bind(this);
    this.editSelectedIndexField = this.editSelectedIndexField.bind(this);
    this.editSelectedOperator = this.editSelectedOperator.bind(this);
    this.editFieldRowSearchText = this.editFieldRowSearchText.bind(this);
    this.addNewFieldValueRow = this.addNewFieldValueRow.bind(this);
    this.deleteFieldValueRow = this.deleteFieldValueRow.bind(this);
    this.toggleDisplaySearchOptions =
      this.toggleDisplaySearchOptions.bind(this);
    this.onUserUpdate = this.onUserUpdate.bind(this);
    this.editSelectedUser = this.editSelectedUser.bind(this);
    this.editSelectedStep = this.editSelectedStep.bind(this);
    this.editSelectedAction = this.editSelectedAction.bind(this);
    this.editStartDate = this.editStartDate.bind(this);
    this.editEndDate = this.editEndDate.bind(this);
    this.editSelectedQueueUser = this.editSelectedQueueUser.bind(this);
    this.onEditAutoComplete = this.onEditAutoComplete.bind(this);
    this.closeAllList = this.closeAllList.bind(this);
    this.onClickAutoComplete = this.onClickAutoComplete.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);
    this.toggleFields = this.toggleFields.bind(this);
    this.toggleDocument = this.toggleDocument.bind(this);
    this.toggleCheckAll2 = this.toggleCheckAll2.bind(this);
    this.toggleGetAllDocuments = this.toggleGetAllDocuments.bind(this);
    this.toggleGetActiveDocuments = this.toggleGetActiveDocuments.bind(this);
    this.toggleGetCompleted = this.toggleGetCompleted.bind(this);
    this.toggleGetDeleted = this.toggleGetDeleted.bind(this);
  }

  componentDidMount() {
    SearchStore.addChangeListener(this.onSearchUpdate);
    UserStore.addChangeListener(this.onUserUpdate);
    window.onresize = this.onWindowResize;
    console.log('search mount');
    this.checkParams();
    GetIndexFields();
    GetUserList();
    GetWorkflowUserSteps();
    this.onWindowResize();
    document.title = 'TrinDocs - Search'

  }

  componentWillUnmount() {
    SearchStore.removeChangeListener(this.onSearchUpdate);
    UserStore.removeChangeListener(this.onUserUpdate);
  }

  onWindowResize(e) {
    //setViewWidth
    let topHalf = document.getElementById('td-search-info');
    let topBar = document.getElementById('tdAppHeader');

    this.setState({ scrollWidth: window.innerWidth - 322 });
    this.setState({
      scrollHeight:
        window.innerHeight - (topHalf.clientHeight + topBar.clientHeight + 5),
    });
  }

  LoadStep(id, bpId, searchText) {
    console.log('Search:LoadStep');

    if(searchText === undefined) {
      searchText = this.state.searchText;
    }
    if(searchText !== undefined)
    {
      customHistory.push(
        `/Search?Step=${id.toString()}&BP=${bpId.toString()}&Value=${searchText}`
      );
    } else {
      searchText = '';
    }

    let order = SearchStore.getOrder();
    let column = SearchStore.getColumn();
    console.log(column);
    let columnValue = 'user';
    let orderValue = 'asc';
    if (column !== '' && column !== undefined && column !== null && column.length !== 0){
      columnValue = column;
    }
    if (order !== '' && order !== undefined && order !== null && order.length !== 0){
      orderValue = order;
    }
    console.log(columnValue);

    var documentsToSearch = {
      includeActiveDocuments: this.state.getActiveDocuments,
      includeCompleted: this.state.getCompletedDocuments,
      includeDeleted: this.state.getDeletedDocuments
    };

    console.log(this.state.currentSearchType);

    GetSearchStep(
      id,
      bpId,
      searchText.replaceAll('/', '-'),
      this.state.currentSearchType,
      this.state.fieldValueRows,
      this.state.userAudit,
      this.state.selectedQueueUser,
      columnValue,
      orderValue,
      '0',
      documentsToSearch
    );
    //GetWorkflowFromStep(id);
    GetBPPermissions(bpId);

    this.setState({ results: true });
  }

  toggleFields() {
    let f = this.state.showFields;
    this.setState({ showFields: !f });
  }

  goToDoc(docId) {
    if (window.location.pathname === '/Search') {
      var docLink = document.getElementById(`docLink${docId}`);
      var returnPath = window.location.pathname + window.location.search;
      localStorage.setItem('docReturn', returnPath);

      docLink.click();
    }
  }

  onUserUpdate() {
    let lastAction = UserStore.getLastChange();
    if (lastAction === actionTypes.UsersGetUsers) {
      let users = UserStore.getUserList();
      let sortedUsers = users.sort(function (a, b) {
        return a.userName.localeCompare(b.userName);
      });
      this.setState({ users: sortedUsers });
    }
  }

  onSearchUpdate() {
    let lastAction = SearchStore.getLastAction();

    if (lastAction === actionTypes.SearchByDocId) {
      let docId = SearchStore.getDocument();

      if (docId !== 0) {
        // navigate to document
        this.setState({ gotoDocId: docId, noMatchesFound: false }, () => {
          let link = document.getElementById('searchDocGoto');
          console.log('click2');
          console.log(this.state.gotoDocId);
          console.log(docId);
          link.href = "/Document/" + docId;
          console.log(link);

           let link2 = document.createElement("a");

           link2.href="/Document/" + docId;
            link2.click();

          //link.click();

        });
      } else {
        this.setState({ noMatchesFound: true });
      }
    }

    if (
      lastAction === actionTypes.SearchByValues ||
      lastAction === actionTypes.SearchByNotes ||
      lastAction === actionTypes.SearchByProperties ||
      lastAction === actionTypes.SearchByUserQueue ||
      lastAction === actionTypes.SearchByFieldValue
    ) {
      let docCount = SearchStore.getDocCount();
      console.log(docCount);

      if (docCount === 1) {
        // navigate to document
        console.log('got one result');
        let docId = SearchStore.getDocList()[0];
        console.log(docId);
        this.setState({ gotoDocId: docId }, () => {
          console.log(this.state.gotoDocId);
          //let link = document.getElementById('searchDocGoto2');
          let link = document.createElement("a");
          link.href="/Document/" + docId;
          console.log('click');
          link.click();
        });
      }

      if (docCount === 0) {
        let lastSearch = SearchStore.getLastSearch();
        this.setState({ searchText: lastSearch });
        this.setState({ noMatchesFound: true });
      } else {
        this.setState({ noMatchesFound: false });
      }

      if(docCount > 1)
      {

        let lastSearch = SearchStore.getLastSearch();
        if(lastSearch !== undefined) {
          console.log(lastSearch);
          this.setState({ searchText: lastSearch
            , results: false });
        }
        // this.setState({
        //   includeActiveDocuments: true,
        //   includeCompleted: true,
        //   includeDeleted: false,
        //   //currentSearchType: 'values',
        // });
        let adTree = SearchStore.getAdTree();
        console.log(adTree);
        let firstBp = this.findFirstBp(adTree);
        console.log(firstBp);
        let firstStep = firstBp.children[0];
        this.LoadStep(firstStep.id, firstBp.id, lastSearch);
        //SearchByValues(quickSearchInput.value);


      }

      this.setState({sidebarLoading: false});
    }

    if (
      lastAction === actionTypes.SearchGetStep ||
      lastAction === actionTypes.SearchGetBp
    ) {
      var docs = SearchStore.getDocs();
      var count = SearchStore.getDocCount();
      var cols = SearchStore.getColumns();
      var ft = SearchStore.getCurrentFormType();
      let bp = SearchStore.getBPName();
      let step = SearchStore.getStepName();
      let offset = SearchStore.getOffset();

      let loadingMore = localStorage.getItem('loadingMore');
      //let currentDocArray = docs;
      if (loadingMore === 'true') {
        let currentDocArray = this.state.currentDocs;
        let toReturn = currentDocArray.concat(docs);
        this.setState({
          currentDocs: toReturn,
          docCount: count,
          columns: cols,
          currentFormType: ft,
          activeStep: step,
          activeBP: bp,
          currentOffset: offset,
        });

        localStorage.setItem('loadingMore', false);
      } else {
        this.setState({
          currentDocs: docs,
          docCount: count,
          columns: cols,
          currentFormType: ft,
          activeStep: step,
          activeBP: bp,
          currentOffset: offset,
          sidebarLoading: false,
        });
      }
    }

    if (lastAction === actionTypes.GetIndexFields) {
      var indexFields = SearchStore.getIndexFields();
      var formTypes = SearchStore.getFormTypes();

      let sortedFormTypes = formTypes.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });
      let sortedFields = indexFields.sort(function (a, b) {
        return a.colName.localeCompare(b.colName);
      });
      this.setState({
        allIndexFields: sortedFields,
        formTypes: sortedFormTypes,
      });
    }

    if (lastAction === actionTypes.SearchByFieldValue) {
      let docCount = SearchStore.getDocCount();
      if (docCount === 1) {
        // navigate to document
        let docId = SearchStore.getDocList()[0];
        this.setState({ gotoDocId: docId, noMatchesFound: false }, () => {
          //let link = document.getElementById('searchDocGoto');
          console.log('click3');

          let link = document.createElement("a");
          link.href="/Document/" + docId;
          link.click();
          //link.click();
        });
      } else if (docCount === 0) {
        this.setState({ noMatchesFound: true });
      }
    }

    if (lastAction === actionTypes.SearchByUserAudit) {
      let docCount = SearchStore.getDocCount();
      if (docCount === 1) {
        // navigate to document
        let docId = SearchStore.getDocList()[0];
        this.setState({ gotoDocId: docId, noMatchesFound: false }, () => {
          //let link = document.getElementById('searchDocGoto');
          let link = document.createElement("a");
          link.href="/Document/" + docId;
          console.log('click4');
          link.click();
        });
      } else if (docCount === 0) {
        this.setState({ noMatchesFound: true });
      }
      if (docCount > 0) {
        this.setState({ noMatchesFound: false });
      }

      this.setState({sidebarLoading: false});
    }

    if (lastAction === actionTypes.WorkflowGetUserSteps) {
      var steps = SearchStore.getUserWfSteps();
      let sortedSteps = steps.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });
      this.setState({ steps: sortedSteps });
    }

    if (lastAction === actionTypes.SearchQuickSearch) {
      console.log('Quick Search: Search.js');
      let docCount = SearchStore.getDocCount();
      if (docCount > 1) {

        // if user uses quick search bar while on full search page, put query in full search bar and load results
        let quickSearchInput = document.getElementById("tdqSearchTxt");
        let lastSearch = SearchStore.getLastSearch();
        console.log(lastSearch);
        this.setState({ searchText: lastSearch, results: false });
        this.setState({
          includeActiveDocuments: true,
          getCompletedDocuments: true,
          includeDeleted: false,
          currentSearchType: 'values',
        });
        let adTree = SearchStore.getAdTree();
        console.log(adTree);
        let firstBp = this.findFirstBp(adTree);
        console.log(firstBp);
        let firstStep = firstBp.children[0];
        this.LoadStep(firstStep.id, firstBp.id, lastSearch);
        //SearchByValues(quickSearchInput.value);
        quickSearchInput.value = '';
      }
      else if (docCount === 0){

        //SearchByValues(quickSearchInput.value);
        this.setState({ noMatchesFound: true, results: false });
      }
    }
  }

  findFirstBp(nodeList)
  {
    if(nodeList.length === 0)
      return undefined;
    let node = nodeList[0];
    if(node.type === 'ou')
      {
        return node.children[0];
      }
    if(node.type === 'bp')
      {
        return node;
      }
      return undefined;
  }

  toggleDisplaySearchOptions() {
    if (this.state.displaySearchOptions === true) {
      this.setState({ displaySearchOptions: false });
    } else {
      this.setState({ displaySearchOptions: true });
    }
  }

  toggleDownloadConfirmation() {
    if (this.state.downloadConfirmation === true) {
      this.setState({ downloadConfirmation: false });
    } else {
      this.setState({ downloadConfirmation: true });
    }
  }

  toggleFormatDocIdasTDLink() {
    if (this.state.formatDocumentIdAsTDLink === false) {
      this.setState({ formatDocumentIdAsTDLink: true });
    } else {
      this.setState({ formatDocumentIdAsTDLink: false });
    }
  }

  editSearchText(e) {
    this.setState({ searchText: e.target.value });
  }

  toggleDocument(docId) {
    let docs = this.state.currentDocs;
    let doc = docs.find((d) => d.documentId === docId);
    if (doc) {
      doc.isChecked = !doc.isChecked;
      let checkCount = 0;
      docs.forEach((d) => {
        if (d.isChecked) checkCount++;
      });

      if (!doc.isChecked) {
        this.setState({ checkAll: false });
      }

      this.setState({ currentDocs: docs, checkCount: checkCount });
    }
  }

  toggleCheckAll2() {
    let tempCheckAll = null;
    if (this.state.checkAll === false) {
      tempCheckAll = true;
      this.setState({ checkAll: true });
    } else {
      tempCheckAll = !this.state.checkAll;
      this.setState({ checkAll: tempCheckAll });
    }
    let checkCount = 0;
    let docs = this.state.currentDocs;
    docs.forEach((d) => {
      d.isChecked = tempCheckAll;
      if (tempCheckAll) checkCount++;
    });
    this.setState({ currentDocs: docs, checkCount: checkCount });

    // set same check to all others.
  }

  toggleGetAllDocuments() {

    if (this.state.getAllDocuments === true) {
      this.setState({ getAllDocuments: false, getActiveDocuments: false, getCompletedDocuments: false, getDeletedDocuments: false });
    } else {
      this.setState({ getAllDocuments: true, getActiveDocuments: true, getCompletedDocuments: true, getDeletedDocuments: true });
    }

  }

  toggleGetActiveDocuments() {
    if (this.state.getActiveDocuments === true) {
      this.setState({ getActiveDocuments: false, getAllDocuments: false });
    } else {
      this.setState({ getActiveDocuments: true });
      if (this.state.getCompletedDocuments === true && this.state.getDeletedDocuments === true) {
        this.setState({ getAllDocuments: true });
      }
    }
  }

  toggleGetCompleted() {
    if (this.state.getCompletedDocuments === true) {
      this.setState({ getCompletedDocuments: false, getAllDocuments: false });
    } else {
      this.setState({ getCompletedDocuments: true });
      if (this.state.getActiveDocuments === true && this.state.getDeletedDocuments === true) {
        this.setState({ getAllDocuments: true });
      }
    }

  }

  toggleGetDeleted() {
    if (this.state.getDeletedDocuments === true) {
      this.setState({ getDeletedDocuments: false, getAllDocuments: false });
    } else {
      this.setState({ getDeletedDocuments: true });
      if (this.state.getActiveDocuments === true && this.state.getCompletedDocuments === true) {
        this.setState({ getAllDocuments: true });
      }
    }

  }

  editFieldRowSearchText(event, fieldValueRow) {
    var indexOfObject = this.state.fieldValueRows.findIndex((object) => {
      return object.id === fieldValueRow.id;
    });

    var list = this.state.fieldValueRows;
    list[indexOfObject].search = event.target.value;

    this.setState({ fieldValuesRows: list });
  }

  onSearchKeyDown(e) {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      e.preventDefault();
      if (this.state.searchText.length > 0) {
        var documentsToSearch = {
          includeActiveDocuments: this.state.getActiveDocuments,
          includeCompleted: this.state.getCompletedDocuments,
          includeDeleted: this.state.getDeletedDocuments
        };
        if (this.state.currentSearchType === 'values') {
          SearchByValues(this.state.searchText, documentsToSearch);
          this.setState({sidebarLoading: true});
        } else if (this.state.currentSearchType === 'docId') {
          SearchByDocumentId(this.state.searchText);
        } else if (this.state.currentSearchType === 'properties') {
          SearchByProperties(this.state.searchText);
        } else if (this.state.currentSearchType === 'notes') {
          SearchByNotes(this.state.searchText);
        }
      }
      if (this.state.currentSearchType === 'fieldValue') {
        SearchByFieldValue(this.state.fieldValueRows);
      } else if (this.state.currentSearchType === 'userAudit') {
        SearchByUserAudit(this.state.userAudit);
        this.setState({sidebarLoading: true});
      } else if (this.state.currentSearchType === 'queues') {
        SearchByUserQueue(this.state.selectedQueueUser);
      }

      this.setState({ results: false });
    }
  }

  onSearchButton() {


    var documentsToSearch = {
      includeActiveDocuments: this.state.getActiveDocuments,
      includeCompleted: this.state.getCompletedDocuments,
      includeDeleted: this.state.getDeletedDocuments
    };


    if (this.state.searchText !== undefined && this.state.searchText.length > 0) {
      if (this.state.currentSearchType === 'values') {
        SearchByValues(this.state.searchText, documentsToSearch);
        this.setState({sidebarLoading: true});
      } else if (this.state.currentSearchType === 'docId') {
        SearchByDocumentId(this.state.searchText);
      } else if (this.state.currentSearchType === 'properties') {
        SearchByProperties(this.state.searchText, documentsToSearch);
        this.setState({sidebarLoading: true});
      } else if (this.state.currentSearchType === 'notes') {
        SearchByNotes(this.state.searchText, documentsToSearch);
        this.setState({sidebarLoading: true});
      }
    }
    if (this.state.currentSearchType === 'fieldValue') {
      SearchByFieldValue(this.state.fieldValueRows, documentsToSearch);
      this.setState({sidebarLoading: true});
    } else if (this.state.currentSearchType === 'userAudit') {
      SearchByUserAudit(this.state.userAudit, documentsToSearch);

      this.setState({sidebarLoading: true});
    } else if (this.state.currentSearchType === 'queues') {
      SearchByUserQueue(this.state.selectedQueueUser, documentsToSearch);
      this.setState({sidebarLoading: true});
    }

    this.setState({ results: false });
  }

  LoadBP(id) {
    customHistory.push(
      `/Search?BP=${id.toString()}&Value=${this.state.searchText}`
    );

    var documentsToSearch = {
      includeActiveDocuments: this.state.getActiveDocuments,
      includeCompleted: this.state.getCompletedDocuments,
      includeDeleted: this.state.getDeletedDocuments
    };

    GetSearchBp(
      id,
      this.state.searchText.replaceAll('/', '-'),
      this.state.currentSearchType,
      this.state.fieldValueRows,
      this.state.userAudit,
      this.state.selectedQueueUser,
      '0',
      'asc',
      '0',
      documentsToSearch
    );
    GetWorkflowFromStep(id);
    GetBPPermissions(id);

    this.setState({ results: true });
  }

  changeSearchType(e) {
    if (e === 1) {
      this.setState({ currentSearchType: 'values' });
    }
    if (e === 2) {
      this.setState({ currentSearchType: 'docId' });
    }
    if (e === 3) {
      this.setState({ currentSearchType: 'properties' });
    }
    if (e === 4) {
      this.setState({ currentSearchType: 'notes' });
    }
    if (e === 5) {
      this.setState({ currentSearchType: 'queues' });
    }
    if (e === 6) {
      this.setState({ currentSearchType: 'fieldValue' });
    }
    if (e === 7) {
      this.setState({ currentSearchType: 'userAudit' });
    }

    this.setState(
      {
        searchText: '',
        selectedQueueUser: '',
        selectedFormTypeId: 0,
        fieldValueRows: [
          {
            id: 1,
            fieldId: 0,
            operatorValue: '',
            search: '',
            dataType: 0,
          },
        ],
        userAudit: {
          userId: '-1',
          stepId: '-1',
          startDate: '',
          endDate: '',
          action: 'approve',
        },
      },
      () => this.onWindowResize(e)
    );
  }

  checkParams() {
    console.log('checkParams');
    let params = new URLSearchParams(window.location.search);
    var stepParam = params.get('Step');
    var bpParam = params.get('BP');
    var valueParam = params.get('Value');

    // oldschool params
    //let n = params.get('n');
    //let f = params.get('f');
    let s = params.get('s');

    if (stepParam && valueParam === null && s === null) {
      let lastSearch = SearchStore.getLastSearch();

      this.setState({ searchText: lastSearch, results: false });
      this.LoadStep(stepParam, bpParam, lastSearch);
    }

    if (valueParam) {
      let lastSearch = SearchStore.getLastSearch();
      if(lastSearch === valueParam && valueParam !== undefined)
        {
          console.log('we already have results');
          console.log(lastSearch);
          let lastTree = SearchStore.getAdTree();
          console.log(lastTree);
          let firstBp = this.findFirstBp(lastTree);
          this.setState({
            getActiveDocuments: true,
            getCompletedDocuments: true,
            getDeletedDocuments: false,
            currentSearchType: 'values',
            searchText: lastSearch,
          });
          let firstStep = firstBp.children[0];
          this.LoadStep(firstStep.id, firstBp.id, lastSearch);
        } else {

      this.setState({ searchText: valueParam });
      var documentsToSearch = {
        includeActiveDocuments: true,
        includeCompleted: true,
        includeDeleted: false
      };
      SearchByValues(valueParam, documentsToSearch);
      this.setState({sidebarLoading: true});
        }
    }

    if (s) {
      this.setState({ searchText: s });
      var documentsToSearch = {
        includeActiveDocuments: true,
        includeCompleted: true,
        includeDeleted: false
      };
      SearchByValues(s, documentsToSearch);
      this.setState({sidebarLoading: true});
    }
  }

  editSelectedFormType(event) {
    var filteredList = this.state.allIndexFields.filter(
      (x) =>
        x.formTypeName.replaceAll(' ', '') ===
        event.target.value.replaceAll(' ', '')
    );

    this.setState({
      selectedFormTypeId: event.target.value,
      indexFieldsForFormType: filteredList,
    });
  }

  editSelectedIndexField(event, fieldValueRow) {
    var indexField = this.state.allIndexFields.find(
      (x) => x.id === parseInt(event.target.value)
    );

    var indexOfObject = this.state.fieldValueRows.findIndex((object) => {
      return object.id === fieldValueRow.id;
    });

    var list = this.state.fieldValueRows;
    list[indexOfObject].fieldId = indexField.fieldId;
    list[indexOfObject].dataType = indexField.dataType;

    this.setState({ selectedIndexField: indexField, fieldValuesRows: list });
  }

  editSelectedOperator(event, fieldValueRow) {
    var indexOfObject = this.state.fieldValueRows.findIndex((object) => {
      return object.id === fieldValueRow.id;
    });

    var list = this.state.fieldValueRows;
    list[indexOfObject].operatorValue = event.target.value;

    this.setState({ fieldValuesRows: list });
  }

  addNewFieldValueRow() {
    var x = {
      fieldId: 0,
      operatorValue: '',
      search: '',
      dataType: 0,
      id: this.state.fieldValueRowCount + 1,
    };
    this.state.fieldValueRows.push(x);
    this.setState({
      fieldValueRows: this.state.fieldValueRows,
      fieldValueRowCount: this.state.fieldValueRowCount + 1,
    });
  }

  deleteFieldValueRow(fieldValueRow) {
    var list = this.state.fieldValueRows.filter(
      (x) => x.id !== fieldValueRow.id
    );
    this.setState({ fieldValueRows: list });
  }

  editSelectedUser(event) {
    var userAudit = this.state.userAudit;
    userAudit.userId = event.target.value;
    this.setState({ userAudit: userAudit });
  }

  editSelectedQueueUser(event) {
    this.setState({ selectedQueueUser: event.target.value });
  }

  editSelectedStep(event) {
    var userAudit = this.state.userAudit;
    userAudit.stepId = event.target.value.toString();
    this.setState({ userAudit: userAudit });
  }

  editSelectedAction(event) {
    var userAudit = this.state.userAudit;
    userAudit.action = event.target.value;
    this.setState({ userAudit: userAudit });
  }

  editStartDate(event) {
    var userAudit = this.state.userAudit;
    userAudit.startDate = event.target.value;
    this.setState({ userAudit: userAudit });
  }

  editEndDate(event) {
    var userAudit = this.state.userAudit;
    userAudit.endDate = event.target.value;
    this.setState({ userAudit: userAudit });
  }

  onEditAutoComplete(e, type) {
    let auto = document.getElementById('autoContainer-userQueue');
    let inp = auto.getElementsByTagName('input');

    let val = e.target.value;
    this.closeAllList();
    let a = document.createElement('DIV');
    a.setAttribute('id', 'autocomplete-list');
    a.setAttribute('class', 'autocomplete-items');
    a.setAttribute('style', 'max-height: 200px; overflow: auto');
    auto.appendChild(a);

    if (type === 'userQueue') {
      for (let i = 0; i < this.state.users.length; i++) {
        if (
          this.state.users[i].userName
            .substring(0, val.length)
            .toUpperCase() === val.toUpperCase()
        ) {
          let b = document.createElement('DIV');
          b.innerHTML =
            '<strong tabIndex="-1">' +
            this.state.users[i].userName.substring(0, val.length) +
            '</strong>';
          b.innerHTML += this.state.users[i].userName.substring(val.length);
          //b.OnClick = () => this.onClickAutoComplete(b,i); //
          b.addEventListener('mousedown', (e) =>
            this.onClickAutoComplete(e, i, inp[0])
          );
          //onClickAutoComplete(b, i);
          //this.props.updateValue(this.props.extender.allowedValues[i].textValue, this.props.fieldId, this.props.systemName);
          //this.props.closeAllList(b);
          //});

          a.appendChild(b);
        }
      }
      this.setState({ selectedQueueUser: e.target.value });
    }
  }

  onClickAutoComplete(e, i, inp) {
    this.setState({ selectedQueueUser: this.state.users[i].userName });
    this.closeAllList();
    e.preventDefault();
  }

  closeAllList() {
    var x = document.getElementsByClassName('autocomplete-items');
    for (var i = 0; i < x.length; i++) {
      x[i].parentNode.removeChild(x[i]);
    }
  }

  updateColumnSettings() {
    var settings = {
      headerSettings: [],
    };
    this.state.columns.forEach((col) => {
      let newCol = {
        colName: col.colName,
        display: col.show,
        colWidth: col.colWidth,
      };
      settings.headerSettings.push(newCol);
    });
    //var summary = IRStore.getSummary();
    //UpdateUserHeaderSettings(summary.formTypeId, settings.headerSettings);
  }

  toggleFieldDisplay(col) {
    let columns = this.state.columns;
    let editCol = columns.find((f) => f.colId === col.colId);
    if (editCol) {
      editCol.show = !editCol.show;
      this.setState({ columns: columns }, this.updateColumnSettings);
    }
  }

  render() {
    return (
      <div className='sidebarLayout d-flex flex-row flex-fill'>
        <SearchSidebar
          LoadStepClick={this.LoadStep}
          LoadBPClick={this.LoadBP}
          Loading={this.state.sidebarLoading}
        />
        <Link
          id='searchDocGoto'
          to={'/Document/' + this.state.gotoDocId}
          style={{ display: 'none' }}
        >
          HiddenLink
        </Link>
        <div className='d-flex flex-column flex-fill'>
          {this.state.displaySearchOptions && (
            <div id='td-search-info'>
              {(this.state.currentSearchType === 'values' ||
                this.state.currentSearchType === 'docId' ||
                this.state.currentSearchType === 'properties' ||
                this.state.currentSearchType === 'notes') && (
                <Form className='d-flex td-search-bar justify-content-center mt-4'>
                  <div className='td-search-container'>
                    <div className='form-outline'>
                      <input
                        type='search'
                        placeholder='Search'
                        id='tdSearchTxt'
                        className='td-search-input'
                        aria-label='Search'
                        size='sm'
                        value={this.state.searchText}
                        onChange={this.editSearchText}
                        onKeyDown={this.onSearchKeyDown}
                        autoComplete='off'
                      />
                      {/* <button type="button" class="btn btn-primary">
                                                    <FontAwesomeIcon icon={solid('search')}/>
                                                </button> */}
                    </div>

                    <Link
                      id='searchDocGoto2'
                      to={'/Document/' + this.state.gotoDocId}
                      style={{ display: 'none' }}
                    >
                      HiddenLink
                    </Link>
                  </div>
                </Form>
              )}

              {this.state.currentSearchType === 'queues' && (
                <div
                  className='mb-3'
                  style={{ margin: 'auto', maxWidth: '65%' }}
                >
                  <div className='row justify-content-center'>
                    <div
                      className='col-3 pt-2 font-weight-bold'
                      style={{ textAlign: 'center', fontWeight: 'bold' }}
                    >
                      User
                    </div>
                  </div>
                  <div className='row justify-content-center'>
                    <div className='col-3 pt-2'>
                      <div
                        id={`autoContainer-userQueue`}
                        className='autocomplete'
                      >
                        <input
                          className={`form-control`}
                          value={this.state.selectedQueueUser}
                          //onBlur={this.onBlurAutoComplete}
                          //onChange={this.onEditAutoComplete}
                          onChange={(event) =>
                            this.onEditAutoComplete(event, 'userQueue')
                          }
                          //onKeyDown={this.onKeyDown}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {this.state.currentSearchType === 'fieldValue' && (
                <div
                  className='mb-3'
                  style={{ margin: 'auto', maxWidth: '65%' }}
                >
                  <div className='row justify-content-center'>
                    <div
                      className='col-3 pt-2 font-weight-bold'
                      style={{ textAlign: 'center', fontWeight: 'bold' }}
                    >
                      Form Type
                    </div>
                  </div>
                  <div className='row justify-content-center'>
                    <div className='col-3 pt-2'>
                      <select
                        className='form-select'
                        value={this.state.selectedFormTypeId}
                        onChange={this.editSelectedFormType}
                      >
                        <option value='0'></option>
                        {this.state.formTypes.map((formType) => (
                          <option value={formType.id} key={formType.id}>
                            {formType.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className='row justify-content-center'>
                    <div
                      className='col-3 pt-2 font-weight-bold'
                      style={{ textAlign: 'center', fontWeight: 'bold' }}
                    >
                      Field
                    </div>
                    <div
                      className='col-3 pt-2 font-weight-bold'
                      style={{ textAlign: 'center', fontWeight: 'bold' }}
                    >
                      Operator
                    </div>
                    <div
                      className='col-5 pt-2 font-weight-bold'
                      style={{ textAlign: 'center', fontWeight: 'bold' }}
                    >
                      Value
                    </div>
                    <div
                      className='col-1 pt-2 font-weight-bold'
                      style={{ textAlign: 'center', fontWeight: 'bold' }}
                    ></div>
                    <div
                      className='col-1 pt-2 font-weight-bold'
                      style={{ textAlign: 'center', fontWeight: 'bold' }}
                    ></div>
                  </div>

                  {this.state.fieldValueRows.map((fieldValueRow) => (
                    <div
                      className='row justify-content-center'
                      key={fieldValueRow.id}
                    >
                      <div className='col-3 pt-2'>
                        <select
                          className='form-select'
                          value={this.state.selectedIndexField.Id}
                          onChange={(event) =>
                            this.editSelectedIndexField(event, fieldValueRow)
                          }
                          //onChange={this.editSelectedIndexField(fieldValueRow)}
                        >
                          <option value='0'></option>
                          {this.state.indexFieldsForFormType.map(
                            (indexField) => (
                              <option value={indexField.id} key={indexField.id}>
                                {indexField.colName}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className='col-3 pt-2'>
                        {fieldValueRow.dataType === 0 && (
                          <select
                            className='form-select'
                            value={fieldValueRow.operatorValue}
                            onChange={(event) =>
                              this.editSelectedOperator(event, fieldValueRow)
                            }
                          >
                            <option value='0'></option>
                            <option value='equals'>=</option>
                            <option value='partialMatch'>Partial Match</option>
                          </select>
                        )}
                        {fieldValueRow.dataType === 1 && (
                          <select
                            className='form-select'
                            value={fieldValueRow.operatorValue}
                            onChange={(event) =>
                              this.editSelectedOperator(event, fieldValueRow)
                            }
                          >
                            <option value='0'></option>
                            <option value='equals'>=</option>
                            <option value='lessThan'>{'<'}</option>
                            <option value='lessThanEqualTo'>{'<='}</option>
                            <option value='greaterThan'>{'>'}</option>
                            <option value='greaterThanEqualTo'>{'>='}</option>
                            <option value='partialMatch'>Partial Match</option>
                          </select>
                        )}
                        {fieldValueRow.dataType === 2 && (
                          <select
                            className='form-select'
                            value={fieldValueRow.operatorValue}
                            onChange={(event) =>
                              this.editSelectedOperator(event, fieldValueRow)
                            }
                          >
                            <option value='0'></option>
                            <option value='equals'>=</option>
                            <option value='before'>{'Before'}</option>
                            <option value='after'>{'After'}</option>
                          </select>
                        )}
                      </div>
                      <div className='col-5 pt-2'>
                        <div className='td-search-container2 w-100'>
                          <div className='form-outline'>
                            <input
                              type='search'
                              placeholder='Search'
                              id='tdSearchTxt'
                              className='td-search-input'
                              aria-label='Search'
                              value={fieldValueRow.search}
                              onChange={(event) =>
                                this.editFieldRowSearchText(
                                  event,
                                  fieldValueRow
                                )
                              }
                              onKeyDown={this.onSearchKeyDown}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-1 pt-2'>
                        <button
                          type='button'
                          className='btn btn-success'
                          style={{ marginTop: '4px', marginRight: '4px' }}
                          onClick={this.addNewFieldValueRow}
                        >
                          {'  '}
                          <FontAwesomeIcon icon={solid('plus')} />
                        </button>
                      </div>
                      <div className='col-1 pt-2'>
                        {fieldValueRow.id > 1 && (
                          <button
                            type='button'
                            className='btn btn-danger'
                            style={{ marginTop: '4px', marginRight: '4px' }}
                            onClick={() => {
                              this.deleteFieldValueRow(fieldValueRow);
                            }}
                          >
                            {'  '}
                            <FontAwesomeIcon icon={solid('times')} />
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {this.state.currentSearchType === 'userAudit' && (
                <div
                  className='mb-3'
                  style={{ margin: 'auto', maxWidth: '50%' }}
                >
                  <div className='row justify-content-center'>
                    <div
                      className='col-3 pt-2 font-weight-bold'
                      style={{ textAlign: 'center', fontWeight: 'bold' }}
                    >
                      User
                    </div>
                    <div
                      className='col-3 pt-2 font-weight-bold'
                      style={{ textAlign: 'center', fontWeight: 'bold' }}
                    >
                      Step
                    </div>
                    <div
                      className='col-2 pt-2 font-weight-bold'
                      style={{ textAlign: 'center', fontWeight: 'bold' }}
                    >
                      Start Date
                    </div>
                    <div
                      className='col-2 pt-2 font-weight-bold'
                      style={{ textAlign: 'center', fontWeight: 'bold' }}
                    >
                      End Date
                    </div>
                    <div
                      className='col-2 pt-2 font-weight-bold'
                      style={{ textAlign: 'center', fontWeight: 'bold' }}
                    >
                      Action
                    </div>
                  </div>

                  <div className='row justify-content-center'>
                    <div
                      className='col-3 pt-2'
                      style={{ paddingLeft: '3px', paddingRight: '3px' }}
                    >
                      <select
                        className='form-select'
                        value={this.state.userAudit.userId}
                        onChange={(event) => this.editSelectedUser(event)}
                      >
                        <option value='-1'>{'All'}</option>
                        {this.state.users.map((user) => (
                          <option value={user.userId} key={user.userId}>
                            {user.userName}
                          </option>
                        ))}
                      </select>
                      {/* <div id={`autoContainer-userQueue`} className='autocomplete'><input
                                                className={`form-control`}
                                                value={this.state.userAudit.userId}
                                                //onBlur={this.onBlurAutoComplete}
                                                //onChange={this.onEditAutoComplete}
                                                onChange={event => this.onEditAutoComplete(event, 'userAudit')}
                                                //onKeyDown={this.onKeyDown}
                                                />
                                            </div> */}
                    </div>
                    <div
                      className='col-3 pt-2'
                      style={{ paddingLeft: '3px', paddingRight: '3px' }}
                    >
                      <select
                        className='form-select'
                        value={this.state.userAudit.stepId}
                        onChange={(event) => this.editSelectedStep(event)}
                      >
                        <option value='-1'>{'Any'}</option>
                        {this.state.steps.map((step) => (
                          <option
                            value={step.workflowStepId}
                            key={step.workflowStepId}
                          >
                            {step.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div
                      className='col-2 pt-2'
                      style={{ paddingLeft: '3px', paddingRight: '3px' }}
                    >
                      <div className='td-search-container w-100'>
                        <div className='form-outline'>
                          <input
                            type='search'
                            placeholder='Search'
                            id='tdSearchTxt'
                            className='td-search-input'
                            aria-label='Search'
                            size='sm'
                            value={this.state.userAudit.startDate}
                            onChange={(event) => this.editStartDate(event)}
                            onKeyDown={this.onSearchKeyDown}
                            autoComplete='off'
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className='col-2 pt-2'
                      style={{ paddingLeft: '3px', paddingRight: '3px' }}
                    >
                      <div className='td-search-container w-100'>
                        <div className='form-outline'>
                          <input
                            type='search'
                            placeholder='Search'
                            id='tdSearchTxt'
                            className='td-search-input'
                            aria-label='Search'
                            size='sm'
                            value={this.state.userAudit.endDate}
                            onChange={(event) => this.editEndDate(event)}
                            onKeyDown={this.onSearchKeyDown}
                            autoComplete='off'
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className='col-2 pt-2'
                      style={{ paddingLeft: '3px', paddingRight: '3px' }}
                    >
                      <select
                        className='form-select'
                        value={this.state.userAudit.action}
                        onChange={(event) => this.editSelectedAction(event)}
                      >
                        <option value='0'></option>
                        <option value='all'>{'All'}</option>
                        <option value='approve'>{'Approve'}</option>
                        <option value='reject'>{'Reject'}</option>
                        <option value='save'>{'Save'}</option>
                        <option value='view'>{'View'}</option>
                        <option value='delete'>{'Delete'}</option>
                      </select>
                    </div>
                  </div>
                </div>
              )}

              <div className='d-flex justify-content-center mt-3'>
                <div className='form-check form-check-inline'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='searchOptions'
                    id='byValue'
                    onChange={() => this.changeSearchType(1)}
                    checked={this.state.currentSearchType === 'values'}
                  />
                  <label className='form-check-label'>Value</label>
                </div>
                <div className='form-check form-check-inline'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='searchOptions'
                    id='byDocumentId'
                    onChange={() => this.changeSearchType(2)}
                    checked={this.state.currentSearchType === 'docId'}
                  />
                  <label className='form-check-label'>Document Id</label>
                </div>
                <div className='form-check form-check-inline'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='searchOptions'
                    id='byProperties'
                    onChange={() => this.changeSearchType(3)}
                    checked={this.state.currentSearchType === 'properties'}
                  />
                  <label className='form-check-label'>Properties</label>
                </div>
                <div className='form-check form-check-inline'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='searchOptions'
                    id='notes'
                    onChange={() => this.changeSearchType(4)}
                    checked={this.state.currentSearchType === 'notes'}
                  />
                  <label className='form-check-label'>Notes</label>
                </div>
                <div className='form-check form-check-inline'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='searchOptions'
                    id='byQueues'
                    onChange={() => this.changeSearchType(5)}
                    checked={this.state.currentSearchType === 'queues'}
                  />
                  <label className='form-check-label'>Queues</label>
                </div>
                <div className='form-check form-check-inline'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='searchOptions'
                    id='byFieldValue'
                    onChange={() => this.changeSearchType(6)}
                    checked={this.state.currentSearchType === 'fieldValue'}
                  />
                  <label className='form-check-label'>Field Value</label>
                </div>
                <div className='form-check form-check-inline'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='searchOptions'
                    id='byUserAudit'
                    onChange={() => this.changeSearchType(7)}
                    checked={this.state.currentSearchType === 'userAudit'}
                  />
                  <label className='form-check-label'>User Audit</label>
                </div>
              </div>

              <div className='d-flex justify-content-center mt-2'>


                  <label className="checkbox-inline" style={{ paddingLeft: '10px' }}>
                    <input type="checkbox" checked={this.state.getActiveDocuments} onChange={this.toggleGetActiveDocuments}/>{' '}Active Documents {' '}
                  </label>

                  <label className="checkbox-inline" style={{ paddingLeft: '10px' }}>
                    <input type="checkbox" checked={this.state.getCompletedDocuments} onChange={this.toggleGetCompleted}/>{' '}Completed {' '}
                  </label>

                  <label className="checkbox-inline" style={{ paddingLeft: '10px' }}>
                    <input type="checkbox" checked={this.state.getDeletedDocuments} onChange={this.toggleGetDeleted}/>{' '}Deleted {' '}
                  </label>

                </div>

              <div className='d-flex justify-content-center mt-2 pb-2'>
                <button
                  type='button'
                  className='btn btn-primary'
                  style={{ marginTop: '4px', marginRight: '4px' }}
                  onClick={this.onSearchButton}
                >
                  Search {'  '}
                  <FontAwesomeIcon icon={solid('search')} />
                </button>
              </div>
            </div>
          )}

          {this.state.noMatchesFound && (
            <div className='d-flex justify-content-center mt-4'>
              <div>
                <h3>No matching documents found</h3>
              </div>
            </div>
          )}

          {this.state.results && (
            <DocumentList
              mode='SEARCH'
              documents={this.state.currentDocs}
              FormTypeId={this.state.currentFormType}
              Reload={this.checkParams}
              bpName={this.state.activeBP}
              stepName={this.state.activeStep}
              docCount={this.state.docCount}
              scrollWidth={this.state.scrollWidth}
              scrollHeight={this.state.scrollHeight}
              offset={this.state.currentOffset}
              toggleDocument={this.toggleDocument}
              toggleCheckAll2={this.toggleCheckAll2}
              checkAll={this.state.checkAll}
              checkCount={this.state.checkCount}
              includeActiveDocuments={this.state.getActiveDocuments}
              includeCompleted={this.state.getCompletedDocuments}
              includeDeleted={this.state.getDeletedDocuments}
            />
          )}
        </div>
      </div>
    );
  }
}

export default Search;
