import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Link } from 'react-router-dom';
import actionTypes from '../../../actions/ActionTypes';
import {
  GetUserSettings,
  GetColumnSettings,
  UpdateUserHeaderSettings,
} from '../../../actions/DocumentListActions';
import DocumentListStore from '../../../stores/DocumentListStore';
import {
  BulkActionApprove,
  BulkActionApproveAll,
  BulkActionReject,
  BulkActionRejectAll,
  BulkActionDelete,
  BulkActionDeleteAll,
  BulkActionUnassign,
  BulkActionUnassignAll,
  BulkActionSendToImageReview,
  BulkActionSendToImageReviewAll,
  BulkActionChangeToReadyStatus,
  BulkActionChangeToReadyStatusAll,
  BulkActionMoveAndAssign,
  BulkActionMoveAndAssignAll,
  GetWorkflowStepsByBpId,
  BulkActionsDownload,
} from '../../../actions/BulkActions';
import {
  FetchAssignableUsers,
  UpdateTemplates,
  GetAvailableTemplatesByBp,
  GetAvailableBps,
  GetAvailableTemplatesByBp2,
  ChangeBusinessProcesses,
} from '../../../actions/DocumentActions';
import { GetADTree } from '../../../actions/ActiveDocumentActions';
import BulkActionStore from '../../../stores/BulkActionStore';
import DocumentStore from '../../../stores/DocumentStore';
import IRStore from '../../../stores/ImageReviewStore';
import ActiveDocumentStore from '../../../stores/ActiveDocumentStore';
//import StretchContainer from '../../../components/StretchContainer';
import {
  GetADStep,
  GetBP,
  ExportActiveDocuments,
} from '../../../actions/ActiveDocumentActions';
import { GetInboxStep, GetInboxBp } from '../../../actions/InboxActions';
import InboxStore from '../../../stores/InboxStore';
import SearchStore from '../../../stores/SearchStore';
import { GetSearchStep, GetSearchBp } from '../../../actions/SearchActions';

const toolBarBack = '#EFEFEF';
const toolBarBorder = 'solid 1px #BBB';

var isDraggingColumn = false;
var startColX = 0;
var dragColIndex = 0;
var startColWidth = 0;
var useMagic = true;

class DocumentList extends React.Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      userSettings: { fontSizeMode: 1 },
      scrollWidth: window.innerWidth - 322,
      scrollHeight: window.innerHeight - 167,
      canBulkAction: false,
      colSizes: [180, 150, 150, 150, 150, 150],
      colOffsets: [0, 180, 330, 480, 630, 780],
      downloadConfirmation: false,
      formatDocumentIdAsTDLink: true,
      showFields: false,
      fieldSettings: {
        formTypeId: 0,
        columnSettings: [
          {
            ColId: 1,
            colWidth: 100,
          },
        ],
      },
      showBulkActions: true,
      showBulkEditMenu: false,
      bulkEditorEnabled: false,
      showBulkApprove: false,
      showBulkReject: false,
      showBulkDelete: false,
      showBulkMoveAndAssign: false,
      showBulkUnassign: false,
      showBulkSendToImageReview: false,
      showBulkChangeToReadyStatus: false,
      showBulkChangeBusinessProcess: false,
      showBulkChangeTemplate: false,
      showBulkDownloadPdfs: false,

      showMoveAssign: false,
      checkedDocs: [],
      showBulkResults: false,
      bulkResultsArray: [],
      moveToStepId: 0,
      businessProcess: 0,
      bulkWorkflowStepArray: [],
      moveAssignStepId: -1,
      moveAssignUserSearch: '',
      moveAssignableUsers: [],
      moveAssignUsers: [],
      moveAssignSelectedUsers: [],

      //templates
      showTemplateChange: false,
      selectedTemplate: { templateId: -1, templateName: 'Template...' },
      suggestedTemplateId: { templateId: -1, templateName: 'Template...' },
      templateSearchText: '',
      availableTemplates: [],

      filteredTemplates: [],
      applyTemplate: true,
      //templates

      //templates2
      showTemplateChange2: false,
      selectedTemplate2: { templateId: -1, templateName: 'Template...' },
      suggestedTemplateId2: { templateId: -1, templateName: 'Template...' },
      templateSearchText2: '',
      availableTemplates2: [],

      filteredTemplates2: [],
      applyTemplate2: true,
      //templates
      //bp
      availableBps: [],
      selectedBpId2: -1,
      currentSortField: 0,
      currentSortDir: 'up',
      lastBpId: '0',
      lastStepId: '0',
      //bp

      //sorting by column
      latestListLoad: {
        type: '',
        stepId: 0,
        bpId: 0,
        value: '',
      },
      inboxType: '',
      allFields: false,
      contentWidth: 1563.5,
      contentHeight: 800,
      bpPermissions: {
        canEdit: false,
        canView: false,
        canDownload: false,
        canApprove: false,
        canImageReview: false,
        canDelete: false,
        canEditTemplate: false,
        canEditProperties: false,
      },
      loadingMore: false,
      recentQuery: '',
      hoverId: -1,
    };

    this.toggleDownloadConfirmation =
      this.toggleDownloadConfirmation.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);
    this.DragColumnSize = this.DragColumnSize.bind(this);
    this.stopDraggingColSize = this.stopDraggingColSize.bind(this);
    this.onDocListChange = this.onDocListChange.bind(this);
    this.toggleFields = this.toggleFields.bind(this);
    this.toggleFieldDisplay = this.toggleFieldDisplay.bind(this);
    this.updateColumnSettings = this.updateColumnSettings.bind(this);

    //#bulk editor variables
    this.toggleBulkEditor = this.toggleBulkEditor.bind(this);
    this.toggleBulkApprove = this.toggleBulkApprove.bind(this);
    this.toggleBulkReject = this.toggleBulkReject.bind(this);
    this.toggleBulkDelete = this.toggleBulkDelete.bind(this);
    this.toggleBulkMoveAndAssign = this.toggleBulkMoveAndAssign.bind(this);
    this.toggleBulkUnassign = this.toggleBulkUnassign.bind(this);
    this.toggleBulkSendToImageReview =
      this.toggleBulkSendToImageReview.bind(this);
    this.toggleChangeToReadyStatus = this.toggleChangeToReadyStatus.bind(this);
    this.toggleBulkChangeBusinessProcess =
      this.toggleBulkChangeBusinessProcess.bind(this);
    this.toggleBulkChangeTemplate = this.toggleBulkChangeTemplate.bind(this);
    this.bulkApproveClick = this.bulkApproveClick.bind(this);
    this.onBulkChange = this.onBulkChange.bind(this);
    this.toggleshowBulkResults = this.toggleshowBulkResults.bind(this);
    this.toggleShowMoveAssign = this.toggleShowMoveAssign.bind(this);
    this.bulkRejectClick = this.bulkRejectClick.bind(this);
    this.bulkDeleteClick = this.bulkDeleteClick.bind(this);
    this.bulkUnassignClick = this.bulkUnassignClick.bind(this);
    this.bulkSendToImageReviewClick =
      this.bulkSendToImageReviewClick.bind(this);
    this.bulkChangeToReadyStatusClick =
      this.bulkChangeToReadyStatusClick.bind(this);
    this.moveToStep = this.moveToStep.bind(this);
    this.getWorkflowStepsByBpId = this.getWorkflowStepsByBpId.bind(this);

    this.editMoveAssignStep = this.editMoveAssignStep.bind(this);
    this.moveAssignUserBlur = this.moveAssignUserBlur.bind(this);
    this.editMoveAssignUser = this.editMoveAssignUser.bind(this);
    this.onDocumentChange = this.onDocumentChange.bind(this);
    this.onClickMoveAssignableUser = this.onClickMoveAssignableUser.bind(this);
    this.moveAssignDocClick = this.moveAssignDocClick.bind(this);
    this.onClickRemoveMoveAssignUser =
      this.onClickRemoveMoveAssignUser.bind(this);

    //templates
    this.toggleTemplateChange = this.toggleTemplateChange.bind(this);
    this.suggestTemplateItem = this.suggestTemplateItem.bind(this);
    this.templateSuggestionClick = this.templateSuggestionClick.bind(this);
    this.onChangeTemplateSearchText =
      this.onChangeTemplateSearchText.bind(this);
    this.onClickTemplateSearch = this.onClickTemplateSearch.bind(this);
    this.filterTemplates = this.filterTemplates.bind(this);
    this.toggleApplyTemplate = this.toggleApplyTemplate.bind(this);
    this.onClickTemplateChange = this.onClickTemplateChange.bind(this);
    //templates

    //templates 2
    this.toggleTemplateChange2 = this.toggleTemplateChange2.bind(this);
    this.suggestTemplateItem2 = this.suggestTemplateItem2.bind(this);
    this.templateSuggestionClick2 = this.templateSuggestionClick2.bind(this);
    this.onChangeTemplateSearchText2 =
      this.onChangeTemplateSearchText2.bind(this);
    this.onClickTemplateSearch2 = this.onClickTemplateSearch2.bind(this);
    this.filterTemplates2 = this.filterTemplates2.bind(this);
    this.toggleApplyTemplate2 = this.toggleApplyTemplate2.bind(this);
    //templates 2

    this.editBps = this.editBps.bind(this);
    this.moveBpDocsClick = this.moveBpDocsClick.bind(this);
    //#endregion

    this.beginEdit = this.beginEdit.bind(this);

    this.toggleShowMoveAssign = this.toggleShowMoveAssign.bind(this);
    this.editMoveAssignStep = this.editMoveAssignStep.bind(this);
    this.moveAssignUserBlur = this.moveAssignUserBlur.bind(this);
    this.editMoveAssignUser = this.editMoveAssignUser.bind(this);

    this.onClickMoveAssignableUser = this.onClickMoveAssignableUser.bind(this);
    this.onClickRemoveMoveAssignUser =
      this.onClickRemoveMoveAssignUser.bind(this);
    this.onClickColumnHeader = this.onClickColumnHeader.bind(this);
    this.toggleAllFields = this.toggleAllFields.bind(this);
    this.removeColumn = this.removeColumn.bind(this);
    this.onExcelDownloadConfirmation =
      this.onExcelDownloadConfirmation.bind(this);
    this.toggleFormatDocIdasTDLink = this.toggleFormatDocIdasTDLink.bind(this);
    this.onMagicScroll = this.onMagicScroll.bind(this);
    this.onMainScroll = this.onMainScroll.bind(this);
    this.onActiveDocChange = this.onActiveDocChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onInboxChanges = this.onInboxChanges.bind(this);
    this.onRefresh = this.onRefresh.bind(this);
    this.onLoadMore = this.onLoadMore.bind(this);
    this.onRowEnter = this.onRowEnter.bind(this);
    this.toggleSendToImageReview = this.toggleSendToImageReview.bind(this);
    this.downloadPdfs = this.downloadPdfs.bind(this);
    this.toggleBulkDownloadPDFs = this.toggleBulkDownloadPDFs.bind(this);
  }

  onClickMoveAssignableUser(e, user) {}

  editMoveAssignUser(e) {
    var x = document.getElementsByClassName('moveAssignUserItem');
    for (var i = 0; i < x.length; i++) {
      x[i].parentNode.removeChild(x[i]);
    }

    let auto = document.getElementById('moveContainerAssignUserText');

    let val = e.target.value;
    let a = document.createElement('DIV');
    a.setAttribute('id', 'autocomplete-list');
    a.setAttribute('class', 'moveAssignUserItem');

    a.setAttribute('style', 'max-height: 260px; overflow: auto');
    auto.appendChild(a);
    for (let i = 0; i < this.state.moveAssignUsers.length; i++) {
      if (
        this.state.moveAssignUsers[i].userName
          .substring(0, val.length)
          .toUpperCase() === val.toUpperCase() ||
        this.state.moveAssignUsers[i].fullName
          .substring(0, val.length)
          .toUpperCase() === val.toUpperCase()
      ) {
        let b = document.createElement('DIV');
        b.setAttribute('class', 'docAssignItem');
        b.innerHTML =
          '<div><strong tabIndex="-1">' +
          this.state.moveAssignUsers[i].fullName +
          '</strong></div>';
        b.innerHTML +=
          '<div class="docAssignSubHeader">' +
          this.state.moveAssignUsers[i].userName +
          '</div>';
        b.addEventListener('mousedown', (e) =>
          this.onClickMoveAssignableUser(e, this.state.moveAssignUsers[i])
        );
        a.appendChild(b);
      }
    }

    //this.props.updateValue(e.target.value, this.props.fieldId, this.props.systemName);
    this.setState({ moveAssignUserSearch: e.target.value });
  }
  moveAssignUserBlur() {
    var x = document.getElementsByClassName('moveAssignUserItem');
    for (var i = 0; i < x.length; i++) {
      x[i].parentNode.removeChild(x[i]);
    }
    this.setState({ moveAssignUserSearch: '' });
  }
  // editMoveAssignStep(e) {
  //   this.setState({ moveAssignStepId: parseInt(e.target.value) });
  //   //FetchAssignableUsers(parseInt(e.target.value,10), this.state.currentDoc.businessProcessId);
  // }
  componentDidMount() {
    DocumentListStore.addChangeListener(this.onDocListChange);
    BulkActionStore.addChangeListener(this.onBulkChange);
    DocumentStore.addChangeListener(this.onDocumentChange);
    ActiveDocumentStore.addChangeListener(this.onActiveDocChange);
    SearchStore.addChangeListener(this.onSearchChange);
    InboxStore.addChangeListener(this.onInboxChanges);
    window.onresize = this.onWindowResize;
    GetUserSettings();
  }

  componentDidUpdate() {
    if (this.props.FormTypeId !== this.state.fieldSettings.formTypeId) {
      let newSettings = {
        formTypeId: this.props.FormTypeId,
        columnSettings: [
          {
            ColId: 1,
            colWidth: 100,
          },
        ],
      };
      GetColumnSettings(this.props.FormTypeId);
      this.setState({ fieldSettings: newSettings });
    } else {
    }

    if (this.state.contentHeight !== this.props.documents.length * 25) {
      this.setState({ contentHeight: this.props.documents.length * 25 });
    }
  }

  componentWillUnmount() {
    DocumentListStore.removeChangeListener(this.onDocListChange);
    BulkActionStore.removeChangeListener(this.onBulkChange);
    DocumentStore.removeChangeListener(this.onDocumentChange);
    ActiveDocumentStore.removeChangeListener(this.onActiveDocChange);
    SearchStore.removeChangeListener(this.onSearchChange);
    InboxStore.removeChangeListener(this.onInboxChanges);
  }

  startDraggingColumn(e, colId) {
    let col = this.state.fieldSettings.columnSettings.find(
      (c) => c.colId === colId
    );
    let colIndex = this.state.fieldSettings.columnSettings.indexOf(col);

    if (colIndex >= 0) {
      isDraggingColumn = true;
      startColX = e.clientX;

      let colInfo = this.state.fieldSettings.columnSettings[colIndex];

      dragColIndex = colIndex;
      if (colInfo) {
        startColWidth = colInfo.colWidth;
      }
    }
  }

  goToDoc(e, docId) {
    //docLink.setAttribute('href', `/Document/${docId}`);
    if(e.ctrlKey) {
      window.open('/Document/' + docId.toString());
    } else {
      let returnPath = window.location.pathname + window.location.search;
      localStorage.setItem('docReturn', returnPath);
      //docLink.click();
      this.setState({ goToDocId: docId }, () => {
        let docLink = document.getElementById('docLink');
        docLink.click();
      });
    }
  }

  DragColumnSize(e) {
    if (isDraggingColumn) {
      var delta = e.clientX - startColX;
      if (
        startColWidth + delta > 20 &&
        !(dragColIndex === 0 && startColWidth + delta < 130)
      ) {
        let info = this.state.fieldSettings.columnSettings;
        let colInfo = info[dragColIndex];
        colInfo.colWidth = startColWidth + delta;

        this.setState({ columns: info });
      }
    }
  }
  // goToDoc(docId) {
  //   if (window.location.pathname === '/ActiveDocuments') {
  //     var docLink = document.getElementById(`docLink`);
  //     var returnPath = window.location.pathname + window.location.search;
  //     localStorage.setItem('docReturn', returnPath);

  //     docLink.click();
  //   }
  // }
  updateColumnSettings() {
    var colSettings = [];
    let contentWidth = 0;
    this.state.fieldSettings.columnSettings.forEach((col) => {
      if (col.show) {
        contentWidth += col.colWidth;
      }
      //let sf = this.state.currentSortField;
      let sd = this.state.currentSortDir;
      let sortDir = null;
      if(col.colId == this.state.currentSortField)
      {
        sortDir = sd;
      }

      colSettings.push({
        colName: col.displayName,
        colWidth: col.colWidth,
        display: col.show,
        sortDir: sortDir,
        fieldId: col.fieldId,
      });

    });
    this.setState({ contentWidth: contentWidth });
    UpdateUserHeaderSettings(this.props.FormTypeId, colSettings);
  }

  stopDraggingColSize() {
    if (isDraggingColumn) {
      isDraggingColumn = false;
      this.updateColumnSettings();
    }
  }
  toggleFields() {
    let f = this.state.showFields;
    this.setState({ showFields: !f });
  }

  toggleFieldDisplay(col) {
    let fs = this.state.fieldSettings;
    let columns = fs.columnSettings;
    let editCol = columns.find((f) => f.colId === col.colId);
    if (editCol) {
      editCol.show = !editCol.show;
      this.setState({ fieldSettings: fs }, this.updateColumnSettings);
    }
  }

  onDocumentChange() {
    let lastChange = DocumentStore.lastChange();
    switch (lastChange) {
      case actionTypes.DocumentAssignUsers:
        let assUsers = DocumentStore.getMoveAssignUsers();
        this.setState({ moveAssignUsers: assUsers.assignableUsers });
        break;
      default:
        break;
    }
  }

  onActiveDocChange() {
    let lastChange = ActiveDocumentStore.lastChange();
    console.log(lastChange);
    switch (lastChange) {
      case actionTypes.BPGetPermission:
        let permissions = ActiveDocumentStore.getBPPermissions();
        this.setState({ bpPermissions: permissions });
        break;

      case actionTypes.ADGetStep:
        let params = new URLSearchParams(window.location.search);
        var stepParam = params.get('Step');
        var bpParam = params.get('BP');
        let main = document.getElementById('mainRowView');
        let magic = document.getElementById('magicScroll');
        let top = 0;
        console.log(stepParam);
        if(stepParam !== null)
        {
          this.setState({showBulkActions: true});
        } else {
          this.setState({showBulkActions: false, showBulkEditMenu: false});
        }

        if (
          (stepParam !== this.state.lastStepId && stepParam !== null) ||
          bpParam !== this.state.lastBpId
        ) {
          this.setState({
            //currentSortField: 0,
            //currentSortDir: 'up',
            lastStepId: stepParam,
            lastBpId: bpParam,
          });
        }
        if (!this.state.loadingMore && main !== null && magic !== null) {
          main.scrollTop = top;
          magic.scrollTop = top;
        }

        let query = ActiveDocumentStore.getRecentQuery();
        //set this variable to false when we have received more docs, to prevent multiple requests
        this.setState({ loadingMore: false, recentQuery: query });
        break;

      default:
        break;
    }
  }

  onSearchChange() {
    let params = new URLSearchParams(window.location.search);
    let stepParam = params.get('Step');
    let bpParam = params.get('BP');
    let main = document.getElementById('mainRowView');
    let magic = document.getElementById('magicScroll');
    let top = 0;

    let lastChange = SearchStore.getLastAction();
    switch (lastChange) {
      case actionTypes.SearchGetStep:
        this.setState({showBulkActions: true});

        if (stepParam !== this.state.lastStepId) {
          this.setState({
            //currentSortField: 0,
            //currentSortDir: 'up',
            lastStepId: stepParam,
          });
        }

        if (!this.state.loadingMore && main !== null && magic !== null) {
          main.scrollTop = top;
          magic.scrollTop = top;
        }
        let query = SearchStore.getRecentQuery();

        //set this variable to false when we have received more docs, to prevent multiple requests
        this.setState({ loadingMore: false, recentQuery: query });
        break;

      case actionTypes.SearchGetBp:
        this.setState({showBulkActions: false});
        if (
          stepParam !== this.state.lastStepId ||
          bpParam !== this.state.lastBpId
        ) {
          this.setState({
            currentSortField: 0,
            currentSortDir: 'up',
            lastStepId: stepParam,
            lastBpId: bpParam,
          });
        }

        if (!this.state.loadingMore && main !== null && magic !== null) {
          main.scrollTop = top;
          magic.scrollTop = top;
        }

        let query2 = SearchStore.getRecentQuery();

        //set this variable to false when we have received more docs, to prevent multiple requests
        this.setState({ loadingMore: false, recentQuery: query2 });
        break;

      default:
        break;
    }
  }

  onInboxChanges() {
    let params = new URLSearchParams(window.location.search);
    let stepParam = params.get('Step');
    let bpParam = params.get('BP');
    let main = document.getElementById('mainRowView');
    let magic = document.getElementById('magicScroll');
    let top = 0;

    let lastChange = InboxStore.lastChange();
    switch (lastChange) {
      case actionTypes.InboxGetStep:
        this.setState({showBulkActions:true});
        if (stepParam !== this.state.lastStepId) {
          this.setState({
            currentSortField: 0,
            currentSortDir: 'up',
            lastStepId: stepParam,
          });
        }

        if (!this.state.loadingMore && main !== null && magic !== null) {
          main.scrollTop = top;
          magic.scrollTop = top;
        }
        let query = InboxStore.getRecentQuery();

        //set this variable to false when we have received more docs, to prevent multiple requests
        this.setState({ loadingMore: false, recentQuery: query });
        break;

      case actionTypes.InboxGetBp:
        this.setState({showBulkActions: false});
        if (
          stepParam !== this.state.lastStepId ||
          bpParam !== this.state.lastBpId
        ) {
          this.setState({
            currentSortField: 0,
            currentSortDir: 'up',
            lastStepId: stepParam,
            lastBpId: bpParam,
          });
        }

        if (!this.state.loadingMore && main !== null && magic !== null) {
          main.scrollTop = top;
          magic.scrollTop = top;
        }
        let query2 = InboxStore.getRecentQuery();

        //set this variable to false when we have received more docs, to prevent multiple requests
        this.setState({ loadingMore: false, recentQuery: query2 });
        break;

      default:
        break;
    }
  }

  onClickRemoveMoveAssignUser(e, user) {
    let assignedUsers = this.state.moveAssignUsers;
    let idx = assignedUsers.indexOf(user);
    assignedUsers.splice(idx, 1);
    this.setState({ moveAssignUsers: assignedUsers });
  }

  onDocListChange() {
    let lastChange = DocumentListStore.lastChange();
    switch (lastChange) {
      case actionTypes.DocListSetting:
        let settings = DocumentListStore.getUserSettings();
        this.setState({ userSettings: settings });
        break;
      case actionTypes.DocListColumns:
        let colSettings = DocumentListStore.getColumnSettings();
        let fs = this.state.fieldSettings;
        let sf = 0
        let sd = 'up';
        fs.columnSettings = colSettings;
        let cWidth = 0;
        fs.columnSettings.forEach((col) => {
          if (col.show) {
            cWidth += col.colWidth;
          }
          if(col.sortDir !== undefined && col.sortDir !== null)
          {
            let idx = fs.columnSettings.indexOf(col);
            sf = col.colId;
            sd = col.sortDir;
          }
        });
        this.setState({
          columns: colSettings,
          fieldSettings: fs,
          contentWidth: cWidth,
          currentSortField: sf,
          currentSortDir: sd,
        });
        break;
      default:
        break;
    }
  }

  toggleDownloadConfirmation() {
    if (this.state.downloadConfirmation === true) {
      this.setState({ downloadConfirmation: false });
    } else {
      this.setState({ downloadConfirmation: true });
    }
  }

  beginEdit() {
    let docs = this.props.documents;
    let docIds = [];
    docs.forEach((d) => {
      if (d.isChecked) {
        docIds.push(d.documentId);
      }
    });

    IRStore.setReviewDocIds(docIds);
    let link = document.getElementById('imageReviewEditLink');
    link.click();
  }

  onLoadMore() {
    let offset = this.props.documents.length;
    let params = new URLSearchParams(window.location.search);
    let stepParam = params.get('Step');
    let bpParam = params.get('BP');

    let sort = 'desc';

    if (this.state.currentSortDir === 'up') {
      sort = 'asc';
    }

    localStorage.setItem('loadingMore', true);

    if (
      this.props.mode === 'ACTIVE_DOCUMENTS' &&
      this.state.loadingMore === false
    ) {
      if (stepParam) {
        GetADStep(
          stepParam,
          bpParam,
          this.state.currentSortField,
          sort,
          offset
        );
      } else {
        // just bp
        var completedDocsInput = document.getElementById('showCompletedDocs');
        GetBP(
          bpParam,
          this.state.currentSortField,
          sort,
          completedDocsInput.checked.toString(),
          offset
        );
      }

      this.setState({ loadingMore: true });
    } else if (
      this.props.mode === 'INBOX' &&
      this.state.loadingMore === false
    ) {
      var type = InboxStore.getInboxType();

      if (stepParam) {
        GetInboxStep(
          stepParam,
          bpParam,
          type,
          this.state.currentSortField,
          sort,
          offset
        );
      } else {
        GetInboxBp(bpParam, type, this.state.currentSortField, sort, offset);
      }
      this.setState({ loadingMore: true });
    } else if (
      this.props.mode === 'SEARCH' &&
      this.state.loadingMore === false
    ) {
      let lastSearch = SearchStore.getLastSearch();

      if (stepParam) {
        GetSearchStep(
          lastSearch.id,
          lastSearch.bpId,
          lastSearch.value.replaceAll('/', '-'),
          lastSearch.searchType,
          lastSearch.data,
          lastSearch.userAudit,
          lastSearch.userName,
          this.state.currentSortField,
          sort,
          offset,
          {
            includeActiveDocuments: this.props.includeActiveDocuments,
            includeCompleted: this.props.includeCompleted,
            includeDeleted: this.props.includeDeleted
          }
        );
      } else {
        // just bp
        GetSearchBp(
          lastSearch.bpId,
          lastSearch.value.replaceAll('/', '-'),
          lastSearch.searchType,
          lastSearch.data,
          lastSearch.userAudit,
          lastSearch.userName,
          this.state.currentSortField,
          sort,
          offset,
          {
            includeActiveDocuments: this.props.includeActiveDocuments,
            includeCompleted: this.props.includeCompleted,
            includeDeleted: this.props.includeDeleted
          }
        );
      }
      this.setState({ loadingMore: true });
    }

    //GetADStep(stepParam, bpParam, this.state.currentSortField, x, offset);
  }

  onWindowResize(e) {
    //setViewWidth
    this.setState({ scrollWidth: window.innerWidth - 322 });
    this.setState({ scrollHeight: window.innerHeight - 175 });
  }
  moveToStep(e) {
    let from = parseInt(e.target.value);
    this.setState({ moveToStepId: from });
  }

  onExcelDownloadConfirmation() {
    this.setState({ downloadConfirmation: false });
    if (this.props.documents.length > 0) {
      //let firstDoc = this.props.documents[0];

      const today = new Date();
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();

      if (dd < 10) dd = '0' + dd;
      if (mm < 10) mm = '0' + mm;

      const formattedToday = mm + '-' + dd + '-' + yyyy;

      let downloadName =
        this.props.mode +
        '_' +
        //firstDoc.stepName +
        //'_' +
        formattedToday +
        '.xlsx';

      console.log('FormTypeId');
      console.log(this.props.FormTypeId);
      ExportActiveDocuments(
        this.state.recentQuery,
        this.state.formatDocumentIdAsTDLink,
        window.location.href,
        downloadName,
        this.props.FormTypeId
      );
    }
  }

  toggleFormatDocIdasTDLink() {
    if (this.state.formatDocumentIdAsTDLink === false) {
      this.setState({ formatDocumentIdAsTDLink: true });
    } else {
      this.setState({ formatDocumentIdAsTDLink: false });
    }
  }

  //#region bulk methods
  onBulkChange() {
    let lastChange = BulkActionStore.getLastChange();
    let temp = [];
    if (lastChange === actionTypes.BulkAction) {
      temp = [];
      let result = BulkActionStore.getBulkResults();
      result.responseDocuments.map((doc) =>
        temp.push({
          documentId: doc.documentId,
          message: doc.message,
          status: doc.status,
        })
      );
      this.setState({ bulkResultsArray: temp });
    }
    if(lastChange === actionTypes.BulkDownload) {
      this.setState({showBulkDownloadPdfs: false});
    }
    if (lastChange === actionTypes.WorkflowStepAction) {
      let temp2 = [];
      let result = BulkActionStore.getWorkflowStepsResults();
      result.responseDocuments.map((doc2) =>
        temp2.push({
          stepId: doc2.stepId,
          stepName: doc2.stepName,
        })
      );
      this.setState({ bulkWorkflowStepArray: temp2 });
      return;
    }
    if (lastChange === actionTypes.DocumentGetAvailTemplatesByBp) {
      let templates = BulkActionStore.getWorkflowAvailableTemplatesResults();
      this.setState({ availableTemplates: templates });
      return;
    }
    if (lastChange === actionTypes.DocumentGetAvailTemplatesByBp2) {
      let templates = BulkActionStore.getWorkflowAvailableTemplatesResults();
      this.setState({ availableTemplates2: templates });
      this.filterTemplates2('');
      return;
    }

    if (lastChange === actionTypes.DocumentGetAvailBpByBp) {
      let bps = BulkActionStore.getBulkBpResults();
      this.setState({ availableBps: bps });
      return;
    }

    this.props.Reload();
    //GetADTree();
    this.setState({ showBulkEditMenu: false, bulkEditorEnabled: false });
    this.setState({
      showBulkStatusMsg:
        'Bulk Actions processed.  Document count (' + temp.length + ').',
    });
  }

  editMoveAssignStep(e) {
    this.setState({ moveAssignStepId: parseInt(e.target.value) });

    let params = new URLSearchParams(window.location.search);
    var bpParam = params.get('BP');
    FetchAssignableUsers(parseInt(e.target.value, 10), parseInt(bpParam));
  }

  editBps(e) {
    let selectedVal = parseInt(e.target.value, 10);
    this.setState({ selectedBpId2: selectedVal });

    GetAvailableTemplatesByBp2(selectedVal);
  }
  //#region bulk executes

  bulkApproveClick() {
    if (this.props.checkAll) {
      BulkActionApproveAll(this.state.recentQuery);
    } else {
      var selectedDocs = { docIds: [] };
      this.props.documents
        .filter((filt) => filt.isChecked === true)
        .map((doc) =>
          selectedDocs.docIds.push({
            documentId: doc.documentId,
            stepId: doc.stepId,
          })
        );
      BulkActionApprove(selectedDocs);
    }

    this.setState({ showBulkApprove: false });
    this.setState({ showBulkResults: true });
    this.setState({
      showBulkStatusMsg: 'Bulk Actions processing.  Please wait.',
    });
  }

  bulkRejectClick() {
    if (this.props.checkAll) {
      BulkActionRejectAll(this.state.recentQuery);
    } else {
      var selectedDocs = { docIds: [] };
      this.props.documents
        .filter((filt) => filt.isChecked === true)
        .map((doc) =>
          selectedDocs.docIds.push({
            documentId: doc.documentId,
            stepId: doc.stepId,
          })
        );
      BulkActionReject(selectedDocs);
    }
    this.setState({ showBulkReject: false });
    this.setState({ showBulkResults: true });
    this.setState({
      showBulkStatusMsg: 'Bulk Actions processing.  Please wait.',
    });
  }

  bulkDeleteClick() {
    if (this.props.checkAll) {
      BulkActionDeleteAll(this.state.recentQuery);
    } else {
      var selectedDocs = { docIds: [] };
      this.props.documents
        .filter((filt) => filt.isChecked === true)
        .map((doc) =>
          selectedDocs.docIds.push({
            documentId: doc.documentId,
            stepId: doc.stepId,
          })
        );
      BulkActionDelete(selectedDocs);
    }
    this.setState({ showBulkDelete: false });
    this.setState({ showBulkResults: true });
    this.setState({
      showBulkStatusMsg: 'Bulk Actions processing.  Please wait.',
    });
  }

  bulkUnassignClick() {
    if (this.props.checkAll) {
      BulkActionUnassignAll(this.state.recentQuery);
    } else {
      var selectedDocs = { docIds: [] };
      this.props.documents
        .filter((filt) => filt.isChecked === true)
        .map((doc) =>
          selectedDocs.docIds.push({
            documentId: doc.documentId,
            stepId: doc.stepId,
          })
        );
      BulkActionUnassign(selectedDocs);
    }
    this.setState({ showBulkUnassign: false });
    this.setState({ showBulkResults: true });
    this.setState({
      showBulkStatusMsg: 'Bulk Actions processing.  Please wait.',
    });
  }
  bulkSendToImageReviewClick() {
    if (this.props.checkAll) {
      BulkActionSendToImageReviewAll(this.state.recentQuery);
    } else {
      var selectedDocs = { docIds: [] };
      this.props.documents
        .filter((filt) => filt.isChecked === true)
        .map((doc) =>
          selectedDocs.docIds.push({
            documentId: doc.documentId,
            stepId: doc.stepId,
          })
        );
      BulkActionSendToImageReview(selectedDocs);
    }
    this.setState({ showBulkSendToImageReview: false });
    this.setState({ showBulkResults: true });
    this.setState({
      showBulkStatusMsg: 'Bulk Actions processing.  Please wait.',
    });
  }
  bulkChangeToReadyStatusClick() {
    if (this.props.checkAll) {
      BulkActionChangeToReadyStatusAll(this.state.recentQuery);
    } else {
      var selectedDocs = { docIds: [] };
      this.props.documents
        .filter((filt) => filt.isChecked === true)
        .map((doc) =>
          selectedDocs.docIds.push({
            documentId: doc.documentId,
            stepId: doc.stepId,
          })
        );

      BulkActionChangeToReadyStatus(selectedDocs);
    }
    this.setState({ showBulkChangeToReadyStatus: false });
    this.setState({ showBulkResults: true });
    this.setState({
      showBulkStatusMsg: 'Bulk Actions processing.  Please wait.',
    });
  }

  moveAssignDocClick() {
    let stepId = this.state.moveAssignStepId;
    //var users = this.state.moveAssignUserSearch;
    let selectedDocs = { docIds: [], stepId, assignToUserIds: [], query: '' };

    let uids = [];
    this.state.moveAssignSelectedUsers.forEach((u) => uids.push(u.userId));
    selectedDocs.assignToUserIds = uids;

    if (this.props.checkAll) {
      selectedDocs.query = this.state.recentQuery;
      BulkActionMoveAndAssignAll(selectedDocs);
    } else {
      this.props.documents
        .filter((filt) => filt.isChecked === true)
        .map((doc) =>
          selectedDocs.docIds.push({
            documentId: doc.documentId,
            stepId: doc.stepId,
          })
        );

      BulkActionMoveAndAssign(selectedDocs);
    }
    this.setState({ showBulkMoveAndAssign: false });
    this.setState({ showBulkResults: true });
    this.setState({
      showBulkStatusMsg:
        'Bulk Actions processing.  Please wait.  If you leave this page the actions will still be processed.',
    });
  }

  bulkMoveAndAssignClick() {
    var selectedDocs = { docIds: [] };
    this.props.documents
      .filter((filt) => filt.isChecked === true)
      .map((doc) =>
        selectedDocs.docIds.push({
          documentId: doc.documentId,
          stepId: doc.stepId,
        })
      );
    BulkActionMoveAndAssign(selectedDocs);
    this.setState({ showBulkMoveAndAssign: false });
    this.setState({ showBulkResults: true });
    this.setState({
      showBulkStatusMsg:
        'Bulk Actions processing.  Please wait. If you leave this page the actions will still be processed.',
    });
  }

  moveBpDocsClick() {
    var selectedDocs = [];
    this.props.documents
      .filter((filt) => filt.isChecked === true)
      .map((doc) => selectedDocs.push(doc.documentId));

    ChangeBusinessProcesses(
      selectedDocs,
      this.state.selectedTemplate2.templateId,
      this.state.applyTemplate2,
      this.state.selectedBpId2
    );
    this.setState({ showBulkChangeBusinessProcess: false });
    this.setState({ showBulkResults: true });
    this.setState({
      showBulkStatusMsg: 'Bulk Actions processing.  Please wait.',
    });
  }
  //#endregion

  //#region bulk show-hides
  toggleBulkEditor(e) {
    let showMenu = this.state.showBulkEditMenu;
    let params = new URLSearchParams(window.location.search);
    var bpParam = params.get('BP');
    if (bpParam != null) {
      GetAvailableTemplatesByBp(bpParam.toString());
      GetAvailableBps(bpParam.toString());
    }
    this.setState({
      showBulkEditMenu: !showMenu,
      bulkEditorEnabled: !showMenu,
    });

    //this.setState({ checkAll: true });
    //this.props.toggleCheckAll2();
    if (this.state.bulkEditorEnabled === true) {
      this.setState({ checkAll: null });
    }
    e.stopPropagation();
  }

  toggleShowMoveAssign() {
    let moveAssign = this.state.showBulkMoveAndAssign;
    this.setState({ showBulkMoveAndAssign: !moveAssign });
    let checkCount = 0;
    let docs = this.props.documents;

    docs.forEach((d) => {
      if (d.isChecked) checkCount++;
    });
    this.checkedDocs = checkCount;
    let params = new URLSearchParams(window.location.search);
    var bpParam = params.get('BP');
    GetWorkflowStepsByBpId(bpParam.toString());
  }

  toggleBulkReject() {
    let audit = this.state.showBulkReject;
    this.setState({ showBulkReject: !audit });
    let checkCount = 0;
    let docs = this.props.documents;

    docs.forEach((d) => {
      if (d.isChecked) checkCount++;
    });
    //this.checkedDocs = checkCount;
    this.setState({ checkedDocs: checkCount });
  }

  toggleBulkApprove() {
    let audit = this.state.showBulkApprove;
    this.setState({ showBulkApprove: !audit });
    let checkCount = 0;
    let docs = this.props.documents;

    docs.forEach((d) => {
      if (d.isChecked) checkCount++;
    });
    //this.checkedDocs = checkCount;
    this.setState({ checkedDocs: checkCount });
  }

  toggleBulkDelete() {
    let audit = this.state.showBulkDelete;
    this.setState({ showBulkDelete: !audit });
    let checkCount = 0;
    let docs = this.props.documents;

    docs.forEach((d) => {
      if (d.isChecked) checkCount++;
    });
    //this.checkedDocs = checkCount;
    this.setState({ checkedDocs: checkCount });
  }

  toggleBulkDownloadPDFs() {
    let audit = this.state.showBulkDownloadPdfs;
    this.setState({ showBulkDownloadPdfs: !audit });
    let checkCount = 0;
    let docs = this.props.documents;

    docs.forEach((d) => {
      if (d.isChecked) checkCount++;
    });
    //this.checkedDocs = checkCount;
    this.setState({ checkedDocs: checkCount });
  }

  toggleBulkMoveAndAssign() {
    let audit = this.state.showBulkMoveAndAssign;
    this.setState({ showBulkMoveAndAssign: !audit });

    let checkCount = 0;
    let docs = this.props.documents;

    docs.forEach((d) => {
      if (d.isChecked) checkCount++;
    });
    //this.checkedDocs = checkCount;
    this.setState({ checkedDocs: checkCount });

    let params = new URLSearchParams(window.location.search);
    //var stepParam = params.get('Step');
    let bpParam = params.get('BP');

    //if(bpParam===null|| bpParam==="0"){
    //return;
    //}
    GetWorkflowStepsByBpId(bpParam);
  }

  getWorkflowStepsByBpId() {
    let params = new URLSearchParams(window.location.search);
    //var stepParam = params.get('Step');
    var bpParam = params.get('BP');

    if (bpParam === null || bpParam === '0') {
      return;
    }
    GetWorkflowStepsByBpId(bpParam);
  }

  toggleBulkUnassign() {
    let audit = this.state.showBulkUnassign;
    this.setState({ showBulkUnassign: !audit });
    let checkCount = 0;
    let docs = this.props.documents;

    docs.forEach((d) => {
      if (d.isChecked) checkCount++;
    });
    //this.checkedDocs = checkCount;
    this.setState({ checkedDocs: checkCount });
  }

  toggleshowBulkResults() {
    let audit = this.state.showBulkResults;
    //if(audit===false){
    //window.location.reload(false);
    //}

    this.setState({ bulkResultsArray: [] });

    this.setState({ showBulkResults: !audit });
    let checkCount = 0;
    let docs = this.props.documents;

    docs.forEach((d) => {
      if (d.isChecked) checkCount++;
    });
    //this.checkedDocs = checkCount;
    this.setState({ checkedDocs: checkCount });
  }

  toggleBulkSendToImageReview() {
    let audit = this.state.showBulkSendToImageReview;
    this.setState({ showBulkSendToImageReview: !audit });
    let checkCount = 0;
    let docs = this.props.documents;

    docs.forEach((d) => {
      if (d.isChecked) checkCount++;
    });
    //this.checkedDocs = checkCount;
    this.setState({ checkedDocs: checkCount });
  }

  toggleChangeToReadyStatus() {
    let audit = this.state.showBulkChangeToReadyStatus;
    this.setState({ showBulkChangeToReadyStatus: !audit });
    let checkCount = 0;
    let docs = this.props.documents;

    docs.forEach((d) => {
      if (d.isChecked) checkCount++;
    });
    //this.checkedDocs = checkCount;
    this.setState({ checkedDocs: checkCount });
  }

  toggleBulkChangeBusinessProcess() {
    let audit = this.state.showBulkChangeBusinessProcess;
    this.setState({ showBulkChangeBusinessProcess: !audit });
    let checkCount = 0;
    let docs = this.props.documents;

    docs.forEach((d) => {
      if (d.isChecked) checkCount++;
    });
    //this.checkedDocs = checkCount;
    this.setState({ checkedDocs: checkCount });
  }

  toggleBulkChangeTemplate() {
    let audit = this.state.showBulkChangeTemplate;
    this.setState({ showBulkChangeTemplate: !audit });
    let checkCount = 0;
    let docs = this.props.documents;

    docs.forEach((d) => {
      if (d.isChecked) checkCount++;
    });
    //this.checkedDocs = checkCount;
    this.setState({ checkedDocs: checkCount });
  }

  chevronColor(status) {
    switch (status) {
      case 0:
        return '#3FAD42';
      case 1:
        return '#DDC562';
      case 2:
        return '#ED2923';
      case 3:
        return '#42B3C1';
      case 4:
        return '#2989d8';
      case 5:
        return '#a80077';
      default:
        return '#3FAD42';
    }
  }

  //#region template helpers
  toggleTemplateChange() {
    let stc = this.state.showTemplateChange;

    if (!stc) {
      this.filterTemplates('');
    }
    this.setState({ showTemplateChange: !stc });
  }

  filterTemplates(searchText) {
    if (searchText === '') {
      this.setState({ filteredTemplates: [...this.state.availableTemplates] });
    } else {
      let newList = [];
      this.state.availableTemplates.forEach((temp) => {
        if (
          temp.templateName.toLowerCase().includes(searchText.toLowerCase())
        ) {
          newList.push(temp);
        }
      });
      this.setState({ filteredTemplates: newList });
      if (newList.length > 0) {
        this.setState({ suggestedTemplateId: newList[0] });
      }
    }
  }

  suggestTemplateItem(tempOption) {
    this.setState({ suggestedTemplateId: tempOption });
  }

  templateSuggestionClick(tempOption) {
    this.setState({
      selectedTemplate: tempOption,
      templateSearchText: tempOption.templateName,
    });
  }

  onChangeTemplateSearchText(e) {
    this.setState({ templateSearchText: e.target.value });
    this.filterTemplates(e.target.value);
    // do the search filtering thingy...
  }

  onClickTemplateSearch() {
    if (this.state.selectedTemplate.templateId > 0) {
      this.setState({
        selectedTemplate: {
          templateId: -1,
          templateName: 'Template...',
        },
        templateSearchText: '',
      });
      this.filterTemplates('');
    }
  }

  onClickTemplateChange() {
    var selectedDocs = [];
    this.props.documents
      .filter((filt) => filt.isChecked === true)
      .map((doc) => selectedDocs.push(doc.documentId));

    UpdateTemplates(
      selectedDocs,
      this.state.selectedTemplate.templateId,
      this.state.applyTemplate
    );
    this.setState({ showTemplateChange: false });
    this.setState({ showBulkResults: true });
    this.setState({
      showBulkStatusMsg: 'Bulk Actions processing.  Please wait.',
    });
  }

  toggleApplyTemplate(e) {
    this.setState({ applyTemplate: e.target.checked });
  }
  //#endregion

  //#region template helpers 2
  toggleTemplateChange2() {
    let stc = this.state.showTemplateChange2;

    if (!stc) {
      this.filterTemplates2('');
    }
    this.setState({ showTemplateChange2: !stc });
  }

  filterTemplates2(searchText) {
    if (searchText === '') {
      this.setState({
        filteredTemplates2: [...this.state.availableTemplates2],
      });
    } else {
      let newList = [];
      this.state.availableTemplates2.forEach((temp) => {
        if (
          temp.templateName.toLowerCase().includes(searchText.toLowerCase())
        ) {
          newList.push(temp);
        }
      });
      this.setState({ filteredTemplates2: newList });
      if (newList.length > 0) {
        this.setState({ suggestedTemplateId2: newList[0] });
      }
    }
  }

  suggestTemplateItem2(tempOption) {
    this.setState({ suggestedTemplateId2: tempOption });
  }

  templateSuggestionClick2(tempOption) {
    this.setState({
      selectedTemplate2: tempOption,
      templateSearchText2: tempOption.templateName,
    });
  }

  onChangeTemplateSearchText2(e) {
    this.setState({ templateSearchText2: e.target.value });
    this.filterTemplates2(e.target.value);
    // do the search filtering thingy...
  }

  onClickTemplateSearch2() {
    if (this.state.selectedTemplate2.templateId > 0) {
      this.setState({
        selectedTemplate2: {
          templateId: -1,
          templateName: 'Template...',
        },
        templateSearchText2: '',
      });
      this.filterTemplates2('');
    }
  }

  toggleApplyTemplate2(e) {
    this.setState({ applyTemplate2: e.target.checked });
  }

  onClickMoveAssignableUser(e, user) {
    let assignedUsers = this.state.moveAssignSelectedUsers;
    let idx = assignedUsers.indexOf(user);
    let availUsers2 = this.state.moveAssignUsers;
    //let idxavailUsers = availUsers2.indexOf(user);
    if (idx === -1) {
      assignedUsers.push(user);
      this.setState({ moveAssignSelectedUsers: assignedUsers });
    }
    //var availUsers3 = availUsers2.filter(word => word.userId !== user.userId);
    //this.setState({moveAssignUsers: availUsers3});
  }

  onClickRemoveMoveAssignUser(e, user) {
    let assignedUsers = this.state.moveAssignSelectedUsers;
    let idx = assignedUsers.indexOf(user);
    assignedUsers.splice(idx, 1);
    this.setState({ moveAssignSelectedUsers: assignedUsers });
  }

  assignUserBlur() {
    var x = document.getElementsByClassName('docAssignUserItem');
    for (var i = 0; i < x.length; i++) {
      x[i].parentNode.removeChild(x[i]);
    }
    this.setState({ userSearch: '' });
  }

  moveAssignUserBlur() {
    var x = document.getElementsByClassName('moveAssignUserItem');
    for (var i = 0; i < x.length; i++) {
      x[i].parentNode.removeChild(x[i]);
    }
    this.setState({ moveAssignUserSearch: '' });
  }

  onClickColumnHeader(colId) {
    if (this.state.currentSortField === colId) {
      if (this.state.currentSortDir === 'down') {
        this.setState({ currentSortDir: 'up', currentSortField: colId }, () => this.updateColumnSettings());
        this.reloadFilteredDocs(colId, 'asc');
      }
      if (this.state.currentSortDir === 'up') {
        this.setState({ currentSortDir: 'down', currentSortField: colId }, () => this.updateColumnSettings());
        this.reloadFilteredDocs(colId, 'desc');
      }
    } else {
      this.setState({ currentSortDir: 'up', currentSortField: colId }, () => this.updateColumnSettings() );
      this.reloadFilteredDocs(colId, 'asc');
    }
  }

  reloadFilteredDocs(colId, currentSortDir) {
    let params = new URLSearchParams(window.location.search);
    var stepParam = params.get('Step');
    var bpParam = params.get('BP');

    localStorage.setItem('loadingMore', false);

    if (this.props.mode === 'IMAGE_REVIEW') {
      var type = InboxStore.getInboxType();
      if (stepParam) {
        GetInboxStep(stepParam, bpParam, type, colId, currentSortDir, '0');
      } else {
        GetInboxBp(bpParam, type, colId, currentSortDir, '0');
      }
    } else if (this.props.mode === 'ACTIVE_DOCUMENTS') {
      var completedDocsInput = document.getElementById('showCompletedDocs');
      if (stepParam) {
        GetADStep(stepParam, bpParam, colId, currentSortDir, 0,completedDocsInput.checked.toString());
      } else {
        // just bp

        GetBP(
          bpParam,
          colId,
          currentSortDir,
          completedDocsInput.checked.toString(),
          0,
        );
      }
    } else if (this.props.mode === 'INBOX') {
      var type = InboxStore.getInboxType();

      if (stepParam) {
        GetInboxStep(stepParam, bpParam, type, colId, currentSortDir, '0');
      } else {
        GetInboxBp(bpParam, type, colId, currentSortDir, '0');
      }
    } else if (this.props.mode === 'SEARCH') {
      console.log('DL: reloadFilter');
      let lastSearch = SearchStore.getLastSearch();

      if (stepParam) {
        GetSearchStep(
          lastSearch.id,
          lastSearch.bpId,
          lastSearch.value.replaceAll('/', '-'),
          lastSearch.searchType,
          lastSearch.data,
          lastSearch.userAudit,
          lastSearch.userName,
          colId.toString(),
          currentSortDir,
          '0',
          {
            includeActiveDocuments: this.props.includeActiveDocuments,
            includeCompleted: this.props.includeCompleted,
            includeDeleted: this.props.includeDeleted
          }
        );
      } else {
        // just bp
        GetSearchBp(
          lastSearch.bpId,
          lastSearch.value.replaceAll('/', '-'),
          lastSearch.searchType,
          lastSearch.data,
          lastSearch.userAudit,
          lastSearch.userName,
          colId.toString(),
          currentSortDir,
          '0',
          {
            includeActiveDocuments: this.props.includeActiveDocuments,
            includeCompleted: this.props.includeCompleted,
            includeDeleted: this.props.includeDeleted
          }
        );
      }
    }
  }

  toggleAllFields() {
    let allF = this.state.allFields;
    this.setState({ allFields: !allF });

    let fs = this.state.fieldSettings;
    let columns = fs.columnSettings;
    columns.forEach((col) => {
      if (col.colName !== 'Id') {
        col.show = !allF;
      }
    });
    this.setState({ fieldSettings: fs }, this.updateColumnSettings);

    //let editCol = columns.find((f) => f.colId === col.colId);
    //if (editCol) {
    //editCol.show = !editCol.show;
    //this.setState({ fieldSettings: fs }, this.updateColumnSettings);
    //}
  }

  removeColumn(colId) {
    let fs = this.state.fieldSettings;
    let columns = fs.columnSettings;
    let col = columns.find((c) => c.colId === colId);
    col.show = false;
    this.setState({ fieldSettings: fs }, this.updateColumnSettings);
  }

  onMagicScroll() {
    if (useMagic) {
      let magic = document.getElementById('magicScroll');
      let main = document.getElementById('mainRowView');
      let idCol = document.getElementById('dlist-id-scroll');
      main.scrollTop =
        (magic.scrollTop * main.scrollHeight) / magic.scrollHeight;
      idCol.scrollTop =
        (magic.scrollTop * main.scrollHeight) / magic.scrollHeight;
      let sp = this.getScrollPercent(magic);

      if (sp > 85 && this.props.documents.length < this.props.docCount) {
        this.onLoadMore();
      }
    } else {
      useMagic = true;
    }
  }

  onMainScroll(e) {
    let main = document.getElementById('mainRowView');
    let magic = document.getElementById('magicScroll');
    let idCol = document.getElementById('dlist-id-scroll');
    let top = main.scrollTop + e.deltaY;
    if (top < 0) top = 0;
    if (top > main.scrollHeight) top = main.scrollHeight;
    main.scrollTop = top;
    magic.scrollTop = top;
    idCol.scrollTop = top;
    useMagic = false;

    let sp = this.getScrollPercent(magic);

    if (sp > 85 && this.props.documents.length < this.props.docCount) {
      this.onLoadMore();
    }
  }

  getScrollPercent(elm) {
    var p = elm.parentNode;
    return (p.scrollTop / (p.scrollHeight - p.clientHeight)) * 100;
  }

  onRefresh() {
    this.props.Reload();
  }

  onRowEnter(row) {
    this.setState({ hoverId: row.documentId });
  }


  toggleSendToImageReview() {
    let show = this.state.showBulkSendToImageReview;
    this.setState({showBulkSendToImageReview: !show});
  }

  downloadPdfs() {
    let docs = this.props.documents;
    let docIds = [];
    docs.forEach((d) => {
      if (d.isChecked) {
        docIds.push(d.documentId);

      }
    });

    BulkActionsDownload(docIds);
  }

  render() {
    return (
      <div
        className='d-flex flex-column flex-fill'
        style={{
          width: `${this.props.scrollWidth}px`,
          height: `${this.props.scrollHeight}px`,
          position: 'relative',
        }}
      >
        <div
          className='d-flex '
          style={{
            background: toolBarBack,
            borderBottom: toolBarBorder,
            width: `${this.props.scrollWidth}px`,
          }}
        >
          {this.props.bpName !== undefined && this.props.bpName !== null && (
            <div className='p-2 me-2'>
              <strong>BP</strong>: {this.props.bpName}
            </div>
          )}
          {this.props.stepName !== undefined &&
            this.props.stepName !== null && (
              <div className='p-2 me-2'>
                <strong>Step</strong>: {this.props.stepName}
              </div>
            )}
          {this.props.docCount !== undefined &&
            this.props.docCount !== null && (
              <div className='p-2 me-auto'>
                <strong>Count</strong>: {this.props.docCount}
              </div>
            )}
          {this.props.checkCount !== undefined &&
            this.props.checkCount !== null &&
            this.state.showBulkEditMenu && (
              <div className='p-2 me-2'>
                <strong>Checked Count</strong>:{' '}
                {this.props.checkAll && <span>{this.props.docCount}</span>}
                {!this.props.checkAll && <span>{this.props.checkCount}</span>}
              </div>
            )}

          {this.props.mode === 'IMAGE_REVIEW' && (
            <div>
              {this.props.checkCount > 0 && (
                <button
                  className='btn btn-success btn-sm mt-1 me-3'
                  onClick={this.beginEdit}
                >
                  Begin Edit
                </button>
              )}
              <Link
                id='imageReviewEditLink'
                to={'/ImageReviewEdit'}
                style={{ display: 'none' }}
              >
                HiddenLink
              </Link>
            </div>
          )}

          <div
            className='p-2 me-3'
            style={{ cursor: 'pointer' }}
            onClick={this.onRefresh}
          >
            <FontAwesomeIcon icon={solid('redo')} /> Refresh
          </div>
          <div
            onClick={this.toggleDownloadConfirmation}
            className='p-2 me-3'
            style={{ cursor: 'pointer' }}
          >
            <FontAwesomeIcon icon={regular('file-excel')} /> Excel
          </div>
          {this.props.mode !== 'IMAGE_REVIEW' && this.state.showBulkActions &&
            (this.state.bpPermissions.canApprove ||
              this.state.bpPermissions.canDelete ||
              this.state.bpPermissions.canEditProperties ||
              this.state.bpPermissions.canImageReview)  && (
              <div className='dropdown float-start'>
                <div
                  onClick={this.toggleBulkEditor}
                  className='p-2 me-3'
                  style={{ cursor: 'pointer' }}
                >
                  <FontAwesomeIcon icon={solid('list-ul')} /> Bulk Actions
                </div>

                {this.state.showBulkEditMenu && this.props.checkCount > 0 &&  (
                  <div className='dropdown-content-bulk-menu'>
                    {this.state.bpPermissions.canApprove && (
                      <div
                        className='dropdown-menuitem'
                        onClick={this.toggleBulkApprove}
                      >
                        <FontAwesomeIcon
                          icon={solid('thumbs-up')}
                          style={{ color: '#38C158', marginRight: '8px' }}
                        />
                        Approve
                      </div>
                    )}
                    {this.state.bpPermissions.canApprove && (
                      <div
                        className='dropdown-menuitem'
                        onClick={this.toggleBulkReject}
                      >
                        <FontAwesomeIcon
                          icon={solid('thumbs-down')}
                          style={{ color: '#DF3E3E', marginRight: '8px' }}
                        />
                        Reject
                      </div>
                    )}
                    {this.state.bpPermissions.canEditProperties && (
                      <div
                        className='dropdown-menuitem'
                        onClick={this.toggleBulkChangeBusinessProcess}
                      >
                        <FontAwesomeIcon
                          icon={solid('code-branch')}
                          style={{ color: '#38C158', marginRight: '8px' }}
                        />
                        Change Business Process
                      </div>
                    )}
                    {this.state.bpPermissions.canEditProperties && (
                      <div
                        className='dropdown-menuitem'
                        onClick={this.toggleTemplateChange}
                      >
                        <FontAwesomeIcon
                          icon={solid('columns')}
                          style={{ color: '#38C158', marginRight: '8px' }}
                        />
                        Change Template
                      </div>
                    )}
                    {this.state.bpPermissions.canEditProperties && (
                      <div
                        className='dropdown-menuitem'
                        onClick={this.toggleChangeToReadyStatus}
                      >
                        <FontAwesomeIcon
                          icon={solid('check-circle')}
                          style={{ color: '#38C158', marginRight: '8px' }}
                        />
                        Change to Ready Status
                      </div>
                    )}
                    {this.state.bpPermissions.canDelete && (
                      <div
                        className='dropdown-menuitem'
                        onClick={this.toggleBulkDelete}
                      >
                        <FontAwesomeIcon
                          icon={solid('remove')}
                          style={{ color: '#EC4B4B', marginRight: '8px' }}
                        />
                        Delete
                      </div>
                    )}
                    {this.state.bpPermissions.canEditProperties && (
                      <div
                        className='dropdown-menuitem'
                        onClick={this.toggleShowMoveAssign}
                      >
                        <FontAwesomeIcon
                          icon={solid('exchange')}
                          style={{ color: '#38C158', marginRight: '8px' }}
                        />
                        Move & Assign
                      </div>
                    )}
                    {this.state.bpPermissions.canImageReview && (
                      <div
                        className='dropdown-menuitem'
                        onClick={this.toggleBulkSendToImageReview}
                      >
                        <FontAwesomeIcon
                          icon={solid('file-pdf')}
                          style={{ color: '#AE4BCF', marginRight: '8px' }}
                        />
                        Send To Image Review
                      </div>
                    )}
                    {this.state.bpPermissions.canEditProperties && (
                      <div
                        className='dropdown-menuitem'
                        onClick={this.toggleBulkUnassign}
                      >
                        <FontAwesomeIcon
                          icon={solid('backspace')}
                          style={{ color: '#38C158', marginRight: '8px' }}
                        />
                        Unassign
                      </div>
                    )}
                     {this.state.bpPermissions.canEditProperties && (
                      <div
                        className='dropdown-menuitem'
                        onClick={this.toggleBulkDownloadPDFs}
                      >
                        <FontAwesomeIcon
                          icon={solid('download')}
                          style={{ color: '#D20707', marginRight: '8px' }}
                        />
                        Download
                      </div>
                    )}
                    <div
                      className='dropdown-menuitem'
                      onClick={this.toggleBulkEditor}
                    >
                      <FontAwesomeIcon
                        icon={solid('external-link-alt')}
                        style={{ color: '#38C158', marginRight: '8px' }}
                      />
                      Exit Bulk Actions
                    </div>
                  </div>
                )}
                {this.state.showBulkEditMenu && (this.props.checkCount === 0 || this.props.checkCount === undefined) &&  (
                  <div className='dropdown-content-bulk-menu'>
                    {this.state.bpPermissions.canApprove && (
                      <div className='dropdown-menuitem2'>
                        <FontAwesomeIcon
                          icon={solid('thumbs-up')}
                          style={{ color: '#CCCCCC', marginRight: '8px' }}
                        />Approve</div>
                    )}
                    {this.state.bpPermissions.canApprove && (
                      <div className='dropdown-menuitem2'>
                        <FontAwesomeIcon
                          icon={solid('thumbs-down')}
                          style={{ color: '#CCCCCC', marginRight: '8px' }}
                        />
                        Reject
                      </div>
                    )}
                    {this.state.bpPermissions.canEditProperties && (
                      <div
                        className='dropdown-menuitem2'
                      >
                        <FontAwesomeIcon
                          icon={solid('code-branch')}
                          style={{ color: '#CCCCCC', marginRight: '8px' }}
                        />
                        Change Business Process
                      </div>
                    )}
                    {this.state.bpPermissions.canEditProperties && (
                      <div
                        className='dropdown-menuitem2'

                      >
                        <FontAwesomeIcon
                          icon={solid('columns')}
                          style={{ color: '#CCCCCC', marginRight: '8px' }}
                        />
                        Change Template
                      </div>
                    )}
                    {this.state.bpPermissions.canEditProperties && (
                      <div
                        className='dropdown-menuitem2'
                        onClick={this.toggleChangeToReadyStatus}
                      >
                        <FontAwesomeIcon
                          icon={solid('check-circle')}
                          style={{ color: '#CCCCCC', marginRight: '8px' }}
                        />
                        Change to Ready Status
                      </div>
                    )}
                    {this.state.bpPermissions.canDelete && (
                      <div
                        className='dropdown-menuitem2'
                        onClick={this.toggleBulkDelete}
                      >
                        <FontAwesomeIcon
                          icon={solid('remove')}
                          style={{ color: '#CCCCCC', marginRight: '8px' }}
                        />
                        Delete
                      </div>
                    )}
                    {this.state.bpPermissions.canEditProperties && (
                      <div
                        className='dropdown-menuitem2'
                      >
                        <FontAwesomeIcon
                          icon={solid('exchange')}
                          style={{ color: '#CCCCCC', marginRight: '8px' }}
                        />
                        Move & Assign
                      </div>
                    )}
                    {this.state.bpPermissions.canImageReview && (
                      <div
                        className='dropdown-menuitem2'
                      >
                        <FontAwesomeIcon
                          icon={solid('file-pdf')}
                          style={{ color: '#CCCCCC', marginRight: '8px' }}
                        />
                        Send To Image Review
                      </div>
                    )}
                    {this.state.bpPermissions.canEditProperties && (
                      <div
                        className='dropdown-menuitem2'

                      >
                        <FontAwesomeIcon
                          icon={solid('backspace')}
                          style={{ color: '#CCCCCC', marginRight: '8px' }}
                        />
                        Unassign
                      </div>
                    )}
                    {this.state.bpPermissions.canEditProperties && (
                      <div
                        className='dropdown-menuitem2'

                      >
                        <FontAwesomeIcon
                          icon={solid('download')}
                          style={{ color: '#CCCCCC', marginRight: '8px' }}
                        />
                        Download
                      </div>
                    )}
                    <div
                      className='dropdown-menuitem'
                      onClick={this.toggleBulkEditor}
                    >
                      <FontAwesomeIcon
                        icon={solid('external-link-alt')}
                        style={{ color: '#38C158', marginRight: '8px' }}
                      />
                      Exit Bulk Actions
                    </div>
                  </div>
                )}
              </div>
            )}
          <div
            className='p-2 me-3'
            onClick={this.toggleFields}
            style={{ cursor: 'pointer' }}
          >
            <FontAwesomeIcon icon={solid('th-list')} /> Fields
          </div>
          <Link
            id='docLink'
            to={'/Document/' + this.state.goToDocId}
            style={{ display: 'None' }}
          />
        </div>

        <div className='flex-fill d-flex flex-row'>
          <div
            style={{
              width: `${
                this.state.fieldSettings.columnSettings[0].colWidth + 1
              }px`,
            }}
          >
            {this.props.documents !== undefined &&
              this.props.documents.length > 0 &&
              this.props.FormTypeId == this.state.fieldSettings.formTypeId && (
                <table>
                  <tbody>
                    <tr
                      onMouseMove={this.DragColumnSize}
                      onMouseUp={this.stopDraggingColSize}
                      onMouseLeave={this.stopDraggingColSize}
                    >
                      {(this.state.bulkEditorEnabled ||
                        this.props.mode === 'IMAGE_REVIEW') && (
                        <td className='adc-col-header2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            checked={this.props.checkAll}
                            onChange={this.props.toggleCheckAll2}
                          />
                        </td>
                      )}
                      {true && (
                        <React.Fragment>
                          <td
                            className='adc-col-header2 adc-large'
                            style={{
                              maxWidth: `${
                                this.state.fieldSettings.columnSettings[0]
                                  .colWidth -
                                8 -
                                (this.state.bulkEditorEnabled ? 32 : 0)
                              }px`,
                              minWidth: `${
                                this.state.fieldSettings.columnSettings[0]
                                  .colWidth -
                                8 -
                                (this.state.bulkEditorEnabled ? 32 : 0)
                              }px`,
                            }}
                          >
                            {this.state.fieldSettings.columnSettings[0].colName}
                            {this.state.currentSortField === 0 &&
                              this.state.currentSortDir === 'down' && (
                                <FontAwesomeIcon
                                  className='ms-2'
                                  style={{
                                    color: '#165DC3',
                                    cursor: 'pointer',
                                  }}
                                  icon={solid('sort-down')}
                                  onClick={() => this.onClickColumnHeader(0)}
                                />
                              )}
                            {this.state.currentSortField === 0 &&
                              this.state.currentSortDir === 'up' && (
                                <FontAwesomeIcon
                                  className='ms-2'
                                  style={{
                                    color: '#165DC3',
                                    cursor: 'pointer',
                                  }}
                                  icon={solid('sort-up')}
                                  onClick={() => this.onClickColumnHeader(0)}
                                />
                              )}
                            {this.state.currentSortField !== 0 &&
                            (
                              <FontAwesomeIcon
                                className='ms-2'
                                style={{
                                  color: '#777',
                                  cursor: 'pointer',
                                }}
                                icon={solid('sort')}
                                onClick={() => this.onClickColumnHeader(0)}
                              />
                            )}
                          </td>
                          <td
                            className='document-grider-header-right'
                            onMouseDown={(e) => this.startDraggingColumn(e, 0)}
                            style={{ maxWidth: '8px', minWidth: '8px' }}
                          >
                            {' '}
                          </td>
                        </React.Fragment>
                      )}
                    </tr>
                  </tbody>
                </table>
              )}
            <div
              id='dlist-id-scroll'
              style={{
                height: `${this.props.scrollHeight - 105}px`,
                width: `${
                  this.state.fieldSettings.columnSettings[0].colWidth + 1
                }px`,
                overflowX: 'hidden',
                overflowY: 'hidden',
              }}
              onWheel={this.onMainScroll}
            >
              {this.props.documents !== undefined &&
                this.props.documents.length > 0 &&
                this.props.FormTypeId ==
                  this.state.fieldSettings.formTypeId && (
                  <>
                    <table className='adc-table'>
                      <tbody>
                        {this.props.documents.map((doc) => (
                          <tr
                            key={doc.documentId}
                            className={`adc-row ${
                              this.state.hoverId === doc.documentId
                                ? 'hasHover'
                                : ''
                            } `}
                            onMouseEnter={() => this.onRowEnter(doc)}
                          >
                            {(this.state.bulkEditorEnabled ||
                              this.props.mode === 'IMAGE_REVIEW') && (
                              <td
                                style={{
                                  width: '30px',
                                  minWidth: '30px',
                                  paddingLeft: '6px',
                                  backgroundColor:
                                    this.props.mode === 'IMAGE_REVIEW'
                                      ? '#B98CC7'
                                      : '#EAEAEA',
                                }}
                              >
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  checked={doc.isChecked || this.props.checkAll}
                                  onChange={(e) =>
                                    this.props.toggleDocument(doc.documentId, e)
                                  }
                                />
                              </td>
                            )}
                            {(doc.status === 0 ||
                              doc.status === 2 ||
                              doc.status === 3 ||
                              doc.status === 4 ||
                              doc.status === 5) && (
                              <td
                                className={`col-one ${
                                  this.state.userSettings.fontSizeMode === 0
                                    ? 'td-small'
                                    : 'td-large'
                                }`}
                                width={`${this.state.fieldSettings.columnSettings[0].colWidth}px`}
                                style={{
                                  maxWidth: `${this.state.fieldSettings.columnSettings[0].colWidth}px`,
                                  minWidth: `${this.state.fieldSettings.columnSettings[0].colWidth}px`,
                                }}
                                onClick={(e) => this.goToDoc(e, doc.documentId)}
                              >
                                <div className='row'>
                                  {doc.status === 0 && (
                                    <div className='col-auto col-ready'>
                                      <FontAwesomeIcon icon={solid('check')} />
                                    </div>
                                  )}
                                  {doc.status === 4 && (
                                    <div className='col-auto col-delete'>
                                      <FontAwesomeIcon icon={solid('trash')} />
                                    </div>
                                  )}
                                  {doc.status === 3 && (
                                    <div className='col-auto col-complete'>
                                      <FontAwesomeIcon
                                        icon={solid('square-check')}
                                      />
                                    </div>
                                  )}
                                  {doc.status === 2 && (
                                    <div className='col-auto col-error'>
                                      <FontAwesomeIcon
                                        icon={solid('exclamation-triangle')}
                                      />
                                    </div>
                                  )}
                                  {doc.status === 5 && (
                                    <div className='col-auto col-review'>
                                      <FontAwesomeIcon icon={solid('image')} />
                                    </div>
                                  )}
                                  <div className='col-auto trans'>
                                    {this.state.userSettings.fontSizeMode ===
                                      0 && (
                                      <svg width='8' height='20'>
                                        <polygon
                                          points='0,0 8,10, 0,20'
                                          fill={this.chevronColor(doc.status)}
                                        />
                                      </svg>
                                    )}
                                    {this.state.userSettings.fontSizeMode ===
                                      1 && (
                                      <svg width='8' height='30'>
                                        <polygon
                                          points='0,0 8,15, 0,30'
                                          fill={this.chevronColor(doc.status)}
                                        />
                                      </svg>
                                    )}
                                  </div>

                                  <div className='col-auto col-id'>
                                    {doc.documentId}
                                  </div>
                                </div>
                              </td>
                            )}
                            {doc.status === 1 && (
                              <td
                                className={`col-one ${
                                  this.state.userSettings.fontSizeMode === 0
                                    ? 'td-small'
                                    : 'td-large'
                                }`}
                                style={{
                                  maxWidth: `${this.state.fieldSettings.columnSettings[0].colWidth}px`,
                                  minWidth: `${this.state.fieldSettings.columnSettings[0].colWidth}px`,
                                }}
                                onClick={(e) => this.goToDoc(e, doc.documentId)}
                              >
                                <div className='row'>
                                  <div className='col-auto col-lock'>
                                    <FontAwesomeIcon
                                      icon={solid('user-lock')}
                                    />{' '}
                                    {doc.checkOutUserName}
                                  </div>
                                  <div className='col-auto trans'>
                                    {this.state.userSettings.fontSizeMode ===
                                      0 && (
                                      <svg width='8' height='20'>
                                        <polygon
                                          points='0,0 8,10, 0,20'
                                          fill='#DDC562'
                                        />
                                      </svg>
                                    )}
                                    {this.state.userSettings.fontSizeMode ===
                                      1 && (
                                      <svg width='8' height='30'>
                                        <polygon
                                          points='0,0 8,15, 0,30'
                                          fill='#DDC562'
                                        />
                                      </svg>
                                    )}
                                  </div>
                                  <div className='col-auto col-id'>
                                    {doc.documentId}
                                    <Link
                                      id={`docLink${doc.documentId}`}
                                      to={`/Document/${doc.documentId}`}
                                      style={{ display: 'none' }}
                                    />
                                  </div>
                                </div>
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                )}
            </div>
          </div>
          <div
            className='coolScroll2'
            style={{
              width: `${
                this.props.scrollWidth -
                this.state.fieldSettings.columnSettings[0].colWidth
              }px`,
              overflowX: 'auto',
              overflowY: 'hidden',
            }}
          >
            {this.props.documents !== undefined &&
              this.props.documents.length > 0 &&
              this.props.FormTypeId == this.state.fieldSettings.formTypeId && (
                <>
                  <table>
                    <tbody>
                      <tr
                        onMouseMove={this.DragColumnSize}
                        onMouseUp={this.stopDraggingColSize}
                        onMouseLeave={this.stopDraggingColSize}
                      >
                        {/*(this.state.bulkEditorEnabled ||
                          this.props.mode === 'IMAGE_REVIEW') && (
                          <td className='adc-col-header2'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              checked={this.props.checkAll}
                              onChange={this.props.toggleCheckAll2}
                            />
                          </td>
                          )*/}
                        {this.state.fieldSettings.columnSettings.map(
                          (col) =>
                            col.colId !== 0 &&
                            col.show && (
                              <React.Fragment key={col.colId}>
                                <td
                                  className={`adc-col-header2 adc-large ${
                                    this.state.userSettings.fontSizeMode === 0
                                      ? 'td-small'
                                      : 'td-large'
                                  }`}
                                  style={{
                                    maxWidth: `${col.colWidth - 8}px`,
                                    minWidth: `${col.colWidth - 8}px`,
                                  }}
                                >
                                  {col.displayName}
                                  {this.state.currentSortField === col.colId &&
                                    this.state.currentSortDir === 'down' && (
                                      <FontAwesomeIcon
                                        className='ms-2'
                                        style={{
                                          color: '#165DC3',
                                          cursor: 'pointer',
                                        }}
                                        icon={solid('sort-down')}
                                        onClick={() =>
                                          this.onClickColumnHeader(col.colId)
                                        }
                                      />
                                    )}
                                  {this.state.currentSortField === col.colId &&
                                    this.state.currentSortDir === 'up' && (
                                      <FontAwesomeIcon
                                        className='ms-2'
                                        style={{
                                          color: '#165DC3',
                                          cursor: 'pointer',
                                        }}
                                        icon={solid('sort-up')}
                                        onClick={() =>
                                          this.onClickColumnHeader(col.colId)
                                        }
                                      />
                                    )}
                                  {this.state.currentSortField !==
                                    col.colId && (
                                    <FontAwesomeIcon
                                      className='ms-2'
                                      style={{
                                        color: '#777',
                                        cursor: 'pointer',
                                      }}
                                      icon={solid('sort')}
                                      onClick={() =>
                                        this.onClickColumnHeader(col.colId)
                                      }
                                    />
                                  )}
                                  {col.colId !== 0 && (
                                    <FontAwesomeIcon
                                      className='ms-2'
                                      style={{
                                        color: '#C31616',
                                        cursor: 'pointer',
                                      }}
                                      icon={solid('times')}
                                      onClick={() =>
                                        this.removeColumn(col.colId)
                                      }
                                    />
                                  )}
                                </td>
                                <td
                                  className='document-grider-header-right'
                                  onMouseDown={(e) =>
                                    this.startDraggingColumn(e, col.colId)
                                  }
                                  style={{ maxWidth: '8px', minWidth: '8px' }}
                                >
                                  {' '}
                                </td>
                              </React.Fragment>
                            )
                        )}
                      </tr>
                    </tbody>
                  </table>

                  <div
                    className='coolScroll2'
                    id='mainRowView'
                    style={{
                      height: `${this.props.scrollHeight - 105}px`,
                      width: `${this.state.contentWidth + 1}px`,

                      //maxHeight: `${this.props.scrollHeight - 500}px`,
                      overflowY: 'hidden',
                      overflowX: 'hidden',
                    }}
                    //onScroll={this.onMainScroll}
                    onWheel={this.onMainScroll}
                    //onScroll={this.onMainScroll}
                  >
                    <div
                      id='magicScroll'
                      className='coolScroll'
                      style={{
                        backgroundColor: 'rgba(255,0,255, 0.0)',
                        color: '#FFF',

                        position: 'absolute',
                        right: '1px',
                        top: '83px',
                        height: `${this.props.scrollHeight - 110}px`,
                        width: '20px',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                      }}
                      onScroll={this.onMagicScroll}
                    >
                      <div style={{ height: `${this.state.contentHeight}px` }}>
                        <span style={{paddingLeft: '50px'}}>.</span>
                        {' '}
                      </div>
                    </div>
                    <table className='adc-table'>
                      <tbody>
                        {this.props.documents.map((doc) => (
                          <tr
                            key={doc.documentId}
                            className={`adc-row ${
                              this.state.hoverId === doc.documentId
                                ? 'hasHover'
                                : ''
                            } `}
                            onMouseEnter={() => this.onRowEnter(doc)}
                          >


                            {this.state.fieldSettings.columnSettings.length >=
                              4 && (
                              <>
                                {this.state.fieldSettings.columnSettings[1]
                                  .show && (
                                  <td
                                    className={`adc-col-value ${
                                      this.state.userSettings.fontSizeMode === 0
                                        ? 'td-small'
                                        : 'td-large'
                                    }`}
                                    style={{
                                      maxWidth: `${this.state.fieldSettings.columnSettings[1].colWidth}px`,
                                      minWidth: `${this.state.columns[1].colWidth}px`,
                                    }}
                                  >
                                    {doc.stepName}
                                  </td>
                                )}
                                {this.state.fieldSettings.columnSettings[2]
                                  .show && (
                                  <td
                                    className={`adc-col-value ${
                                      this.state.userSettings.fontSizeMode === 0
                                        ? 'td-small'
                                        : 'td-large'
                                    }`}
                                    style={{
                                      maxWidth: `${this.state.fieldSettings.columnSettings[2].colWidth}px`,
                                      minWidth: `${this.state.columns[2].colWidth}px`,
                                    }}
                                  >
                                    {doc.pageCount}
                                  </td>
                                )}
                                {this.state.fieldSettings.columnSettings[3]
                                  .show && (
                                  <td
                                    className={`adc-col-value ${
                                      this.state.userSettings.fontSizeMode === 0
                                        ? 'td-small'
                                        : 'td-large'
                                    }`}
                                    style={{
                                      maxWidth: `${this.state.fieldSettings.columnSettings[3].colWidth}px`,
                                      minWidth: `${this.state.columns[3].colWidth}px`,
                                    }}
                                  >
                                    {doc.queue}
                                  </td>
                                )}
                                {this.state.fieldSettings.columnSettings[4]
                                  .show && (
                                  <td
                                    className={`adc-col-value ${
                                      this.state.userSettings.fontSizeMode === 0
                                        ? 'td-small'
                                        : 'td-large'
                                    }`}
                                    style={{
                                      maxWidth: `${this.state.fieldSettings.columnSettings[4].colWidth}px`,
                                      minWidth: `${this.state.columns[4].colWidth}px`,
                                    }}
                                  >
                                    {doc.notes}
                                  </td>
                                )}
                                {this.state.fieldSettings.columnSettings[5]
                                  .show && (
                                  <td
                                    className={`adc-col-value ${
                                      this.state.userSettings.fontSizeMode === 0
                                        ? 'td-small'
                                        : 'td-large'
                                    }`}
                                    style={{
                                      maxWidth: `${this.state.fieldSettings.columnSettings[5].colWidth}px`,
                                      minWidth: `${this.state.columns[5].colWidth}px`,
                                    }}
                                  >
                                    {doc.relatedCount}
                                  </td>
                                )}

                                {this.state.fieldSettings.columnSettings.map(
                                  (head) =>
                                    head.show &&
                                    head.colId > 5 && (
                                      <td
                                        className={`adc-col-value ${
                                          this.state.userSettings.fontSizeMode === 0
                                            ? 'td-small'
                                            : 'td-large'
                                        }`}
                                        key={head.colId}
                                        style={{
                                          maxWidth: `${head.colWidth}px`,
                                          minWidth: `${head.colWidth}px`,
                                        }}
                                      >
                                        {
                                          doc.headers.find(
                                            (c) => c.colId === head.colId
                                          )?.value
                                        }
                                      </td>
                                    )
                                )}
                              </>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            {this.props.documents.length === 0 && (
              <div
                className='flex-fill'
                style={{ height: `${this.state.scrollHeight}px` }}
              >
                <div className='h-100 d-flex flex-column align-items-center justify-content-center'>
                  {this.props.mode === 'ACTIVE_DOCUMENTS' && (
                    <>
                      <FontAwesomeIcon icon={solid('cubes')} size='4x' />
                      <h3 style={{ color: '#999' }}>Active Documents</h3>
                      <span>No Documents</span>
                    </>
                  )}
                  {this.props.mode === 'INBOX' && (
                    <>
                      <FontAwesomeIcon icon={solid('inbox')} size='4x' />
                      <h3 style={{ color: '#999' }}>Inbox</h3>
                    </>
                  )}
                  {this.props.mode === 'SEARCH' && (
                    <>
                      <FontAwesomeIcon icon={solid('search')} size='4x' />
                      <h3 style={{ color: '#999' }}>Search</h3>
                    </>
                  )}
                  {this.props.mode === 'IMAGE_REVIEW' && (
                    <>
                      <FontAwesomeIcon icon={solid('file-image')} size='4x' />
                      <h3 style={{ color: '#999' }}>Image Review</h3>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        {this.state.downloadConfirmation && (
          <div className='td-dialogue-container'>
            <div
              className='td-dialogue card'
              style={{ height: '220px', width: '400px' }}
            >
              <div className='card-header'>
                Excel
                <button
                  onClick={this.toggleDownloadConfirmation}
                  className='btn-close'
                />
              </div>
              <div className='card-body'>
                <div className='form-check  mt-2'>
                  <input
                    className='form-check-input'
                    checked={this.state.formatDocumentIdAsTDLink}
                    onClick={this.toggleFormatDocIdasTDLink}
                    type='checkbox'
                  />
                  <label className='form-check-label'>
                    Format Document Id as TrinDocs Link
                  </label>
                </div>
                <button
                  onClick={() =>
                    this.onExcelDownloadConfirmation(
                      this.state.formatDocumentIdAsTDLink
                    )
                  }
                  className='btn btn-success mt-3 w-100'
                >
                  Export to Excel
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Field dialog */}
        {this.state.showFields && (
          <div className='td-dialogue-container'>
            <div className='td-dialogue card' style={{ width: '400px' }}>
              <div className='card-header'>
                Fields
                <button
                  type='button'
                  className='btn-close'
                  onClick={this.toggleFields}
                />
              </div>
              <div
                className='card-body ps-4'
                style={{ maxHeight: '400px', overflow: 'auto' }}
              >
                <strong>Fields:</strong>
                <div className='form-check mb-2'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    checked={this.state.allFields}
                    onChange={this.toggleAllFields}
                  />
                  <label className='form-check-label'>Select All:</label>
                </div>
                <div>
                  {this.state.fieldSettings.columnSettings.map((col) => (
                    <div key={col.colId}>
                      {col.colId !== 0 && (
                    <div  className='form-check'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        checked={col.show}
                        onChange={() => this.toggleFieldDisplay(col)}
                      />
                      <label className='form-check-label'>{col.displayName}</label>

                    </div>
                    )}
                    </div>
                  ))}

                </div>
              </div>
            </div>
          </div>
        )}
        {this.state.showBulkApprove && (
          <div className='td-dialogue-container'>
            <div
              className='td-dialogue card'
              style={{ height: '600px', width: '600px' }}
            >
              <div className='card-header'>
                Bulk Approve
                <button
                  onClick={this.toggleBulkApprove}
                  className='btn-close'
                />
              </div>
              <div className='card-body'>
                <div className='form-check  mt-2'>
                  <label className='form-check-label'>
                    {this.props.checkAll && (
                      <>{this.props.docCount} document(s) will be approved</>
                    )}
                    {!this.props.checkAll && (
                      <>{this.checkedDocs} document(s) will be approved</>
                    )}
                  </label>
                </div>
                <button
                  onClick={this.bulkApproveClick}
                  className='btn btn-success mt-3 w-100'
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>
        )}

        {this.state.showBulkReject && (
          <div className='td-dialogue-container'>
            <div
              className='td-dialogue card'
              style={{ height: '600px', width: '600px' }}
            >
              <div className='card-header'>
                Bulk Reject
                <button onClick={this.toggleBulkReject} className='btn-close' />
              </div>
              <div className='card-body'>
                <div className='form-check  mt-2'>
                  <label className='form-check-label'>
                    {this.props.checkAll && (
                      <>{this.props.docCount} document(s) will be rejected</>
                    )}
                    {!this.props.checkAll && (
                      <>{this.props.checkCount} document(s) will be rejected</>
                    )}
                  </label>
                </div>
                <button
                  onClick={this.bulkRejectClick}
                  className='btn btn-success mt-3 w-100'
                >
                  Reject
                </button>
              </div>
            </div>
          </div>
        )}

        {this.state.showBulkDelete && (
          <div className='td-dialogue-container'>
            <div
              className='td-dialogue card'
              style={{ height: '600px', width: '600px' }}
            >
              <div className='card-header'>
                Bulk Delete
                <button onClick={this.toggleBulkDelete} className='btn-close' />
              </div>
              <div className='card-body'>
                <div className='form-check  mt-2'>
                  <label className='form-check-label'>
                    {this.props.checkAll && (
                      <>{this.props.docCount} document(s) will be deleted</>
                    )}
                    {!this.props.checkAll && (
                      <>{this.props.checkCount} document(s) will be deleted</>
                    )}
                  </label>
                </div>
                <button
                  onClick={this.bulkDeleteClick}
                  className='btn btn-success mt-3 w-100'
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>
        )}

        {this.state.showBulkUnassign && (
          <div className='td-dialogue-container'>
            <div
              className='td-dialogue card'
              style={{ height: '600px', width: '600px' }}
            >
              <div className='card-header'>
                Bulk UnAssign
                <button
                  onClick={this.toggleBulkUnassign}
                  className='btn-close'
                />
              </div>
              <div className='card-body'>
                <div className='form-check  mt-2'>
                  <label className='form-check-label'>
                    {this.props.checkAll && (
                      <>{this.props.docCount} document(s) will be unassigned</>
                    )}
                    {!this.props.checkAll && (
                      <>
                        {this.props.checkCount} document(s) will be unassigned
                      </>
                    )}
                  </label>
                </div>
                <button
                  onClick={this.bulkUnassignClick}
                  className='btn btn-success mt-3 w-100'
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>
        )}

        {this.state.showBulkSendToImageReview && (
          <div className='td-dialogue-container'>
            <div
              className='td-dialogue card'
              style={{ height: '600px', width: '600px' }}
            >
              <div className='card-header'>
                Bulk Image Review
                <button
                  onClick={this.toggleSendToImageReview}
                  className='btn-close'
                />
              </div>
              <div className='card-body'>
                <div className='form-check  mt-2'>
                  <label className='form-check-label'>
                    {this.props.checkAll && (
                      <>
                        {this.props.docCount} document(s) will be sent to Image
                        Review
                      </>
                    )}
                    {!this.props.checkAll && (
                      <>
                        {this.props.checkCount} document(s) will be sent to
                        Image Review
                      </>
                    )}
                  </label>
                </div>
                <button
                  onClick={this.bulkSendToImageReviewClick}
                  className='btn btn-success mt-3 w-100'
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>
        )}

        {this.state.showBulkChangeToReadyStatus && (
          <div className='td-dialogue-container'>
            <div
              className='td-dialogue card'
              style={{ height: '600px', width: '600px' }}
            >
              <div className='card-header'>
                Bulk Change To Ready
                <button
                  onClick={this.toggleChangeToReadyStatus}
                  className='btn-close'
                />
              </div>
              <div className='card-body'>
                <div className='form-check  mt-2'>
                  <label className='form-check-label'>
                    {this.props.checkAll && (
                      <>
                        {this.props.docCount} document(s) will be set to Ready
                      </>
                    )}
                    {!this.props.checkAll && (
                      <>
                        {this.props.checkCount} document(s) will be set to Ready
                      </>
                    )}
                  </label>
                </div>
                <button
                  onClick={this.bulkChangeToReadyStatusClick}
                  className='btn btn-success mt-3 w-100'
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>
        )}

        {this.state.showBulkDownloadPdfs && (
          <div className='td-dialogue-container'>
            <div
              className='td-dialogue card'
              style={{ height: '600px', width: '600px' }}
            >
              <div className='card-header'>
                Bulk Downlaod PDFs
                <button
                  onClick={this.toggleBulkDownloadPDFs}
                  className='btn-close'
                />
              </div>
              <div className='card-body'>
                <div className='form-check  mt-2'>
                  <label className='form-check-label'>
                    {this.props.checkAll && (
                      <>
                        {this.props.docCount} pdf document(s) will be downloaded
                      </>
                    )}
                    {!this.props.checkAll && (
                      <>
                        {this.props.checkCount} pdf document(s) will be downloaded
                      </>
                    )}
                  </label>
                </div>
                <button
                  onClick={this.downloadPdfs}
                  className='btn btn-success mt-3 w-100'
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>
        )}


        {this.state.showBulkMoveAndAssign && (
          <div className='td-dialogue-container'>
            <div className='td-dialogue card' style={{ width: '720px' }}>
              <div className='card-header'>
                Move and Assign
                <button
                  type='button'
                  className='btn-close'
                  onClick={this.toggleShowMoveAssign}
                />
              </div>
              <div
                className='card-body'
                style={{ height: '500px', padding: '40px', overflow: 'auto' }}
              >
                <label className='form-label'>Workflow step</label>
                <select
                  className='form-select'
                  value={this.state.moveAssignStepId}
                  onChange={this.editMoveAssignStep}
                >
                  <option value='-1'></option>
                  {this.state.bulkWorkflowStepArray.map((dest) => (
                    <option key={dest.stepId} value={dest.stepId}>
                      {dest.stepName}
                    </option>
                  ))}
                </select>
                <label className='form-label mt-4'>Assign User(s)</label>
                <div className='d-flex flex-row'>
                  {this.state.moveAssignSelectedUsers.map((user) => (
                    <div key={user.userId} className='queueAssignOther mt-2'>
                      {user.userName}{' '}
                      <FontAwesomeIcon
                        style={{ cursor: 'pointer' }}
                        onClick={(e) =>
                          this.onClickRemoveMoveAssignUser(e, user)
                        }
                        icon={solid('times')}
                      />
                    </div>
                  ))}
                </div>
                <div id='moveContainerAssignUserText' className='autocomplete2'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Assign User...'
                    onFocus={this.editMoveAssignUser}
                    value={this.state.moveAssignUserSearch}
                    onBlur={this.moveAssignUserBlur}
                    onChange={this.editMoveAssignUser}
                  ></input>
                </div>
                <div className='form-check  mt-2'>
                  <label className='form-check-label'>
                    {this.props.checkAll && (
                      <>{this.props.docCount} document(s) will be moved.</>
                    )}
                    {!this.props.checkAll && (
                      <>{this.props.checkCount} document(s) will be moved.</>
                    )}
                  </label>
                </div>
                {this.state.moveAssignStepId !== -1 && (
                  <button
                    className='btn btn-success mt-3'
                    onClick={this.moveAssignDocClick}
                  >
                    <FontAwesomeIcon icon={solid('check')} /> OK
                  </button>
                )}
              </div>
            </div>
          </div>
        )}

        {this.state.showBulkResults && (
          <div className='td-dialogue-container'>
            <div
              className='td-dialogue card'
              style={{ height: '600px', width: '600px' }}
            >
              <div className='card-header'>
                Bulk Results
                <button
                  onClick={this.toggleshowBulkResults}
                  className='btn-close'
                />
              </div>
              <div className='card-body'>
                <div className='form-check  mt-2'>
                  <label className='form-check-label'>
                    {this.state.showBulkStatusMsg}
                  </label>
                </div>
              </div>
              <div
                className='card-body'
                style={{ overflowY: 'scroll', height: '500px' }}
              >
                {this.state.bulkResultsArray.map((doc) => (
                  <div
                    style={{
                      color: doc.status === 'Error' ? '#EC4B4B' : '#000',
                    }}
                  >
                    {doc.message}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {this.state.showTemplateChange && (
          <div className='td-dialogue-container'>
            <div className='td-dialogue card' style={{ width: '700px' }}>
              <div className='card-header'>
                Change Template:
                <button
                  type='button'
                  className='btn-close'
                  onClick={this.toggleTemplateChange}
                />
              </div>
              <div
                className='card-body'
                style={{ height: '350px', overflow: 'auto' }}
              >
                <label className='form-label'>Choose Template</label>
                <div className='document-dropdown-container'>
                  <div className='input-group rounded'>
                    <input
                      id='changeTemplate-search-input'
                      type='search'
                      value={this.state.templateSearchText}
                      className='form-control'
                      placeholder={this.state.suggestedTemplateId.templateName}
                      aria-label='Template'
                      onChange={this.onChangeTemplateSearchText}
                      readOnly={this.state.selectedTemplate.templateId > 0}
                      onClick={this.onClickTemplateSearch}
                    ></input>
                    <span
                      className='input-group-text border-0'
                      id='search-addon'
                    >
                      <FontAwesomeIcon icon={solid('search')} />
                    </span>
                  </div>
                  {this.state.selectedTemplate.templateId < 0 && (
                    <ul className='document-dropdown-list l2'>
                      {this.state.filteredTemplates.map((tempOption) => (
                        <li
                          className={
                            this.state.suggestedTemplateId.templateId ===
                            tempOption.templateId
                              ? 'active'
                              : ''
                          }
                          onMouseEnter={() =>
                            this.suggestTemplateItem(tempOption)
                          }
                          onClick={() =>
                            this.templateSuggestionClick(tempOption)
                          }
                          key={tempOption.templateId}
                        >
                          {tempOption.templateName}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>

                <div className='form-check form-switch mt-3'>
                  <input
                    className='form-check-input'
                    onChange={this.toggleApplyTemplate}
                    value={this.state.applyTemplate}
                    type='checkbox'
                    id='changeTemplate-applyNow'
                  />
                  <label className='form-check-label'>Apply Now?</label>
                </div>
                {this.state.selectedTemplate.templateId >= 0 && (
                  <div>
                    <button
                      className='btn btn-success mt-2'
                      onClick={this.onClickTemplateChange}
                    >
                      <FontAwesomeIcon icon={solid('check')} /> OK
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {this.state.showBulkChangeBusinessProcess && (
          <div className='td-dialogue-container'>
            <div className='td-dialogue card' style={{ width: '720px' }}>
              <div className='card-header'>
                Change Business Process
                <button
                  type='button'
                  className='btn-close'
                  onClick={this.toggleBulkChangeBusinessProcess}
                />
              </div>
              <div
                className='card-body'
                style={{ height: '500px', padding: '40px', overflow: 'auto' }}
              >
                <label className='form-label'>Business Process</label>
                <select
                  className='form-select'
                  value={this.state.selectedBpId2}
                  onChange={this.editBps}
                >
                  <option value='-1'></option>
                  {this.state.availableBps.map((dest) => (
                    <option key={dest.bpId} value={dest.bpId}>
                      {dest.bpName}
                    </option>
                  ))}
                </select>
                <label className='form-label'>Choose Template</label>
                <div className='document-dropdown-container'>
                  <div className='input-group rounded'>
                    <input
                      id={'changeTemplate-search-input2'}
                      type='search'
                      value={this.state.templateSearchText2}
                      className='form-control'
                      placeholder={this.state.suggestedTemplateId2.templateName}
                      aria-label='Template'
                      onChange={this.onChangeTemplateSearchText2}
                      readOnly={this.state.selectedTemplate2.templateId > 0}
                      onClick={this.onClickTemplateSearch2}
                    ></input>
                    <span
                      className='input-group-text border-0'
                      id='search-addon'
                    >
                      <FontAwesomeIcon icon={solid('search')} />
                    </span>
                  </div>
                  {this.state.selectedTemplate2.templateId < 0 && (
                    <ul className='document-dropdown-list l2'>
                      {this.state.filteredTemplates2.map((tempOption) => (
                        <li
                          className={
                            this.state.suggestedTemplateId2.templateId ===
                            tempOption.templateId
                              ? 'active'
                              : ''
                          }
                          onMouseEnter={() =>
                            this.suggestTemplateItem2(tempOption)
                          }
                          onClick={() =>
                            this.templateSuggestionClick2(tempOption)
                          }
                          key={tempOption.templateId}
                        >
                          {tempOption.templateName}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <div className='form-check  mt-2'>
                  <label className='form-check-label'>
                    {this.props.checkAll && (
                      <>{this.props.docCount} document(s) will be moved.</>
                    )}
                    {!this.props.checkAll && (
                      <>{this.props.checkCount} document(s) will be moved.</>
                    )}
                  </label>
                </div>
                {this.state.selectedBpId !== -1 && (
                  <button
                    className='btn btn-success mt-3'
                    onClick={this.moveBpDocsClick}
                  >
                    <FontAwesomeIcon icon={solid('check')} /> OK
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default DocumentList;
