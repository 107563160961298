import * as React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import actionTypes from '../../../actions/ActionTypes';
import ActiveDocumentStore from '../../../stores/ActiveDocumentStore';
import { GetADTree } from '../../../actions/ActiveDocumentActions';

class ActiveDocumentSidebar extends React.Component {
  //isList: Array<BPModel>;
  constructor(props) {
    super(props);
    this.state = {
      nodeTree: [],
      h: window.innerHeight - 500,
      favorites: [],
      showCompletedDocs: false,
      searching: true,
    };

    this.onReceiveActiveDocChange = this.onReceiveActiveDocChange.bind(this);
    this.toggleNode = this.toggleNode.bind(this);
    this.resizeH = this.resizeH.bind(this);
    this.toggleFavNode = this.toggleFavNode.bind(this);
    this.bpClick = this.bpClick.bind(this);
    this.toggleShowCompletedDocs = this.toggleShowCompletedDocs.bind(this);
    // this.onSearchChange = this.onSearchChange.bind(this);
  }

  componentDidMount() {
    ActiveDocumentStore.addChangeListener(this.onReceiveActiveDocChange);
    GetADTree(false);
    this.setState({searching: true});
    this.resizeH();
    window.addEventListener('resize', this.resizeH);
  }

  componentWillUnmount() {
    ActiveDocumentStore.removeChangeListener(this.onReceiveActiveDocChange);
    window.removeEventListener('resize', this.resizeH);
  }

  onReceiveActiveDocChange() {
    let lastChange = ActiveDocumentStore.lastChange();

    if (lastChange === actionTypes.ADGetADTree) {
      let adTree = ActiveDocumentStore.getADTree();
      var settings = this.getADSettings();
      var favTree = [];

      if (settings !== null) {
        settings.fav.forEach((id) => {
          var node1 = adTree.find((n1) => n1.type === 'bp' && n1.id === id);
          if (node1) {
            node1.isFav = true;
            if (settings.favOpen.indexOf(node1.id) > -1) {
              //node1.isOpen = true;
            }

            favTree.push(node1);
          } else {
            // search children
            adTree.forEach((ou) => {
              if (ou.type === 'ou') {
                let node2 = ou.children.find(
                  (n2) => n2.type === 'bp' && n2.id === id
                );

                if (node2) {
                  let favNode = { ...node2 };
                  node2.isFav = true;
                  favNode.isFav = true;
                  favNode.isOpen = false;
                  //if (settings.favOpen.indexOf(node2.id) > -1) {
                  //  node2.isOpen = true;
                  //}
                  favTree.push(favNode);
                }
              }
            });
          }
        });

        adTree.forEach((ou) => {
          if (ou.type === 'ou') {
            if (settings.ouOpen.indexOf(ou.id) > -1) {
              ou.isOpen = true;
            } else {
              ou.isOpen = false;
            }
          }
        });

        favTree.forEach((fav) => {
          if (fav.type === 'bp') {
            if (settings.favOpen.indexOf(fav.id) > -1) {
              fav.isOpen = true;
            } else {
              fav.isOpen = false;
            }
          }
        });

        adTree.forEach((oubp) => {
          if (oubp.type === 'bp') {
            if (settings.bpOpen.indexOf(oubp.id) > -1) {
              oubp.isOpen = true;
            } else {
              oubp.isOpen = false;
            }
          }
          if (oubp.type === 'ou') {
            oubp.children.forEach((bp) => {
              if (settings.bpOpen.indexOf(bp.id) > -1) {
                bp.isOpen = true;
              } else {
                bp.isOpen = false;
              }
            });
          }
        });

        // settings.bpOpen.forEach((id) => {
        //   adTree.forEach((node) => {
        //     if (node.id === id && node.type === 'bp') {
        //       node.isOpen = true;
        //     }
        //     if (node.type === 'ou') {
        //       node.children.forEach((node2) => {
        //         if (node2.id === id && node2.type === 'bp') {
        //           node2.isOpen = true;
        //         }
        //       });
        //     }
        //   });
        // });

        this.setState({
          nodeTree: adTree,
          favorites: favTree,
          searching: false,
        });
      }
    }
  }

  toggleShowCompletedDocs() {
    if (this.state.showCompletedDocs === true) {
      this.setState({ showCompletedDocs: false });
      this.props.ChangeComplete(false);
      GetADTree(false);
    } else {
      this.setState({ showCompletedDocs: true });
      this.props.ChangeComplete(true);
      GetADTree(true);
    }

  }


  refreshNode(node) {}

  resizeH() {
    this.setState({ h: window.innerHeight - 180 });
  }

  bpClick(node) {}

  copyNode(node) {
    var newNode = {
      id: node.id,
      type: node.type,
      name: node.name,
      isOpen: false,
      isFav: node.isFav,
      count: node.count,
      stepType: node.stepType,
      children: [],
    };
    node.children.forEach((n) => {
      newNode.children.push(this.copyNode(n));
    });
    return newNode;
  }

  getADSettings() {
    var settings = null;
    try {
      settings = JSON.parse(localStorage.getItem('ADSettings'));
    } catch {}

    if (settings === null) {
      settings = {
        fav: [],
        favOpen: [],
        ouOpen: [],
        bpOpen: [],
      };
    }
    return settings;
  }

  toggleFavorite(node) {
    // if (node.type === 'bp') {
    //   this.props.LoadBPClick(node.id);
    // }
    var settings = this.getADSettings();
    var tree = this.state.nodeTree;
    var favTree = this.state.favorites;
    var favNode = favTree.find((f) => f.id === node.id && f.type === node.type);

    var idx = settings.fav.indexOf(node.id);
    if (idx === -1 && !node.isFav) {
      settings.fav.push(node.id);
    } else if (idx > -1 && node.isFav) {
      settings.fav.splice(idx, 1);
    }
    localStorage.setItem('ADSettings', JSON.stringify(settings));

    if (node.isFav && favNode !== undefined) {
      if (favNode) {
        favTree.splice(favTree.indexOf(node), 1);
      }
    }
    if (!node.isFav && favNode === undefined) {
      favTree.push(this.copyNode(node));
    }
    var node1 = tree.find((n1) => n1.type === node.type && n1.id === node.id);
    if (node1) {
      node1.isFav = !node1.isFav;
    } else {
      // search children
      tree.forEach((ou) => {
        if (ou.type === 'ou') {
          var node2 = ou.children.find(
            (n2) => n2.type === node.type && n2.id === node.id
          );
          if (node2) {
            node2.isFav = !node2.isFav;
          }
        }
      });
    }
    console.log(favTree);

    //this.setState({nodeTree: tree});
  }

  toggleNode(node) {
    if (node.type === 'bp') {
      this.props.LoadBPClick(node.id);
    }
    var settings = this.getADSettings();
    if (node.type === 'bp') {
      let idx = settings.bpOpen.indexOf(node.id);
      if (idx === -1 && !node.isOpen) {
        settings.bpOpen.push(node.id);
      } else if (idx > -1 && node.isOpen) {
        settings.bpOpen.splice(idx, 1);
      }
    }
    if (node.type === 'ou') {
      let idx2 = settings.ouOpen.indexOf(node.id);
      if (idx2 === -1 && !node.isOpen) {
        settings.ouOpen.push(node.id);
      } else if (idx2 > -1 && node.isOpen) {
        settings.ouOpen.splice(idx2, 1);
      }
    }
    localStorage.setItem('ADSettings', JSON.stringify(settings));

    var tree = this.state.nodeTree;
    var node1 = tree.find((n1) => n1.type === node.type && n1.id === node.id);
    if (node1) {
      node1.isOpen = !node1.isOpen;
    } else {
      // search children
      tree.forEach((ou) => {
        if (ou.type === 'ou') {
          var node2 = ou.children.find(
            (n2) => n2.type === node.type && n2.id === node.id
          );
          if (node2) {
            node2.isOpen = !node2.isOpen;
          }
        }
      });
    }
    this.setState({ nodeTree: tree });
  }

  toggleFavNode(node) {
    var tree = this.state.favorites;
    var node1 = tree.find((n1) => n1.type === node.type && n1.id === node.id);
    var settings = this.getADSettings();
    if (node.type === 'bp') {
      this.props.LoadBPClick(node.id);
    }
    var idx = settings.favOpen.indexOf(node.id);
    if (idx === -1 && !node.isOpen) {
      settings.favOpen.push(node.id);
    } else if (idx > -1 && node.isOpen) {
      settings.favOpen.splice(idx, 1);
    }
    localStorage.setItem('ADSettings', JSON.stringify(settings));
    if (node1) {
      node1.isOpen = !node1.isOpen;
    } else {
      // search children
      tree.forEach((ou) => {
        if (ou.type === 'ou') {
          var node2 = ou.children.find(
            (n2) => n2.type === node.type && n2.id === node.id
          );
          if (node2) {
            node2.isOpen = !node2.isOpen;
          }
        }
      });
    }
    this.setState({ favorites: tree });
  }

  nodeMouseUp(e) {}

  render() {
    return (
      <div
        className='d-flex flex-column pt-3 ps-3 text-white bg-dark'
        style={{ maxHeight: '100%', width: '320px' }}
      >
        <Link
          to='/ActiveDocuments'
          className='d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none'
        >
          <span className='fs-4'>
            <FontAwesomeIcon
              style={{ color: '#E2D45B' }}
              icon={solid('cubes')}
            />
            {'   '}
            Active Documents
          </span>
        </Link>
        <div className='form-check form-check-inline mt-2'>
          <input
            id='showCompletedDocs'
            className='form-check-input me-1'
            checked={this.state.showCompletedDocs}
            onChange={(event) => this.toggleShowCompletedDocs(event)}
            type='checkbox'
          />
          <label className='form-check-label'>Show completed documents</label>
        </div>
        <hr />

        {this.state.searching &&

          <div className='h-100 d-flex flex-column align-items-center justify-content-center'>
          <div style={{color: '#8DF5F5', marginBottom: '8px'}}><FontAwesomeIcon icon={solid('circle-notch')} size='3x' spin /></div>
          <div style={{color: '#8DF5F5', fontSize: '14pt', fontFamily: 'Tilt Neon, "Courier New", monospace'}}>Loading Documents</div>
          </div>

        }
        {!this.state.searching &&
        <div
          className='coolScroll'
          style={{ maxHeight: `${this.state.h}px`, overflow: 'auto' }}
        >
          <ul className='list-group active-documents'>
            <li className='list-group-item d-flex justify-content-between aligh-items-start'>
              <div className='me-auto'>
                <FontAwesomeIcon
                  style={{ color: '#EAE83A' }}
                  icon={solid('star')}
                />{' '}
                <strong>Favorites</strong>
              </div>
            </li>
            <div className='ms-3'>
              {this.state.favorites.map((node) => (
                <React.Fragment key={node.id}>
                  <li className='list-group-item d-flex justify-content-between aligh-items-start'>
                    <div className='me-auto doc-tree-content'>
                      <span onClick={() => this.toggleFavNode(node)}>
                        {node.isOpen && (
                          <FontAwesomeIcon icon={solid('caret-down')} />
                        )}
                        {!node.isOpen && (
                          <FontAwesomeIcon icon={solid('caret-right')} />
                        )}{' '}
                        <span
                          style={{
                            cursor: 'pointer',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {node.name}
                        </span>{' '}
                      </span>
                    </div>
                    <span
                      className={`badge ${
                        node.count > 0 ? 'bg-success' : 'bg-secondary'
                      } rounded-pill`}
                      style={{ height: '22px' }}
                    >
                      {' '}
                      {node.count}
                    </span>
                  </li>
                  {node.isOpen && (
                    <div className='ms-3'>
                      <ul className='list-group active-documents'>
                        {node.children.map((step) => (
                          <li
                            key={step.id}
                            className='list-group-item d-flex justify-content-between aligh-items-start'
                            onClick={(s) =>
                              this.props.LoadStepClick(step.id, node.id)
                            }
                          >
                            <div
                              className='me-auto'
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {step.stepType === 'User' && (
                                <span>
                                  <FontAwesomeIcon icon={solid('user')} />{' '}
                                  {step.name}
                                </span>
                              )}
                              {step.stepType !== 'User' && (
                                <span style={{ color: '#C1C1C1' }}>
                                  <FontAwesomeIcon icon={solid('cog')} />{' '}
                                  {step.name}
                                </span>
                              )}
                            </div>
                            {step.count > 0 && (
                              <span
                                className={`badge ${
                                  step.count > 0 ? 'bg-success' : 'bg-secondary'
                                } rounded-pill`}
                                style={{ height: '22px' }}
                              >
                                {step.count}
                              </span>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
            <hr />
            <li className='list-group-item d-flex justify-content-between aligh-items-start'>
              <div className='me-auto'>
                <FontAwesomeIcon icon={solid('cubes')} />{' '}
                <strong>All Documents</strong>{' '}
                <FontAwesomeIcon className='redo-icon' icon={solid('redo')} />
              </div>
            </li>
            {this.state.nodeTree.map((node) => (
              <React.Fragment key={node.id + 100}>
                {node.type === 'ou' && (
                  <React.Fragment key={node.id}>
                    <li className='list-group-item d-flex justify-content-between aligh-items-start'>
                      <div
                        className='me-auto'
                        onClick={() => this.toggleNode(node)}
                      >
                        {node.isOpen && (
                          <FontAwesomeIcon icon={solid('caret-down')} />
                        )}
                        {!node.isOpen && (
                          <FontAwesomeIcon icon={solid('caret-right')} />
                        )}{' '}
                        <FontAwesomeIcon
                          className='orgUnit-icon'
                          icon={solid('folder')}
                        />{' '}
                        {node.name}
                      </div>
                      <span
                        className={`badge ${
                          node.count > 0 ? 'bg-success' : 'bg-secondary'
                        } rounded-pill`}
                        style={{ height: '22px' }}
                      >
                        {node.count}
                      </span>
                    </li>
                    {node.isOpen && (
                      <div className='ms-3'>
                        {node.children.map((child) => (
                          <React.Fragment key={child.id}>
                            <li className='list-group-item d-flex justify-content-between aligh-items-start'>
                              <div className='me-auto'>
                                <span onClick={() => this.toggleNode(child)}>
                                  {child.isOpen && (
                                    <FontAwesomeIcon
                                      icon={solid('caret-down')}
                                      style={{ cursor: 'pointer' }}
                                    />
                                  )}
                                  {!child.isOpen && (
                                    <FontAwesomeIcon
                                      icon={solid('caret-right')}
                                      style={{ cursor: 'pointer' }}
                                    />
                                  )}{' '}
                                  <span
                                    style={{
                                      cursor: 'pointer',
                                      textOverflow: 'ellipsis',
                                    }}
                                  >
                                    {child.name}
                                  </span>
                                </span>
                              </div>
                              <span style={{ whiteSpace: 'nowrap' }}>
                                {!child.isFav && (
                                  <FontAwesomeIcon
                                    onClick={() => this.toggleFavorite(child)}
                                    style={{ color: '#9C9C9C' }}
                                    icon={regular('star')}
                                  />
                                )}
                                {child.isFav && (
                                  <FontAwesomeIcon
                                    style={{ color: '#EAE83A' }}
                                    onClick={() => this.toggleFavorite(child)}
                                    icon={solid('star')}
                                  />
                                )}{' '}
                                <span
                                  className={`badge ${
                                    child.count > 0
                                      ? 'bg-success'
                                      : 'bg-secondary'
                                  } rounded-pill`}
                                  style={{ height: '22px' }}
                                >
                                  {child.count}
                                </span>
                              </span>
                            </li>
                            {child.isOpen && (
                              <div className='ms-3'>
                                <ul className='list-group active-documents'>
                                  {child.children.map((step) => (
                                    <li
                                      key={step.id}
                                      className='list-group-item d-flex justify-content-between aligh-items-start'
                                      onClick={(s) =>
                                        this.props.LoadStepClick(
                                          step.id,
                                          child.id
                                        )
                                      }
                                    >
                                      <div
                                        className='me-auto'
                                        style={{
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                        }}
                                      >
                                        {step.stepType === 'User' && (
                                          <span>
                                            <FontAwesomeIcon
                                              icon={solid('user')}
                                            />{' '}
                                            {step.name}
                                          </span>
                                        )}
                                        {step.stepType !== 'User' && (
                                          <span style={{ color: '#C1C1C1' }}>
                                            <FontAwesomeIcon
                                              icon={solid('cog')}
                                            />{' '}
                                            {step.name}
                                          </span>
                                        )}
                                      </div>
                                      {step.count > 0 && (
                                        <span
                                          className={`badge ${
                                            step.count > 0
                                              ? 'bg-success'
                                              : 'bg-secondary'
                                          } rounded-pill`}
                                          style={{ height: '22px' }}
                                        >
                                          {step.count}
                                        </span>
                                      )}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </React.Fragment>
                        ))}
                      </div>
                    )}
                  </React.Fragment>
                )}

                {node.type === 'bp' && (
                  <React.Fragment key={node.id}>
                    <li className='list-group-item d-flex justify-content-between aligh-items-start'>
                      <div className='me-auto'>
                        <span onClick={() => this.toggleNode(node)}>
                          {node.isOpen && (
                            <FontAwesomeIcon
                              icon={solid('caret-down')}
                              style={{ cursor: 'pointer' }}
                            />
                          )}
                          {!node.isOpen && (
                            <FontAwesomeIcon
                              icon={solid('caret-right')}
                              style={{ cursor: 'pointer' }}
                            />
                          )}{' '}
                          <span
                            style={{
                              cursor: 'pointer',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {node.name}
                          </span>
                        </span>
                      </div>
                      <span>
                        {' '}
                        {!node.isFav && (
                          <FontAwesomeIcon
                            onClick={() => this.toggleFavorite(node)}
                            style={{ color: '#9C9C9C' }}
                            icon={regular('star')}
                          />
                        )}
                        {node.isFav && (
                          <FontAwesomeIcon
                            style={{ color: '#EAE83A' }}
                            onClick={() => this.toggleFavorite(node)}
                            icon={solid('star')}
                          />
                        )}{' '}
                        <span
                          className={`badge ${
                            node.count > 0 ? 'bg-success' : 'bg-secondary'
                          } rounded-pill`}
                          style={{ height: '22px' }}
                        >
                          {node.count}
                        </span>
                      </span>
                    </li>
                    {node.isOpen && (
                      <div className='ms-3'>
                        <ul className='list-group active-documents'>
                          {node.children.map((step) => (
                            <li
                              key={step.id}
                              className='list-group-item d-flex justify-content-between aligh-items-start'
                              onClick={() =>
                                this.props.LoadStepClick(step.id, node.id)
                              }
                            >
                              <div
                                className='me-auto'
                                style={{ overflow: 'hidden' }}
                              >
                                {step.stepType === 'User' && (
                                  <span>
                                    <FontAwesomeIcon icon={solid('user')} />{' '}
                                    {step.name}
                                  </span>
                                )}
                                {step.stepType !== 'User' && (
                                  <span style={{ color: '#C1C1C1' }}>
                                    <FontAwesomeIcon icon={solid('cog')} />{' '}
                                    {step.name}
                                  </span>
                                )}
                              </div>
                              {step.count > 0 && (
                                <span
                                  className={`badge ${
                                    step.count > 0
                                      ? 'bg-success'
                                      : 'bg-secondary'
                                  } rounded-pill`}
                                  style={{ height: '22px' }}
                                >
                                  {step.count}
                                </span>
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            ))}
          </ul>
        </div>
        }
      </div>
    );
  }
}

export default ActiveDocumentSidebar;
