import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSpring, a } from 'react-spring';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import aiIcon from '../images/TD AI Icon 2 Color.png';

function SiteMenu(props) {
  const [showInbox, setShowInbox] = useState(false);
  const [showSysConfig, setShowSysConfig] = useState(true);

  useEffect(() => {});

  const growInbox = useSpring({
    height: showInbox ? 130 : 0,
    opacity: showInbox ? 1 : 0,
  });
  const growSysManager = useSpring({
    height: showSysConfig ? 300 : 0,
    opacity: showSysConfig ? 1 : 0,
  });
  return (
    <div>
      <Link to='/Inbox' style={{ textDecoration: 'none', color: 'white' }}>
        <div
          // onClick={() => {
          //   setShowInbox(!showInbox);
          //   setShowSysConfig(false);
          //   console.log(props);
          //}}
          className='navHeader'
          onClick={props.onToggle}
        >
          <span style={{ color: '#48B048' }}>
            <FontAwesomeIcon icon={solid('inbox')} />
          </span>{' '}
          Inbox
        </div>
      </Link>
      {showInbox && (
        <a.div style={growInbox}>
          <div className='navSubHeader'>
            <span style={{ color: '#48B048' }}>
              <FontAwesomeIcon icon={solid('inbox')} />
            </span>{' '}
            View Inbox
          </div>
          <div className='navSubHeader'>
            <span style={{ color: '#DADADA' }}>
              <FontAwesomeIcon icon={solid('list')} />
            </span>{' '}
            My Queues <span className='badge bg-primary'>36</span>
          </div>
          <div className='navSubHeader'>
            <span style={{ color: '#DADADA' }}>
              <FontAwesomeIcon icon={solid('filter')} />
            </span>{' '}
            My Filters
          </div>
          <div className='navSubHeader'>
            <span style={{ color: '#E542D6' }}>
              <FontAwesomeIcon icon={regular('file')} />
            </span>{' '}
            Image Review <span className='badge bg-primary'>4</span>
          </div>
        </a.div>
      )}

      <Link
        to='/ActiveDocuments'
        style={{ textDecoration: 'none', color: 'white' }}
      >
        <div className='navHeader' onClick={props.onToggle}>
          <span style={{ color: '#E2D45B' }}>
            <FontAwesomeIcon icon={solid('cubes')} />
          </span>{' '}
          Active Documents
        </div>
      </Link>

      <div className='ms-2'>{/*<ActiveDocuments /> */}</div>
      <Link to='/Search' style={{ textDecoration: 'none', color: 'white' }}>
        <div className='navHeader' onClick={props.onToggle}>
          <span style={{ color: '#4BB5CF' }}>
            <FontAwesomeIcon icon={solid('search')} />
          </span>{' '}
          Advanced Search
        </div>
      </Link>

      {/*{props.canImageReview && (
        <Link
          to='/ImageReview'
          style={{ textDecoration: 'none', color: 'white' }}
        >
          <div className='navHeader' onClick={props.onToggle}>
            <span style={{ color: '#AE4BCF' }}>
              <FontAwesomeIcon icon={solid('file-image')} />
            </span>{' '}
            Image Review
          </div>
        </Link>
      )}*/}
      {props.licenseLevel >= 3 && (
        <Link
          to='/TemplateEditor'
          style={{ textDecoration: 'none', color: 'white' }}
        >
          <div className='navHeader' onClick={props.onToggle}>
            <span style={{ color: '#79EA6E' }}>
              <FontAwesomeIcon icon={solid('puzzle-piece')} />
            </span>{' '}
            Templates
          </div>
        </Link>
      )}

      {showSysConfig && props.licenseLevel >= 4 && (
        <a.div style={growSysManager}>
          <div className='navLabel'>Business Process</div>
          <Link
            to='/Admin/OrgUnits'
            style={{ textDecoration: 'none', color: 'white' }}
          >
            <div className='navSubHeader' onClick={props.onToggle}>
              <span style={{ color: '#8090FF' }}>
                <FontAwesomeIcon icon={solid('th-list')} />
              </span>{' '}
              Organizational Units
            </div>
          </Link>

          <Link
            to='/Admin/BusinessProcess'
            style={{ textDecoration: 'none', color: 'white' }}
          >
            <div className='navSubHeader' onClick={props.onToggle}>
              <span style={{ color: '#8090FF' }}>
                <FontAwesomeIcon icon={solid('building')} />
              </span>{' '}
              Business Processes
            </div>
          </Link>

          <Link
            to='/Admin/FormTypes'
            style={{ textDecoration: 'none', color: 'white' }}
          >
            <div className='navSubHeader' onClick={props.onToggle}>
              <span style={{ color: '#8090FF' }}>
                <FontAwesomeIcon icon={solid('file-invoice')} />
              </span>{' '}
              Form Types
            </div>
          </Link>
          <Link
            to='/Admin/Workflows'
            style={{ textDecoration: 'none', color: 'white' }}
          >
            <div className='navSubHeader' onClick={props.onToggle}>
              <span style={{ color: '#8090FF' }}>
                <FontAwesomeIcon icon={solid('sitemap')} />
              </span>{' '}
              Workflows
            </div>
          </Link>

          <div className='navLabel'>Integrations</div>

          <Link
            to='/Admin/Modules'
            style={{ textDecoration: 'none', color: 'white' }}
          >
            <div className='navSubHeader' onClick={props.onToggle}>
              <span style={{ color: '#6CE84E' }}>
                <FontAwesomeIcon icon={solid('box')} />
              </span>{' '}
              Modules
            </div>
          </Link>

          <Link
            to='/Admin/Connection'
            style={{ textDecoration: 'none', color: 'white' }}
          >
            <div className='navSubHeader' onClick={props.onToggle}>
              <span style={{ color: '#6CE84E' }}>
                <FontAwesomeIcon icon={solid('database')} />
              </span>{' '}
              Connections
            </div>
          </Link>
          <Link
            to='/Admin/Schedules'
            style={{ textDecoration: 'none', color: 'white' }}
          >
            <div className='navSubHeader' onClick={props.onToggle}>
              <span style={{ color: '#6CE84E' }}>
                <FontAwesomeIcon icon={solid('calendar-check')} />
              </span>{' '}
              Schedule
            </div>
          </Link>
          <Link
            to='/Admin/Secret'
            style={{ textDecoration: 'none', color: 'white' }}
          >
            <div className='navSubHeader' onClick={props.onToggle}>
              <span style={{ color: '#6CE84E' }}>
                <FontAwesomeIcon icon={solid('key')} />
              </span>{' '}
              Secrets
            </div>
          </Link>
          <div className='navLabel'>User Management</div>
          <Link
            to='/Admin/Users'
            style={{ textDecoration: 'none', color: 'white' }}
          >
            <div className='navSubHeader' onClick={props.onToggle}>
              <span style={{ color: '#E8C54E' }}>
                <FontAwesomeIcon icon={solid('user')} />
              </span>{' '}
              Users
            </div>
          </Link>
          <Link
            to='/Admin/Roles'
            style={{ textDecoration: 'none', color: 'white' }}
          >
            <div className='navSubHeader' onClick={props.onToggle}>
              <span style={{ color: '#E8C54E' }}>
                <FontAwesomeIcon icon={solid('user-friends')} />
              </span>{' '}
              Roles
            </div>
          </Link>
          <Link
            to='/Admin/LicenseAllocation'
            style={{ textDecoration: 'none', color: 'white' }}
          >
            <div className='navSubHeader' onClick={props.onToggle}>
              <span style={{ color: '#E8C54E' }}>
                <FontAwesomeIcon icon={solid('id-card')} />
              </span>{' '}
              License Allocation
            </div>
          </Link>
          <Link
            to='/Admin/LoginSettings'
            style={{ textDecoration: 'none', color: 'white' }}
          >
            <div className='navSubHeader' onClick={props.onToggle}>
              <span style={{ color: '#E8C54E' }}>
                <FontAwesomeIcon icon={solid('sign-in-alt')} />
              </span>{' '}
              AD/OAUTH
            </div>
          </Link>
          <div className='navLabel'>Intake</div>
          <Link
            to='/Admin/UploadTracker'
            style={{ textDecoration: 'none', color: 'white' }}
          >
            <div className='navSubHeader' onClick={props.onToggle}>
              <span style={{ color: '#4EE8E8' }}>
                <FontAwesomeIcon icon={solid('upload')} />
              </span>{' '}
              Upload Tracker
            </div>
          </Link>
          <Link
            to='/Admin/Directories'
            style={{ textDecoration: 'none', color: 'white' }}
          >
            <div className='navSubHeader' onClick={props.onToggle}>
              <span style={{ color: '#4EE8E8' }}>
                <FontAwesomeIcon icon={solid('folder-open')} />
              </span>{' '}
              Directories
            </div>
          </Link>
          <Link
            to='/Admin/InboundAutomationHome'
            style={{ textDecoration: 'none', color: 'white' }}
          >
            <div className='navSubHeader' onClick={props.onToggle}>
              <span style={{ color: '#4EE8E8' }}>
                <FontAwesomeIcon icon={regular('envelope-open')} />
              </span>{' '}
              InboundAutomation
            </div>
          </Link>
          <Link
            to='/Admin/IntakeStrategies'
            style={{ textDecoration: 'none', color: 'white' }}
          >
            <div className='navSubHeader' onClick={props.onToggle}>
              <span style={{ color: '#4EE8E8' }}>
                <FontAwesomeIcon icon={regular('paper-plane')} />
              </span>{' '}
              Intake Strategies
            </div>
          </Link>
          <Link
            to='/Admin/IntakeSettings'
            style={{ textDecoration: 'none', color: 'white' }}
          >
            <div className='navSubHeader' onClick={props.onToggle}>
              <span style={{ color: '#4EE8E8' }}>
                <FontAwesomeIcon icon={solid('cog')} />
              </span>{' '}
              Intake Settings
            </div>
          </Link>
          <Link
            to='/Admin/AutoOCR'
            style={{ textDecoration: 'none', color: 'white' }}
          >
            <div className='navSubHeader' onClick={props.onToggle}>
              <span style={{ color: '#4EE8E8' }}>
                <FontAwesomeIcon icon={solid('wand-magic')} />
              </span>{' '}
              Auto OCR
              <span>
                <img src={aiIcon} className='aiMenuIcon' alt='aiIcon' />
              </span>
            </div>
          </Link>
          <div className='navLabel'>Configuration</div>
          <Link
            to='/Admin/Archive'
            style={{ textDecoration: 'none', color: 'white' }}
          >
            <div className='navSubHeader' onClick={props.onToggle}>
              <span style={{ color: '#BE4EE8' }}>
                <FontAwesomeIcon icon={solid('archive')} />
              </span>{' '}
              Document Storage
            </div>
          </Link>
          <Link
            to='/Admin/Companion'
            style={{ textDecoration: 'none', color: 'white' }}
          >
            <div className='navSubHeader' onClick={props.onToggle}>
              <span style={{ color: '#BE4EE8' }}>
                <FontAwesomeIcon icon={solid('table')} />
              </span>{' '}
              Companion Tables
            </div>
          </Link>
          <Link
            to='/Admin/Mailbox'
            style={{ textDecoration: 'none', color: 'white' }}
          >
            <div className='navSubHeader' onClick={props.onToggle}>
              <span style={{ color: '#BE4EE8' }}>
                <FontAwesomeIcon icon={solid('envelope-square')} />
              </span>{' '}
              Mailboxes
            </div>
          </Link>
          <Link
            to='/Admin/Logs'
            style={{ textDecoration: 'none', color: 'white' }}
          >
            <div className='navSubHeader' onClick={props.onToggle}>
              <span style={{ color: '#BE4EE8' }}>
                <FontAwesomeIcon icon={regular('list-alt')} />
              </span>{' '}
              Logs
            </div>
          </Link>
          <Link
            to='/Admin/License'
            style={{ textDecoration: 'none', color: 'white' }}
          >
            <div className='navSubHeader' onClick={props.onToggle}>
              <span style={{ color: '#BE4EE8' }}>
                <FontAwesomeIcon icon={solid('file-signature')} />
              </span>{' '}
              License
            </div>
          </Link>
          <Link
            to='/Admin/SystemSettings'
            style={{ textDecoration: 'none', color: 'white' }}
          >
            <div className='navSubHeader' onClick={props.onToggle}>
              <span style={{ color: '#BE4EE8' }}>
                <FontAwesomeIcon icon={solid('cog')} />
              </span>{' '}
              System Settings
            </div>
          </Link>
          <Link
            to='/Admin/TableEditorView/ClientIntegrationConfigSettings'
            style={{ textDecoration: 'none', color: 'white' }}
          >
            <div className='navSubHeader' onClick={props.onToggle}>
              <span style={{ color: '#BE4EE8' }}>
                <FontAwesomeIcon icon={solid('table')} />
              </span>{' '}
              Client Integration Config
            </div>
          </Link>
        </a.div>
      )}
    </div>
  );
}
export default SiteMenu;
