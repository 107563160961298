import * as React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import AutoOcrStore from '../../../../stores/AutoOcrStore';
import FormTypeStore from '../../../../stores/FormTypeStore';
import { GetAutoOcrDocumentTypeList } from '../../../../actions/AutoOcrActions';
import { GetAllFormTypes } from '../../../../actions/FormTypeActions';
import actionTypes from '../../../../actions/ActionTypes';
import aiIcon from '../../../../images/TD AI Icon 2 Color.png';

class AutoOcrSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      documentTypeList: [],
      formTypeList: [],
    };

    this.onReceiveDocumentTypeChange = this.onReceiveDocumentTypeChange.bind(this);
    this.onReceiveFormTypeChange = this.onReceiveFormTypeChange.bind(this);
  }

  componentDidMount() {
    AutoOcrStore.addChangeListener(this.onReceiveDocumentTypeChange);
    FormTypeStore.addChangeListener(this.onReceiveFormTypeChange);
    GetAutoOcrDocumentTypeList();
    GetAllFormTypes();
  }

  componentWillUnmount() {
    AutoOcrStore.removeChangeListener(this.onReceiveDocumentTypeChange);
    FormTypeStore.addChangeListener(this.onReceiveFormTypeChange);
  }

  onReceiveDocumentTypeChange() {
    let lastChange = AutoOcrStore.lastChange();
    if (lastChange === actionTypes.AutoOcrDocumentTypeGetList) {
      let documentTypes = AutoOcrStore.getDocumentTypes();
      this.setState({
        documentTypeList: documentTypes
      });
    }

    if (lastChange === actionTypes.AutoOcrDataUpdate) {
      GetAutoOcrDocumentTypeList();
    }
  }

  onReceiveFormTypeChange() {
    let lastChange = FormTypeStore.getLastAction();
    if (lastChange.actionType === 'FTGetAll') {
      let formTypes = FormTypeStore.getAllFormTypes();
      this.setState({
        formTypeList: formTypes
      });
    }
  }

  render() {
    let documentTypes;
    if (this.state.documentTypeList !== undefined) {
        documentTypes = this.state.documentTypeList.map((docType) => (
        <div style={{ marginBottom: '8px' }} key={docType.id}>
          <Link
            className='sidebar-link'
            to={'/Admin/AutoOCRDocumentType/' + docType.id}
            //onClick={() => this.handleBPClick(bp)}
            style={{ cursor: 'Pointer', wordWrap: 'break-word' }}
          >
            <FontAwesomeIcon icon={solid('file-invoice')} />{' '}
            {docType.name}
          </Link>
        </div>
      ));
    } else {
        documentTypes = <div>No Auto Ocr defined</div>;
    }

    let formTypes;
    if (this.state.formTypeList !== undefined) {
        formTypes = this.state.formTypeList.map((formType) => (
        <div style={{ marginBottom: '8px' }} key={`formType-`+formType.formTypeId}>
          <Link
            className='sidebar-link'
            to={'/Admin/AutoOCRMapping/' + formType.formTypeId}
            style={{ cursor: 'Pointer', wordWrap: 'break-word' }}
          >
            {formType.name}
          </Link>
        </div>
      ));
    } else {
        documentTypes = <div>No configurations defined</div>;
    }

    return (
      <div
        className='d-flex flex-column p-3 text-white bg-dark'
        style={{ height: '100%', width: '245px' }}
      >
        <Link
          to='/Admin/AutoOcrDocumentType'
          className='d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none'
        >
          <span className='fs-4'>
          <img src={aiIcon} className='aiMenuIcon2' alt='aiIcon' />{' '}
            {'   '}
            Auto OCR
          </span>
        </Link>
        <hr />

        <div style={{ maxHeight: '50%', overflow: 'auto' }}>
          {documentTypes}
        </div>

        <hr />
        <Link className='sidebar-link' to='/Admin/AutoOCRDocumentType/0'>
          New Document Type
        </Link>


        <hr />
        <span className='fs-6'>
            Auto Ocr Form Type configuration
        </span>
        <hr />

        <div style={{ maxHeight: '50%', overflow: 'auto' }}>
          {formTypes}
        </div>

      </div>
    );
  }
}

export default AutoOcrSidebar;
