import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Link } from 'react-router-dom';
import { GetActiveLicense } from '../../../actions/LicenseActions';
import {
  GetUploads,
  GetOneUpload,
  QueryUpload,
  GetUploadDetail,
  AbortUpload,
  GetMoreUploads,
  DownloadFail,
  ReprocessDoc,
  QueryUploadGetMore
} from '../../../actions/UploadTrackerActions';
import LicenseStore from '../../../stores/LicenseStore';
import UploadTrackerStore from '../../../stores/UploadTrackerStore';

import { UploadQuery, UploadQueryGetMore } from '../../../models/admin/UploadTracker';

class UploadTrackerView extends React.Component {
  //private interval: any;
  constructor() {
    super();
    // state
    //this.interval = undefined;

    this.state = {
      validLicense: true,
      currentUploads: [],
      sourceFilter: 'ALL',
      statusFilter: 'ALL',
      useQuery: false,
      showDialog: false,
      currentInfo: undefined,
      currentTracker: undefined,
      currentOCRInfo: undefined,
      currentTab: 'Documents',
      searchText: '',
      linkPath: '',
      bottomHeight: window.innerHeight - 400,
    };

    // bindings
    this.onLicenseUpdate = this.onLicenseUpdate.bind(this);
    this.onUploadUpdate = this.onUploadUpdate.bind(this);
    this.onChangeSourceFilter = this.onChangeSourceFilter.bind(this);
    this.onChangeStatusFilter = this.onChangeStatusFilter.bind(this);
    this.onQuerySearch = this.onQuerySearch.bind(this);
    this.onRefresh = this.onRefresh.bind(this);
    this.onClickUploadLink = this.onClickUploadLink.bind(this);
    this.onCloseDialog = this.onCloseDialog.bind(this);
    this.tabButtonClassDocuments = this.tabButtonClassDocuments.bind(this);
    this.onClickDocumentTab = this.onClickDocumentTab.bind(this);
    this.onClickMessagesTab = this.onClickMessagesTab.bind(this);
    this.showMore = this.showMore.bind(this);
    this.onSearchTextChange = this.onSearchTextChange.bind(this);
    this.onDownloadFailClick = this.onDownloadFailClick.bind(this);
    this.onReprocessClick = this.onReprocessClick.bind(this);
    this.routeTo = this.routeTo.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);
  }

  componentDidMount() {
    UploadTrackerStore.addChangeListener(this.onUploadUpdate);
    LicenseStore.addChangeListener(this.onLicenseUpdate);
    window.onresize = this.onWindowResize;
    //window.addEventListener(this.onWindowResize);
    GetActiveLicense();
    GetUploads();
    document.title = 'TrinDocs - Upload Tracker';
  }

  onWindowResize(e) {
    let bh = window.innerHeight - 400;
    this.setState({bottomHeight: bh});
  }

  componentWillUnmount() {
    LicenseStore.removeChangeListener(this.onLicenseUpdate);
    UploadTrackerStore.removeChangeListener(this.onUploadUpdate);
    window.removeEventListener('resize', this.onWindowResize);

  }

  onLicenseUpdate() {
    let lic = LicenseStore.getCurrentLicense();

    let canUpload = false;
    if (
      lic.hasLicense === true &&
      lic.isValid === true &&
      lic.isExpired === false
    ) {
      canUpload = true;
    }

    this.setState({ validLicense: canUpload });
  }

  onUploadUpdate() {
    let lastAction = UploadTrackerStore.getLastAction();
    if (lastAction === 'bulk') {
      let uploads = UploadTrackerStore.getCurrentUploads();
      this.setState({ currentUploads: uploads });
    } else if (lastAction === 'detail') {
      let detail = UploadTrackerStore.getCurrentUploadInfo();
      console.log(detail);
      let tracker = UploadTrackerStore.getCurrentTracker();
      let ocrInfo = UploadTrackerStore.getCurrentOCRInfo();
      this.setState({ currentInfo: detail, currentTracker: tracker, currentOCRInfo: ocrInfo });
    } else if (lastAction === 'reprocess') {
      this.setState({ showDialog: false });
      GetOneUpload(this.state.currentTracker.uploadId);
    }
  }

  onUploadOneClick(uploadId) {
    GetOneUpload(uploadId);
  }

  onAbortClick(uploadId) {
    AbortUpload(uploadId);
  }

  onQuerySearch() {
    let q = new UploadQuery();
    q.source = this.state.sourceFilter;
    q.status = this.state.statusFilter;
    q.numResult = 50;
    q.searchText = this.state.searchText;

    this.setState({ useQuery: true });
    QueryUpload(q);
  }

  onQuerySearchMoreUploads(maxId) {
    let q = new UploadQueryGetMore();
    q.source = this.state.sourceFilter;
    q.status = this.state.statusFilter;
    q.numResult = 50;
    q.searchText = this.state.searchText;
    q.maxId = maxId;

    this.setState({ useQuery: true });
    QueryUploadGetMore(q);
  }

  showMore() {
    if (this.state.currentUploads.length > 0) {
      let maxId = Math.min.apply(
        Math,
        this.state.currentUploads.map((o) => o.uploadId)
      );
      let useQuery = this.state.useQuery;
      if (useQuery) {
        this.onQuerySearchMoreUploads(maxId);
      } else {
        GetMoreUploads(maxId);
      }
    }
  }

  onRefresh() {
    let useQuery = this.state.useQuery;
    if (useQuery) {
      this.onQuerySearch();
    } else {
      GetUploads();
    }
  }

  onReprocessClick() {
    let uploadId = this.state.currentTracker.uploadId;
    ReprocessDoc(uploadId);
  }

  onChangeSourceFilter(e) {
    this.setState({ sourceFilter: e.target.value });
  }

  onSearchTextChange(e) {
    this.setState({ searchText: e.target.value });
  }

  RouteToDoc(e, docId) {
    if (e.ctrlKey) {
      window.open('/Document/' + docId.toString());
    } else {
      this.routeTo('/Document/' + docId.toString());
    }
  }

  routeTo(path) {
    this.setState({ linkPath: path }, () => {
      var link = document.getElementById('nextDocLink');
      console.log(this.state.linkPath);
      link.click();
    });
  }

  onChangeStatusFilter(e) {
    this.setState({ statusFilter: e.target.value });
  }

  onClickUploadLink(id) {
    GetUploadDetail(id);
    this.setState({ showDialog: true });
  }

  onClickDocumentTab() {
    this.setState({ currentTab: 'Documents' });
  }

  onClickMessagesTab() {
    this.setState({ currentTab: 'Messages' });
  }

  onCloseDialog() {
    this.setState({ showDialog: false });
  }

  onDownloadFailClick() {
    DownloadFail(
      this.state.currentTracker.uploadId,
      this.state.currentTracker.originalName
    );
  }

  onTimerClick() {}

  render() {
    return (
      <div
        className='d-flex flex-column h-100'
        style={{ backgroundColor: '#efefef', width: '100%' }}
      >
        <div>
          {!this.state.validLicense && (
            <div
              className='alert alert-warning'
              role='alert'
              style={{ padding: '18px' }}
            >
              <FontAwesomeIcon icon={solid('exclamation-triangle')} /> Your
              license is currently expired. License are required for intake
            </div>
          )}
          <div
            className='card'
            style={{
              marginTop: '20px',
              marginLeft: '30px',
              marginRight: '30px',
              maxWidth: '500px',
            }}
          >
            <div className='card-header'>
              <h4>Upload Search</h4>
            </div>
            <div className='card-body'>
              <div className='row' style={{ marginBottom: '8px' }}>
                <div className='col'>
                  <label className='form-label'>Source</label>
                  <select
                    className='form-select'
                    value={this.state.sourceFilter}
                    onChange={this.onChangeSourceFilter}
                  >
                    <option value='ALL'>All</option>
                    <option value='IA'>InboundAutomation</option>
                    <option value='WEB'>Web Upload</option>
                    <option value='DIR'>File Watch</option>
                    <option value='FEED'>Direct feed</option>
                    <option value='MOBILE'>Mobile</option>
                    <option value='FILLABLE'>Fillable Form</option>
                  </select>
                </div>
                <div className='col'>
                  <label className='form-label'>Status</label>
                  <select
                    className='form-select'
                    value={this.state.statusFilter}
                    onChange={this.onChangeStatusFilter}
                  >
                    <option value='ALL'>All</option>
                    <option value='IMP'>Importing</option>
                    <option value='REG'>Registered</option>
                    <option value='REJ'>Rejected</option>
                    <option value='FAIL'>Fail</option>
                  </select>
                </div>
              </div>
              <div className='row' style={{ marginBottom: '8px' }}>
                <div className='col'>
                  <label className='form-label'>Search for:</label>
                  <input
                    type='text'
                    className='form-control'
                    id='searchTexttxt'
                    value={this.state.searchText}
                    style={{ width: '300px' }}
                    onChange={this.onSearchTextChange}
                  />
                </div>
              </div>
              <div className='clearfix'>
                <button
                  type='button'
                  className='btn btn-primary btn-lg float-start'
                  onClick={this.onQuerySearch}
                >
                  Search
                </button>
                <button
                  type='button'
                  className='btn btn-info float-end'
                  style={{ marginLeft: '6px' }}
                  onClick={this.onRefresh}
                >
                  <FontAwesomeIcon icon={solid('sync')} /> Refresh
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='flex-fill' style={{ margin: '8px' }}>
          <div
            className='card'
            style={{
              marginTop: '20px',
              marginLeft: '30px',
              marginRight: '30px',
              maxHeight: `${this.state.bottomHeight}px`,
            }}
          >
            <div className='card-header'>
              <h4>Uploads</h4>
            </div>
            <div
              className='card-body'
              style={{
                overflowY: 'auto',
                overflow: 'scroll',
                maxHeight: '20%',
              }}
            >
              <div>
                <table className='tdGrid'>
                  <tbody>
                    <tr>
                      <th>Id</th>
                      <th>Source</th>
                      <th>Original Name</th>
                      <th>Upload Time</th>
                      <th>Status</th>
                      <th>Intake Step</th>
                      <th>Page Count</th>
                      <th style={{ textAlign: 'center' }}>
                        <FontAwesomeIcon icon={solid('ellipsis-h')} />
                      </th>
                    </tr>
                    {this.state.currentUploads !== undefined &&
                      this.state.currentUploads.map((upload) => (
                        <tr
                          key={upload.uploadId}
                          style={{ borderTop: '1pt solid #A1A1A1' }}
                        >
                          <td>
                            <span
                              className='link'
                              onClick={() =>
                                this.onClickUploadLink(upload.uploadId)
                              }
                            >
                              {upload.uploadId}
                            </span>{' '}
                          </td>
                          {upload.source === 'InboundAutomation' && (
                            <td>
                              <FontAwesomeIcon
                                icon={regular('envelope-open')}
                              />{' '}
                              InboundAutomation
                            </td>
                          )}
                          {upload.source === 'Fillable' && (
                            <td>
                              <FontAwesomeIcon icon={regular('edit')} />{' '}
                              Fillable
                            </td>
                          )}
                          {upload.source === 'Mobile' && (
                            <td>
                              <FontAwesomeIcon
                                icon={solid('mobile-screen-button')}
                              />{' '}
                              Mobile
                            </td>
                          )}
                          {upload.source === 'WebUpload' && (
                            <td>
                              <FontAwesomeIcon icon={solid('upload')} /> Web
                              Upload
                            </td>
                          )}
                          {upload.source === 'FileWatch' && (
                            <td>
                              <FontAwesomeIcon icon={regular('folder-open')} />{' '}
                              File Watch
                            </td>
                          )}
                          {upload.source === 'NonOCRUpload' && (
                            <td>
                              <FontAwesomeIcon
                                icon={solid('angle-double-right')}
                              />{' '}
                              Direct Feed
                            </td>
                          )}
                          {upload.source === 'FillableForm' && (
                            <td>
                              <FontAwesomeIcon icon={regular('edit')} />{' '}
                              Fillable Form
                            </td>
                          )}
                          <td>
                            <FontAwesomeIcon icon={regular('file-pdf')} />{' '}
                            {upload.originalName}
                          </td>
                          <td>{this.convertDate(upload.uploadTime)}</td>
                          {upload.status === 'Importing' && (
                            <td style={{ color: '#28A7B1' }}>
                              <FontAwesomeIcon icon={solid('spinner')} spin />{' '}
                              Importing
                            </td>
                          )}
                          {upload.status === 'Registered' && (
                            <td style={{ color: '#28B156' }}>
                              <FontAwesomeIcon icon={solid('file-alt')} />{' '}
                              Registered
                            </td>
                          )}
                          {upload.status === 'Rejected' && (
                            <td style={{ color: '#B12828' }}>
                              <FontAwesomeIcon icon={solid('copy')} /> Rejected
                              (
                              <span
                                className='link'
                                onClick={(e) =>
                                  this.RouteToDoc(e, upload.rejectId)
                                }
                                // onClick={() =>
                                //   this.onClickUploadLink(upload.rejectId)

                                // }
                              >
                                {upload.rejectId}
                              </span>
                              )
                            </td>
                          )}
                          {upload.status === 'Fail' && (
                            <td
                              style={{
                                color: '#B12828',
                                fontWeight: 700,
                              }}
                            >
                              <FontAwesomeIcon icon={solid('times')} /> Failed
                            </td>
                          )}
                          <td>{upload.statusDetail}</td>
                          {upload.pageCount === 0 && <td>unknown</td>}
                          {upload.pageCount > 0 && <td>{upload.pageCount}</td>}
                          <td>
                            {upload.status === 'Importing' && (
                              <div>
                                <button
                                  type='button'
                                  className='btn btn-info btn-sm'
                                  title='Refresh'
                                  onClick={() =>
                                    this.onUploadOneClick(upload.uploadId)
                                  }
                                >
                                  <FontAwesomeIcon icon={solid('sync')} />{' '}
                                </button>
                                <button
                                  type='button'
                                  className='btn btn-danger btn-sm'
                                  title='Abort'
                                  style={{ marginLeft: '3px' }}
                                  onClick={() =>
                                    this.onAbortClick(upload.uploadId)
                                  }
                                >
                                  <FontAwesomeIcon icon={solid('stop')} />
                                </button>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}

                    <tr>
                      <td
                        colSpan={8}
                        style={{
                          textAlign: 'center',
                          background: '#DCE9F7',
                        }}
                      >
                        {' '}
                        <span className='link' onClick={this.showMore}>
                          Show More
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {this.state.showDialog && this.state.currentInfo !== undefined && (
          <div className='td-dialogue-container'>
            <div className='td-dialogue card' style={{width: '800px'}}>
              <div className='card-header'>Upload Information
              <button
                  type='button'
                  style={{ float: 'right' }}
                  className='btn-close'
                  onClick={this.onCloseDialog}
                />
              </div>
              <div className='card-body'>
                <div className='row' style={{ marginBottom: '5px' }}>
                  <div className='col-8'>
                    <span className='td-dialogue-label'>Upload Time:</span>{' '}
                    {this.convertDate(this.state.currentTracker.uploadTime)}
                  </div>
                  <div className='col-4'>
                  <span className='td-dialogue-label'>Page Count: </span>{this.state.currentTracker.pageCount}
                  </div>

                </div>
                <div className='row' style={{ marginBottom: '5px' }}>
                  <div className='col-4'>
                    <span className='td-dialogue-label'>Status: </span>{this.state.currentTracker.status}
                  </div>
                  <div className='col-4'>
                    <span className='td-dialogue-label'>Documents: </span>{this.state.currentInfo.docs.length}
                  </div>
                  <div className='col-4'>
                    <span className='td-dialogue-label'>OCR Quality: </span>{this.state.currentOCRInfo.ocrQuality}
                  </div>
                </div>
                <div className='row' style={{ marginBottom: '5px' }}>
                  <div className='col-4'>
                    <span className='td-dialogue-label'>Intake Strategy: </span>{this.state.currentOCRInfo.intakeStrategy}
                  </div>
                  <div className='col-4'>
                    <span className='td-dialogue-label'>Barcodes: </span>{this.state.currentOCRInfo.barcodes}
                  </div>
                  <div className='col-4'>
                    <span className='td-dialogue-label'>OCR Type: </span>{this.state.currentOCRInfo.ocrType}
                  </div>
                </div>
                {this.state.currentTracker.status === 'Fail' && (
                  <div style={{ marginBottom: '5px' }}>
                    <button
                      className='btn btn-warning'
                      style={{ marginRight: '4px' }}
                      onClick={this.onDownloadFailClick}
                    >
                      <FontAwesomeIcon icon={solid('download')} /> - Download
                      original file
                    </button>
                    <button
                      className='btn btn-success'
                      onClick={this.onReprocessClick}
                    >
                      <FontAwesomeIcon icon={solid('redo')} /> - Reprocess file
                    </button>
                  </div>
                )}
                <div className='row' style={{ marginBottom: '5px' }}>
                  <div className='col-6'>
                    {this.state.currentInfo.hasError && (
                      <span style={{ color: 'red' }}>Has Errors</span>
                    )}
                  </div>
                </div>
                <hr />
                <ul className='nav nav-pills' style={{ marginBottom: '6px' }}>
                  <li
                    className='nav-item primary'
                    style={{ cursor: 'pointer' }}
                    onClick={this.onClickDocumentTab}
                  >
                    <div className={this.tabButtonClassDocuments('Documents')}>
                      Documents
                    </div>
                  </li>
                  <li
                    className='nav-item'
                    style={{ cursor: 'pointer' }}
                    onClick={this.onClickMessagesTab}
                  >
                    <div className={this.tabButtonClassDocuments('Messages')}>
                      Messages
                    </div>
                  </li>
                </ul>
                {this.state.currentTab === 'Documents' && (
                  <div style={{ maxHeight: '200px', overflow: 'auto' }}>
                    <table className='table'>
                      <thead>
                        <tr className='table-dark'>
                          <th scope='col'>Doc Id:</th>
                          <th scope='col'>Pages</th>
                          <th scope='col'>Split</th>
                          <th scope='col'>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.currentInfo.docs.map((doc) => (
                          <tr key={doc.uploadGuid}>
                            <td>
                              <span
                                className='link'
                                onClick={(e) =>
                                  this.RouteToDoc(e, doc.documentId)
                                }
                              >
                                {doc.documentId}
                              </span>
                            </td>
                            <td>{doc.pageCount}</td>
                            <td>{doc.pageRange}</td>
                            <td>{doc.documentStatus}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                {this.state.currentTab === 'Messages' && (
                  <div style={{ maxHeight: '200px', overflow: 'auto' }}>
                    <table className='table'>
                      <thead>
                        <tr className='table-dark'>
                          <th scope='col'>Date/Time:</th>
                          <th scope='col'>Message</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.currentInfo.messages.map((msg) => (
                          <tr>
                            <td>{this.convertDate(msg.messageDtime)}</td>
                            <td style={{ wordWrap: 'break-word' }}>
                              {msg.message}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                <button
                  className='btn btn-secondary'
                  style={{ marginRight: '4px' }}
                  onClick={this.onCloseDialog}
                >
                  <FontAwesomeIcon icon={solid('times')} /> Close
                </button>
                <button className='btn btn-info'>Refresh</button>
              </div>
            </div>
          </div>
        )}
        <Link
          id='nextDocLink'
          to={this.state.linkPath}
          style={{ display: 'none' }}
        >
          HiddenLink
        </Link>
      </div>
    );
  }

  convertDate(strDate) {
    let strDate2 = strDate.toString();
    if (!strDate2.endsWith('Z')) {
      strDate2 = strDate2 + 'Z';
    }
    let date = new Date(strDate2);
    let d1 = date.getDate();
    let day = d1.toString();
    let m1 = date.getMonth();
    m1 = m1 + 1;
    let mon = m1.toString();
    let year = date.getFullYear().toString();
    let h1 = date.getHours();

    let mer = 'AM';
    if (h1 >= 12) mer = 'PM';
    if (h1 > 12) {
      h1 = h1 - 12;
    }
    if (h1 === 0) h1 = 12;

    let h = h1.toString();
    let m = date.getUTCMinutes().toString();
    if (m.length === 1) {
      m = '0' + m;
    }

    let fulldtime =
      mon + '/' + day + '/' + year + ' ' + h + ':' + m + ' ' + mer;
    return fulldtime;
  }

  tabButtonClassDocuments(tab) {
    if (this.state.currentTab === tab) {
      return 'nav-link active';
    } else {
      return 'nav-link';
    }
  }
}

export default UploadTrackerView;
