import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Dropdown } from 'react-bootstrap';
import { ChangePassword } from '../../../actions/UserActions';
import UserStore from '../../../stores/UserStore';
import actionTypes from '../../../actions/ActionTypes';
import {
  GetSettingsForUser,
  GetCurrentUser,
  SaveUserSettings,
} from '../../../actions/UserSettingActions';
import UserSettingStore from '../../../stores/UserSettingStore';

/*import { UpdateName } from '../../../Actions/BPActions';
import ISStore from '../../../Stores/IntakeStrategyStore';
import { IntakeStrategyModel } from '../../../Models/Admin/IntakeStrategy';*/
/*import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
   faCog,
   faSitemap,
   faFileInvoice,
} from '@fortawesome/free-solid-svg-icons';*/
class UserProfileView extends React.Component {
  constructor(props) {
    // state
    super(props);

    this.state = {
      currentUser: {
        userId: 1,
        userName: 'system',
        isPermanant: true,
        licenseLevel: 0,
        emailAddress: 'trindocs@trindocs.com',
        //licenseLevelName: 'System Admin',
        //licenseStyle: 'llbtn-sysadmin',
        //licenseLevelName: 'System Manager',
        //licenseStyle: 'llbtn-busadmin',
        firstName: 'system',
        lastName: 'acct',
        isActive: true,
        isADuser: false,
        //isNew: false,\
        data: null,
        //roles: [],
      },
      currentView: 'roles',
      password1: '',
      password2: '',
      passwordError: '',
      isDirty: false,
      licenseSummary: undefined,
      hasError: false,
      errorMessage: '',
      currentFontSize: '0',
      currentFontSizeName: 'Large',
      quickSearchPriorityValue: 0,
      quickSearchPriorityText: 'Values -> Document Id',
      firstName: '',
      lastName: '',
      email: '',
      sendEmailNotifications: false,
      currentUserLicenseLevel: 0,
      defaultHome: 'Active Documents',
    };

    this.componentDidUpdate = this.componentDidUpdate.bind(this);
    this.setQuickSearchPriority = this.setQuickSearchPriority.bind(this);
    this.loadUserFromDB = this.loadUserFromDB.bind(this);
    this.onUserUpdate = this.onUserUpdate.bind(this);
    this.onChangePassword1 = this.onChangePassword1.bind(this);
    this.onChangePassword2 = this.onChangePassword2.bind(this);
    this.onChangePasswordClick = this.onChangePasswordClick.bind(this);
    this.onChangeFirstName = this.onChangeFirstName.bind(this);
    this.onChangeLastName = this.onChangeLastName.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.onUserSettingChange = this.onUserSettingChange.bind(this);
    this.setDefaultHome = this.setDefaultHome.bind(this);
    // this.sendEmailNotificationsChange =
    //   this.sendEmailNotificationsChange.bind(this);
  }

  componentDidMount() {
    UserStore.addChangeListener(this.onUserUpdate);
    UserSettingStore.addChangeListener(this.onUserSettingChange);
    this.loadUserFromDB();
    GetCurrentUser();
    document.title = 'TrinDocs - User Profile'
  }

  componentWillUnmount() {
    UserStore.removeChangeListener(this.onUserUpdate);
    UserSettingStore.removeChangeListener(this.onUserSettingChange);
  }

  componentDidUpdate() {
    //this.loadUserFromDB();
  }

  onUserUpdate() {
    let lastChange = UserStore.getLastChange();
    if (lastChange === actionTypes.UsersGetOne) {
      let newUser = UserStore.getCurrentUser();
      console.log(newUser);
      if (newUser.licenseLevel === 4 || newUser.licenseLevel === 5) {
        newUser.licenseLevelName = 'System Manager';
        newUser.licenseStyle = 'llbtn-sysadmin';
      }
      if (newUser.licenseLevel === 5) {
        newUser.isPermanant = true;
      } else {
        newUser.isPermanant = false;
      }
      if (newUser.licenseLevel === 3) {
        newUser.licenseLevelName = 'Admin';
        newUser.licenseStyle = 'llbtn-busadmin';
      }
      if (newUser.licenseLevel === 2) {
        newUser.licenseLevelName = 'Approver';
        newUser.licenseStyle = 'llbtn-approver';
      }
      if (newUser.licenseLevel === 1) {
        newUser.licenseLevelName = 'Viewer';
        newUser.licenseStyle = 'llbtn-viewer';
      }

      this.setState({
        currentUser: newUser,
        initialLicenseLevel: newUser.licenseLevelName,
        hasError: false,
        isDirty: false,
      });
    }

    if (lastChange === actionTypes.UserChangePassword) {
      this.setState({ password1: '', password2: '' });
    }

    if (lastChange === 'UserUpdateError') {
      let err = UserStore.getLastError();
      this.setState({ hasError: true, errorMessage: err });
    }
  }

  onUserSettingChange() {
    let action = UserSettingStore.getLastChange();
    if (action === actionTypes.GetCurrentUser) {
      let user = UserSettingStore.getCurrentUser();
      console.log(user);
      this.setState({
        currentUser: user,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.emailAddress,
        currentUserLicenseLevel: user.licenseLevel,
        isDirty: false,
      });
    }

    if (action === actionTypes.GetUserSettings) {
      let userSettings = UserSettingStore.getUserSettingList();

      for (let i = 0; i < userSettings.length; i++) {
        switch (userSettings[i].name) {
          case 'send_email_notifications':
            if (userSettings[i].value.toLowerCase() === 'true') {
              this.setState({ sendEmailNotifications: true });
            } else {
              this.setState({ sendEmailNotifications: false });
            }
            // code block
            break;
          case 'quick_search_priority':
            let searchText = '';
            let searchValue = '';
            if (userSettings[i].value === '0') {
              searchValue = '0';
              searchText = 'Values -> Document Id';
            }
            if (userSettings[i].value === '1') {
              searchValue = '1';
              searchText = 'Document Id -> Values';
            }
            this.setState({
              quickSearchPriorityText: searchText,
              quickSearchPriorityValue: searchValue,
            });
            // code block
            break;
          case 'font_size_mode':
            let fontSize = '';
            let fontSizeName = '';
            if (userSettings[i].value === '0') {
              fontSize = '0';
              fontSizeName = 'Small';
            }
            if (userSettings[i].value === '1') {
              fontSize = '1';
              fontSizeName = 'Large';
            }
            this.setState({
              currentFontSize: fontSize,
              currentFontSizeName: fontSizeName,
            });
            break;
          case 'default_home':
            let defaultHome = 'Active Documents';
            if(userSettings[i].value === 'Inbox')
            {
              defaultHome = 'Inbox';
            }

            this.setState({
              defaultHome: defaultHome,
            });
            break;
          default:
        }
      }
      this.setState({ isDirty: false });
    }

    if (action === actionTypes.ChangeUserSettings) {
      this.setState({ isDirty: false });
    }
  }

  setQuickSearchPriority(priority) {
    let priorityText = '';
    let priorityValue = '';
    if (priority === 0) {
      priorityValue = '0';
      priorityText = 'Values -> Document Id';
    } else {
      priorityValue = '1';
      priorityText = 'Document Id -> Values';
    }

    this.setState({
      quickSearchPriorityValue: priorityValue,
      quickSearchPriorityText: priorityText,
      isDirty: true,
    });
  }

  setFontSize(size) {
    let fontSize = '';
    let fontSizeName = '';
    if (size === 0) {
      fontSize = '0';
      fontSizeName = 'Small';
    } else {
      fontSize = '1';
      fontSizeName = 'Large';
    }

    this.setState({
      currentFontSize: fontSize,
      currentFontSizeName: fontSizeName,
      isDirty: true,
    });
  }

  loadUserFromDB() {
    let loc = window.location.pathname;
    let pathParts = loc.split('/');
    let userId = parseInt(pathParts[pathParts.length - 1], 10);
    if (this.state.currentUser.userId !== 0 && userId === 0) {
      let newUser = {
        userId: 0,
        userName: '',
        isPermanant: false,
        licenseLevel: 1,
        emailAddress: '',

        licenseLevelName: 'Viewer',
        licenseStyle: 'llbtn-viewer',
        firstName: '',
        lastName: '',
        isActive: true,
        isNew: true,
        isADuser: false,

        roles: [],
      };

      this.setState({
        currentUser: newUser,
        initialLicenseLevel: newUser.licenseLevelName,
        isDirty: true,
      });
    } else if (this.state.currentUser.userId !== userId) {
      let user = this.state.currentUser;
      user.userId = userId;
      this.setState({ currentUser: user, isDirty: false });
      GetSettingsForUser(userId);
    }
  }

  onChangeFirstName(e) {
    this.setState({ firstName: e.target.value, isDirty: true });
  }

  onChangeLastName(e) {
    this.setState({ lastName: e.target.value, isDirty: true });
  }

  switchView(newView) {
    this.setState({ currentView: newView });
  }

  onChangePassword1(e) {
    this.setState({ password1: e.target.value });
  }

  onChangePassword2(e) {
    this.setState({ password2: e.target.value });
  }

  onSaveClick() {
    console.log(this.state.defaultHome);
    SaveUserSettings(
      this.state.firstName,
      this.state.lastName,
      this.state.currentFontSize,
      this.state.quickSearchPriorityValue.toString(),
      this.state.sendEmailNotifications,
      this.state.defaultHome,
    );
  }

  // sendEmailNotificationsChange(e) {
  //   this.setState({ sendEmailNotifications: e.target.checked, isDirty: true });
  // }

  onChangePasswordClick() {
    if (this.state.password1.length === 0) {
      this.setState({ passwordError: 'Password is required' });
    } else if (this.state.password1.length <= 4) {
      this.setState({
        passwordError: 'Password must be greater than four characters',
      });
    } else if (this.state.password1 !== this.state.password2) {
      this.setState({ passwordError: "Passwords don't match" });
    } else {
      this.setState({ passwordError: '' });
      ChangePassword(this.state.currentUser.userId, this.state.password2);
    }
  }

  setDefaultHome(homeValue)
  {
    console.log('setDefaultHome');
    console.log(homeValue);
    this.setState({defaultHome: homeValue, isDirty: true});
  }

  render() {
    return (
      <div className='d-flex flex-row flex-fill'>
        <div className='p-4'>
          <div id='userInfo' className='card'>
            <div className='card-header'>
              <FontAwesomeIcon icon={solid('user')} /> User Profile
            </div>
            <div className='card-body'>
              <div className='row' style={{ marginTop: '8px' }}>
                <div className='form-group col-6'>
                  <label htmlFor='txtFirstName'>First Name</label>
                  <input
                    type='text'
                    className='form-control'
                    id='txtFirstName'
                    value={this.state.firstName}
                    onChange={this.onChangeFirstName}
                    placeholder='first name'
                  />
                </div>
                <div className='form-group col-6'>
                  <label htmlFor='txtLastName'>Last Name</label>
                  <input
                    type='text'
                    className='form-control'
                    id='txtLastName'
                    value={this.state.lastName}
                    onChange={this.onChangeLastName}
                    placeholder='last name'
                  />
                </div>
              </div>
              <div className='row' style={{ marginTop: '16px' }}>
                <div className='form-group col-6'>
                  <label htmlFor='txtEmail'>Email Address</label>
                  <input
                    type='text'
                    className='form-control'
                    id='txtEmail'
                    readOnly
                    value={this.state.currentUser.emailAddress}
                    onChange={this.onChangeEmail}
                    placeholder='last name'
                  />
                </div>
                {/* <div className='col-6'>
                  <div className='form-check form-switch'>
                    <input
                      type='checkbox'
                      className='form-check-input'
                      checked={this.state.sendEmailNotifications}
                      onChange={this.sendEmailNotificationsChange}
                      id='chkActive'
                    />
                  </div>
                  </div> */}
              </div>

              <div className='row' style={{ marginTop: '16px' }}>
                <div className='col-4'>
                  <label htmlFor='txtUserName'>Font Size</label>
                  <Dropdown>
                    <Dropdown.Toggle
                      id='ddLicenseLevel'
                      //   className={
                      //     this.state.currentUser.isPermanant
                      //       ? 'disabled btn-danger'
                      //       : this.state.currentUser.licenseStyle
                      //   }
                    >
                      {this.state.currentFontSizeName}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        href=''
                        onClick={() => this.setFontSize(0)}
                      >
                        Small
                      </Dropdown.Item>
                      <Dropdown.Item
                        href=''
                        onClick={() => this.setFontSize(1)}
                      >
                        Large
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className='col-6'>
                  <label htmlFor='txtUserName'>Quick Search Priority</label>
                  <Dropdown>
                    <Dropdown.Toggle
                      id='ddLicenseLevel'
                      //   className={
                      //     this.state.currentUser.isPermanant
                      //       ? 'disabled btn-danger'
                      //       : this.state.currentUser.licenseStyle
                      //   }
                    >
                      {this.state.quickSearchPriorityText}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        href=''
                        onClick={() => this.setQuickSearchPriority(0)}
                      >
                        {'Values -> Document Id'}
                      </Dropdown.Item>
                      <Dropdown.Item
                        href=''
                        onClick={() => this.setQuickSearchPriority(1)}
                      >
                        {'Document Id -> Values'}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div style={{marginTop: '8px'}}>
              <label>Default Home Page</label>
                  <Dropdown >
                    <Dropdown.Toggle
                      id='dddefaultHome'
                      className='btn-success'
                    >
                      {this.state.defaultHome}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        href=''
                        onClick={() => this.setDefaultHome('Inbox')}
                      >
                        {'Inbox'}
                      </Dropdown.Item>
                      <Dropdown.Item
                        href=''
                        onClick={() => this.setDefaultHome('Active Documents')}
                      >
                        {'Active Documents'}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
              </div>
            </div>
          </div>
          <div>
            {this.state.hasError && (
              <div className='alert alert-danger' style={{ marginTop: '16px' }}>
                {this.state.errorMessage}
              </div>
            )}
          </div>

          <div id='userPills' className='mt-3'>
            <ul className='nav nav-pills'>
              {this.state.currentUserLicenseLevel < 5 && (
                <li className='nav-item'>
                  <span
                    className={`nav-link ${
                      this.state.currentView === 'password' ? 'active' : ''
                    }`}
                    style={{ cursor: 'pointer' }}
                    onClick={() => this.switchView('password')}
                  >
                    Change Password
                  </span>
                </li>
              )}

              {this.state.isDirty && (
                <li className='nav_item'>
                  <button
                    className='btn btn-success'
                    onClick={this.onSaveClick}
                    style={{ marginLeft: '30px' }}
                    disabled={this.state.invalidLicenseChange}
                  >
                    <FontAwesomeIcon icon={solid('save')} /> Save Changes
                  </button>
                </li>
              )}
            </ul>
          </div>

          {this.state.currentView === 'password' && (
            <div
              id='Aliases'
              className='card'
              style={{
                gridColumnStart: '1',
                gridColumnEnd: '3',
                gridRow: '3 / 3',
                margin: '16px',
              }}
            >
              <div className='card-header'>Change Password</div>
              <div className='card-body'>
                <div className='row'>
                  <div className='form-group col-6'>
                    <label htmlFor='pwdPassword1'>New Password</label>
                    <input
                      type='password'
                      value={this.state.password1}
                      onChange={this.onChangePassword1}
                      className='form-control'
                      id='pwdPassword1'
                      placeholder='Password'
                    />
                  </div>
                </div>
                <div className='row' style={{ marginTop: '20px' }}>
                  <div className='form-group col-6'>
                    <label htmlFor='pwdPassword2'>Confirm New Password</label>
                    <input
                      type='password'
                      value={this.state.password2}
                      onChange={this.onChangePassword2}
                      className='form-control'
                      id='pwdPassword2'
                      placeholder='Password'
                    />
                    {this.state.passwordError.length > 0 && (
                      <div
                        style={{ marginTop: '10px' }}
                        className='alert alert-danger'
                        role='alert'
                      >
                        {this.state.passwordError}
                      </div>
                    )}
                  </div>
                </div>

                <button
                  onClick={this.onChangePasswordClick}
                  style={{ marginTop: '20px' }}
                  className='btn btn-primary'
                >
                  Change Password
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default UserProfileView;
