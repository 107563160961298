import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';

const _compare = require('lodash');
const CHANGE_EVENT = 'documentChange';
let _currentDocument = undefined;
let _lastChange = '';
let _lastError = '';
let _images = [];
let _auditInfo = undefined;
let _templates = [];
let _extenderResults = [];
let _extenderRowResults = [];
let _lastRowId = 0;
let _lastExtUpdate = 0;
let _lstExtRowUpdate = 0;
let _lstFieldSetId = 0;
let _newNote = undefined;
let _pullData = undefined;
let _attachments = [];
let _moveAssignUsers = [];
let _docStatus = undefined;
let _assignees = [];
let _zoomSetting = 100;
let _submitResult = undefined;
let _relatedDocs = [];
let _independentRowExtenders = [];
let _extenderCache = [];
let _lastKey = '';

class DocumentStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getCurrentDocuent() {
    return _currentDocument;
  }

  getImages() {
    return _images;
  }

  lastChange() {
    return _lastChange;
  }

  getLastError() {
    return _lastError;
  }

  getAuditInfo() {
    return _auditInfo;
  }

  getAvailTemplates() {
    return _templates;
  }

  getExtender(extId) {
    let e = _extenderResults.find((ex) => ex.extenderId === extId);
    return e;
  }

  getLastExtenderId() {
    return _lastExtUpdate;
  }

  getLastRowExtenderId() {
    return _lstExtRowUpdate;
  }

  getLastRowId() {
    return _lastRowId;
  }

  getLastKey() {
    return _lastKey;
  }

  getRowExtender(extId, lookupKey) {
    let e = _extenderRowResults.find((ex) => ex.extenderId === extId);
    if(e !== undefined)
    {
      let key = e.keys.find((k) => k.key === lookupKey);
      return key;
    }
    return undefined;
  }

  getNote() {
    return _newNote;
  }

  getPullData() {
    return _pullData;
  }

  getAttachments() {
    return _attachments;
  }

  getMoveAssignUsers() {
    return _moveAssignUsers;
  }

  getLastFieldSet() {
    return _lstFieldSetId;
  }

  getDocStatus() {
    return _docStatus;
  }

  getAssignees() {
    return _assignees;
  }

  getZoomSetting() {
    return _zoomSetting;
  }

  getSubmitResult() {
    return _submitResult;
  }

  getIndependentRowExtenders() {
    return _independentRowExtenders;
  }
  getRelatedDocs() {
    return _relatedDocs;
  }

  getExtenderCache(){
    return _extenderCache;
  }

  getExtenderFromCache(data) {
    let e = _extenderCache.filter(obj => obj.cache.dataSourceValue === data.cache.dataSourceValue
                                      && obj.cache.extenderId === data.cache.extenderId
                                      && _compare.isEqual(obj.cache.parentFields, data.cache.parentFields));
    return e;
  }

  getParentIdFromCache(data) {
    // parent id is unique for extender
    let e = _extenderCache.filter(obj => obj.cache.extenderId === data.cache.extenderId);
    return e;
  }
}



const store = new DocumentStore();

Dispatcher.register((action) => {
  _lastChange = action.actionType;
  switch (action.actionType) {
    // loading the full list of Companion schemas
    case actionTypes.DocumentLoad:
      _lastChange = 'LoadDoc';
      _currentDocument = action.Result;
      store.emitChange();
      break;
    case actionTypes.ImageLoad:
      _lastChange = action.actionType;
      _images = action.Result;
      store.emitChange();
      break;
    case actionTypes.ImageFetch:
      _lastChange = action.actionType;
      var newImg = action.Result;
      var img = _images.find((i) => i.pageId === newImg.pageId);
      if (img) {
        img.imageBytes = newImg.imageBytes;
        img.imageWidth = newImg.imageWidth;
        img.imageHeight = newImg.imageHeight;
      }
      store.emitChange();
      break;
    case actionTypes.DocumentSave:
      _lastChange = action.actionType;
      store.emitChange();
      break;
    case actionTypes.DocumentSubmit:
      _lastChange = action.actionType;
      _submitResult = action.Result;
      store.emitChange();
      break;
    case actionTypes.DocumentEndOfList:
      _lastChange = action.actionType;
      store.emitChange();
      break;
    case actionTypes.DocumentGetAudit:
      _lastChange = action.actionType;
      _auditInfo = action.Result;
      store.emitChange();
      break;
    case actionTypes.DocumentGetAvailTemplates:
      _lastChange = action.actionType;
      _templates = action.Result;
      store.emitChange();
      break;
    case actionTypes.DocumentUpdateTemp:
      _lastChange = action.actionType;
      store.emitChange();
      break;
    case actionTypes.DocumentToImageReview:
      _lastChange = action.actionType;
      store.emitChange();
      break;
    case actionTypes.DocumentUpdateMoveTemp:
      _lastChange = action.actionType;
      store.emitChange();
      break;
    case actionTypes.DocumentUpdateTempWDoc:
      _lastChange = action.actionType;
      _currentDocument = action.Result.document;

      store.emitChange();
      break;
    case actionTypes.DocumentChagneQueue:
      _lastChange = action.actionType;
      _currentDocument.queueAssignments = action.Result.assignments;
      _currentDocument.canAddNotes = action.Result.canAddNotes;
      _currentDocument.canApprove = action.Result.canApprove;
      _currentDocument.canDelete = action.Result.canDelete;
      _currentDocument.canDownload = action.Result.canDownload;
      _currentDocument.canEdit = action.Result.canEdit;
      _currentDocument.canImageReview = action.Result.canImageReview;
      _currentDocument.canMoveAssign = action.Result.canMoveAssign;
      _currentDocument.canView = action.Result.canView;
      _currentDocument.canViewConfidential = action.Result.canViewConfidential;
      store.emitChange();
      break;
    case actionTypes.DocumentGetStatusInfo:
      _lastChange = action.actionType;
      _docStatus = action.Result;
      store.emitChange();
      break;
    case actionTypes.DocumentDelete:
      _lastChange = action.actionType;
      store.emitChange();
      break;
    case actionTypes.DocumentAddNote:
      _lastChange = actionTypes.DocumentAddNote;
      _newNote = action.Result;
      store.emitChange();
      break;
    case actionTypes.DocumentExtender:
      _lastExtUpdate = action.Result.extenderId;
      let e = _extenderResults.find(
        (ex) => ex.extenderId === action.Result.extenderId
      );
      if (e) {
        e.defaultValue = action.Result.defaultValue;
        e.allowedValues = [...action.Result.allowedValues];
      } else {
        _extenderResults.push(action.Result);
      }
      store.emitChange();
      break;
    case actionTypes.DocumentAddNote:
      _lastChange = actionTypes.DocumentAddNote;

      store.emitChange();
      break;
    case actionTypes.DocumentExtenderRow:
      _lstExtRowUpdate = action.Result.data.extenderId;
      _lastRowId = action.Result.rowId;
      _lstFieldSetId = action.Result.fieldSetId;
      _lastKey = action.key;
      if(action.key !== undefined) {
        let e2 = _extenderRowResults.find(
          (ex) => ex.extenderId === action.extId
        );
        if (e2) {
          // look for key:
          let extKey = e2.keys.find((k) => k.key === action.key);
          if(extKey) {
            extKey.allowedValues = action.Result.data.allowedValues;
            extKey.defaultValue = action.Result.data.defaultValue;
          }
          else {
            let result = {...action.Result.data};
            let newKey = {
              key: action.key,
              defaultValue: action.Result.data.defaultValue,
              allowedValues: result.allowedValues,
            }
            e2.keys.push(newKey);
          }

          // e2.defaultValue = action.Result.data.defaultValue;
          // e2.allowedValues = [...action.Result.data.allowedValues];
          // e2.exeSource = action.Result.data.exeSource;
        } else {
          let newExt = {
            extenderId: action.extId,

            keys: [],
          }

          let result = {...action.Result.data};
          let newKey = {
            key: action.key,
            defaultValue: action.Result.data.defaultValue,
            allowedValues: result.allowedValues,
          }
          newExt.keys.push(newKey);
          _extenderRowResults.push(newExt);

          //result.key = action.key;
          //result.extId = action.extId;
          //let existing = _extenderRowResults.find(f => f.extenderId === action.Result.extId);

          // if(existing !== undefined)
          // {
          //   let idx = _extenderRowResults.indexOf(existing);
          //   _extenderRowResults.splice(idx, 1);
          // }
          // _extenderRowResults.push(result);

        }

      } else {
      //let currentCache = action.Result.data.cache;

      // let cacheMatches = _extenderCache.filter(obj => obj.cache.dataSourceValue === currentCache.dataSourceValue
      //                                   && obj.cache.extenderId === currentCache.extenderId
      //                                   && _compare.isEqual(obj.cache.parentFields, currentCache.parentFields));



      // if (cacheMatches.length === 0){
      //   // if there are no matches add to cache
      //   _extenderCache.push(JSON.parse(JSON.stringify(action.Result.data)));
      // }
      // else{
      //   // do not add to cache
      // }

        let e2 = _extenderRowResults.find(
          (ex) => ex.extenderId === action.Result.data.extenderId
        );
        if (e2) {
          e2.defaultValue = action.Result.data.defaultValue;
          e2.allowedValues = [...action.Result.data.allowedValues];
          e2.exeSource = action.Result.data.exeSource;
        } else {
          _extenderRowResults.push(action.Result.data);
        }
      }
      store.emitChange();
      break;


    case actionTypes.DocumentFirstExtenderRow:
      _lstExtRowUpdate = action.Result.data.extenderId;
      _lastRowId = action.Result.rowId;
      _lstFieldSetId = action.Result.fieldSetId;
      _lastKey = action.key;

      let indValues = {
        extenderId: action.Result.data.extenderId,
        allowedValues: [...action.Result.data.allowedValues]
      };
      let val = _independentRowExtenders.find((i) => i.extenderId === action.Result.data.extenderId);

      if (val === undefined){
        _independentRowExtenders.push(indValues);
      }

      let e23 = _extenderRowResults.find(
        (ex) => ex.extenderId === action.Result.data.extenderId
      );
      if (e23) {
        e23.defaultValue = action.Result.data.defaultValue;
        e23.allowedValues = [...action.Result.data.allowedValues];
        e23.exeSource = action.Result.data.exeSource;
      } else {
        _extenderRowResults.push(action.Result.data);
      }
      store.emitChange();
      break;

    case actionTypes.DocumentPull:
      _lastChange = actionTypes.DocumentPull;
      _pullData = action.Result;
      store.emitChange();
      break;
    case actionTypes.DocumentUndelete:
      _lastChange = actionTypes.DocumentUndelete;
      _pullData = action.Result;
      store.emitChange();
      break;
    case actionTypes.DocumentDeleteAttachment:
      _lastChange = 'ALTER_ATTCH';
      _attachments = action.Result.attachments;
      store.emitChange();
      break;
    case actionTypes.DocumentUploadAttachment:
      _lastChange = 'ALTER_ATTCH';
      _attachments = action.Result.attachments;
      store.emitChange();
      break;
    case actionTypes.DocumentAssignUsers:
      _lastChange = actionTypes.DocumentAssignUsers;

      _moveAssignUsers = action.Result;
      store.emitChange();
      break;
    case actionTypes.DocumentGetAssignees:
      _lastChange = actionTypes.DocumentGetAssignees;
      _assignees = action.Result;
      store.emitChange();
      break;
    case actionTypes.BulkAction:
      _lastChange = actionTypes.BulkAction;
      store.emitChange();
      break;
    case actionTypes.DocumentZoomSettingUpdate:
      _lastChange = actionTypes.DocumentZoomSettingUpdate;
      store.emitChange();
      break;
    case actionTypes.GetDocumentZoomSetting:
      _lastChange = actionTypes.GetDocumentZoomSetting;
      _zoomSetting = action.Result;
      store.emitChange();
      break;

    case actionTypes.DocumentGetRelatedDocs:
      _lastChange = actionTypes.DocumentGetRelatedDocs;
      _relatedDocs = action.Result;
      store.emitChange();
      break;

    default:
  }
});

export default store;
