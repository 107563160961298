import dispatcher from '../Dispatcher';
import actionTypes from './ActionTypes';


export function RaiseHotKeyEvent(key) {
    dispatcher.dispatch({
        actionType: actionTypes.HotKey,
        hotkey: key
    });
  }


