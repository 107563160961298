import dispatcher from '../Dispatcher';
import actionTypes from './ActionTypes';
import axios from 'axios';
import * as Globals from '../Globals';
import { PostAlert } from './AlertActions';
import { Logout } from './LoginActions';

export function GetTemplateList() {
  axios
    .get(`${Globals.baseUrl}/Template/GetTemplateGroups`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.TemplateList,
          Result: res.data,
        });
      }
    })
    .catch((res) => {
      if (res.response.status === 401) {
        console.log('unauthorized');
        Logout();
      }
    });
}

export function GetTemplate(templateId) {
  console.log('GetTemplate');
  axios
    .get(`${Globals.baseUrl}/Template/GetTemplate/${templateId}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.TempalteGetOne,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      if (res.response.status === 401) {
        console.log('unauthorized');
        Logout();
      }
    });
}

export function GetTemplateHeaderFields(templateId) {
  axios
    .get(`${Globals.baseUrl}/Template/GetTemplateHeaderFields/${templateId}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.TemplateHeaders,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      if (res.response.status === 401) {
        console.log('unauthorized');
        Logout();
      }
    });
}

export function GetTemplateTables(templateId) {
  axios
    .get(`${Globals.baseUrl}/Template/GetTemplateTables/${templateId}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.TemplateTables,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      if (res.response.status === 401) {
        console.log('unauthorized');
        Logout();
      }
    });
}

export function CreateTemplate(templateName, formTypeId, saveAsId) {
  axios
    .post(
      `${Globals.baseUrl}/Template/CreateTemplate`,
      {
        templateName: templateName,
        formTypeId: formTypeId,
        saveAsTemplateId: saveAsId,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        if (res.data.responseType === 'Success') {
          dispatcher.dispatch({
            actionType: actionTypes.TemplateCreateTemplate,
            Result: res.data.data,
          });
          PostAlert('success', 'Template Created');
        } else if (res.data.responseType === 'Error') {
          PostAlert('error', 'Unable to create Template');
          PostAlert('error', res.data.responseMessage);
        }
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      if (res.response.status === 401) {
        console.log('unauthorized');
        Logout();
      }
    });
}

export function GetFormTypesICanEditTemplates() {
  axios
    .get(`${Globals.baseUrl}/Template/GetFormTypesICanEditTemplates`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.TemplateFormTypes,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      if (res.response.status === 401) {
        console.log('unauthorized');
        Logout();
      }
    });
}

export function GetSamples(templateId) {
  axios
    .get(`${Globals.baseUrl}/Template/GetSamples/${templateId}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.TemplateGetSamples,
          Result: res.data,
        });
      }
    })
    .catch((res) => {
      console.log(res);
      if (res.response.status === 401) {
        console.log('unauthorized');
        Logout();
      }
    });
}

export function GetSampleFromDocument(docId) {
  axios
    .get(`${Globals.baseUrl}/Template/GetSampleFromDoc/${docId}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.TemplateGetSampleForDoc,
          Result: res.data,
        });
      }
    })
    .catch((res) => {
      console.log(res);
      if (res.response.status === 401) {
        console.log('unauthorized');
        Logout();
      }
    });
}

export function CreateSample(templateId, drop) {
  let fd = new FormData();
  fd.append('file', drop.drop, drop.drop.name);
  fd.append('templateId', templateId.toString());
  fd.append('pageCount', drop.imageCount.toString());
  fd.append('ocrType', drop.ocrType.toString());
  fd.append('readBC', drop.readBC.toString());
  fd.append('ocrQuality', drop.ocrQuality.toString());
  fd.append('includeLastPage', drop.includeLastPage.toString());
  axios
    .post(`${Globals.baseUrl}/Template/CreateSample`, fd, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        if (res.data.errorMessage !== '') {
          PostAlert('error', res.data.errorMessage);
        }
        dispatcher.dispatch({
          actionType: actionTypes.TemplateCreateSample,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      if (res.response.status === 401) {
        console.log('unauthorized');
        Logout();
      }
    });
}

export function CheckForData(sampleId) {
  axios
    .get(`${Globals.baseUrl}/Template/CheckForData/${sampleId}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        if (res.data) {
          GetSample(sampleId);
        }
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      if (res.response.status === 401) {
        console.log('unauthorized');
        Logout();
      }
    });
}

export function DeleteSample(sampleId) {
  axios
    .post(
      `${Globals.baseUrl}/Template/DeleteSample`,
      { sampleId: sampleId },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.TemplateDeleteSample,
          Result: res.data,
        });
        PostAlert('success', 'Sample deleted');
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      if (res.response.status === 401) {
        console.log('unauthorized');
        Logout();
      }
    });
}

export function GetSample(sampleId) {
  axios
    .get(`${Globals.baseUrl}/Template/GetSample/${sampleId}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.TemplateGetSample,
          Result: res.data,
        });
      }
    })
    .catch((res) => {
      if (res.status === 401) {
        console.log('unauthorized');
        Logout();
      }
    });
}


export function GetDocSample(docId) {
  axios
    .get(`${Globals.baseUrl}/Template/GetSampleFromDoc/${docId}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.TemplateDocSample,
          Result: res.data,
        });
      }
    })
    .catch((res) => {
      if (res.status === 401) {
        console.log('unauthorized');
        Logout();
      }
    });
}

export function UpdateSampleName(sampleId, sampleName) {
  axios
    .post(
      `${Globals.baseUrl}/Template/UpdateSampleName`,
      {
        sampleId: sampleId,
        sampleName: sampleName,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        GetSample(res.data.sampleId);
      }
    })
    .catch((res) => {
      if (res.response.status === 401) {
        console.log('unauthorized');
        Logout();
      }
    });
}

export function UpdateTemplate(tempId, tempData) {
  console.log('Update');
  axios
    .post(
      `${Globals.baseUrl}/Template/UpdateTemplate`,
      {
        templateId: tempId,
        data: tempData,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        if (res.data.responseType === 'Error') {
          PostAlert('error', 'Template failed to update');
        }
        if (res.data.responseType === 'Success') {
          PostAlert('success', 'Template Updated');
          dispatcher.dispatch({
            actionType: actionTypes.TemplateUpdate,
            Result: res.data.data,
          });
        }
      }
    })
    .catch((res) => {
      if (res.response.status === 401) {
        console.log('unauthorized');
        Logout();
      }
    });
}

export function GetSampleInfo(sampleId) {

  axios
    .get(
      `${Globals.baseUrl}/Template/GetSampleInfo/${sampleId}`,
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {

      if (res.status === 200) {
        var info = res.data;
        var info = { type: 'sample', info: res.data };
          dispatcher.dispatch({
            actionType: actionTypes.TemplateGetSampleInfo,
            Result: info
          });
      }
    })
    .catch((res) => {
      if (res.response.status === 401) {
        console.log('unauthorized');
        Logout();
      }
    });
}

export function GetDocSampleInfo(sampleName) {

  axios
    .get(
      `${Globals.baseUrl}/Template/GetDocSampleInfo/${sampleName}`,
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {

      if (res.status === 200) {

          var info = { type: 'doc', info: res.data };

          dispatcher.dispatch({
            actionType: actionTypes.TemplateGetSampleInfo,
            Result: info,
          });
      }
    })
    .catch((res) => {
      if (res.response.status === 401) {
        console.log('unauthorized');
        Logout();
      }
    });
}

export function ConvertToSample(sampleName, templateId) {

  axios
    .get(
      `${Globals.baseUrl}/Template/ConvertToSample/${sampleName}/${templateId}`,
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {

      if (res.status === 200) {



          dispatcher.dispatch({
            actionType: actionTypes.TemplateConvertSample,
            Result: res.data,
          });
      }
    })
    .catch((res) => {
      if (res.response.status === 401) {
        console.log('unauthorized');
        Logout();
      }
    });
}




export function DownloadSamplePDF(sampleId, samplefileName) {
  axios
    .get(
      `${Globals.baseUrl}/Template/DownloadSamplePDF/${sampleId}`,
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
        responseType: 'blob',
      }
    )
    .then((res) => {
      if (res.status === 200) {
        var link = document.createElement('a');
        link.setAttribute('download', '');
        link.href = window.URL.createObjectURL(new Blob([res.data]));
        link.download = samplefileName;
        document.body.appendChild(link);
        link.click();
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {});
}

export function GetTemplateDetails() {

  axios
    .get(
      `${Globals.baseUrl}/Template/GetTemplateDetails`,
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {

      if (res.status === 200) {

          dispatcher.dispatch({
            actionType: actionTypes.TemplateGetDetails,
            Result: res.data,
          });
      }
    })
    .catch((res) => {
      console.log(res);
      if (res.response.status === 401) {
        console.log('unauthorized');
        Logout();
      }
    });
}

export function UpdateTemplateActive(tid, isActive) {

  axios
    .post(
      `${Globals.baseUrl}/Template/UpdateTemplateActive`,
      {
        templateId: tid,
        isActive: isActive
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {

      if (res.status === 200) {

          dispatcher.dispatch({
            actionType: actionTypes.TemplateUpdateActive,
            Result: res.data,
          });
      }
    })
    .catch((res) => {
      console.log(res);
      if (res.response.status === 401) {
        console.log('unauthorized');
        Logout();
      }
    });
}

export function GetTemplateAudit(tid) {

  axios
    .get(
      `${Globals.baseUrl}/Template/GetTemplateAudit/${tid}`,
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {

      if (res.status === 200) {

          dispatcher.dispatch({
            actionType: actionTypes.TemplateGetAudit,
            Result: res.data,
          });
      }
    })
    .catch((res) => {
      console.log(res);
      if (res.response.status === 401) {
        console.log('unauthorized');
        Logout();
      }
    });
}


export function DownloadTemplate(templateId, templateName) {
  axios
    .get(
      `${Globals.baseUrl}/Template/DownloadTemplate/${templateId}`,
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
        responseType: 'blob',
      }
    )
    .then((res) => {
      if (res.status === 200) {
        var link = document.createElement('a');
        link.setAttribute('download', '');
        link.href = window.URL.createObjectURL(new Blob([res.data]));
        link.download = `${templateName}.zip`;
        document.body.appendChild(link);
        link.click();
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {});
}

export function UploadTemplate(upload, ftId) {
  let fd = new FormData();
  console.log('upload');
  fd.append('file', upload, upload.name);
  fd.append('ftId', ftId);
  axios
  .post(`${Globals.baseUrl}/Template/UploadTemplate`, fd, {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${Globals.getJwtToken()}`,
    },
  })
  .then((res) => {
    if (res.status === 200) {
      dispatcher.dispatch({
        actionType: actionTypes.TemplateUploadTemplate,
        Result: res.data,
      });
    } else if (res.status === 400 || res.status === 401) {
      console.log('error: 401');
    }
  })
  .catch((res) => {
    let result = {
      updateStatus: 'Error',
      errorMessage: '401: Bad request',
    };
    dispatcher.dispatch({
      actionType: actionTypes.WebUploadUpload,
      Result: result,
    });
  });
}

export function GetTemplateUsage(templateId)
{
  axios
  .get(
    `${Globals.baseUrl}/Template/GetTemplateUsage/${templateId}`,
    {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    }
  )
  .then((res) => {

    if (res.status === 200) {
        console.log(res.data);
        dispatcher.dispatch({
          actionType: actionTypes.TemplateGetUsage,
          Result: res.data,
        });
    }
  })
  .catch((res) => {
    console.log(res);
    if (res.response.status === 401) {
      console.log('unauthorized');
      Logout();
    }
  });
}