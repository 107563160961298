import * as React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import actionTypes from '../../../../actions/ActionTypes';
import { GetScheduleList } from '../../../../actions/ScheduleActions';
import ScheduleStore from '../../../../stores/ScheduleStore';

class ScheduleSidebar extends React.Component {
  constructor() {
    super();
    this.state = {
      searchText: '',
      filteredSchedules: [],
      showInactive: false,
      maxHeight: (window.innerHeight - 350)
    };
    this.onReceiveScheduleChange = this.onReceiveScheduleChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.changeShowInactive = this.changeShowInactive.bind(this);
  }

  componentDidMount() {
    ScheduleStore.addChangeListener(this.onReceiveScheduleChange);
    GetScheduleList();
  }

  componentWillUnmount() {
    ScheduleStore.removeChangeListener(this.onReceiveScheduleChange);
  }

  onReceiveScheduleChange() {
    var lastChange = ScheduleStore.getLastChange();
    if(lastChange === actionTypes.SchedulesGetList)
    {
      var schedList = ScheduleStore.getScheduleList();
      var filter = [...schedList];
        this.setState({scheduleList: schedList, filteredSchedules: filter});
    }

    if(lastChange === actionTypes.ScheduleUpdateOne)
    {
      GetScheduleList();
    }

  }

  changeShowInactive(e) {
    let showInactive = e.target.checked;
    if (showInactive) {
      //GetAllUserList();
    } else {
      //GetUserList();
    }
    this.setState({ showInactive: showInactive });
  }

  onSearchChange(e) {
    let search = e.target.value;
    if (search === '') {
      // no search, show all
      let newList = [...this.state.scheduleList];
      this.setState({ searchText: search, filteredSchedules: newList });
    } else {
      // filter results
      let newList = [];
      this.state.scheduleList.forEach((formType) => {
        if (formType.name.toLowerCase().includes(search.toLowerCase())) {
          newList.push(formType);
        }
      });
      this.setState({ searchText: search, filteredSchedules: newList });
    }
  }

  render() {
    let listOfScheds;
    if (this.state.filteredSchedules !== undefined) {
        listOfScheds = this.state.filteredSchedules.map((schedule) => (

<span>
        { (schedule.isActive === true || this.state.showInactive === true) && (
        <div key={schedule.id}>
        {schedule.isActive === false && (
          <span className='me-2' style={{ color: '#B7E4EA' }}>
            <FontAwesomeIcon icon={solid('lock')} />
          </span>
        )}
        {schedule.jobType === 1 && (
           <span className='me-2' style={{ color: '#B7E4EA' }}>
              <FontAwesomeIcon icon={solid('calendar-check')} />
            </span>
        )}
        {schedule.jobType === 2 && (
           <span className='me-2' style={{ color: '#B7E4EA' }}>
              <FontAwesomeIcon icon={solid('envelope-circle-check')} />
            </span>
        )}
        {schedule.jobType === 5 && (
           <span className='me-2' style={{ color: '#B7E4EA' }}>
              <FontAwesomeIcon icon={solid('magnifying-glass-plus')} />
            </span>
        )}
          <Link className='sidebar-link' to={'/Admin/Schedule/' + schedule.id}>
            {schedule.name}
          </Link>


        </div>
        )}
    </span>
      ));
    } else {
        listOfScheds = <div />;
    }
return (
      <div
        className='d-flex flex-column p-3 text-white bg-dark'
        style={{ height: '100%', width: '350px' }}
      >
        <div //link
          to='/Admin/Schedules'
          className='d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none'
        >
          <span className='fs-4'>
            <span style={{ color: '#6CE84E' }}>
              <FontAwesomeIcon icon={solid('calendar-check')} />
            </span>

            {'   '}
            Schedules
          </span>
        </div>{' '}
        {/* link */}
        <hr />
        <div className='input-group rounded' style={{ marginBottom: '16px' }}>
          <input
            type='search'
            value={this.state.searchText}
            className='form-control'
            placeholder='Search'
            aria-label='Search'
            onChange={this.onSearchChange}
            aria-describedby='search-addon'
          />
          <span className='input-group-text border-0' id='search-addon'>
            <FontAwesomeIcon icon={solid('search')} />
          </span>
        </div>
        <hr />
        <div className='form-check form-switch'>
          <input
            type='checkbox'
            className='form-check-input'
            checked={this.state.showInactive}
            onChange={this.changeShowInactive}
            id='chkActive'
          />
          <label
            className='form-check-label'
            style={{ fontSize: '8pt' }}
            htmlFor='chkActive'
          >
            Show Inactive
          </label>
        </div>
        <div className='coolScroll' style={{maxHeight: `${this.state.maxHeight}px`, overflowY: 'auto'}}>{listOfScheds}</div>
        <hr />
        <Link className='sidebar-link' to='/Admin/Schedule/0'>
          New Schedule
        </Link>
      </div>
    );
  }
}

export default ScheduleSidebar;
