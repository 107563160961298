import dispatcher from '../Dispatcher';
import actionTypes from './ActionTypes';
import axios from 'axios';
import * as Globals from '../Globals';

export function GetImages(documentId) {
  axios
    .get(`${Globals.baseUrl}/Image/GetImages/${documentId}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.ImageLoad,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}

export function FetchImage(documentId, imageId) {
  console.log('Fetch');
  axios
    .post(
      `${Globals.baseUrl}/Image/FetchImage`,
      { documentId: documentId, pageId: imageId },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.ImageFetch,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}

export function RotateImage(documentId, pageId, angle, base) {
  axios
    .post(
      `${Globals.baseUrl}/Image/RotateImage`,
      {
        documentId: documentId,
        pageId: pageId,
        rotationAngle: angle,
        currentBase: base,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.ImageFetch,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}
