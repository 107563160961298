import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';

const CHANGE_EVENT = 'secretChange';
let _allSecrets = undefined;
let _currentSecret = undefined;
let _lastChange = '';
let _lastError = '';

class SecretStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getSecrets() {
    return _allSecrets;
  }

  getCurrentSecret() {
    return _currentSecret;
  }

  lastChange() {
    return _lastChange;
  }

  getLastError() {
    return _lastError;
  }
}

const store = new SecretStore();

Dispatcher.register((action) => {
  _lastChange = action.actionType;
  switch (action.actionType) {
    // loading the full list of Companion schemas
    case actionTypes.SecretList:
      _lastChange = 'SecretList';
      _allSecrets = action.Result;
      store.emitChange();
      break;
    case actionTypes.SecretLoadOne:
      _lastChange = 'SecretLoadOne';
      _currentSecret = action.Result;
      store.emitChange();
      break;
    case actionTypes.SecretUpdateOne:
      _lastChange = 'SecretUpdateOne';
      if (action.Result.status === 'Success') {
        _lastError = '';
        _currentSecret = action.Result;
      } else {
        _lastError = action.Result.updateMessage;
      }
      store.emitChange();
      break;
    case actionTypes.SecretCreateOne:
        _lastChange = 'SecretCreateOne';
        if (action.Result.status === 'Success') {
          _lastError = '';
          _currentSecret = action.Result;
        } else {
          _lastError = action.Result.updateMessage;
        }
        store.emitChange();
        break;
    case actionTypes.SecretDelete:
      _lastChange = 'SecretDelete';
      if (action.Result.updateStatus === 'Success') {
        _lastError = '';
        _currentSecret = action.Result.result;
      } else {
        _lastError = action.Result.errorMessage;
      }
      store.emitChange();
      break;
    default:
  }
});

export default store;
