import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';

const CHANGE_EVENT = 'alertChange';
let _alerts = [];
let _lastAlertId = 3;

class AlertStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getAlerts() {
    return _alerts;
  }
}

const store = new AlertStore();

function retireAlert(id) {
  let alertToRemove = _alerts.find((a) => a.id === id);
  if (alertToRemove) {
    let idx = _alerts.indexOf(alertToRemove);
    _alerts.splice(idx, 1);
  }
  store.emitChange();
}

Dispatcher.register((action) => {
  switch (action.actionType) {
    case actionTypes.AlertAdd:
      let nextId = _lastAlertId + 1;
      let newAlert = action.Result;
      newAlert.id = nextId;
      _alerts.push(newAlert);
      _lastAlertId = nextId;
      setTimeout(() => retireAlert(nextId), 3000);
      store.emitChange();
      break;
    case actionTypes.AlertDelete:
      let alertToRemove = _alerts.find((a) => a.id === action.Result);
      if (alertToRemove) {
        let idx = _alerts.indexOf(alertToRemove);
        _alerts.splice(idx, 1);
      }
      store.emitChange();
      break;
    default:
  }
});

export default store;
