import * as React from 'react';
import ConnectionSidebar from '../Connection/ConnectionSidebar';
import { createBrowserHistory } from 'history';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  LoadConnection,
  GetConnectionList,
  CreateConnection,
  UpdateConnection,
  DeleteConnection,
} from '../../../../actions/ConnectionActions';
import ConnectionStore from '../../../../stores/ConnectionStore';
import { Link } from 'react-router-dom';

const customHistory = createBrowserHistory();

class ConnectionView extends React.Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      currentConnection: {
        id: 0,
        name: '',
        connectionString: '',
      },
      connectionId: 0,
      isDirty: false,
      error: '',
      isSaving: false,
      deleteStatus: 0,
    };

    this.updateConnection = this.updateConnection.bind(this);
    this.onStoreUpdate = this.onStoreUpdate.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleConnectionStringChange = this.handleConnectionStringChange.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);

    this.startDelete = this.startDelete.bind(this);
    this.deleteConnection = this.deleteConnection.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
  }

  componentDidMount() {
    ConnectionStore.addChangeListener(this.onStoreUpdate);
    this.updateConnection();
  }

  componentWillUnmount() {
    ConnectionStore.removeChangeListener(this.onStoreUpdate);
  }

  componentDidUpdate() {
    this.updateConnection();
  }

  updateConnection() {
    let loc = window.location.pathname;
    let pathParts = loc.split('/');
    let connectionId = parseInt(pathParts[pathParts.length - 1], 10);
    if (
        connectionId !== 0 &&
      (this.state.currentConnection === undefined ||
        this.state.currentConnection.id !== connectionId)
    ) {
        console.log('load conn');
      LoadConnection(connectionId);
    } else if (
        connectionId === 0 &&
      this.state.currentConnection !== undefined &&
      this.state.currentConnection.id !== 0
    ) {
      let currentConnection = {};
      currentConnection.id = 0;
      currentConnection.name = '';
      currentConnection.connectionString = '';
      this.setState({
        currentConnection: currentConnection,
        connectionName: '',
      });
    }
  }

  onStoreUpdate() {
    let lastAction = ConnectionStore.lastChange();
    if (lastAction === 'ConnectionLoadOne') {
      let connection = ConnectionStore.getCurrentConnection();
      console.log(connection);
      this.setState({
        currentConnection: connection,
        connectionName: connection.name,
        isDirty: false,
        isSaving: false,
      });
    }
    if (lastAction === 'ConnectionCreateOne') {
      let lastError = ConnectionStore.getLastError();
      if (lastError === '' || lastError === undefined) {
        let newConnection = ConnectionStore.getCurrentConnection();
        if (newConnection.id !== this.state.currentConnection.id) {
          customHistory.push(`/Admin/ConnectionView/${newConnection.data.id}`);
        }

        this.setState({
          error: '',
          currentConnection: newConnection,
          currentName: newConnection.name,
          isSaving: false,
          isDirty: false,
        });
        GetConnectionList();
      } else {
        this.setState({ isSaving: false, error: lastError });
      }
    }
    if (lastAction === 'ConnectionUpdateOne') {
      let lastError = ConnectionStore.getLastError();
      if (lastError === '' || lastError === undefined) {
        let newConnection = ConnectionStore.getCurrentConnection();
        if (newConnection.data.id !== this.state.currentConnection.id) {
          customHistory.push(`/Admin/ConnectionView/${newConnection.data.id}`);
        }

        this.setState({
          error: '',
          currentConnection: newConnection,
          currentName: newConnection.name,
          isSaving: false,
          isDirty: false,
        });
        GetConnectionList();
      } else {
        this.setState({ isSaving: false, error: lastError });
      }
    }
    if (lastAction === 'ConnectionDelete') {
      let lastError = ConnectionStore.getLastError();
      if (lastError === '') {
        let link = document.getElementById('connectionHome');
        link.click();
      } else {
        this.setState({ isSaving: false, error: lastError });
      }
    }
  }

  handleNameChange(e) {
    let currentConnection = this.state.currentConnection;
    currentConnection.name = e.target.value;
    this.setState({ currentConnection: currentConnection, isDirty: true });
  }

  handleConnectionStringChange(e) {
    let currentConnection = this.state.currentConnection;
    currentConnection.connectionString = e.target.value;
    this.setState({ currentConnection: currentConnection, isDirty: true });
    console.log(currentConnection);
    console.log(this.state.currentConnection);
  }

  handleSaveClick(e) {
    this.setState({ isSaving: true });
    if (this.state.currentConnection.id === 0) {
      CreateConnection(this.state.currentConnection);
    } else {
      UpdateConnection(this.state.currentConnection);
    }
  }

  startDelete() {
    this.setState({ deleteStatus: 1 });
  }

  deleteConnection() {
    DeleteConnection(this.state.currentConnection.id);
    this.setState({ deleteStatus: 0 });
  }

  cancelDelete() {
    this.setState({ deleteStatus: 0 });
  }

  render() {
    return (
      <div className='container-fluid' style={{ height: '100%' }}>
        <div className='row flex-nowrap' style={{ height: '100%' }}>
          <ConnectionSidebar />
          <Link
              id='connectionHome'
              className='nav-link'
              to={`/Admin/Connection`}
              style={{ display: 'none' }}
            >
            </Link>
          {this.state.currentConnection !== undefined && (
            <div
              className='flex-fill'
              style={{ backgroundColor: '#efefef', overflow: 'scroll' }}
            >
              <div
                className='card'
                style={{
                  marginTop: '20px',
                  marginLeft: '30px',
                  marginRight: '30px',
                  maxHeight: '620px',
                }}
              >
                <div className='card-header'>
                  <h4>
                    <FontAwesomeIcon icon={solid('database')} />
                    {'  '}
                    {this.state.currentConnection.name}
                  </h4>
                </div>
                <div className='card-body'>
                  <div className='mb-3'>
                    <label htmlFor='nameTxt' className='form-label'>
                      Name:
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      id='nameTxt'
                      value={this.state.currentConnection.name}
                      onChange={this.handleNameChange}
                    />
                  </div>

                  <div className='mb-3'>
                    <label htmlFor='valueTxt' className='form-label'>
                      Value:
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      id='valueTxt'
                      value={this.state.currentConnection.connectionString}
                      onChange={this.handleConnectionStringChange}
                    />
                  </div>

                  <div className='mb-3' style={{ marginTop: '8px' }}>
                    {this.state.isDirty && !this.state.isSaving && (
                      <button
                        className='btn btn-primary'
                        onClick={this.handleSaveClick}
                      >
                        Save
                      </button>
                    )}
                    {!this.state.isDirty && !this.state.isSaving && (
                      <div>
                        <button className='btn btn-primary disabled'>
                          Save
                        </button>
                        <button
                          className='btn btn-danger'
                          style={{ marginLeft: '6px' }}
                          onClick={this.startDelete}
                        >
                          Delete
                        </button>
                      </div>
                    )}
                    {this.state.isSaving && (
                      <FontAwesomeIcon icon={solid('spinner')} size='2x' spin />
                    )}
                    {this.state.deleteStatus === 1 && (
                      <div
                        className='alert alert-danger'
                        style={{ marginTop: '6px' }}
                        role='alert'
                      >
                        <p>Are you sure you want to delete this connection?</p>

                        <button
                          className='btn btn-danger'
                          onClick={this.deleteConnection}
                        >
                          Yes - Delete
                        </button>
                        <button
                          className='btn btn-secondary'
                          style={{ marginLeft: '6px' }}
                          onClick={this.cancelDelete}
                        >
                          Cancel
                        </button>
                      </div>
                    )}

                    {this.state.error !== '' && (
                      <div
                        className='alert alert-danger'
                        style={{ marginTop: '6px' }}
                        role='alert'
                      >
                        {this.state.error}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ConnectionView;
