import * as React from 'react';
import { createBrowserHistory } from 'history';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import OUSidebar from './OrgUnitSidebar';
import { GetOU, GetOUList, UpdateOU, DeleteOU } from '../../../../actions/OUActions';
import OUStore from '../../../../stores/OUStore';
import actionTypes from '../../../../actions/ActionTypes';

const customHistory = createBrowserHistory();

class OrgUnitView extends React.Component {
  constructor(props) {
    // state
    super(props);

    this.state = {
      currentOU: {
        orgUnitId: 0,
        name: '',
      },
      isDirty: false,
    };

    this.onChangeOUName = this.onChangeOUName.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onOrgUpdate = this.onOrgUpdate.bind(this);
  }

  componentDidMount() {
    OUStore.addChangeListener(this.onOrgUpdate);
    this.loadOUFromDB();
  }

  componentWillUnmount() {
    OUStore.removeChangeListener(this.onOrgUpdate);
  }

  componentDidUpdate() {
    //console.log('update');
    this.loadOUFromDB();
  }

  onOrgUpdate() {
    let lastChange = OUStore.getLastChange();
    if (lastChange === actionTypes.OUUpdate) {
      let cou = OUStore.getCurrentOU();
      if (this.state.currentOU.orgUnitId === 0 && cou.orgUnitId !== 0) {
        customHistory.push(`/Admin/OrgUnit/${cou.orgUnitId}`);
        this.setState({ currentOU: cou, isDirty: false });
        GetOUList();
      } else {
        console.log(cou);
        this.setState({ currentOU: cou, isDirty: false });
        GetOUList();
      }
    }
    if (lastChange === actionTypes.OUGetOne) {
      let cou = OUStore.getCurrentOU();
      this.setState({ currentOU: cou, isDirty: false });
    }

    if (lastChange === actionTypes.OUDelete) {
      let cou = {
        orgUnitId: 0,
        name: '',
      };
      customHistory.push(`/Admin/OrgUnit/0`);
      this.setState({ currentOU: cou, isDirty: false });
      GetOUList();
    }
  }

  onSaveClick() {
    UpdateOU(this.state.currentOU.orgUnitId, this.state.currentOU.name);
  }

  onDeleteClick() {
    DeleteOU(this.state.currentOU.orgUnitId, this.state.currentOU.name);
  }

  loadOUFromDB() {
    let loc = window.location.pathname;
    let pathParts = loc.split('/');
    let orgUnitId = parseInt(pathParts[pathParts.length - 1], 10);
    if (this.state.currentOU.orgUnitId !== 0 && orgUnitId === 0) {
      let newOU = {
        orgUnitId: 0,
        name: '',
      };

      this.setState({ currentOU: newOU, isDirty: false }, () => {
        document.getElementById('txtOUName').focus();
      });
    } else if (this.state.currentOU.orgUnitId !== orgUnitId) {
      //let ou = this.state.currentOU;

      GetOU(orgUnitId);
    }
  }

  onChangeOUName(e) {
    let ou = this.state.currentOU;
    ou.name = e.target.value;
    this.setState({ currentOU: ou, isDirty: true });
  }

  render() {
    return (
      <div className='sidebarLayout d-flex flex-row flex-fill'>
        <div className='sidebar-container'>
          <OUSidebar />
        </div>
        <div
          className='flex-fill'
          style={{
            display: 'grid',
            gridTemplateColumns: '650px 1fr',
            gridTemplateRows: '250px 60px 1fr 15px',
            paddingLeft: '60px',
          }}
        >
          <div
            id='ouInfo'
            className='card'
            style={{ gridColumn: '1', gridRow: '1', margin: '16px' }}
          >
            <div className='card-header'>
            {!this.state.isDirty && (
              <>
                <FontAwesomeIcon icon={solid('folder')} /> Organizational Unit
              </>
            )}
              
              {this.state.isDirty && (
                <>
                  <FontAwesomeIcon icon={solid('folder')} style={{ marginTop: '12px' }} /> Organizational Unit
                  <button
                    style={{float: 'right' }}
                    className='btn btn-success'
                    onClick={this.onSaveClick}
                  >
                    Save changes
                  </button>
                </>
              )}
            </div>
            <div className='card-body'>
              <div className='row'>
                <div className='form-group col-6'>
                  <label htmlFor='txtOUName'>Name:</label>
                  <input
                    type='text'
                    className={`form-control`}
                    id='txtOUName'
                    value={this.state.currentOU.name}
                    onChange={this.onChangeOUName}
                    placeholder='organizational unit name'
                  />
                  {this.state.currentOU.orgUnitId !== 0 && (
                    <button
                        style={{ marginTop: '12px' }}
                        className='btn btn-danger'
                        onClick={this.onDeleteClick}
                      >
                        Delete Organizational Unit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OrgUnitView;
