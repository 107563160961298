// @flow
import * as React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import MailboxStore from '../../../../stores/MailboxStore';
import { GetMailboxList } from '../../../../actions/MailboxActions';

class MailboxSidebar extends React.Component {
  //isList: Array<BPModel>;
  constructor(props) {
    super(props);
    this.state = {
      mailboxList: [],
      iaMailboxList: [],
      smtpMailboxList: [],
      mailboxesLoaded: false,
    };

    this.onReceiveMailboxChange = this.onReceiveMailboxChange.bind(this);
  }

  componentDidMount() {
    MailboxStore.addChangeListener(this.onReceiveMailboxChange);
    GetMailboxList();
  }

  componentWillUnmount() {
    MailboxStore.removeChangeListener(this.onReceiveMailboxChange);
  }

  onReceiveMailboxChange() {
    let lastChange = MailboxStore.lastChange();
    if (lastChange === 'MailboxList') {
      let mailboxes = MailboxStore.getMailboxes();
      this.setState({
        mailboxList: mailboxes,
        iaMailboxList: mailboxes.filter((i) => i.mailboxType === 11),
        smtpMailboxList: mailboxes.filter((i) => i.mailboxType === 12),
        mailboxesLoaded: true,
      });
    }
  }

  render() {
    let listOfIAMailboxes;
    let listOfSmtpMailboxes;
    if (
      this.state.iaMailboxList !== undefined &&
      this.state.iaMailboxList.length > 0
    ) {
      listOfIAMailboxes = this.state.iaMailboxList.map((mailbox) => (
        <div style={{ marginBottom: '2px' }} key={mailbox.mailboxId}>
          <Link
            className='sidebar-link'
            to={'/Admin/MailboxView/' + mailbox.mailboxId}
            //onClick={() => this.handleBPClick(bp)}
            style={{ cursor: 'Pointer', wordWrap: 'break-word' }}
          >
            <FontAwesomeIcon
              style={{ color: '#86AFC0' }}
              icon={solid('envelope-square')}
            />{' '}
            {mailbox.name}
          </Link>
        </div>
      ));
    } else {
      listOfIAMailboxes = (
        <div style={{ fontSize: '10pt', color: '#B2B2B2' }}>
          No IA mailboxes
        </div>
      );
    }

    if (
      this.state.smtpMailboxList !== undefined &&
      this.state.smtpMailboxList.length > 0
    ) {
      listOfSmtpMailboxes = this.state.smtpMailboxList.map((mailbox) => (
        <div style={{ marginBottom: '8px' }} key={mailbox.mailboxId}>
          <Link
            className='sidebar-link'
            to={'/Admin/MailboxView/' + mailbox.mailboxId}
            //onClick={() => this.handleBPClick(bp)}
            style={{ cursor: 'Pointer', wordWrap: 'break-word' }}
          >
            <FontAwesomeIcon
              style={{ color: '#BCA5D0' }}
              icon={solid('envelope-square')}
            />{' '}
            {mailbox.name}
          </Link>
        </div>
      ));
    } else {
      listOfSmtpMailboxes = (
        <div style={{ fontSize: '10pt', color: '#B2B2B2' }}>
          No SMTP mailboxes
        </div>
      );
    }
    return (
      <div
        className='d-flex flex-column p-3 text-white bg-dark'
        style={{ height: '100%', width: '245px' }}
      >
        <Link
          to='/Admin/Mailbox'
          className='d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none'
        >
          <span className='fs-4'>
            <FontAwesomeIcon icon={solid('envelope-square')} />
            {'   '}
            Mailboxes
          </span>
        </Link>
        <hr />

        <div
          className='coolScroll'
          style={{ maxHeight: '50%', overflow: 'auto' }}
        >
          <div>InboundAutomation</div>
          <div style={{ marginLeft: '8px' }}>{listOfIAMailboxes}</div>
          <div className='mt-3'>Notifications</div>
          <div style={{ marginLeft: '8px' }}>{listOfSmtpMailboxes}</div>
        </div>

        <hr />
        <Link className='sidebar-link' to='/Admin/MailboxView/0'>
          New Mailbox
        </Link>
      </div>
    );
  }
}

export default MailboxSidebar;
