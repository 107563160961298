import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';

const CHANGE_EVENT = 'userChange';
let _lastChange = '';

let _indexFields = [];
let _buildPercent = 0;
let _buildStatus = 0;
let _indexId = 0;
let _docCounts = {
  activeCount: 0,
  completeCount: 0,
  deleteCount: 0,
};
//let _lastError = '';

class SearchStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getIndexFields() {
    return _indexFields;
  }

  getIndexId() {
    return _indexId;
  }

  getLastAction() {
    return _lastChange;
  }

  getBuildPercent() {
    return _buildPercent;
  }

  getBuildStatus() {
    return _buildStatus;
  }

  getDocCounts() {
    return _docCounts;
  }
}

const store = new SearchStore();

Dispatcher.register((action) => {
  switch (action.actionType) {
    case actionTypes.GetIndexFieldsByFormTypeId:
      _indexFields = action.Result;
      _lastChange = actionTypes.GetIndexFieldsByFormTypeId;

      store.emitChange();
      break;

    case actionTypes.GetBuildPercentage:
      _buildPercent = action.Result.buildPercent;
      _buildStatus = action.Result.status;
      _lastChange = actionTypes.GetBuildPercentage;

      store.emitChange();
      break;

    case actionTypes.IndexUpdate:
      _lastChange = actionTypes.IndexUpdate;
      _indexId = action.Result.data;
      store.emitChange();
      break;

    case actionTypes.IndexDelete:
      _lastChange = actionTypes.IndexDelete;
      store.emitChange();
      break;

    case actionTypes.UpdateBuildStatus:
      _lastChange = actionTypes.UpdateBuildStatus;
      store.emitChange();
      break;

    case actionTypes.IndexGetDocCounts:
      _lastChange = actionTypes.IndexGetDocCounts;
      _docCounts = action.Result;
      store.emitChange();
      break;
    default:
  }
});

export default store;
