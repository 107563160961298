import dispatcher from '../Dispatcher';
import actionTypes from './ActionTypes';
import axios from 'axios';
import * as Globals from '../Globals';
import { Logout } from './LoginActions';
import { PostAlert } from './AlertActions';

export function BulkActionApprove(docs) {
    console.log(docs);
    axios
      .post(
        `${Globals.baseUrl}/BulkEdit/BulkApprove`,
         docs,
        {
          headers: {
            'content-type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${Globals.getJwtToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          let result = res.data;
          console.log(result);
            dispatcher.dispatch({
              actionType: actionTypes.BulkAction,
              Result: res.data,
            });

        } else if (res.status === 400 || res.status === 401) {
          console.log('error: 401');
        }
      })
      .catch((res) => {
        console.log(res);
      });
  }

  export function BulkActionApproveAll(query) {
    axios
      .post(
        `${Globals.baseUrl}/BulkEdit/BulkApproveAll`,
        {
          query: query
        },
        {
          headers: {
            'content-type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${Globals.getJwtToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          let result = res.data;
          console.log(result);
            dispatcher.dispatch({
              actionType: actionTypes.BulkAction,
              Result: res.data,
            });

        } else if (res.status === 400 || res.status === 401) {
          console.log('error: 401');
        }
      })
      .catch((res) => {
        console.log(res);
      });
  }

  export function BulkActionReject(docs) {
    console.log(docs);
    axios
      .post(
        `${Globals.baseUrl}/BulkEdit/BulkReject`,
         docs,
        {
          headers: {
            'content-type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${Globals.getJwtToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          let result = res.data;
          console.log(result);
            dispatcher.dispatch({
              actionType: actionTypes.BulkAction,
              Result: res.data,
            });

        } else if (res.status === 400 || res.status === 401) {
          console.log('error: 401');
        }
      })
      .catch((res) => {
        console.log(res);
      });
  }

  export function BulkActionRejectAll(query) {
    axios
      .post(
        `${Globals.baseUrl}/BulkEdit/BulkRejectAll`,
        {
          query: query
        },
        {
          headers: {
            'content-type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${Globals.getJwtToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          let result = res.data;
          console.log(result);
            dispatcher.dispatch({
              actionType: actionTypes.BulkAction,
              Result: res.data,
            });

        } else if (res.status === 400 || res.status === 401) {
          console.log('error: 401');
        }
      })
      .catch((res) => {
        console.log(res);
      });
  }

  export function BulkActionDelete(docs) {
    console.log(docs);
    axios
      .post(
        `${Globals.baseUrl}/BulkEdit/BulkDelete`,
         docs,
        {
          headers: {
            'content-type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${Globals.getJwtToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          let result = res.data;
          console.log(result);
            dispatcher.dispatch({
              actionType: actionTypes.BulkAction,
              Result: res.data,
            });

        } else if (res.status === 400 || res.status === 401) {
          console.log('error: 401');
        }
      })
      .catch((res) => {
        console.log(res);
      });
  }

  export function BulkActionDeleteAll(query) {
    axios
      .post(
        `${Globals.baseUrl}/BulkEdit/BulkDeleteAll`,
        {
          query: query
        },
        {
          headers: {
            'content-type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${Globals.getJwtToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          let result = res.data;
          console.log(result);
            dispatcher.dispatch({
              actionType: actionTypes.BulkAction,
              Result: res.data,
            });

        } else if (res.status === 400 || res.status === 401) {
          console.log('error: 401');
        }
      })
      .catch((res) => {
        console.log(res);
      });
  }

  export function BulkActionMoveAndAssign(docs) {
    console.log(docs);
    axios
      .post(
        `${Globals.baseUrl}/BulkEdit/BulkMoveAndAssign`,
         docs,
        {
          headers: {
            'content-type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${Globals.getJwtToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          let result = res.data;
          console.log(result);
            dispatcher.dispatch({
              actionType: actionTypes.BulkAction,
              Result: res.data,
            });

        } else if (res.status === 400 || res.status === 401) {
          console.log('error: 401');
        }
      })
      .catch((res) => {
        console.log(res);
      });
}

  export function BulkActionMoveAndAssignAll(req) {
    console.log(req);
    axios
      .post(
        `${Globals.baseUrl}/BulkEdit/BulkMoveAndAssignAll`,
        {
          docIds: req.docIds,
          stepId: req.stepId,
          assignToUserIds: req.assignToUserIds,
          query: req.query,
        },
        {
          headers: {
            'content-type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${Globals.getJwtToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          let result = res.data;
          console.log(result);
            dispatcher.dispatch({
              actionType: actionTypes.BulkAction,
              Result: res.data,
            });

        } else if (res.status === 400 || res.status === 401) {
          console.log('error: 401');
        }
      })
      .catch((res) => {
        console.log(res);
      });
  }

  export function BulkActionUnassign(docs) {
    console.log(docs);
    axios
      .post(
        `${Globals.baseUrl}/BulkEdit/BulkUnassign`,
         docs,
        {
          headers: {
            'content-type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${Globals.getJwtToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          let result = res.data;
          console.log(result);
            dispatcher.dispatch({
              actionType: actionTypes.BulkAction,
              Result: res.data,
            });

        } else if (res.status === 400 || res.status === 401) {
          console.log('error: 401');
        }
      })
      .catch((res) => {
        console.log(res);
      });
  }

  export function BulkActionUnassignAll(query) {
    axios
      .post(
        `${Globals.baseUrl}/BulkEdit/BulkUnassignAll`,
        {
          query: query
        },
        {
          headers: {
            'content-type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${Globals.getJwtToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          let result = res.data;
          console.log(result);
            dispatcher.dispatch({
              actionType: actionTypes.BulkAction,
              Result: res.data,
            });

        } else if (res.status === 400 || res.status === 401) {
          console.log('error: 401');
        }
      })
      .catch((res) => {
        console.log(res);
      });
  }

  export function BulkActionSendToImageReview(docs) {
    console.log(docs);
    axios
      .post(
        `${Globals.baseUrl}/BulkEdit/BulkSendToImageReview`,
         docs,
        {
          headers: {
            'content-type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${Globals.getJwtToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          let result = res.data;
          console.log(result);
            dispatcher.dispatch({
              actionType: actionTypes.BulkAction,
              Result: res.data,
            });

        } else if (res.status === 400 || res.status === 401) {
          console.log('error: 401');
        }
      })
      .catch((res) => {
        console.log(res);
      });
  }

  export function BulkActionSendToImageReviewAll(query) {
    axios
      .post(
        `${Globals.baseUrl}/BulkEdit/BulkSendToImageReviewAll`,
        {
          query: query
        },
        {
          headers: {
            'content-type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${Globals.getJwtToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          let result = res.data;
          console.log(result);
            dispatcher.dispatch({
              actionType: actionTypes.BulkAction,
              Result: res.data,
            });

        } else if (res.status === 400 || res.status === 401) {
          console.log('error: 401');
        }
      })
      .catch((res) => {
        console.log(res);
      });
  }

  export function BulkActionChangeToReadyStatus(docs) {
    console.log(docs);
    axios
      .post(
        `${Globals.baseUrl}/BulkEdit/BulkChangeToReadyStatus`,
         docs,
        {
          headers: {
            'content-type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${Globals.getJwtToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          let result = res.data;
          console.log(result);
            dispatcher.dispatch({
              actionType: actionTypes.BulkAction,
              Result: res.data,
            });

        } else if (res.status === 400 || res.status === 401) {
          console.log('error: 401');
        }
      })
      .catch((res) => {
        console.log(res);
      });
  }

  export function BulkActionChangeToReadyStatusAll(query) {
    axios
      .post(
        `${Globals.baseUrl}/BulkEdit/BulkChangeToReadyStatusAll`,
        {
          query: query
        },
        {
          headers: {
            'content-type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${Globals.getJwtToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          let result = res.data;
          console.log(result);
            dispatcher.dispatch({
              actionType: actionTypes.BulkAction,
              Result: res.data,
            });

        } else if (res.status === 400 || res.status === 401) {
          console.log('error: 401');
        }
      })
      .catch((res) => {
        console.log(res);
      });
  }



  export function BulkActionChangeTemplate(docs) {
    console.log(docs);
    axios
      .post(
        `${Globals.baseUrl}/BulkEdit/BulkChangeTemplate`,
         docs,
        {
          headers: {
            'content-type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${Globals.getJwtToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          let result = res.data;
          console.log(result);
            dispatcher.dispatch({
              actionType: actionTypes.BulkAction,
              Result: res.data,
            });

        } else if (res.status === 400 || res.status === 401) {
          console.log('error: 401');
        }
      })
      .catch((res) => {
        console.log(res);
      });
  }

  export function BulkActionChangeTemplateAll(query) {
    axios
      .post(
        `${Globals.baseUrl}/BulkEdit/BulkChangeTemplateAll`,
        {
          query: query
        },
        {
          headers: {
            'content-type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${Globals.getJwtToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          let result = res.data;
          console.log(result);
            dispatcher.dispatch({
              actionType: actionTypes.BulkAction,
              Result: res.data,
            });

        } else if (res.status === 400 || res.status === 401) {
          console.log('error: 401');
        }
      })
      .catch((res) => {
        console.log(res);
      });
  }


  export function GetWorkflowStepsByBpId(bpId) {
    axios
      .get(`${Globals.baseUrl}/BulkEdit/GetWorkflowStepsByBpId/${bpId}`, {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatcher.dispatch({
            actionType: actionTypes.WorkflowStepAction,
            Result: res.data,
          });
        } else if (res.status === 400 || res.status === 401) {
          console.log('error: 401');
        }
      })
      .catch((res) => {});
  }




  export function BulkActionsDownload(docs) {
    console.log(docs);
    axios
      .post(
        `${Globals.baseUrl}/BulkEdit/BulkDownload`,
         {
          Docs: docs
         },
        {
          headers: {
            'content-type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${Globals.getJwtToken()}`,
          },
          responseType: 'blob'
        }
      )
      .then((res) => {
        if (res.status === 200) {
          var link = document.createElement('a');
          link.setAttribute('download', '');
          let today = new Date();
          console.log(today);
          let year = today.getFullYear();
          let day = today.getDate();
          let month = today.getMonth() + 1;
          console.log(month);
          link.href = window.URL.createObjectURL(new Blob([res.data]));
          link.download = `TrinDocs_BulkDownload_${year}-${month}-${day}.zip`;
          //link.download = 'download.zip';
          document.body.appendChild(link);
          link.click();
          dispatcher.dispatch({
            actionType: actionTypes.BulkDownload,
            Result: res.data,
          });
        } else if (res.status === 400 || res.status === 401) {
          console.log('error: 401');
        }
      })
      .catch((res) => {
        console.log(res);
      });
  }

