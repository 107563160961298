import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import AutoCompleteExtender from '../../../components/AutoCompleteExtender';
import ExtenderStore from '../../../components/ExtenderStore';

class FieldEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentText: '',
      currentValue: '',
      extenderKey: '',
      isKeyValue: false,
      myFieldTitle: '',
      startDate: new Date(),
      setStartDate: new Date(),
    };

    this.onEditField = this.onEditField.bind(this);
    this.onEditExtender = this.onEditExtender.bind(this);
    this.onCopyField = this.onCopyField.bind(this);
    this.onEditNumField = this.onEditNumField.bind(this);
    this.onEditDateField = this.onEditDateField.bind(this);
    this.onBlurDateField = this.onBlurDateField.bind(this);
    this.onEditAutoComplete = this.onEditAutoComplete.bind(this);
    this.onBlurAutoComplete = this.onBlurAutoComplete.bind(this);
    this.onEditKeyValue = this.onEditKeyValue.bind(this);
    this.onBlurKeyValue = this.onBlurKeyValue.bind(this);
    this.onBlurNumField = this.onBlurNumField.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onBlurTextField = this.onBlurTextField.bind(this);
    this.onClickAutoComplete = this.onClickAutoComplete.bind(this);
    this.editFromFilter = this.editFromFilter.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.OnAutoCompleteEdit = this.OnAutoCompleteEdit.bind(this);
    this.AutoCompleteSetError = this.AutoCompleteSetError.bind(this);
    this.onMouseDownCapture = this.onMouseDownCapture.bind(this);
    this.OnExtenderChange = this.OnExtenderChange.bind(this);
    this.expandView = this.expandView.bind(this);
  }

  componentDidMount() {
    this.setState({
      currentText: this.props.textValue,
      currentValue: this.props.value,
      extenderKey: this.props.extenderKey,
    });
    if (this.props.dataType === 1) {
      let tempNumber = 0;

      let numDecimalPlaces = this.props.decimalPlaces;

      if (numDecimalPlaces === null || numDecimalPlaces === undefined) {
        numDecimalPlaces = 2;
      }

      this.setState({
        myFieldTitle: 'Format: ' + tempNumber.toFixed(numDecimalPlaces),
      });
    }
    if (this.props.dataType === 2) {
      //let tempNumber = 0;

      let numDecimalPlaces = this.props.decimalPlaces;

      if (numDecimalPlaces === null || numDecimalPlaces === undefined) {
        numDecimalPlaces = 2;
      }

      this.setState({ myFieldTitle: 'Format: ' + this.props.format });
    }
    if (this.props.extender !== null) {
      ExtenderStore.addChangeListener(this.OnExtenderChange);
    }
    if (
      this.props.extender !== null &&
      this.props.extender.allowedValues !== null &&
      this.props.extender.allowedValues.length > 0
    ) {
      if (this.props.extender.type === 4) {
        let x = this.props.extender.allowedValues[0];
        if (x.description !== null) {
          //this.state.isKeyValue = true;
          this.setState({ isKeyValue: true });
        }
      }
      //if value from database doesnt exist in allowed values create value and set error
      let val = this.props.extender.allowedValues.find(
        (av) => av.textValue === this.props.textValue
      );
      if (val !== undefined && val !== null) {
      } else if (val === undefined || val === null) {
        //let x = this.props.extender.allowedValues;
        //x.push()
        if (this.props.extender.type === 4) {
          //ok its a dropdown

          /*
          let auto = document.getElementById(
            this.props.fieldId.toString() + '_dropdown'
          );
          var option = document.createElement('option');
          option.text = this.props.textValue;
          //<option key={op.keyValue} value={op.keyValue}></option>
          option.key = '-200';
          option.value = this.props.textValue;
          //var x2 = auto.options;
          //let x2 = Object.assign({}, auto.options);

          auto.add(option);

          //auto.options.length = 0;
          //x2.map(element => auto.add(element));

          auto.value = this.props.textValue;
*/
          this.props.setError(
            this.props.fieldId,
            'Value is not available in drop down list: ( ' +
              this.props.textValue +
              ' )'
          );
          this.setState({
            currentText: this.props.textValue,
            currentValue: '-1',
          });
        } else if (
          this.props.extender.type === 1 ||
          this.props.extender.type === 2
        ) {
          // TODO     autocomplete control
          this.props.setError(
            this.props.fieldId,
            'Value is not available in drop down list: ( ' +
              this.props.textValue +
              ' )'
          );
          this.setState({
            currentText: this.props.textValue,
            currentValue: '-1',
          });
        }
      }

      this.setState({
        currentText: this.props.textValue,
        currentValue: this.props.textValue,
        extenderKey: this.props.extenderKey,
      });
    }
  }

  OnExtenderChange() {
    let lastExtender = ExtenderStore.getLastExtender();
    console.log('runExtender');
    if (this.props.extender !== null) {
      let idx = this.props.extender.extenderDependencies.find(
        (ed) => ed.extenderId === lastExtender.extenderId
      );

      if (idx) {
        this.props.runExtender(this.props.extenderId);
      }
    }
  }
  // let idx = this.props.extender.childFieldDependencies.indexOf(
  //   lastExtender.fieldId
  // );

  AutoCompleteSetError(textValue, fieldId) {
    this.props.setError(
      this.props.fieldId,
      'Value is not available in drop down list: ( ' + textValue + ' )'
    );
  }

  componentWillUnmount() {
    ExtenderStore.removeChangeListener(this.OnExtenderChange);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.currentValue) {
      return {
        currentText: props.value,
        currentValue: props.value,
      };
    }
    return null;
    //this.setState({currentText: props.fieldValue})
  }

  componentDidUpdate(PrevProps, prevState) {
    if (prevState.currentText !== this.props.textValue) {
      this.setState({ currentText: this.props.textValue });
    }
  }

  onMouseDownCapture(e) {
    if (this.props.hasExtender) {
      if (this.props.extender.allowedValues.length === 1) {
        let x = this.props.extender.allowedValues[0];

        this.props.updateTextValue(
          x.textValue,
          this.props.fieldId,
          x.extenderKey
        );

        this.setState({
          currentText: x.textValue,
          currentValue: x.textValue,
          extenderKey: x.extenderKey,
        });

        this.props.setError(this.props.fieldId, ``);
      }
    }
  }
  onEditField(e) {
    // lots of work to go here
    var newText = e.target.value;
    //var x = e.target;
    var allowChange = true;
    if (this.props.fieldType === 1) {
      // its a number
      if (newText !== '') {
        if (isNaN(newText)) {
          allowChange = false;
        }
      }
      if (newText === '-') {
        allowChange = true;
      }
    } else {
      allowChange = true;
    }
    if (allowChange) {
      this.props.updateTextValue(
        newText,
        this.props.fieldId,
        this.props.systemName
      );
    }
  }

  OnAutoCompleteEdit(textValue, fieldId, extenderKey) {
    console.log(textValue);
    console.log('autoCompleteEdit');

    if (textValue === '') {
      this.props.updateValue(
        textValue,
        this.props.fieldId,
        '',
        this.props.extenderId
      );
    } else {
      let val = this.props.extender.allowedValues.find(
        (av) => av.textValue === textValue
      );
      if (val !== undefined) {
        this.props.setError(this.props.fieldId, ``);
        this.props.updateValue(
          val.textValue,
          this.props.fieldId,
          val.extenderKey,
          this.props.extenderId
        );
      } else {
        this.props.setError(this.props.fieldId, 'Invalid (' + textValue + ')');
        this.props.updateValue(textValue, this.props.fieldId, '-1');
      }
    }

    //this.props.updateValue(textValue, fieldId, extenderKey);
  }

  onEditExtender(e, keyValue) {
    console.log('editExtender');
    var textValue = e.target.value;
    let val = this.props.extender.allowedValues.find(
      (av) => av.textValue === textValue
    );
    if (val !== undefined) {
      this.props.setError(this.props.fieldId, ``);
      this.props.updateValue(
        val.textValue,
        this.props.fieldId,
        val.extenderKey,
        this.props.extenderId
      );
    } else {
      this.props.setError(this.props.fieldId, 'Invalid (' + textValue + ')');
      this.props.updateValue(
        textValue,
        this.props.fieldId,
        '-1',
        this.props.extender
      );
    }
  }

  onEditNumField(e) {
    let newValue = e.target.value;
    let allowUpdate = false;
    let currentVal = this.props.value;

    if (newValue === '') allowUpdate = true;
    if (newValue === '-') allowUpdate = true;
    if (!isNaN(newValue)) {
      let indexOfDecimal = newValue.indexOf('.');
      if (indexOfDecimal > -1) {
        allowUpdate = true;
        // there is a decimal
        if (this.props.decimalPlaces === 0) {
          // no need for decimal place when using 0 decimal places
          allowUpdate = false;
        }
        let currentDigits = newValue.length - indexOfDecimal - 1;
        if (
          currentDigits > this.props.decimalPlaces &&
          newValue.length > currentVal.length
        ) {
          allowUpdate = false;
        }
      } else {
        allowUpdate = true;
      }
    }

    if (
      isNaN(currentVal) &&
      isNaN(newValue) &&
      currentVal.length > newValue.length
    ) {
      allowUpdate = true;
    }

    if (allowUpdate) {
      this.props.updateValue(
        e.target.value,
        this.props.fieldId,
        this.props.systemName
      );
    }
  }

  onEditDateField(e) {
    let newValue = e.target.value;
    let allowChange = true;
    newValue = newValue.replace('/', '');
    newValue = newValue.replace('/', '');
    newValue = newValue.replace('.', '');
    newValue = newValue.replace('.', '');
    newValue = newValue.replace('-', '');
    newValue = newValue.replace('-', '');
    //let m = 0;
    //let d = 0;
    //let y = 0;
    //let separator = '/';
    //if (this.props.format === 'mm-dd-yyyy') separator = '-';
    if (isNaN(newValue)) {
      allowChange = false;
    }
    if (
      allowChange &&
      (this.props.format === 'mm/dd/yyyy' || this.props.format === 'mm-dd-yyyy')
    ) {
      if (newValue.length === 2) {
      }
      if (newValue.length === 4) {
      }
      if (newValue.length === 6) {
        // m = parseInt(newValue.substring(0, 2), 10);
        // d = parseInt(newValue.substring(2, 4), 10);
        // y = parseInt(newValue.substring(4, 6), 10);
        // y = 2000 + y;
        //let date = new Date(y, m - 1, d);
      }
    }

    if (allowChange) {
      this.props.updateValue(
        e.target.value,
        this.props.fieldId,
        this.props.systemName
      );
    }
  }
  onMouseEnter(e) {
    /*
  if(e.currentTarget.childNodes.length!= this.props.extender.allowedValues.length)
  {
    let len =e.currentTarget.childNodes.length;
      for(let i = 0; i<len; i++){
        let temp = e.currentTarget.childNodes[i];
        if(temp.value==='-200')
        {
          e.currentTarget.childNodes.remove(temp);
        }
      }
  }
*/
  }
  editFromFilter(e) {}
  onEditAutoComplete(e) {}

  onEditKeyValue(e) {}

  onClickAutoComplete(e, i, inp) {}

  tabNext(inp) {
    var inputs = document
      .getElementById('headerFieldContainer')
      .getElementsByTagName('input');
    var inputArr = Array.prototype.slice.call(inputs);
    var index = inputArr.indexOf(inp);
    inputArr[index + 1].focus();
    this.props.closeAllList();
  }

  onBlurTextField(e) {
    if (this.props.isRequired === true && this.props.value === '') {
      this.props.setError(this.props.fieldId, 'Field is required');
    } else if (this.props.isRequired === true && this.props.value !== '') {
      this.props.setError(this.props.fieldId, '');
    }

    if (
      this.props.isRequired === true &&
      this.props.value !== '' &&
      this.props.error !== ''
    ) {
      this.props.setError(this.props.fieldId, '');
    }

    if (this.props.hasExtender) {
      if (this.props.extenderKey !== null) {
        this.props.setError(this.props.fieldId, '');
      }
    }
  }

  onBlurNumField(e) {
    let newValue = e.target.value;
    let updateValue = false;
    if (newValue === '') {
      if (this.props.isRequired === true) {
        this.props.setError(this.props.fieldId, 'Field is required');
      } else {
        this.props.setError(this.props.fieldId, '');
      }

      updateValue = true;
    } else if (isNaN(+newValue)) {
      this.props.setError(this.props.fieldId, 'Value must be numeric');
    } else if (!isNaN(+newValue)) {
      let indexOfDecimal = newValue.indexOf('.');
      this.props.setError(this.props.fieldId, '');
      if (indexOfDecimal > -1) {
        let currentDigits = newValue.length - indexOfDecimal - 1;

        if (currentDigits < this.props.decimalPlaces) {
          while (currentDigits < this.props.decimalPlaces) {
            newValue = newValue + '0';
            currentDigits = newValue.length - indexOfDecimal - 1;
          }
          updateValue = true;
        }
      } else {
        if (this.props.decimalPlaces > 0) {
          // add decimal places
          newValue = newValue + '.';
          for (let i = 0; i < this.props.decimalPlaces; i++) {
            newValue = newValue + '0';
          }
          updateValue = true;
        }
        if (this.props.decimalPlaces === 0) {
          updateValue = true;
        }
      }
    }

    // if (newValue !== '' && this.props.error !== '') {
    //   this.props.setError(this.props.fieldId, '');
    // }

    if (updateValue) {
      if (newValue !== '' && this.props.error !== '') {
        this.props.setError(this.props.fieldId, '');
      }

      this.props.updateValue(
        newValue,
        this.props.fieldId,
        this.props.systemName
      );
    }
  }

  onBlurDateField(e) {
    let newValue = e.target.value;
    if (newValue.length === 5 && !isNaN(newValue)) {
      newValue = '0' + newValue;
    }
    let format = this.props.format;

    if (newValue === '') {
      this.props.updateValue(
        newValue,
        this.props.fieldId,
        this.props.systemName
      );
      return;
    }
    let allowChange = true;

    if (
      format === 'mm/dd/yyyy' ||
      format === 'dd/mm/yyyy' ||
      format === 'mm-dd-yyyy'
    ) {
      let parts1 = newValue.split('/');
      if (format === 'mm-dd-yyyy') parts1 = newValue.split('-');
      if (parts1.length === 3) {
        if (parts1[0].length === 1) {
          parts1[0] = '0' + parts1[0];
        }
        if (parts1[1].length === 1) {
          parts1[1] = '0' + parts1[1];
        }
        if (parts1[2].length === 2) {
          parts1[2] = '20' + parts1[2];
        }
        newValue = parts1[0] + '/' + parts1[1] + '/' + parts1[2];
      }
    }

    newValue = newValue.replace('/', '');
    newValue = newValue.replace('/', '');
    newValue = newValue.replace('.', '');
    newValue = newValue.replace('.', '');
    newValue = newValue.replace('-', '');
    newValue = newValue.replace('-', '');
    let m = 0;
    let d = 0;
    let y = 0;
    //let separator = '/';
    //if (this.props.format === 'mm-dd-yyyy') separator = '-';
    if (isNaN(newValue)) {
      allowChange = false;
    }
    if (
      allowChange &&
      (this.props.format === 'mm/dd/yyyy' || this.props.format === 'mm-dd-yyyy')
    ) {
      if (newValue.length === 6) {
        m = parseInt(newValue.substring(0, 2), 10);
        d = parseInt(newValue.substring(2, 4), 10);
        y = parseInt(newValue.substring(4, 6), 10);
        y = 2000 + y;

        let date = new Date(y, m - 1, d);
        if (date.toString() === 'Invalid Date') {
          allowChange = false;
        }
        if (date.getDate() !== d) {
          allowChange = false;
        }
        let newDate = this.props.format;
        newDate = newDate.replace('mm', newValue.substring(0, 2));
        newDate = newDate.replace('dd', newValue.substring(2, 4));
        newDate = newDate.replace('yyyy', '20' + newValue.substring(4, 6));
        if (allowChange) {
          this.props.updateValue(
            newDate,
            this.props.fieldId,
            this.props.systemName
          );
        }
      } else if (newValue.length === 8) {
        m = parseInt(newValue.substring(0, 2), 10);
        d = parseInt(newValue.substring(2, 4), 10);
        y = parseInt(newValue.substring(4, 8), 10);
        //y = 2000 + y;

        if (m > 12) {
          allowChange = false;
        }
        let date = new Date(y, m - 1, d);
        if (date.toString() === 'Invalid Date') {
          allowChange = false;
        }
        if (date.getDate() !== d) {
          allowChange = false;
        }
        let newDate = this.props.format;
        newDate = newDate.replace('mm', newValue.substring(0, 2));
        newDate = newDate.replace('dd', newValue.substring(2, 4));
        newDate = newDate.replace('yyyy', newValue.substring(4, 8));
        if (allowChange) {
          this.props.updateValue(
            newDate,
            this.props.fieldId,
            this.props.systemName
          );
        }
      } else {
        allowChange = false;
      }
    } else if (
      allowChange &&
      (this.props.format === 'dd/mm/yyyy' || this.props.format === 'dd-yy-yyyy')
    ) {
      if (newValue.length === 6) {
        d = parseInt(newValue.substring(0, 2), 10);
        m = parseInt(newValue.substring(2, 4), 10);
        y = parseInt(newValue.substring(4, 6), 10);
        y = 2000 + y;

        let date = new Date(y, m - 1, d);

        if (date.toString() === 'Invalid Date') {
          allowChange = false;
        }
        if (date.getDate() !== d) {
          allowChange = false;
        }
        let newDate = this.props.format;
        newDate = newDate.replace('mm', newValue.substring(2, 4));
        newDate = newDate.replace('dd', newValue.substring(0, 2));
        newDate = newDate.replace('yyyy', '20' + newValue.substring(4, 6));
        if (allowChange) {
          this.props.updateValue(
            newDate,
            this.props.fieldId,
            this.props.systemName
          );
        }
      } else if (newValue.length === 8) {
        d = parseInt(newValue.substring(0, 2), 10);
        m = parseInt(newValue.substring(2, 4), 10);
        y = parseInt(newValue.substring(4, 8), 10);
        //y = 2000 + y;

        let date = new Date(y, m - 1, d);

        if (date.toString() === 'Invalid Date') {
          allowChange = false;
        }
        if (date.getDate() !== d) {
          allowChange = false;
        }
        let newDate = this.props.format;
        newDate = newDate.replace('dd', newValue.substring(0, 2));
        newDate = newDate.replace('mm', newValue.substring(2, 4));
        newDate = newDate.replace('yyyy', newValue.substring(4, 8));
        if (allowChange) {
          this.props.updateValue(
            newDate,
            this.props.fieldId,
            this.props.systemName
          );
        }
      } else {
        allowChange = false;
      }
    }

    if (!allowChange) {
      this.props.setError(
        this.props.fieldId,
        `Value entered in this field is invalid. Field must follow date format ${this.props.format}`
      );
    }
    if (allowChange && this.props.error !== '') {
      this.props.setError(this.props.fieldId, '');
    }
  }

  onBlurAutoComplete(e) {
    /*
    let hasPick = false;
    let val = this.state.currentText;
    for (let i = 0; i < this.props.extender.allowedValues.length; i++) {
      if (
        this.props.extender.allowedValues[i].textValue
          .substring(0, val.length)
          .toUpperCase() === val.toUpperCase()
      ) {
        hasPick = true;
        this.setState({
          currentText: this.props.extender.allowedValues[i].textValue,
        });

        this.props.updateValue(
          this.props.extender.allowedValues[i].textValue,
          this.props.fieldId,
          this.props.systemName
        );
        break;
      }
    }
    if (!hasPick) {
      this.setState({ currentText: this.props.value });
    }
    this.props.closeAllList();
    */
  }

  onBlurKeyValue(e) {
    let hasPick = false;
    let val = this.state.currentText.trim();
    for (let i = 0; i < this.props.extender.allowedValues.length; i++) {
      if (
        this.props.extender.allowedValues[i].textValue
          .substring(0, val.length)
          .toUpperCase() === val.toUpperCase()
      ) {
        hasPick = true;
        this.setState({
          currentText: this.props.extender.allowedValues[i].textValue,
        });

        // this.props.updateValue(
        //   this.props.extender.allowedValues[i].textValue,
        //   this.props.fieldId,
        //   this.props.extender.allowedValues[i].keyValue
        // );
        break;
      }
    }
    if (!hasPick) {
      this.setState({ currentText: this.props.value });
    }
    this.props.closeAllList();
  }

  onCopyField() {
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(this.props.value);
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = this.props.value;
      textArea.style.position = 'absolute';
      textArea.style.left = '-999999px';
      document.body.prepend(textArea);
      textArea.select();

      try {
        document.execCommand('copy');
      } catch (error) {
        console.error(error);
      } finally {
        textArea.remove();
      }
    }
    navigator.clipboard.writeText(this.props.value);
  }

  onKeyDown(e) {
    if (e.keyCode === 13) {
      //var form = e.target.form;
      var inputs = document
        .getElementById('headerFieldContainer')
        .getElementsByTagName('input');
      var inputArr = Array.prototype.slice.call(inputs);
      var index = inputArr.indexOf(e.target);
      inputArr[index + 1].focus();
      this.props.closeAllList();
      e.preventDefault();
    }
  }

  expandView(text) {
    this.props.expandView(text, this.props.displayName);

  }

  render() {
    return (
      <div className='ps-2 pe-2 pb-1'>
        <div
          className='ps-1'
          style={{ fontSize: this.props.fontSize === 0 ? '9pt' : '12pt' }}
        >
          {' '}
          {this.props.isRequired && (
            <FontAwesomeIcon
              style={{ color: '#5CB5C1' }}
              className='me-2'
              icon={solid('asterisk')}
            />
          )}
          {this.props.displayName}
          {this.props.format !== null &&
            this.props.format !== undefined &&
            this.props.format !== '' &&
            this.props.dataType === 2 && (
              <span className='de-format'>
                {'('}
                {this.props.format}
                {')'}
              </span>
            )}

          <span
            style={{
              float: 'right',
              marginRight: '10px',
              color: '#6D86EC',
              cursor: 'pointer',
              toolTip: 'Copy to clipboard',
            }}
            title='Copy to Clipboard'
          >
            <FontAwesomeIcon onClick={this.onCopyField} icon={solid('paste')} />
          </span>
          <span
            style={{
              float: 'right',
              marginRight: '10px',
              color: '#6D86EC',
              cursor: 'pointer',
              toolTip: 'View',
            }}
            title='View field'
          ><FontAwesomeIcon onClick={() => this.expandView(this.props.textValue)} icon={solid('eye')} /></span>
          {this.props.error !== '' && this.props.error !== undefined && (
            <div
              className='errorField'
              style={{
                float: 'right',
                marginRight: '10px',
                color: '#F43D3D',
                cursor: 'pointer',
              }}
            >
              <FontAwesomeIcon icon={solid('circle-exclamation')} />

              <span className='tooltiptext'>{this.props.error}</span>
            </div>
          )}
        </div>
        <div style={{ position: 'relative' }}>
          {this.props.hasExtender && this.props.extender.type === 4 && (
            <select
              id={`${this.props.fieldId}_dropdown`}
              disabled={this.props.readOnly}
              className={`form-select ${
                this.props.fontSize === 0 ? 'form-select-sm' : ''
              } ${this.props.error === '' ? 'fieldEdit' : 'fieldEditError'}`}
              value={this.props.textValue}
              onBlur={this.onBlurTextField}
              onMouseDownCapture={this.onMouseDownCapture}
              onChange={this.onEditExtender}
              onKeyDown={this.onKeyDown}
              onMouseEnter={(e) => this.onMouseEnter(e)}
            >
              {this.props.extender.allowedValues.map((op) => (
                <option key={op.extenderKey} value={op.textValue}>
                  {op.textValue}
                </option>
              ))}
            </select>
          )}

          {this.props.hasExtender && this.props.extender.type === 1 && (
            <div>
              <AutoCompleteExtender
                id={`autoCompleteExtender_${this.props.fieldId.toString()}`}
                fieldId={this.props.fieldId}
                value={this.state.currentText}
                isKeyValue={this.state.isKeyValue}
                keyValue={this.props.extenderKey}
                disabled={this.props.readOnly}
                allowedValues={this.props.extender.allowedValues}
                valueField=''
                displayField=''
                error={this.props.error}
                extender={this.props.extender}
                onBlur={this.onBlurAutoComplete}
                onEdit={this.OnAutoCompleteEdit}
                setError={this.AutoCompleteSetError}
              />
            </div>
          )}

          {this.props.hasExtender && this.props.extender.type === 2 && (
            <div
              id={`autoContainer${this.props.fieldId.toString()}`}
              className='autocomplete'
            >
              <input
                id={`fieldEdit_${this.props.fieldId}`}
                disabled={this.props.readOnly}
                className={`form-control ${
                  this.props.fontSize === 0 ? 'form-control-sm' : ''
                } ${this.props.error === '' ? 'fieldEdit' : 'fieldEditError'}`}
                value={this.state.currentText}
                onBlur={this.onBlurKeyValue}
                onChange={this.onEditKeyValue}
                onKeyDown={this.onKeyDown}
                autoComplete='off'
              />
            </div>
          )}

          {this.props.dataType === 0 && !this.props.hasExtender && (
            <input
              id={`fieldEdit_${this.props.fieldId}`}
              disabled={this.props.readOnly}
              className={`form-control ${
                this.props.fontSize === 0 ? 'form-control-sm' : ''
              } ${this.props.error === '' ? 'fieldEdit' : 'fieldEditError'}`}
              value={this.props.value}
              onBlur={this.onBlurTextField}
              onChange={this.onEditField}
              onKeyDown={this.onKeyDown}
              autoComplete='off'
            />
          )}

          {this.props.dataType === 1 && !this.props.hasExtender && (
            <>
              <div
                className={`input-group ${
                  this.props.fontSize === 0 ? 'input-group-sm' : ''
                }`}
              >
                {this.props.currencyCode !== null && (
                  <>
                    {this.props.currencyCode === 'USD' && (
                      <span className='input-group-text'>$</span>
                    )}
                    {this.props.currencyCode === 'GBP' && (
                      <span className='input-group-text'>£</span>
                    )}
                    {this.props.currencyCode === 'EUR' && (
                      <span className='input-group-text'>€</span>
                    )}
                    {this.props.currencyCode === 'RUB' && (
                      <span className='input-group-text'>₽</span>
                    )}
                    {this.props.currencyCode === 'JPY' && (
                      <span className='input-group-text'>¥</span>
                    )}
                    {this.props.currencyCode === 'PHP' && (
                      <span className='input-group-text'>₱</span>
                    )}
                  </>
                )}
                <input
                  id={`fieldEdit_${this.props.fieldId}`}
                  disabled={this.props.readOnly}
                  onBlur={this.onBlurNumField}
                  className={`form-control ${
                    this.props.fontSize === 0 ? 'form-control-sm' : ''
                  } ${
                    this.props.error === '' ? 'fieldEdit' : 'fieldEditError'
                  }`}
                  value={this.props.value}
                  onKeyDown={this.onKeyDown}
                  onChange={this.onEditNumField}
                  title={this.state.myFieldTitle}
                  autoComplete='off'
                />
              </div>
            </>
          )}

          {this.props.dataType === 2 && !this.props.hasExtender && (
            <input
              id={`fieldEdit_${this.props.fieldId}`}
              disabled={this.props.readOnly}
              onBlur={this.onBlurDateField}
              className={`form-control ${
                this.props.fontSize === 0 ? 'form-control-sm' : ''
              } ${this.props.error === '' ? 'fieldEdit' : 'fieldEditError'}`}
              value={this.props.value}
              onKeyDown={this.onKeyDown}
              onChange={this.onEditDateField}
              title={this.state.myFieldTitle}
              autoComplete='off'
            />
          )}
        </div>
      </div>
    );
  }
}

export default FieldEditor;
