import * as React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import BPStore from '../../../../stores/BPStore';
import { GetBPList, ImportBP } from '../../../../actions/BPActions';
import actionTypes from '../../../../actions/ActionTypes';
import { PostAlert } from '../../../../actions/AlertActions';

class BPSidebar extends React.Component {
  constructor() {
    super();
    this.state = {
      bpList: [],
      searchText: '',
      filteredBPList: [],
      importBP: false,
      waitingOnImport: false,
      importResult: false,
      importMessage: '',
      importedBPName: '',
      allowImport: false,
    };

    this.onReceiveBPChange = this.onReceiveBPChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onClickImportBp = this.onClickImportBp.bind(this);
    this.closeImportBp = this.closeImportBp.bind(this);
    this.importNewBp = this.importNewBp.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.allowImportCheck = this.allowImportCheck.bind(this);
  }

  componentDidMount() {
    BPStore.addChangeListener(this.onReceiveBPChange);
    GetBPList();
  }

  componentWillUnmount() {
    BPStore.removeChangeListener(this.onReceiveBPChange);
  }

  onReceiveBPChange() {
    let lastChange = BPStore.getLastChange();
    if (lastChange === actionTypes.BPGetList) {
      let bps = BPStore.getBPList();
      this.setState({
        bpList: bps,
        searchText: '',
        filteredBPList: bps,
      });
    }
    if (lastChange === actionTypes.ImportBP) {
      let importMessage = BPStore.getImportMessage();
      this.setState({ waitingOnImport: false, importResult: true, importMessage: importMessage });
      GetBPList();
    }
  }

  onClickImportBp() {
    this.setState({ importBP: true });
  }

  closeImportBp() {
    this.setState({ importBP: false, importMessage: '' });
  }

  importNewBp() {
    new Response(document.getElementById('importBPfile').files[0]).json().then(json => {
      this.setState({ waitingOnImport: true });
      ImportBP(this.state.importedBPName, JSON.stringify(json));
    }, err => {
      // not json
      PostAlert('error', 'Error! File uploaded is not correct json format');
    })

  }

  onChangeName(e) {
    this.setState({ importedBPName: e.target.value });
    this.allowImportCheck();
  }

  allowImportCheck() {
    if (this.state.importedBPName !== '' && document.getElementById('importBPfile').files.length > 0){
      this.setState({ allowImport: true });
    }
    else {
      this.setState({ allowImport: false });
    }
  }

  

  onSearchChange(e) {
    let search = e.target.value;
    if (search === '') {
      let newList = [...this.state.bpList];
      this.setState({ searchText: search, filteredBPList: newList });
    } else {
      let newList = [];
      this.state.bpList.forEach((bp) => {
        if (bp.bpName.toLowerCase().includes(search.toLowerCase())) {
          newList.push(bp);
        }
      });
      this.setState({ searchText: search, filteredBPList: newList });
    }
  }

  render() {
    let listOfBPs;
    if (this.state.bpList !== null) {
      listOfBPs = this.state.filteredBPList.map((bp) => (
        <div key={bp.bpId}>
          <Link
            className='sidebar-link'
            to={'/Admin/BusinessProcess/' + bp.bpId}
            //onClick={() => this.handleBPClick(bp)}
            style={{ cursor: 'Pointer', wordWrap: 'break-word' }}
          >
            {bp.bpName}
          </Link>{' '}
        </div>
      ));
    } else {
      listOfBPs = <div />;
    }
    return (
      <div
        className='d-flex flex-column p-3 text-white bg-dark'
        style={{ height: '100%', width: '274px' }}
      >
        <Link
          to='/Admin/BusinessProcess'
          className='d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none'
        >
          <span className='fs-4'>
            <span style={{ color: '#B023DD' }}>
              <FontAwesomeIcon icon={solid('th-large')} />
            </span>
            {'   '}
            Business Processes
          </span>
        </Link>
        <hr />
        <div className='input-group rounded' style={{ marginBottom: '16px' }}>
          <input
            type='search'
            value={this.state.searchText}
            className='form-control'
            placeholder='Search'
            aria-label='Search'
            onChange={this.onSearchChange}
            aria-describedby='search-addon'
          />
          <span className='input-group-text border-0' id='search-addon'>
            <FontAwesomeIcon icon={solid('search')} />
          </span>
        </div>

        <div>{listOfBPs}</div>
        <hr />
        <Link className='sidebar-link' to='/Admin/BusinessProcess/0'>
          New Business Process
        </Link>

        <Link onClick={this.onClickImportBp} className='sidebar-link' to=''>
          Import Business Process
        </Link>
        {this.state.importBP && (
          <div className='td-dialogue-container'>
            <div className='td-dialogue card' style={{ width: '450px' }}>
              <div className='card-header'>Import new Business Process:
                <button
                  type='button'
                  className='btn-close'
                  onClick={this.closeImportBp}
                />
              </div>
              <div
                className='card-body'
                style={{ height: '250px', overflow: 'auto' }}
              >

                {this.state.waitingOnImport && (
                  <>
                    <div className='fs-5'>
                      Please wait while your new Business Process is being created...
                    </div>
                    <div class="fa-5x" style={{ textAlign: 'center' }}>
                      <FontAwesomeIcon icon={solid('spinner')} className="fa-spin-pulse"/>
                    </div>
                  </>
                )}
                {!this.state.waitingOnImport && (
                  <div className=''>

                    {this.state.importMessage !== '' && (
                      <div className='pt-2'>
                        {this.state.importMessage}
                      </div>
                    )}

                    {this.state.importMessage === '' && (
                      
                      <div className=''>
                        <div className='row mb-3'>
                          <label
                            className='col col-form-label'
                            htmlFor='txtUserName'
                          >
                            Name:
                          </label>
                          <div className='col-sm-10'>
                            <input
                              type='text'
                              className='form-control'
                              id='txtUserName'
                              value={this.state.importedBPName}
                              onChange={this.onChangeName}
                              placeholder='New Business Process Name'
                            />
                          </div>
                        </div>

                        <input id='importBPfile' type="file" onChange={this.allowImportCheck}/>

                        <button className={`btn btn-success ${
                            this.state.allowImport
                              ? ''
                              : 'disabled'
                          }`}
                          onClick={this.importNewBp}>
                          Upload
                        </button>
                      </div>
                      
                    )}
                    
                  </div>
                )}

              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default BPSidebar;
