import dispatcher from '../Dispatcher';
import actionTypes from './ActionTypes';
import axios from 'axios';
import * as Globals from '../Globals';
import { Logout } from './LoginActions';
import { PostAlert } from './AlertActions';



export function GetFieldRelations(formTypeId) {
  axios
    .get(`${Globals.baseUrl}/FieldRelation/GetFieldRelations/${formTypeId}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.GetFieldRelations,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
      dispatcher.dispatch({
        actionType: actionTypes.GetFieldRelations,
        Result: result,
      });
    });
}


export function UpdateFieldRelation(baseFieldId, relatedFormTypeId, relatedFieldId, twoWayLink) {
    axios
      .post(
        `${Globals.baseUrl}/FieldRelation/UpdateFieldRelation`,
        { baseFieldId: baseFieldId, relatedFormTypeId: relatedFormTypeId, relatedFieldId: relatedFieldId, twoWayLink: twoWayLink },
        {
          headers: {
            'content-type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${Globals.getJwtToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          dispatcher.dispatch({
            actionType: actionTypes.UpdateFieldRelation,
            Result: res.data,
          });
          if (res.data.status === 'Success') {
              console.log('post alert');
            PostAlert('success', 'Field relation created');
          }
          if (res.data.status === 'Error') {
            PostAlert('error', 'Unable to create field relation');
          }
        } else if (res.status === 400 || res.status === 401) {
          console.log('error: 401');
        }
      })
      .catch((res) => {
        console.log(res);
      });
  }


export function DeleteFieldRelation(id, twoWayLink) {
  axios
    .post(`${Globals.baseUrl}/FieldRelation/Delete`, 
    { id: id, twoWayLink: twoWayLink },
    {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.DeleteFieldRelation,
          Result: res.data,
        });
        PostAlert('Success', 'Field relation deleted');
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let errorMsg = res.toString();
      if (errorMsg.includes('401')) {
        console.log('Unauthorized');
        Logout();
      }
    });
}