import dispatcher from '../Dispatcher';
import actionTypes from './ActionTypes';
import axios from 'axios';
import * as Globals from '../Globals';

export function GetScheduledIntegrationList() {
  axios
    .get(`${Globals.baseUrl}/Integration/GetScheduledList`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.IntegrationsGetList,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
      dispatcher.dispatch({
        actionType: actionTypes.IntegrationsGetList,
        Result: result,
      });
    });
}