import * as React from 'react';
import CompanionSidebar from './CompanionSidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

class Companion extends React.Component {
  constructor() {
    super();
    // state
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div className='container-fluid' style={{ height: '100%' }}>
        <div className='row flex-nowrap' style={{ height: '100%' }}>
          <CompanionSidebar />
          <div className='h-100 d-flex align-items-center'>
            <div
              className='p-2 col-example'
              style={{
                textAlign: 'center',
                marginLeft: '100px',
                marginRight: '100px',
                width: '60%',
              }}
            >
              <FontAwesomeIcon icon={solid('table')} size='6x' />

              <h2 style={{ fontWeight: 'bolder', fontStyle: 'italic' }}>
                Companion Tables
              </h2>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Companion;
