import * as React from 'react';

class UserSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentText: '',
      filteredValues: [],
      currentSelection: undefined,
      selIndex: 0,
      showList: false,
      previousValue: '',
      forceDropdown: false,
    };

    this.onEditAutoComplete = this.onEditAutoComplete.bind(this);
    this.onBlurAutoComplete = this.onBlurAutoComplete.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onClickAutoComplete = this.onClickAutoComplete.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
  }

  componentDidMount() {
    if (this.props.allowedValues) {
      let node = this.props.allowedValues.find(
        (f) => f[this.props.valueField] === this.props.value
      );

      let textVal = '';
      if (node && this.props.value !== null) {
        textVal = node[this.props.displayField];
      } else {
        textVal = this.props.value;
      }

      if (textVal === null || textVal === undefined) textVal = '';
      this.setState({
        currentText: textVal,
        currentValue: this.props.value,
      });
    }
  }

  componentWillUnmount() {}

  // static getDerivedStateFromProps(props, state) {
  //   if (props.value !== state.currentValue) {
  //     return {
  //       currentText: props.value,
  //       currentValue: props.value,
  //     };
  //   }
  //   return null;
  //   //this.setState({currentText: props.fieldValue})
  // }

  componentDidUpdate() {
    if (this.props.value === 0 && this.state.currentText === 0) {
      this.setState({ currentText: '' });
    }
    if (
      this.props.value !== this.state.currentValue &&
      this.props.allowedValues
    ) {
      let node = this.props.allowedValues.find(
        (f) => f[this.props.valueField] === this.props.value
      );
      let textVal = '';
      if (node && this.props.value !== null) {
        textVal = node[this.props.displayField];
      } else {
        textVal = this.props.value;
      }

      if (textVal === null) textVal = '';
      this.setState({
        currentText: textVal,
        currentValue: this.props.value,
      });
    }
  }

  onEditAutoComplete(e) {
    let filter = [];
    let val = e.target.value;

    for (let i = 0; i < this.props.allowedValues.length; i++) {
      if (
        this.props.allowedValues[i].firstName
          .substring(0, val.length)
          .toUpperCase() === val.toUpperCase() ||
        this.props.allowedValues[i].lastName
          .substring(0, val.length)
          .toUpperCase() === val.toUpperCase() ||
        this.props.allowedValues[i].userName
          .substring(0, val.length)
          .toUpperCase() === val.toUpperCase()
      ) {
        filter.push(this.props.allowedValues[i]);
      }
    }
    let sindex = 0;
    if (filter.length > 0) {
      let sel = filter[0];
      this.setState({
        selIndex: sindex,
        currentSelection: sel,
        showList: true,
      });
    } else {
      this.setState({
        selIndex: 0,
        currentSelection: null,
        showList: false,
        forceDropdown: false,
      });
    }

    //this.props.updateValue(e.target.value, this.props.fieldId, this.props.systemName);
    this.setState({
      currentText: e.target.value,
      filteredValues: filter,
    });
  }

  onClickAutoComplete(e, sel) {
    this.setState({
      currentText: sel[this.props.displayField],
      showList: false,
    });
    if (this.props.onChange) {
      this.props.onChange(sel[this.props.valueField]);
    }
    //this.props.closeAllList();
    //this.tabNext(inp);
    e.preventDefault();
  }

  onBlurAutoComplete(e) {
    if (this.state.currentText !== '') {
      let sel = this.state.currentSelection;
      if (
        sel !== null &&
        sel !== undefined &&
        sel.hasOwnProperty(this.props.displayField)
      ) {
        this.setState({
          currentText: sel[this.props.displayField],
          showList: false,
        });
        if (this.props.onChange) {
          this.props.onChange(sel[this.props.valueField]);
        }
      }
      if (sel === null || sel === undefined) {
        //sel = { id: this.state.currentText, value: this.state.currentText };
        //if (this.props.onChange && this.props.enforce !== 'true') {
        //  this.props.onChange(e.target.value);
        //}
      }
    } else {
      this.props.onChange('');
    }
    this.setState({ showList: false });
    //
  }

  onFocus(e) {
    if (this.props.setFocus !== null && this.props.setFocus !== undefined) {
      this.props.setFocus(this.props.id);
    }

    let fullList = this.props.allowedValues;
    if (fullList) {
      this.setState({ showList: true, filteredValues: [...fullList] });
    }

    e.target.select();
  }

  onKeyDown(e) {
    if (e.keyCode === 13) {
      //var form = e.target.form;
      let sel = this.state.currentSelection;
      this.setState({
        currentText: sel[this.props.displayField],
        showList: false,
      });
      e.preventDefault();
    }

    // up
    if (e.keyCode === 38) {
      let sindex = this.state.selIndex;
      sindex--;
      if (sindex >= 0) {
        this.setState({
          currentSelection: this.state.filteredValues[sindex],
          selIndex: sindex,
          showList: true,
        });
      }
      e.preventDefault();
    }
    // down
    if (e.keyCode === 40) {
      let sindex = this.state.selIndex;
      sindex++;
      if (sindex < this.state.filteredValues.length) {
        this.setState({
          currentSelection: this.state.filteredValues[sindex],
          selIndex: sindex,
          showList: true,
        });
      }
      e.preventDefault();
    }
  }

  onMouseEnter(e, sel) {
    this.setState({ currentSelection: sel });
  }

  render() {
    return (
      <div style={{ position: 'relative' }}>
        <div id={this.props.id} className='autocomplete'>
          <input
            id={`${this.props.id}_text`}
            className='form-control autocomplete'
            value={this.state.currentText}
            onBlur={this.onBlurAutoComplete}
            onChange={this.onEditAutoComplete}
            onFocus={this.onFocus}
            disabled={this.props.disabled ? 'disabled' : ''}
            onKeyDown={this.onKeyDown}
          />
        </div>
        {this.state.showList && (
          <div
            className='autocomplete-items'
            style={{ maxHeight: '200px', overflow: 'auto' }}
          >
            {this.state.filteredValues.map((sel) => (
              <div
                tabIndex='0'
                key={sel[this.props.valueField]}
                style={{
                  cursor: 'pointer',
                  backgroundColor:
                    sel === this.state.currentSelection ? '#E4EFFF' : '#fff',
                }}
                onMouseDown={(e) => this.onClickAutoComplete(e, sel)}
                onMouseEnter={(e) => this.onMouseEnter(e, sel)}
              >
                {sel.firstName} {sel.lastName}
                {' ('}
                {sel.userName}
                {')'}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default UserSelect;
