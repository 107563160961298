import dispatcher from '../Dispatcher';
import actionTypes from './ActionTypes';
import axios from 'axios';
import * as Globals from '../Globals';
import { Logout } from './LoginActions';
import { PostAlert } from './AlertActions';

var activeExtenders = 0;
const maxExtenders = 7;
var pendingExtenders = [];

export function GetDocument(documentId, relatedDocId) {
  let url = `${Globals.baseUrl}/Document/GetDocument/${documentId}/${relatedDocId}`;
  if (
    relatedDocId === undefined ||
    relatedDocId === null ||
    relatedDocId === ''
  )
    url = `${Globals.baseUrl}/Document/GetDocument/${documentId}`;
  axios
    .get(url, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.DocumentLoad,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let errorMsg = res.toString();
      if (errorMsg.includes('401')) {
        console.log('Unauthorized');
        Logout();
      }
    });
}

export function CheckIn(documentId, document) {

  let doc2 = CloneAndCleanDocument(document);
  console.log(doc2);
  axios
    .post(
      `${Globals.baseUrl}/Document/CheckinDocument`,
      {
        documentId: documentId,
        document: doc2,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        let result = res.data;
        if (result.status === 'Error') {
          PostAlert('error', result.message);
        }
        if (result.status === 'Success') {
          PostAlert('success', result.message);
        }
        dispatcher.dispatch({
          actionType: actionTypes.DocumentCheckIn,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}

function CloneAndCleanDocument(document)
{
  let doc2 = {...document};
  doc2.fieldSets = [];
  if (document.fieldSets !== null && document.fieldSets !== undefined){
    for(let i = 0; i < document.fieldSets.length; i++)
      {
        let fs = document.fieldSets[i];
        let fs2 = {...document.fieldSets[i]};
        fs2.rowDependencies = [];
        fs2.rows = [];
        for(let j = 0; j < fs.rows.length; j++)
        {
          let r1 = fs.rows[j];
          let r2 = {...r1};
          r2.fieldData = [];
          for(let k = 0; k < r1.fieldData.length; k++)
          {
            let fd1 = r1.fieldData[k];
            let fd2 = {...fd1};
            fd2.allowedValues = [];
            r2.fileldData = []
            r2.fieldData.push(fd2);
          }
          fs2.rows.push(r2);
        }
        doc2.fieldSets.push(fs2);
      }
  }
  doc2.destinations = [];
  doc2.headerDependencies = [];
  return doc2
}

export function SaveDocument(documentId, document) {

  let doc2 = CloneAndCleanDocument(document);
  console.log('SaveDocument');
  axios
    .post(
      `${Globals.baseUrl}/Document/SaveDocument`,
      {
        documentId: documentId,
        document: doc2,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        let result = res.data;
        if (result.status === 'Error') {
          PostAlert('error', result.message);
        }
        if (result.status === 'Success') {
          PostAlert('success', result.message);
        }
        if (result.status === 'Error' || result.status === 'Success') {
          dispatcher.dispatch({
            actionType: actionTypes.DocumentSave,
            Result: res.data,
          });
        } else if (result.status === 'Submitted') {
          PostAlert('success', 'Document Saved and auto routed');
          dispatcher.dispatch({
            actionType: actionTypes.DocumentSubmit,
            Result: res.data,
          });
        }
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}

export function SubmitDocument(actionCode, document) {
  let doc2 = CloneAndCleanDocument(document);

  console.log('submit');
  axios
    .post(
      `${Globals.baseUrl}/Document/SubmitDocument`,
      {
        action: actionCode,
        document: doc2,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        console.log(res);
        let result = res.data;
        if (result.status === 'Error') {
          PostAlert('error', result.message);
          dispatcher.dispatch({
            actionType: actionTypes.DocumentSubmit,
            Result: res.data,
          });
        }
        if (result.status === 'Success') {
          PostAlert('success', result.message);
          dispatcher.dispatch({
            actionType: actionTypes.DocumentSubmit,
            Result: res.data,
          });
        }
        if (result.status === 'Alert') {
          dispatcher.dispatch({
            actionType: actionTypes.DocumentSubmit,
            Result: res.data,
          });
        }
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}

export function GetDocumentStatusInfo(documentId) {
  //let x = JSON.stringify(document);
  //let y = document;
  //console.log(x);
  axios
    .get(
      `${
        Globals.baseUrl
      }/Document/GetDocumentStatusInfo/${documentId.toString()}`,
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        let result = res.data;

        dispatcher.dispatch({
          actionType: actionTypes.DocumentGetStatusInfo,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}

export function GetRelatedDocs(documentId) {
  //let x = JSON.stringify(document);
  //let y = document;
  //console.log(x);
  axios
    .get(
      `${
        Globals.baseUrl
      }/Document/GetRelatedDocs/${documentId.toString()}`,
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        let result = res.data;

        dispatcher.dispatch({
          actionType: actionTypes.DocumentGetRelatedDocs,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}

export function UpdateUserFieldSettings(formTypeId, settings) {
  axios
    .post(
      `${Globals.baseUrl}/Document/UpdateUserFieldSettings`,
      {
        formTypeId: formTypeId,
        settings: settings,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        //let result = res.data;
        PostAlert('success', 'User settings updated');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}

export function GetNextDocument(oldId, newId) {
  axios
    .post(
      `${Globals.baseUrl}/Document/GetNextDocument`,
      {
        previousDocId: oldId,
        nextDocId: newId,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        var result = res.data;
        if (result.status === 'END') {
          dispatcher.dispatch({
            actionType: actionTypes.DocumentLoad,
            Result: res.data,
          });
        }
        if (result.status === 'ERROR') {
          PostAlert('error', result.message);
        }
        if (result.status === 'SUCCESS') {
          dispatcher.dispatch({
            actionType: actionTypes.DocumentLoad,
            Result: result.document,
          });
        }
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let errorMsg = res.toString();
      if (errorMsg.includes('401')) {
        console.log('Unauthorized');
        Logout();
      }
    });
}

export function GetPreviousDocument(document) {
  let doc2 = CloneAndCleanDocument(document);
  axios
    .post(
      `${Globals.baseUrl}/Document/GetPreviousDocument`,
      {
        documentId: document.documentId,
        document: doc2,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        var result = res.data;
        console.log(res.data);
        if (result.status === 'END') {
          dispatcher.dispatch({
            actionType: actionTypes.DocumentLoad,
            Result: res.data,
          });
        }
        if (result.status === 'ERROR') {
          PostAlert('error', result.message);
        }
        if (result.status === 'SUCCESS') {
          dispatcher.dispatch({
            actionType: actionTypes.DocumentLoad,
            Result: result.document,
          });
        }
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let errorMsg = res.toString();
      if (errorMsg.includes('401')) {
        console.log('Unauthorized');
        Logout();
      }
    });
}

export function GetDocumentAudit(document) {
  axios
    .get(
      `${Globals.baseUrl}/Document/GetDocumentAudit/${document.documentId}`,
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.DocumentGetAudit,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let errorMsg = res.toString();
      if (errorMsg.includes('401')) {
        console.log('Unauthorized');
        Logout();
      }
    });
}

export function UpdateTemplate(documentId, templateId, applyUpdate, moveToStart) {
  console.log(applyUpdate);
  console.log(moveToStart);
  axios
    .post(
      `${Globals.baseUrl}/Document/UpdateTemplate`,
      {
        docId: documentId,
        templateId: templateId,
        apply: applyUpdate,
        moveToStart: moveToStart,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        if (res.data.status === 'Success') {
          PostAlert('success', res.data.message);
          if (applyUpdate && !moveToStart) {
            dispatcher.dispatch({
              actionType: actionTypes.DocumentUpdateTempWDoc,
              Result: res.data,
            });
          } else if(moveToStart) {
            dispatcher.dispatch({
              actionType: actionTypes.DocumentUpdateMoveTemp,
              Result: res.data,
            });
          } else  {
            dispatcher.dispatch({
              actionType: actionTypes.DocumentUpdateTemp,
              Result: res.data,
            });
          }
        }
        if (res.data.status === 'Error') {
          PostAlert('error', res.data.message);
        }
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let errorMsg = res.toString();
      if (errorMsg.includes('401')) {
        console.log('Unauthorized');
        Logout();
      }
    });
}

export function ChangeBusinessProcesses(docs, templateId, applyUpdate, bpId) {
  console.log(docs);
  console.log(templateId);
  axios
    .post(
      `${Globals.baseUrl}/Document/ChangeBusinessProcesses`,
      {
        docs: docs,
        templateId: templateId,
        apply: applyUpdate,
        bpId: bpId,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        let result = res.data;
        console.log(result);
        dispatcher.dispatch({
          actionType: actionTypes.BulkAction,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}

export function UpdateTemplates(docs, templateId, applyUpdate) {
  axios
    .post(
      `${Globals.baseUrl}/Document/UpdateTemplates`,
      {
        docs: docs,
        templateId: templateId,
        apply: applyUpdate,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        console.log('boom');
        let result = res.data;
        console.log(result);
        dispatcher.dispatch({
          actionType: actionTypes.BulkAction,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let errorMsg = res.toString();
      if (errorMsg.includes('401')) {
        console.log('Unauthorized');
        Logout();
      }
    });
}

export function SendDocumentToImageReview(doc) {
  axios
    .post(
      `${Globals.baseUrl}/Document/SendToImageReview`,
      {
        action: 'IMAGEREV',
        document: doc,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        PostAlert('success', 'Document sent to Image Review');
        dispatcher.dispatch({
          actionType: actionTypes.DocumentToImageReview,
          Result: res.data,
        });
      }
    })
    .catch((res) => {
      let errorMsg = res.toString();
      if (errorMsg.includes('401')) {
        console.log('Unauthorized');
        Logout();
      }
    });
}

export function DeleteDocument(document) {
  let doc2 = CloneAndCleanDocument(document);
  axios
    .post(
      `${Globals.baseUrl}/Document/DeleteDocument`,
      {
        action: 'DELETE',
        document: doc2,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        PostAlert('success', 'Document deleted');
        dispatcher.dispatch({
          actionType: actionTypes.DocumentDelete,
          Result: res.data,
        });
      }
    })
    .catch((res) => {
      let errorMsg = res.toString();
      if (errorMsg.includes('401')) {
        console.log('Unauthorized');
        Logout();
      }
    });
}

export function AddNote(noteText, docId) {
  axios
    .post(
      `${Globals.baseUrl}/Document/AddNote`,
      {
        text: noteText,
        docId: docId,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.DocumentAddNote,
          Result: res.data,
        });
      }
    })
    .catch((res) => {
      let errorMsg = res.toString();
      if (errorMsg.includes('401')) {
        console.log('Unauthorized');
        Logout();
      }
    });
}

export function DeleteAttachment(docId, attchId) {
  axios
    .post(
      `${Globals.baseUrl}/Document/DeleteAttachment`,
      {
        documentId: docId,
        attachmentId: attchId,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.DocumentDeleteAttachment,
          Result: res.data,
        });
      }
    })
    .catch((res) => {
      let errorMsg = res.toString();
      if (errorMsg.includes('401')) {
        console.log('Unauthorized');
        Logout();
      }
    });
}

export function DownloadAttachment(docId, attachmentId, originalFileName) {
  axios
    .post(
      `${Globals.baseUrl}/Document/DownloadDocumentAttachment`,
      {
        documentId: docId,
        attachmentId: attachmentId,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
        responseType: 'blob',
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.DocumentDownloadAttachment,
          Result: res.data,
        });
        var link = document.createElement('a');
        link.setAttribute('download', '');
        link.href = window.URL.createObjectURL(new Blob([res.data]));
        link.download = originalFileName;
        document.body.appendChild(link);
        link.click();
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => { console.log(res)});
}

export function UploadAttachment(file, fileName, docId) {
  let fd = new FormData();
  fd.append('file', file, fileName);
  fd.append('docId', docId.toString());
  axios
    .post(`${Globals.baseUrl}/Document/UploadAttachment`, fd, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        if (res.data.responseCode !== 'SUCCESS') {
          PostAlert('error', 'Failed to upload file');
          PostAlert('error', res.data.responseMessage);
        } else {
          PostAlert('success', 'File uploaded');
          dispatcher.dispatch({
            actionType: actionTypes.DocumentUploadAttachment,
            Result: res.data,
          });
        }
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      if (res.response.status === 401) {
        console.log('unauthorized');
        Logout();
      }
    });
}

export function PullDocument(documentId) {
  axios
    .post(
      `${Globals.baseUrl}/Document/PullDocument`,
      {
        documentId: documentId,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        if (res.data.responseCode === 'ERROR') {
          PostAlert('error', res.data.responseMessage);
        } else {
          dispatcher.dispatch({
            actionType: actionTypes.DocumentPull,
            Result: res.data,
          });
        }
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let errorMsg = res.toString();
      if (errorMsg.includes('401')) {
        console.log('Unauthorized');
        Logout();
      }
    });
}

export function UndeleteDocument(documentId) {
  axios
    .post(
      `${Globals.baseUrl}/Document/Undelete`,
      {
        documentId: documentId,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        if (res.data.responseCode === 'ERROR') {
          PostAlert('error', res.data.responseMessage);
        } else {
          dispatcher.dispatch({
            actionType: actionTypes.DocumentUndelete,
            Result: res.data,
          });
        }
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let errorMsg = res.toString();
      if (errorMsg.includes('401')) {
        console.log('Unauthorized');
        Logout();
      }
    });
}

export function GetAvailableTemplates(documentId) {
  axios
    .get(`${Globals.baseUrl}/Document/GetAvailableTemplates/${documentId}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.DocumentGetAvailTemplates,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let errorMsg = res.toString();
      if (errorMsg.includes('401')) {
        console.log('Unauthorized');
        Logout();
      }
    });
}

export function GetAvailableTemplatesByBp(bpId) {
  axios
    .get(`${Globals.baseUrl}/Document/GetAvailableTemplatesByBp/${bpId}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.DocumentGetAvailTemplatesByBp,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let errorMsg = res.toString();
      if (errorMsg.includes('401')) {
        console.log('Unauthorized');
        Logout();
      }
    });
}
export function GetAvailableTemplatesByBp2(bpId) {
  axios
    .get(`${Globals.baseUrl}/Document/GetAvailableTemplatesByBp/${bpId}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.DocumentGetAvailTemplatesByBp2,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let errorMsg = res.toString();
      if (errorMsg.includes('401')) {
        console.log('Unauthorized');
        Logout();
      }
    });
}

export function GetAvailableBps(bpId) {
  axios
    .get(`${Globals.baseUrl}/Document/GetAvailableBps/${bpId}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.DocumentGetAvailBpByBp,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let errorMsg = res.toString();
      if (errorMsg.includes('401')) {
        console.log('Unauthorized');
        Logout();
      }
    });
}

export function ChangeQueueAssignment(uid, docId, stepId, chtype) {
  axios
    .post(
      `${Globals.baseUrl}/Document/ChangeQueueAssignment`,
      {
        userId: uid,
        documentId: docId,
        stepId: stepId,
        changeType: chtype,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.DocumentChagneQueue,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let errorMsg = res.toString();
      if (errorMsg.includes('401')) {
        console.log('Unauthorized');
        Logout();
      }
    });
}

export function ExecuteExtender(
  extId,
  extenderData,
  oldVal,
  fieldId,
  fieldSetId,
  exeSource,
  rowId,
  keyData,
  docId
) {
  //console.log(`DA: ExecuteExtender ${fieldSetId}`);
  if(activeExtenders >= maxExtenders || pendingExtenders.length > 0) {
      pendingExtenders.push({
        extenderId: extId,
        dependencies: extenderData,
        oldValue: oldVal,
        fieldId: fieldId,
        snap: null,
        rowId: rowId,
        exeSource: exeSource,
        fieldSetId: fieldSetId,
        keyData: keyData,
        docId: docId,
      });
  } else {
    try {
      activeExtenders++;
      console.log('get from DATABASE');
      axios
        .post(
          `${Globals.baseUrl}/Extender/ExecuteExtender2`,
          {
            extenderId: extId,
            dependencies: extenderData,
            oldValue: oldVal,
            fieldId: fieldId,
            snap: null,
            rowId: rowId,
            exeSource: exeSource,
            docId: docId
          },
          {
            headers: {
              'content-type': 'application/json; charset=utf-8',
              Authorization: `Bearer ${Globals.getJwtToken()}`,
            },
          }
        )
        .then((res) => {
          activeExtenders--;
          if (res.status === 200) {
            if (rowId === null) {
              dispatcher.dispatch({
                actionType: actionTypes.DocumentExtender,
                Result: res.data,
              });
              ExecutePendingExtender();
            } else {
              let result = {
                rowId: rowId,
                fieldSetId: fieldSetId,
                data: res.data,
              };
              dispatcher.dispatch({
                actionType: actionTypes.DocumentExtenderRow,
                Result: result,
                extId: extId,
                key: keyData
              });
              ExecutePendingExtender();
            }
          } else if (res.status === 400 || res.status === 401) {
            console.log('error: 401');
          }
        })
        .catch((res) => {
          let errorMsg = res.toString();

          if (errorMsg.includes('401')) {
            console.log('Unauthorized');
            Logout();
          } else {
          }
        });
    } catch (e) {
      console.error(e);
    }
  }

}

export function ExecutePendingExtender()
{
  //console.log(`DA: ExecuteExtender ${fieldSetId}`);
  //console.log('ExecutePendingExtender');
  if(pendingExtenders.length > 0) {
    //console.log(pendingExtenders);
    var extender = pendingExtenders.shift();
    //console.log(extender);
    try {
      //console.log(`ExecuteExtender ${extender.extenderId}`);
      console.log('get from DATABASE');
      activeExtenders++;
      axios
        .post(
          `${Globals.baseUrl}/Extender/ExecuteExtender2`,
          extender,
          {
            headers: {
              'content-type': 'application/json; charset=utf-8',
              Authorization: `Bearer ${Globals.getJwtToken()}`,
            },
          }
        )
        .then((res) => {
          activeExtenders--;
          if (res.status === 200) {
            if (extender.rowId === null) {

              dispatcher.dispatch({
                actionType: actionTypes.DocumentExtender,
                Result: res.data,
              });
              ExecutePendingExtender();
            } else {
              let result = {
                rowId: extender.rowId,
                fieldSetId: extender.fieldSetId,
                data: res.data,
              };
              dispatcher.dispatch({
                actionType: actionTypes.DocumentExtenderRow,
                Result: result,
                extId: extender.extenderId,
                key: extender.keyData,
              });
              ExecutePendingExtender();
            }
          } else if (res.status === 400 || res.status === 401) {
            console.log('error: 401');
          }
        })
        .catch((res) => {
          let errorMsg = res.toString();

          if (errorMsg.includes('401')) {
            console.log('Unauthorized');
            Logout();
          } else {
          }
        });
    } catch (e) {
      console.error(e);
    }
  }

}

export function ExecuteExtender3(
  extId,
  extenderData,
  oldVal,
  fieldId,
  fieldSetId,
  exeSource,
  rowId
) {
  //console.log(`DA: ExecuteExtender ${fieldSetId}`);
  try {
    axios
      .post(
        `${Globals.baseUrl}/Extender/ExecuteExtender2`,
        {
          extenderId: extId,
          dependencies: extenderData,
          oldValue: oldVal,
          fieldId: fieldId,
          snap: null,
          rowId: rowId,
          exeSource: exeSource,
        },
        {
          headers: {
            'content-type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${Globals.getJwtToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          if (rowId === null) {
            dispatcher.dispatch({
              actionType: actionTypes.DocumentExtender,
              Result: res.data,
            });
          } else {
            let result = {
              rowId: rowId,
              fieldSetId: fieldSetId,
              data: res.data,
            };
            dispatcher.dispatch({
              actionType: actionTypes.DocumentFirstExtenderRow,
              Result: result,
            });
          }
        } else if (res.status === 400 || res.status === 401) {
          console.log('error: 401');
        }
      })
      .catch((res) => {
        let errorMsg = res.toString();

        if (errorMsg.includes('401')) {
          console.log('Unauthorized');
          Logout();
        } else {
        }
      });
  } catch (e) {
    console.error(e);
  }
}

export function ExecuteExtenderNotUsed(
  extId,
  extenderData,
  oldVal,
  snap,
  rowId
) {
  axios
    .post(
      `${Globals.baseUrl}/Extender/ExecuteExtender2`,
      {
        extenderId: extId,
        dependencies: extenderData,
        oldValue: oldVal,
        snap: snap,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        if (rowId === undefined) {
          dispatcher.dispatch({
            actionType: actionTypes.DocumentExtender,
            Result: res.data,
          });
        } else {
          let result = {
            rowId: rowId,
            data: res.data,
          };
          dispatcher.dispatch({
            actionType: actionTypes.DocumentExtenderRow,
            Result: result,
          });
        }
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let errorMsg = res.toString();
      if (errorMsg.includes('401')) {
        console.log('Unauthorized');
        Logout();
      }
    });
}

export function ExecuteFullExtenderSet(dep, rowId) {
  //console.log(`DA: ExecuteExtender ${fieldSetId}`);
  try {
    axios
      .post(
        `${Globals.baseUrl}/Document/ExecuteFullExtenderSet`,
        {
          dependencies: dep,
        },
        {
          headers: {
            'content-type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${Globals.getJwtToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          if (rowId === undefined) {
            dispatcher.dispatch({
              actionType: actionTypes.DocumentFullExtender,
              Result: res.data,
            });
          }
        } else if (res.status === 400 || res.status === 401) {
          console.log('error: 401');
        }
      })
      .catch((res) => {
        let errorMsg = res.toString();

        if (errorMsg.includes('401')) {
          console.log('Unauthorized');
          Logout();
        } else {
        }
      });
  } catch (e) {
    console.error(e);
  }
}

export function DownloadPDF(docId, originalFileName) {
  axios
    .post(
      `${Globals.baseUrl}/Document/DownloadPDF`,
      {
        documentId: docId,
        useStamps: false,
        includeAttachments: false,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
        responseType: 'blob',
      }
    )
    .then((res) => {
      if (res.status === 200) {
        var link = document.createElement('a');
        link.setAttribute('download', '');
        link.href = window.URL.createObjectURL(new Blob([res.data]));
        link.download = originalFileName;
        document.body.appendChild(link);
        link.click();
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {});
}

export function DownloadRelatedDocumentPacket(docId, relatedIds) {
  axios
    .post(
      `${Globals.baseUrl}/Document/DownloadRelatedDocumentPacket`,
      {
        documentId: docId,
        relatedDocumentIds: relatedIds,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
        responseType: 'blob',
      }
    )
    .then((res) => {
      if (res.status === 200) {
        var link = document.createElement('a');
        link.setAttribute('download', '');
        link.href = window.URL.createObjectURL(new Blob([res.data]));
        link.download =
          'TrinDocs Related Document Packet for Document ' + docId + '.pdf';
        document.body.appendChild(link);
        link.click();
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {});
}

export function DownloadPDFWithStamps(docId, originalFileName, stampConfig) {
  axios
    .post(
      `${Globals.baseUrl}/Document/DownloadPDF`,
      {
        documentId: docId,
        useStamps: true,
        includeAttachments: false,
        stampConfig: stampConfig,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
        responseType: 'blob',
      }
    )
    .then((res) => {
      if (res.status === 200) {
        var link = document.createElement('a');
        link.setAttribute('download', '');
        link.href = window.URL.createObjectURL(new Blob([res.data]));
        link.download = originalFileName;
        document.body.appendChild(link);
        link.click();
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {});
}

export function DownloadPDFWithAttachments(docId, originalFileName) {

  axios
    .post(
      `${Globals.baseUrl}/Document/DownloadPDF`,
      {
        documentId: docId,
        useStamps: false,
        includeAttachments: true,
        stampConfig: null,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
        responseType: 'blob',
      }
    )
    .then((res) => {
      if (res.status === 200) {
        var link = document.createElement('a');
        link.setAttribute('download', '');
        link.href = window.URL.createObjectURL(new Blob([res.data]));
        link.download = originalFileName;
        document.body.appendChild(link);
        link.click();
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {});
}

export function FetchAssignableUsers(stepId, bpId, docId) {
  //console.log('fetch FetchAssignableUsers');
  axios
    .post(
      `${Globals.baseUrl}/Document/AssignableUsers`,
      {
        workflowStepId: stepId,
        businessProcessId: bpId,
        documentId: docId,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.DocumentAssignUsers,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {});
}

export function MoveAssignDocument(docId, stepId, assignees, document) {
  let doc2 = CloneAndCleanDocument(document);
  axios
    .post(
      `${Globals.baseUrl}/Document/MoveAssignDocument`,
      {
        documentId: docId,
        workflowStepId: stepId,
        userAssignments: assignees,
        document: doc2,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        let result = res.data;
        if (result.status === 'Error') {
          PostAlert('error', result.message);
        }
        if (result.status === 'Success') {
          PostAlert('success', result.message);
          dispatcher.dispatch({
            actionType: actionTypes.DocumentSubmit,
            Result: res.data,
          });
        }
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {});
}

export function SendEmail(toAddress, subject, message, docId) {
  axios
    .post(
      `${Globals.baseUrl}/Document/SendEmail`,
      {
        toAddress: toAddress,
        subject: subject,
        message: message,
        docId: docId,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        PostAlert('success', `Document sent to ${toAddress}`);
        dispatcher.dispatch({
          actionType: actionTypes.DocumentSendEmail,
          Result: res.data,
        });
      }
    })
    .catch((res) => {
      let errorMsg = res.toString();
      if (errorMsg.includes('401')) {
        console.log('Unauthorized');
        Logout();
      }
    });
}

export function GetAssignees(docId) {
  axios
    .get(`${Globals.baseUrl}/Document/GetAssignableUsers/${docId}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.DocumentGetAssignees,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {});
}

export function GetDocumentZoomSetting() {
  axios
    .get(`${Globals.baseUrl}/Document/GetDocumentZoomSetting`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.GetDocumentZoomSetting,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {});
}

export function SetDocumentZoomSetting(zoom) {
  axios
    .post(
      `${Globals.baseUrl}/Document/SetDocumentZoomSetting`,
      {
        zoom: zoom
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        if (res.data.responseCode === 'ERROR') {
          PostAlert('error', res.data.responseMessage);
        } else {
          dispatcher.dispatch({
            actionType: actionTypes.DocumentZoomSettingUpdate,
            Result: res.data,
          });
        }
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let errorMsg = res.toString();
      if (errorMsg.includes('401')) {
        console.log('Unauthorized');
        Logout();
      }
    });
}
