import * as React from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import TemplateStore from '../../../stores/TemplateStore';
import {
  GetTemplateList,
  GetFormTypesICanEditTemplates,
  CreateTemplate,
} from '../../../actions/TemplateActions';
import actionTypes from '../../../actions/ActionTypes';

class TemplateSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      templateList: [],
      searchText: '',
      filteredTemplateList: [],
      newTemplateName: '',
      showNewTemplate: false,
      formTypes: [],
      newFT: 0,
      templateId: 7,
      maxHeight: window.innerHeight - 300,
      formTypeFilter: 0,
    };

    this.onReceiveTemplateChange = this.onReceiveTemplateChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.createNewTemplate = this.createNewTemplate.bind(this);
    this.openNewTemplate = this.openNewTemplate.bind(this);
    this.editNewTemplateName = this.editNewTemplateName.bind(this);
    this.closeNewTemplate = this.closeNewTemplate.bind(this);
    this.editNewFT = this.editNewFT.bind(this);
    this.toggleFormGroup = this.toggleFormGroup.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);
    this.onTemplateManagerClick = this.onTemplateManagerClick.bind(this);
    this.onUploadTemplate = this.onUploadTemplate.bind(this);
  }

  componentDidMount() {
    TemplateStore.addChangeListener(this.onReceiveTemplateChange);
    window.onresize = this.onWindowResize;
    GetTemplateList();
    GetFormTypesICanEditTemplates();
  }

  componentWillUnmount() {
    TemplateStore.removeChangeListener(this.onReceiveTemplateChange);
  }

  onReceiveTemplateChange() {
    let lastChange = TemplateStore.getLastChange();
    if (lastChange === actionTypes.TemplateList) {
      let templates = TemplateStore.getTemplateList();

      this.setState({
        templateList: templates,
        searchText: '',
        filteredTemplateList: templates,
      });
    }
    if (lastChange === actionTypes.TemplateFormTypes) {
      let fts = TemplateStore.getFormTypes();
      this.setState({
        formTypes: fts,
      });
    }
    if (lastChange === actionTypes.TemplateCreateTemplate) {
      let newTemplate = TemplateStore.getCurrentTemplate();
      //var customHistory = createBrowserHistory();
      this.setState({ templateId: newTemplate.templateId }, () => {
        let link = document.getElementById('navLink');
        link.click();
      });
      //this.props.history.push(`/TemplateEditor/${newTemplate.templateId}`);
    }
  }

  onWindowResize(e) {
    let newH = window.innerHeight - 300;
    this.setState({ maxHeight: newH });
  }

  onSearchChange(e) {
    console.log('search');
    let search = e.target.value;
    if (search === '') {
      let newList = [...this.state.templateList];
      this.setState({ searchText: search, filteredTemplateList: newList });
    } else {
      let newList = [];
      let fgs = this.state.templateList;

      fgs.forEach((fg) => {
        let newFg = {
          formTypeId: fg.formTypeId,
          formTypeName: fg.formTypeName,
          isOpen: true,
          hasTemplates: false,
          templates: [],
        };
        fg.templates.forEach((temp) => {
          if (temp.name.toLowerCase().includes(search.toLowerCase())) {
            newFg.templates.push(temp);
            newFg.hasTemplates = true;
          }
        });
        if (newFg.hasTemplates) {
          newList.push(newFg);
        }
      });

      this.setState({ searchText: search, filteredTemplateList: newList });
    }
  }

  createNewTemplate() {
    console.log('new');

    let name = this.state.newTemplateName;
    let ft = this.state.newFT;
    console.log(ft);
    if (ft !== 0 && name !== '') {
      console.log('create');
      CreateTemplate(name, ft);
    }
  }

  editNewTemplateName(e) {
    this.setState({ newTemplateName: e.target.value });
  }

  closeNewTemplate() {
    this.setState({ showNewTemplate: false });
  }

  openNewTemplate() {
    this.setState({ showNewTemplate: true });
  }

  editNewFT(e) {
    this.setState({ newFT: parseInt(e.target.value) });
  }

  toggleFormGroup(fg) {
    let ftFilter = 0;
    if(fg !== 'all')
    {
      ftFilter = fg.formTypeId;
    }

    this.setState({formTypeFilter: ftFilter});
    this.props.setFTFilter(fg);

  }

  onTemplateManagerClick(e) {
    if(e.ctrlKey) {
      // new tab
      window.open('/TemplateManager');
    } else {
      // go direct
      let link = document.getElementById('tdManagerLink');
      console.log(link);
      link.click();
    }
  }

  onUploadTemplate() {
    this.props.onUploadTemplate();
  }



  render() {
    let listOfTemplates;
    if (this.state.templateList !== null) {
      listOfTemplates = this.state.filteredTemplateList.map((templateGroup) => (
        <div key={templateGroup.formTypeId}>
          <div
            className={`${this.state.formTypeFilter === templateGroup.formTypeId ? 'te-formtype-open-link2' : 'te-formtype-open-link'}`}
            onClick={() => this.toggleFormGroup(templateGroup)}
            style={{ cursor: 'Pointer' }}
          >
            <FontAwesomeIcon icon={solid('file-invoice')} />{' '}
            {templateGroup.formTypeName}
          </div>

          {/*<Link
            className='sidebar-link'
            to={'/TemplateEditor/' + template.templateId}
            //onClick={() => this.handleTemplateClick(bp)}
            style={{ cursor: 'Pointer', wordWrap: 'break-word' }}
          >
            {template.name}
          </Link>{' '}*/}
        </div>
      ));
    } else {
      listOfTemplates = <div> No Templates {':('}</div>;
    }
    return (
      <div
        className='d-flex flex-column p-3 text-white bg-dark'
        style={{ height: '100%', width: '274px' }}
      >
        <Link
          to='/Admin/TemplateEditor'
          className='d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none'
        >
          <span className='fs-4'>
            <span style={{ color: '#79EA6E' }}>
              <FontAwesomeIcon icon={solid('puzzle-piece')} />
            </span>
            {'   '}
            Templates
          </span>
        </Link>
        <hr />


        <div
          className='coolScroll'
          style={{ maxHeight: this.state.maxHeight, overflowY: 'scroll' }}
        >
          {listOfTemplates}
        </div>

        <div
            className={`${this.state.formTypeFilter === 0 ? 'te-formtype-open-link2' : 'te-formtype-open-link'} mt-2`}
            onClick={() => this.toggleFormGroup('all')}
            style={{ cursor: 'Pointer' }}
          >
            <FontAwesomeIcon icon={solid('file-invoice')} />{' '}
            All Templates
          </div>


        <hr />
        <button
          className='btn btn-primary btn-sm'
          onClick={this.openNewTemplate}
        >
          New Template
        </button>
        <button
          className='btn btn-primary btn-sm mt-2'
          onClick={this.onUploadTemplate}
        >
          Upload Templates
        </button>
      <div style={{height: '20px'}}>

      </div>

        {/*<Link className='sidebar-link' onClick={this.openNewTemplate}>
          New Tempalte
    </Link>*/}
        {this.state.showNewTemplate && (
          <div className='td-dialogue-container'>
            <div
              className='td-dialogue card bg-light'
              style={{ height: '220px', width: '450px' }}
            >
              <div className='card-header'>
                New Template
                <button onClick={this.closeNewTemplate} className='btn-close' />
              </div>
              <div className='card-body'>
                <div className='row mb-2'>
                  <label className='col-4 col-form-label'>Template name:</label>
                  <div className='col-8 '>
                    <input
                      className='form-control'
                      placeholder='name'
                      value={this.state.newTemplateName}
                      onChange={this.editNewTemplateName}
                    />
                  </div>
                </div>
                <div className='row'>
                  <label className='col-4 col-form-label'>Form Type:</label>
                  <div className='col-8 '>
                    <select
                      className='form-select'
                      value={this.state.newFT}
                      onChange={this.editNewFT}
                    >
                      <option value='0'>Select a form type</option>
                      {this.state.formTypes.map((ft) => (
                        <option value={ft.formTypeId} key={ft.formTypeId}>
                          {ft.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <button
                  onClick={this.createNewTemplate}
                  className='btn btn-primary mt-3  w-100'
                >
                  Create
                </button>
                <Link
                  style={{ display: 'none' }}
                  id='navLink'
                  to={`/TemplateEditor/${this.state.templateId}`}
                >
                  ClickMe
                </Link>

              </div>
            </div>
          </div>
        )}
         <Link
                  style={{ display: 'none' }}
                  id='tdManagerLink'
                  to={'/TemplateManager'}
                >
                  ClickMe
                </Link>
      </div>
    );
  }
}

export default TemplateSidebar;
