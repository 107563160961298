import dispatcher from '../Dispatcher';
import actionTypes from './ActionTypes';
import axios from 'axios';
import * as Globals from '../Globals';
import { PostAlert } from './AlertActions';

//import { LicenseStatus } from '../Models/Admin/License';

export function GetActiveLicense() {
  axios
    .get(`${Globals.baseUrl}/License/GetActiveLicense`, {
      headers: { 'content-type': 'application/json; charset=utf-8' },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.LicenseGetLicense,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
      dispatcher.dispatch({
        actionType: actionTypes.LicenseGetLicense,
        Result: result,
      });
    });
}

export function GetLicenseSummary() {
  axios
    .get(`${Globals.baseUrl}/License/GetLicenseSummary`, {
      headers: { 'content-type': 'application/json; charset=utf-8' },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.LicenseGetSummary,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {});
}

export function UpdateLicense(file) {
  console.log('updateLIC2');
  let fd = new FormData();
  fd.append('file', file, file.name);
  axios
    .post(`${Globals.baseUrl}/License/UpdateLicense`, fd, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        PostAlert('success', 'License updated');
        dispatcher.dispatch({
          actionType: actionTypes.LicenseGetSummary,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      } else if (res.status === 500) {
        PostAlert('error', 'License failed to update');
      }
    })
    .catch((res) => {});
}

export function ExportLicenseAllocation() {
  axios
    .get(`${Globals.baseUrl}/License/ExportLicenseAllocation`, {
      headers: { 'content-type': 'application/json; charset=utf-8' },
      responseType: 'blob',
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.LicenseGetLicense,
          Result: res.data,
        });

        var link = document.createElement('a');
        link.setAttribute('download', '');
        link.href = window.URL.createObjectURL(new Blob([res.data]));
        link.download = 'TrinDocs License Allocation.xlsx';
        document.body.appendChild(link);
        link.click();
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
      dispatcher.dispatch({
        actionType: actionTypes.LicenseGetLicense,
        Result: result,
      });
    });
}
