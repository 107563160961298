import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';

const CHANGE_EVENT = 'inboxChange';
let _fieldRelations = [];
let _lastChange = '';

class FieldRelationsStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getFieldRelations() {
    return _fieldRelations;
  }

  lastChange() {
    return _lastChange;
  }
}

const store = new FieldRelationsStore();

Dispatcher.register((action) => {
  _lastChange = action.actionType;
  switch (action.actionType) {
    case actionTypes.GetFieldRelations:
      _lastChange = actionTypes.GetFieldRelations;
      _fieldRelations = action.Result;
      store.emitChange();
      break;

    case actionTypes.UpdateFieldRelation:
        _lastChange = actionTypes.UpdateFieldRelation;
        store.emitChange();
        break;

    case actionTypes.DeleteFieldRelation:
        _lastChange = actionTypes.DeleteFieldRelation;
        store.emitChange();
        break;

    default:
  }
});

export default store;