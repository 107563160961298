import dispatcher from '../Dispatcher';
import actionTypes from './ActionTypes';
import axios from 'axios';
import * as Globals from '../Globals';
import { PostAlert } from './AlertActions';

export function GetConnectionList() {
  axios
    .get(`${Globals.baseUrl}/Connection/GetConnections`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.ConnectionList,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
      dispatcher.dispatch({
        actionType: actionTypes.ConnectionList,
        Result: result,
      });
    });
}

export function LoadConnection(id) {
  axios
    .post(
      `${Globals.baseUrl}/Connection/GetConnection`,
      { id: id },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.ConnectionLoadOne,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log('error');
    });
}

export function CreateConnection(connection) {
  axios
    .post(
      `${Globals.baseUrl}/Connection/CreateConnection`,
      { id: 0
        , 
        name: connection.name, 
        connectionString: connection.connectionString 
    },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.ConnectionCreateOne,
          Result: res.data,
        });
        if (res.data.status === 'Success') {
          PostAlert('success', 'Connection created');
        }
        if (res.data.status === 'Error') {
          PostAlert('error', 'Unable to create Connection');
          PostAlert('error', res.data.updateMessage);
        }
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}

export function UpdateConnection(Connection) {
  axios
    .post(
      `${Globals.baseUrl}/Connection/UpdateConnection`,
      { id: Connection.id, name: Connection.name, connectionString: Connection.connectionString },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.ConnectionUpdateOne,
          Result: res.data,
        });
        if (res.data.status === 'Success') {
          PostAlert('success', 'Connection updated');
        }
        if (res.data.status === 'Error') {
          PostAlert('error', 'Unable to update Connection');
          PostAlert('error', res.data.updateMessage);
        }
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}

export function DeleteConnection(id) {
  axios
    .post(
      `${Globals.baseUrl}/Connection/DeleteConnection`,
      { id: id },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.ConnectionDelete,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}
