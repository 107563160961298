import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ProcessAction } from '../../actions/DocActionActions';
import DocActionStore from '../../stores/DocActionStore';
class DocAction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actionStatus: 'pending',
      errorMessage: 'unknown error has occured',
    };
    this.onDocActionUpdate = this.onDocActionUpdate.bind(this);
    // bindings
  }

  componentDidMount() {
    DocActionStore.addChangeListener(this.onDocActionUpdate);
    let loc = window.location.pathname;
    let url = window.location.href;
    let idx = url.indexOf('?');

    console.log(idx);
    console.log(url.substring(idx + 1));
    let t = url.substring(idx + 1);

    if (t !== null && t !== undefined) {
      console.log('process action');
      ProcessAction(t);
    }
  }

  componentWillUnmount() {
    DocActionStore.removeChangeListener(this.onDocActionUpdate);
  }

  onDocActionUpdate() {
    let action = DocActionStore.getLastAction();
    let msg = DocActionStore.getLastError();
    this.setState({ actionStatus: action, errorMessage: msg });
  }

  render() {
    return (
      <div className='container-fluid' style={{ height: '100%' }}>
        <div className='row flex-nowrap' style={{ height: '100%' }}>
          <div className='h-100 w-100 d-flex align-items-center'>
            {(this.state.actionStatus.toLowerCase() === 'approved' || this.state.actionStatus.toLowerCase() === 'approve') && (
              <div
                className='p-2 col-example'
                style={{
                  textAlign: 'center',
                  width: '100%',
                }}
              >
                <div style={{ width: '99%' }}>
                  <FontAwesomeIcon
                    style={{ color: '#0DAA29' }}
                    icon={solid('thumbs-up')}
                    size='6x'
                  />
                </div>

                <h3 style={{ fontWeight: 'bolder' }}>
                  Your document has been approved!
                </h3>
              </div>
            )}

            {(this.state.actionStatus.toLowerCase() === 'reject' || this.state.actionStatus.toLowerCase() === 'rejected') && (
              <div
                className='p-2 col-example'
                style={{
                  textAlign: 'center',
                  width: '100%',
                }}
              >
                <div style={{ width: '99%' }}>
                  <FontAwesomeIcon
                    style={{ color: '#AA0D0D' }}
                    icon={solid('thumbs-down')}
                    size='6x'
                  />
                </div>

                <h3 style={{ fontWeight: 'bolder' }}>
                  Your document has been rejected!
                </h3>
              </div>
            )}

            {this.state.actionStatus.toLowerCase() === 'error' && (
              <div
                className='p-2 col-example'
                style={{
                  textAlign: 'center',
                  width: '100%',
                }}
              >
                <div style={{ width: '99%' }}>
                  <FontAwesomeIcon
                    style={{ color: '#AA0D0D' }}
                    icon={solid('times')}
                    size='6x'
                  />
                </div>

                <h3 style={{ fontWeight: 'bolder' }}>
                  {this.state.errorMessage}
                </h3>
              </div>
            )}

            {this.state.actionStatus.toLowerCase() === 'pending' && (
              <div
                className='p-2 col-example'
                style={{
                  textAlign: 'center',
                  width: '100%',
                }}
              >
                <div style={{ width: '99%' }}>
                  <FontAwesomeIcon
                    style={{ color: '#A5A5A5' }}
                    icon={solid('spinner')}
                    size='6x'
                    spin
                  />
                </div>

                <h3 style={{ fontWeight: 'bolder' }}>Please wait...</h3>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default DocAction;
