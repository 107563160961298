import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';

const CHANGE_EVENT = 'loginChange';
let _showMSButtonSetting = false;
let _loggedInStatus = false;
let _lastErrorMessage = '';
let _lastAction = '';
let _loggedInUser = {};
let _canWebUpload = false;
let _tdServerVersion = '';

class LoginStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getMSButtonSetting() {
    return _showMSButtonSetting;
  }

  getLoggedInStatus() {
    return _loggedInStatus;
  }

  getError() {
    return _lastErrorMessage;
  }
  getLastAction() {
    return _lastAction;
  }
  getUser() {
    return _loggedInUser;
  }
  getCanWebUpload() {
    return _canWebUpload;
  }

  getTDVersion() {
    return _tdServerVersion;
  }
}

const store = new LoginStore();

Dispatcher.register((action) => {
  switch (action.actionType) {
    case actionTypes.Login_CheckMSButton:
      _lastAction = 'CheckMSButton';
      _showMSButtonSetting = action.Result;
      store.emitChange();
      break;
    case actionTypes.Login_ViaPassword:
      _lastAction = 'Login';
      let result = action.Result;
      if (result.isLoggedIn) {
        _loggedInUser = action.Result;
        _loggedInStatus = true;
        localStorage.setItem('td6Token', action.Result.jwtToken);
      } else {
        _loggedInStatus = false;
        _lastErrorMessage = action.Result.message;
      }
      store.emitChange();
      break;
    case actionTypes.Login_Silent:
      if (action.Result.isLoggedIn) {
        _loggedInUser = action.Result;
        _loggedInStatus = true;
        _lastAction = 'Login';
        localStorage.setItem('td6Token', action.Result.jwtToken);
        store.emitChange();
      }
      break;
    case actionTypes.Login_Logout:
      _lastAction = 'Logout';
      _loggedInUser = null;
      _loggedInStatus = false;
      localStorage.removeItem('td6Token');
      store.emitChange();
      break;

    case actionTypes.CanUserWebUpload:
      _lastAction = actionTypes.CanUserWebUpload;
      _canWebUpload = action.Result;
      store.emitChange();
      break;

    case actionTypes.LoginCheckVer:
      _lastAction = actionTypes.LoginCheckVer;
      _tdServerVersion = action.Result;
      store.emitChange();
      break;
    default:
  }
});

export default store;
