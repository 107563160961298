import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';

const CHANGE_EVENT = 'isChange';
let _intakeStrategies = undefined;
let _lastError = '';
let _currentIs = undefined;
let _doesIronServiceExist = false;
let _doesOcrServiceExist = false;
let _lastChange = '';

class IntakeStrategyStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getIntakeStrategies() {
    return _intakeStrategies;
  }

  getIronService() {
    return _doesIronServiceExist;
  }

  getOcrService() {
    return _doesOcrServiceExist;
  }

  getLastError() {
    return _lastError;
  }

  getCurrentIS() {
    return _currentIs;
  }

  getLastAction() {
    return _lastChange;
  }
}

const store = new IntakeStrategyStore();

Dispatcher.register((action) => {
  _lastChange = action.actionType;
  switch (action.actionType) {
    // creating a new BP
    case actionTypes.IntakeStrategyCreate:
      if (action.Result.status === 'Success') {
        _intakeStrategies.push(action.Result.data);
        _currentIs = action.Result.data;
        _lastError = '';
      }
      if (action.Result.status === 'Fail') {
        _lastError = action.Result.updateMessage;
      }
      store.emitChange();
      break;
    // loading the full list of BPs
    case actionTypes.IntakeStrategyLoad:
      _intakeStrategies = action.Result;
      store.emitChange();
      break;
    case actionTypes.IntakeStrategyLoadOne:
      _doesIronServiceExist = action.Result.doesIronServiceExist;
      _doesOcrServiceExist = action.Result.doesOcrServiceExist;
      _currentIs = action.Result.strategy;
      if (_currentIs.barCodeSplits === undefined) {
        _currentIs.barCodeSplits = [];
      }
      if (_currentIs.intakeTargets === null) {
        _currentIs.intakeTargets = [];
      }
      store.emitChange();
      break;
    case actionTypes.IntakeStrategyUpdate:
      _currentIs = action.Result.data;
      let isInList = _intakeStrategies.find((is) => is.id === _currentIs.id);
      // pop and replace the new bp from the list
      if (isInList !== undefined) {
        let pos = _intakeStrategies.indexOf(isInList);
        _intakeStrategies.splice(pos, 1);
        _intakeStrategies.push(_currentIs);
      }
      store.emitChange();
      break;
    case actionTypes.IntakeStrategyDelete:
      console.log('delete');
      console.log(action.Result);
      let deletedIS = _intakeStrategies.find(
        (b) => b.id === action.Result.data.id
      );
      if (deletedIS !== null) {
        let toRemoveIndex = _intakeStrategies.indexOf(deletedIS);
        _intakeStrategies.splice(toRemoveIndex, 1);
        _currentIs = undefined;
      }
      store.emitChange();
      break;
    default:
  }
});

export default store;
