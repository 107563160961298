import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';

const CHANGE_EVENT = 'templateChange';
let _templateList = [];
let _currentTemplate = undefined;
let _lastChange = '';
let _lastError = '';
let _samples = [];
let _currentSample = undefined;
let _formTypes = [];
let _headers = [];
let _tables = [];
let _currentSampleInfo = undefined;
let _templateDetails = [];
let _activeUpdate = undefined;
let _audit = [];
let _lastUpload = undefined;
let _docSample = undefined;
let _newSampleId = undefined;
let _templateUsage = [];

class TemplateStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getTemplateList() {
    return _templateList;
  }

  getSamples() {
    return _samples;
  }

  getLastChange() {
    return _lastChange;
  }

  getCurrentTemplate() {
    return _currentTemplate;
  }

  getCurrentSample() {
    return _currentSample;
  }

  getLastError() {
    return _lastError;
  }

  getFormTypes() {
    return _formTypes;
  }

  getHeaders() {
    return _headers;
  }

  getTables() {
    return _tables;
  }

  getSampleInfo() {
    return _currentSampleInfo;
  }

  getTemplateDetails() {
    return _templateDetails;
  }

  getActiveUpdate() {

    return _activeUpdate;
  }

  getAudit() {
    return _audit;
  }

  getLastUpload() {
    return _lastUpload;
  }

  getDocSample() {
    return _docSample;
  }

  getNewSampleId() {
    return _newSampleId;
  }

  getTemplateUsage() {
    return _templateUsage;
  }
}

const store = new TemplateStore();

Dispatcher.register((action) => {
  switch (action.actionType) {
    case actionTypes.TemplateList:
      _templateList = action.Result;
      _lastChange = action.actionType;
      store.emitChange();
      break;
    case actionTypes.TemplateCreateSample:
      if (action.Result.errorMessage === '') {
        _samples.push(action.Result);
        _currentSample = action.Result;
      }

      _lastChange = action.actionType;
      store.emitChange();
      break;
    case actionTypes.TempalteGetOne:
      _currentTemplate = action.Result;
      _lastChange = action.actionType;
      store.emitChange();
      break;
    case actionTypes.TemplateUpdate:
      _lastChange = action.actionType;
      store.emitChange();
      break;
    case actionTypes.TemplateGetSamples:
      _lastChange = action.actionType;
      _samples = action.Result;
      _currentSample = action.Result[0];
      store.emitChange();
      break;
    case actionTypes.TemplateFormTypes:
      _lastChange = action.actionType;
      _formTypes = action.Result;
      store.emitChange();
      break;
    case actionTypes.TemplateCreateTemplate:
      _lastChange = action.actionType;
      _currentTemplate = action.Result;
      store.emitChange();
      break;
    case actionTypes.TemplateHeaders:
      _headers = action.Result;
      _lastChange = action.actionType;
      store.emitChange();
      break;
    case actionTypes.TemplateTables:
      _tables = action.Result;
      _lastChange = action.actionType;
      store.emitChange();
      break;
    case actionTypes.TemplateDeleteSample:
      _lastChange = action.actionType;
      store.emitChange();
      break;
    case actionTypes.TemplateGetSampleInfo:
      _lastChange = action.actionType;
      console.log(action.Result);
      _currentSampleInfo = action.Result;
      store.emitChange();
      break;
    case actionTypes.TemplateGetSample:
      _lastChange = action.actionType;

      let sampleResult = action.Result;
      var targetSample = _samples.find(
        (s) => s.sampleId === sampleResult.sampleId
      );
      if (targetSample) {
        targetSample = action.Result;
        _currentSample = action.Result;
        store.emitChange();
      }
      else {
        _samples.push(action.Result);
        _currentSample = action.Result;
        store.emitChange();
      }
      break;
    case actionTypes.TemplateGetSampleForDoc:
      _lastChange = action.actionType;
      console.log('store');
      let sampleResult2 = action.Result;
      var targetSample = _samples.find(
        (s) => s.sampleId === sampleResult2.sampleId
      );
      if (targetSample) {
        targetSample = action.Result;
        _currentSample = action.Result[0];
        store.emitChange();
      } else {
        _samples.push(action.Result);
        _currentSample = action.Result[0];
        store.emitChange();
      }
      break;
    case actionTypes.TemplateGetDetails:
      _lastChange = action.actionType;
      _templateDetails = action.Result;
      store.emitChange();
      break;
    case actionTypes.TemplateUpdateActive:
      _lastChange = action.actionType;
       let fgs = _templateList;
       fgs.forEach((fg) => {
         let temp = fg.templates.find(t => t.templateId === action.Result.templateId);
         if(temp !== undefined)
         {

           temp.isActive = action.Result.isActive;
         }
       })

      _activeUpdate = action.Result;
      store.emitChange();
      break;
    case actionTypes.TemplateGetAudit:
      _lastChange = actionTypes.TemplateGetAudit;
      _audit = action.Result;
      store.emitChange();
      break;
    case actionTypes.TemplateUploadTemplate:
      _lastChange = actionTypes.TemplateUploadTemplate;
      _lastUpload = action.Result;
      store.emitChange();
      break;
    case actionTypes.TemplateDocSample:
      _lastChange = actionTypes.TemplateDocSample;
      _docSample = action.Result;
      console.log(_docSample);
      store.emitChange();
      break;
    case actionTypes.TemplateConvertSample:
      _lastChange = actionTypes.TemplateConvertSample;
      _newSampleId = action.Result.newSampleId;
      store.emitChange();
      break;
    case actionTypes.TemplateGetUsage:
      _lastChange = actionTypes.TemplateGetUsage;
      _templateUsage = action.Result;
      store.emitChange();
      break;
    default:
  }
});

export default store;
