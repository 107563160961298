import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';

const CHANGE_EVENT = 'licenseChange';
let _currentLic = undefined;
let _licenseSummary = undefined;

class LicenseStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getCurrentLicense() {
    return _currentLic;
  }

  getLicenseSummary() {
    return _licenseSummary;
  }
}

const store = new LicenseStore();

Dispatcher.register((action) => {
  switch (action.actionType) {
    case actionTypes.LicenseGetLicense:
      _currentLic = action.Result;
      store.emitChange();
      break;
    case actionTypes.LicenseGetSummary:
      _licenseSummary = action.Result;
      store.emitChange();
      break;
    default:
  }
});

export default store;
