//@flow
import dispatcher from '../Dispatcher';
import actionTypes from './ActionTypes';
import axios from 'axios';
import * as Globals from '../Globals';
import { PostAlert } from './AlertActions';

export function GetMailboxList() {
  axios
    .get(`${Globals.baseUrl}/Mailbox/GetMailboxes`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.MailboxList,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
      dispatcher.dispatch({
        actionType: actionTypes.SecretList,
        Result: result,
      });
    });
}

export function ChangePassword(mailboxId, newPassword) {
  axios
    .post(
      `${Globals.baseUrl}/Mailbox/ChangePassword`,
      {
        id: mailboxId,
        password: newPassword,
      },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        PostAlert('success', 'Password updated');
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      let result = {
        updateStatus: 'Error',
        errorMessage: '401: Bad request',
      };
      dispatcher.dispatch({
        actionType: actionTypes.SecretList,
        Result: result,
      });
    });
}

export function LoadMailbox(id) {
  console.log('LoadMailbox');
  axios
    .get(`${Globals.baseUrl}/Mailbox/GetMailbox/${id}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.MailboxLoadOne,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log('error');
    });
}

export function CreateMailbox(mailbox) {
  axios
    .post(
      `${Globals.baseUrl}/Mailbox/CreateMailbox`,
      { id: 0, configuration: mailbox },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.MailboxCreateOne,
          Result: res.data,
        });
        if (res.data.status === 'Success') {
          PostAlert('success', 'Mailbox created');
        }
        if (res.data.status === 'Error') {
          PostAlert('error', 'Unable to create mailbox');
          PostAlert('error', res.data.updateMessage);
        }
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}

export function TestMailbox(mailbox) {
  console.log(mailbox);
  axios
    .get(
      `${Globals.baseUrl}/Mailbox/TestMailbox/${mailbox.mailboxId.toString()}`,
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.MailboxText,
          Result: res.data,
        });
      }
    })
    .catch((res) => {
      console.log(res);
    });
}

export function UpdateMailbox(mailbox) {
  console.log(mailbox);
  axios
    .post(
      `${Globals.baseUrl}/Mailbox/UpdateMailbox`,
      { id: mailbox.mailboxId, configuration: mailbox },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.MailboxUpdateOne,
          Result: res.data,
        });
        if (res.data.updateStatus === 'Success') {
          PostAlert('success', 'Mailbox updated');
        }
        if (res.data.updateStatus === 'Error') {
          PostAlert('error', 'Unable to update mailbox');
          PostAlert('error', res.data.updateMessage);
        }
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}

export function UpdateMailboxPassword(mailbox, password) {
  axios
    .post(
      `${Globals.baseUrl}/Mailbox/UpdateMailboxPassord`,
      { id: mailbox.mailboxId, password: password },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.MailboxPassword,
          Result: res.data,
        });
        if (res.data.status === 'Success') {
          PostAlert('success', 'Mailbox password changed updated');
        }
        if (res.data.status === 'Error') {
          PostAlert('error', 'Unable to update mailbox');
        }
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}

export function DeleteMailbox(id) {
  axios
    .post(
      `${Globals.baseUrl}/Mailbox/DeleteMailbox`,
      { id: id },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.data.updateStatus === 'Error') {
        PostAlert('error', res.data.errorMessage);
      } else if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.MailboxDelete,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}

export function AuthenticateOAuth(mailboxId) {
  axios
    .post(
      `${Globals.baseUrl}/Mailbox/Auth360Acct`,
      { mailboxId: mailboxId, clientId: '' },
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${Globals.getJwtToken()}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatcher.dispatch({
          actionType: actionTypes.MailboxAuthenticate,
          Result: res.data,
        });
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}

export function InstructionCheck(mailboxId) {
  axios
    .get(`${Globals.baseUrl}/Mailbox/Auth360Instr/${mailboxId}`, {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${Globals.getJwtToken()}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        if (res.data.status === 'Instr') {
          dispatcher.dispatch({
            actionType: actionTypes.MailboxInstructions,
            Result: res.data,
          });
        }
      } else if (res.status === 400 || res.status === 401) {
        console.log('error: 401');
      }
    })
    .catch((res) => {
      console.log(res);
    });
}
