import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';

const CHANGE_EVENT = 'integrationChange';
let _lastChange = '';
let _currentIntegrations = [];
let _currentIntegration = undefined;

class IntegrationStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getIntegration() {
    return _currentIntegration;
  }

  getIntegrationList() {
    return _currentIntegrations;
  }

  getLastChange() {
    return _lastChange;
  }
}

const store = new IntegrationStore();

Dispatcher.register((action) => {
  _lastChange = action.actionType;
  switch (action.actionType) {
    case actionTypes.IntegrationsGetList:
      _currentIntegrations = action.Result;
      store.emitChange();
      break;
    default:
  }
});

export default store;
