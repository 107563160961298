import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';

const CHANGE_EVENT = 'companionChange';
let _compItems = undefined;
let _currentComp = undefined;
let _lastChange = '';
let _lastError = '';
let _currentDataSet = undefined;

class CompanionStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getCompanionList() {
    return _compItems;
  }

  getCurrentCompanion() {
    return _currentComp;
  }

  getCurrentData() {
    return _currentDataSet;
  }

  lastChange() {
    return _lastChange;
  }

  getLastError() {
    return _lastError;
  }
}

const store = new CompanionStore();

Dispatcher.register((action) => {
  _lastChange = action.actionType;
  switch (action.actionType) {
    // loading the full list of Companion schemas
    case actionTypes.CompanionList:
      _compItems = action.Result;
      store.emitChange();
      break;
    case actionTypes.CompanionLoadOne:
      _currentComp = action.Result;
      store.emitChange();
      break;
    case actionTypes.CompanionGetData:
      _currentDataSet = action.Result;
      store.emitChange();
      break;
    default:
  }
});

export default store;
