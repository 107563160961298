export class UploadTracker {
    uploadId;
    source;
    originalName;
    uploadTime;
    status;
    statusDetail;
    pageCount;
    rejectId;
}

export class UploadQuery {
    source;
    status;
    numResult;
    searchText;
}

export class UploadQueryGetMore {
    source;
    status;
    numResult;
    searchText;
    maxId;
}

export class UploadInfo {
    uploadDtime;
    uploadSource;
    uploadSourceDesc;
    uploadStatus;
    hasError;
    originalFileName;
    //intakeFileName;
    pageCount;
    //rejectCount;

    docs;
    messages;
}

export class UploadDocument {
    documentId;
    documentStatus;
    pageRange;
    pageCount;
    uploadGuid;
}

export class UploadMessage {
    messageDtime;
    message;
}


