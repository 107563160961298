import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import IntakeSettingsStore from '../../../../stores/IntakeSettingsStore';
import {
  GetIntakeSettings,
  UpdateIntakeSettings,
} from '../../../../actions/IntakeSettingActions';

class IntakeSettings extends React.Component {
  constructor(props) {
    // state
    super(props);

    this.state = {
      currentSettings: {
        iaDuration: 45,
        directoryDuration: 46,
        rejectDupe: true,
        skipRejectDelete: true,
        defaultOCRQual: '1',
        ocrType: 'smartOCR',
        doesIronServiceExist: false,
        doesOcrServiceExist: false,
      },
    };

    this.onIntakeSettingsUpdate = this.onIntakeSettingsUpdate.bind(this);
    this.toggleRejectDupes = this.toggleRejectDupes.bind(this);
    this.toggleAllowDupes = this.toggleAllowDupes.bind(this);
    this.toggleRemoveDollarSigns = this.toggleRemoveDollarSigns.bind(this);
    this.onEditIADuration = this.onEditIADuration.bind(this);
    this.onEditDirDuration = this.onEditDirDuration.bind(this);
    this.onOCRQualityChange = this.onOCRQualityChange.bind(this);
    this.onOCRMethodChange = this.onOCRMethodChange.bind(this);
    this.saveIntakeSettings = this.saveIntakeSettings.bind(this);
  }

  componentDidMount() {
    IntakeSettingsStore.addChangeListener(this.onIntakeSettingsUpdate);
    GetIntakeSettings();
  }

  componentWillUnmount() {
    IntakeSettingsStore.removeChangeListener(this.onIntakeSettingsUpdate);
  }

  onIntakeSettingsUpdate() {
    let settings = IntakeSettingsStore.getIntakeSettings();
    this.setState({ currentSettings: settings });
  }

  toggleRejectDupes() {
    let cs = this.state.currentSettings;
    cs.rejectDupe = !cs.rejectDupe;
    this.setState({ currentSettings: cs });
  }

  toggleRemoveDollarSigns() {
    let cs = this.state.currentSettings;
    cs.removeDollarSignsFromOCR = !cs.removeDollarSignsFromOCR;
    this.setState({ currentSettings: cs });
  }

  toggleAllowDupes() {
    let cs = this.state.currentSettings;
    cs.skipRejectDelete = !cs.skipRejectDelete;
    this.setState({ currentSettings: cs });
  }

  onEditIADuration(e) {
    let settings = this.state.currentSettings;
    if (e.target.value === '') {
      settings.iaDuration = '';
      this.setState({ currentSettings: settings });
    } else if (!isNaN(e.target.value)) {
      settings.iaDuration = parseInt(e.target.value);
      this.setState({ currentSettings: settings });
    }
  }
  onEditDirDuration(e) {
    let settings = this.state.currentSettings;
    if (e.target.value === '') {
      settings.directoryDuration = '';
      this.setState({ currentSettings: settings });
    } else if (!isNaN(e.target.value)) {
      settings.directoryDuration = parseInt(e.target.value);
      this.setState({ currentSettings: settings });
    }
  }
  onOCRQualityChange(e) {
    let settings = this.state.currentSettings;
    settings.defaultOCRQual = parseInt(e.target.value);
    this.setState({ currentSettings: settings });
  }

  onOCRMethodChange(value) {
    let settings = this.state.currentSettings;
    settings.defaultOCRType = value;
    this.setState({ currentSettings: settings });
  }

  saveIntakeSettings() {
    //UpdateADSettings(this.state.currentSettings);
    UpdateIntakeSettings(this.state.currentSettings);
  }

  render() {
    return (
      <div className='container'>
        {this.state.currentSettings !== undefined && (
          <div
            style={{ maxWidth: '60%', margin: '12px' }}
            id='intakeInfo'
            className='card'
          >
            <div className='card-header'>
              <FontAwesomeIcon icon={solid('gear')} /> Intake Settings
            </div>
            <div className='card-body'>
              <div>
                <label htmlFor='txtLDAP' className='form-label'>
                  InboundAutomation check interval{' '}
                  <span style={{ color: '#C1C1C1' }}>(seconds)</span>
                </label>
                <input
                  type='text'
                  className='form-control'
                  id='txtLDAP'
                  value={this.state.currentSettings.iaDuration}
                  onChange={this.onEditIADuration}
                  placeholder='seconds'
                />
              </div>
              <div>
                <label htmlFor='txtLDAP' className='form-label'>
                  Directory check interval{' '}
                  <span style={{ color: '#C1C1C1' }}>(seconds)</span>
                </label>
                <input
                  type='text'
                  className='form-control'
                  id='txtLDAP'
                  value={this.state.currentSettings.directoryDuration}
                  onChange={this.onEditDirDuration}
                  placeholder='seconds'
                />
              </div>
            </div>
            <div className='card-body'>
              <div className='form-check form-switch'>
                <label className='form-check-label' htmlFor='rejectDupeswitch'>
                  Reject duplicate uploads
                </label>
                <input
                  className='form-check-input'
                  type='checkbox'
                  checked={this.state.currentSettings.rejectDupe}
                  onChange={this.toggleRejectDupes}
                  id='rejectDupeswitch'
                />
              </div>
              <div className='form-check form-switch'>
                <label className='form-check-label' htmlFor='allowDupeswitch'>
                  Allow duplicate when deleted
                </label>
                <input
                  className='form-check-input'
                  type='checkbox'
                  checked={this.state.currentSettings.skipRejectDelete}
                  onChange={this.toggleAllowDupes}
                  id='allowDupeswitch'
                />
              </div>
              <div className='form-check form-switch'>
                <label className='form-check-label' htmlFor='rejectDupeswitch'>
                  Remove Dollar Sign from OCR Results
                </label>
                <input
                  className='form-check-input'
                  type='checkbox'
                  checked={this.state.currentSettings.removeDollarSignsFromOCR}
                  onChange={this.toggleRemoveDollarSigns}
                  id='removeDollarSignsSwitch'
                />
              </div>
            </div>
            <div className='card-body'>
              <label className='form-label' htmlFor='allowDupeswitch'>
                Default OCR Quality
              </label>
              <select
                className='form-select'
                aria-label='Default OCR Quality'
                value={this.state.currentSettings.defaultOCRQual}
                onChange={this.onOCRQualityChange}
              >
                <option value='1'>Standard</option>
                <option value='2'>High</option>
                <option value='3'>Highest</option>
              </select>
              <label className='form-label' htmlFor='allowDupeswitch'>
                Default OCR Method
              </label>

              <div className=''>
      
                        {this.state.currentSettings.doesOcrServiceExist && (
                          <span>
                            <div className='form-check'>
                                <input
                                  className='form-check-input'
                                  type='radio'
                                  name='flexRadioDefault'
                                  id='ocrRadioSmartOCR'
                                  onChange={() => this.onOCRMethodChange("full")}
                                  checked={this.state.currentSettings.defaultOCRType === "full" || this.state.currentSettings.defaultOCRType === "smart"}
                                />
                                <label
                                  className='form-check-label'
                                  htmlFor='flexRadioDefault2'
                                >
                                  OCR 1.0
                                </label>
                              </div>
                              {(this.state.currentSettings.defaultOCRType === "full" || this.state.currentSettings.defaultOCRType === "smart") && (
                                <span className=''>
                                    <div className='form-check form-switch ms-3'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        id='fullOcr'
                                        onChange={() => this.onOCRMethodChange("full")}
                                        checked={this.state.currentSettings.defaultOCRType === "full"}
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='preSplitChange'
                                      >
                                        Full
                                      </label>
                                    </div>
                                    <div className='form-check form-switch ms-3'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        id='barcodeSplit'
                                        onChange={() => this.onOCRMethodChange("smart")}
                                        checked={this.state.currentSettings.defaultOCRType === "smart"}
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='barcodeSplit'
                                      >
                                        Smart
                                      </label>
                                    </div>
                                </span>
                              )}
                          </span>
                        )}
                        {this.state.currentSettings.doesIronServiceExist && (
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='flexRadioDefault'
                              id='ocrRadioIronOCR'
                              onChange={() => this.onOCRMethodChange("iron")}
                              checked={this.state.currentSettings.defaultOCRType === "iron"}
                            />
                              <label
                                className='form-check-label'
                                htmlFor='flexRadioDefault2'
                              >
                              OCR 2.0
                            </label>
                          </div>
                        )}
  
     
                      </div>
         
              <button
                className='btn btn-success btn-sm mt-2'
                onClick={this.saveIntakeSettings}
              >
                Save
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default IntakeSettings;
