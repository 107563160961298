import * as React from 'react';
import { createBrowserHistory } from 'history';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import BPSidebar from './BPSidebar';
import { GetOUList } from '../../../../actions/OUActions';
import OUStore from '../../../../stores/OUStore';
import { ExportBusinessProcess, GetBP, GetBPList, UpdateBP } from '../../../../actions/BPActions';
import BPStore from '../../../../stores/BPStore';
import actionTypes from '../../../../actions/ActionTypes';

const customHistory = createBrowserHistory();

class BusinessProcessView extends React.Component {
  constructor(props) {
    // state
    super(props);

    this.state = {
      currentBP: {
        bpId: 0,
        bpName: '',
        workflowId: 0,
        formTypeId: 0,
      },
      orgUnits: [],
      isDirty: false,
    };

    this.onChangeBPName = this.onChangeBPName.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.onBPUpdate = this.onBPUpdate.bind(this);
    this.loadBPFromDB = this.loadBPFromDB.bind(this);
    this.onOUUpdate = this.onOUUpdate.bind(this);
    this.changeOU = this.changeOU.bind(this);
    this.onExportClick = this.onExportClick.bind(this);
  }

  componentDidMount() {
    BPStore.addChangeListener(this.onBPUpdate);
    OUStore.addChangeListener(this.onOUUpdate);
    this.loadBPFromDB();
    GetOUList();
  }

  componentWillUnmount() {
    BPStore.removeChangeListener(this.onBPUpdate);
    OUStore.removeChangeListener(this.onOUUpdate);
  }

  componentDidUpdate() {
    //console.log('update');
    this.loadBPFromDB();
  }

  onBPUpdate() {
    let lastChange = BPStore.getLastChange();
    if (lastChange === actionTypes.BPUpdate) {
      let bp = BPStore.getCurrentBP();
      if (this.state.currentBP.bpId === 0 && bp.bpId !== 0) {
        customHistory.push(`/Admin/BusinessProcess/${bp.bpId}`);
        this.setState({ currentBP: bp, isDirty: false });
        GetBPList();
      } else {
        console.log(bp);
        this.setState({ currentBP: bp, isDirty: false });
        GetBPList();
      }
    }
    if (lastChange === actionTypes.BPGetOne) {
      let bp = BPStore.getCurrentBP();
      this.setState({ currentBP: bp, isDirty: false });
    }
  }

  onOUUpdate() {
    let lastChange = OUStore.getLastChange();
    if (lastChange === actionTypes.OUGetList) {
      let ous = OUStore.getOUList();
      this.setState({ orgUnits: ous });
    }
  }

  onSaveClick() {
    UpdateBP(
      this.state.currentBP.bpId,
      this.state.currentBP.bpName,
      this.state.currentBP.orgUnitId
    );
  }

  onExportClick() {
    ExportBusinessProcess(
      this.state.currentBP.bpId,
      this.state.currentBP.bpName
    );
  }

  loadBPFromDB() {
    let loc = window.location.pathname;
    let pathParts = loc.split('/');
    let bpId = parseInt(pathParts[pathParts.length - 1], 10);
    if (this.state.currentBP.bpId !== 0 && bpId === 0) {
      let newBP = {
        bpId: 0,
        bpName: '',
      };

      this.setState({ currentBP: newBP, isDirty: false }, () => {
        document.getElementById('txtBPName').focus();
      });
    } else if (this.state.currentBP.bpId !== bpId) {
      //let ou = this.state.currentOU;

      GetBP(bpId);
    }
  }

  onChangeBPName(e) {
    let bp = this.state.currentBP;
    bp.bpName = e.target.value;
    this.setState({ currentBP: bp, isDirty: true });
  }

  changeOU(e) {
    let bp = this.state.currentBP;
    bp.orgUnitId = parseInt(e.target.value);
    this.setState({ currentBP: bp, isDirty: true });
  }

  render() {
    return (
      <div className='sidebarLayout d-flex flex-row flex-fill'>
        <div className='sidebar-container'>
          <BPSidebar />
        </div>
        <div
          className='flex-fill'
          style={{
            display: 'grid',
            gridTemplateColumns: '650px 1fr',
            gridTemplateRows: '180px 60px 1fr 15px',
            paddingLeft: '60px',
          }}
        >
          <div
            id='ouInfo'
            className='card'
            style={{ gridColumn: '1', gridRow: '1', margin: '16px' }}
          >
            <div className='card-header'>
              <FontAwesomeIcon icon={solid('folder')} /> Business Process
            </div>
            <div className='card-body'>
              <div className='row'>
                <div className='form-group col-6'>
                  <label htmlFor='txtOUName'>Name:</label>
                  <input
                    type='text'
                    className={`form-control`}
                    id='txtBPName'
                    value={this.state.currentBP.bpName}
                    onChange={this.onChangeBPName}
                    placeholder='business process name'
                  />
                </div>
                {this.state.currentBP.bpId !== 0 && (
                  <div className='form-group col-6'>
                    <label htmlFor='selectOU'>Organizational Unit:</label>
                    <select
                      type='text'
                      className={`form-select`}
                      id='selectOU'
                      value={this.state.currentBP.orgUnitId}
                      onChange={this.changeOU}
                      placeholder='organizational unit (optional)'
                    >
                      <option value='0'>none</option>
                      {this.state.orgUnits.map((org) => (
                        <option key={org.orgUnitId} value={org.orgUnitId}>
                          {org.name}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            id='bpButtons'
            style={{ gridColumn: '1', gridRow: '2', marginLeft: '18px' }}
          >

              <button className='btn btn-secondary' onClick={this.onExportClick}>
                Export Business Process
              </button>
            {this.state.isDirty &&
              this.state.currentBP.bpName.trim() !== '' && (
                <button className='btn btn-success' onClick={this.onSaveClick}>
                  Save changes
                </button>
              )}
          </div>
          <div
            id='wfandFt'
            style={{ gridColumn: '1', gridRow: '3', marginLeft: '18px' }}
          >
            {this.state.currentBP.bpId !== 0 && (
              <div className='row'>
                <div className='col-6'>
                  <Link
                    to={`/Admin/WorkflowView/${this.state.currentBP.workflowId}`}
                  >
                    <button
                      style={{
                        width: '250px',
                        height: '150px',
                        backgroundColor: '#BADDFF',
                        border: 'solid 1px #4D87BF',
                      }}
                      className='btn'
                    >
                      <div>
                        <FontAwesomeIcon icon={solid('sitemap')} size='2x' />
                      </div>
                      <span style={{ fontWeight: '700' }}>View Workflow</span>
                    </button>
                  </Link>
                </div>
                <div className='col-6'>
                  <Link
                    to={`/Admin/FormType/${this.state.currentBP.formTypeId}`}
                  >
                    <button
                      style={{
                        width: '250px',
                        height: '150px',
                        backgroundColor: '#D4FFBA',
                        border: 'solid 1px #5FB02F',
                      }}
                      className='btn'
                    >
                      <div>
                        <FontAwesomeIcon icon={solid('file-alt')} size='2x' />
                      </div>
                      <span style={{ fontWeight: '700' }}>View Form Type</span>
                    </button>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default BusinessProcessView;
