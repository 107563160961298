import * as React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import OUStore from '../../../../stores/OUStore';
import { GetOUList } from '../../../../actions/OUActions';
import actionTypes from '../../../../actions/ActionTypes';

class OUSidebar extends React.Component {
  constructor() {
    super();
    this.state = {
      ouList: [],
    };

    this.onReceiveOUChange = this.onReceiveOUChange.bind(this);
  }

  componentDidMount() {
    OUStore.addChangeListener(this.onReceiveOUChange);
    GetOUList();
  }

  componentWillUnmount() {
    OUStore.removeChangeListener(this.onReceiveOUChange);
  }

  onReceiveOUChange() {
    let lastChange = OUStore.getLastChange();
    if (lastChange === actionTypes.OUGetList) {
      let ous = OUStore.getOUList();
      this.setState({
        ouList: ous,
      });
    }
  }

  render() {
    let listOfOUs;
    if (this.state.ouList !== null) {
      listOfOUs = this.state.ouList.map((ou) => (
        <div key={ou.orgUnitId}>
          <Link
            className='sidebar-link'
            to={'/Admin/OrgUnit/' + ou.orgUnitId}
            //onClick={() => this.handleBPClick(bp)}
            style={{ cursor: 'Pointer', wordWrap: 'break-word' }}
          >
            {ou.name}
          </Link>{' '}
        </div>
      ));
    } else {
      listOfOUs = <div />;
    }
    return (
      <div
        className='d-flex flex-column p-3 text-white bg-dark'
        style={{ height: '100%', width: '300px' }}
      >
        <Link
          to='/Admin/BusinessProcess'
          className='d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none'
        >
          <span className='fs-4'>
            <span style={{ color: '#EFDA62' }}>
              <FontAwesomeIcon icon={solid('folder')} />
            </span>
            {'   '}
            Organizational Units
          </span>
        </Link>
        <hr />

        <div>{listOfOUs}</div>
        <hr />
        <Link className='sidebar-link' to='/Admin/OrgUnit/0'>
          New Organizational Unit
        </Link>
      </div>
    );
  }
}

export default OUSidebar;
