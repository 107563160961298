import * as React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import LoginStore from '../../../stores/LoginStore';
import UserStore from '../../../stores/UserStore';
import { GetUserList, GetAllUserList } from '../../../actions/UserActions';
import actionTypes from '../../../actions/ActionTypes';

class UsersSidebar extends React.Component {
  constructor() {
    super();
    this.state = {
      userList: [],
      searchText: '',
      filteredUserList: [],
      showInactive: false,
      licenseLevel: 0,
    };

    this.onReceiveUserChange = this.onReceiveUserChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.changeShowInactive = this.changeShowInactive.bind(this);
    this.onLoginUpdate = this.onLoginUpdate.bind(this);
  }

  componentDidMount() {
    UserStore.addChangeListener(this.onReceiveUserChange);
    LoginStore.addChangeListener(this.onLoginUpdate);
    this.updateUserInfo();
    GetUserList();
  }

  componentWillUnmount() {
    UserStore.removeChangeListener(this.onReceiveUserChange);
    LoginStore.removeChangeListener(this.onLoginUpdate);
  }

  onReceiveUserChange() {
    let lastChange = UserStore.getLastChange();

    if (lastChange === actionTypes.UsersGetUsers) {
      let users = UserStore.getUserList();

      if (this.state.showInactive) {
        this.setState({
          userList: users,
          searchText: '',
          filteredUserList: users,
        });
      } else {
        let filter = [];
        users.forEach((user) => {
          if (user.isActive) {
            filter.push(user);
          }
        });
        this.setState({
          userList: users,
          searchText: '',
          filteredUserList: filter,
        });
      }
    }
  }

  onLoginUpdate() {
    let lastAction = LoginStore.getLastAction();
    if (lastAction === 'Login') {
      this.updateUserInfo();
    }
  }

  updateUserInfo() {
    let user = LoginStore.getUser();
    if (user !== undefined && user.userName !== undefined) {
      this.setState({
        licenseLevel: user.licenseLevel,
      });
    }
  }

  onSearchChange(e) {
    let search = e.target.value;
    if (search === '') {
      let newList = [...this.state.userList];
      this.setState({ searchText: search, filteredUserList: newList });
    } else {
      let newList = [];
      this.state.userList.forEach((user) => {
        if (
          user.userName.toLowerCase().includes(search.toLowerCase()) ||
          user.firstName.toLowerCase().includes(search.toLowerCase()) ||
          user.lastName.toLowerCase().includes(search.toLowerCase())
        ) {
          newList.push(user);
        }
      });
      this.setState({ searchText: search, filteredUserList: newList });
    }
  }

  changeShowInactive(e) {
    let showInactive = e.target.checked;
    if (showInactive) {
      GetAllUserList();
    } else {
      GetUserList();
    }
    this.setState({ showInactive: showInactive });
  }

  render() {
    let listOfUsers;
    if (this.state.userList !== null) {
      listOfUsers = this.state.filteredUserList.map((user) => (
        <div
          key={user.userId}
          style={{
            display:
              this.state.licenseLevel === 5 || user.licenseLevel <= 4
                ? 'block'
                : 'none',
          }}
        >
          {user.licenseLevel >= 4 && user.isActive && (
            <FontAwesomeIcon
              icon={solid('circle')}
              style={{ fontSize: '9px', color: '#cd5c5c' }}
            />
          )}
          {user.licenseLevel === 3 && user.isActive && (
            <FontAwesomeIcon
              icon={solid('circle')}
              style={{ fontSize: '9px', color: '#5cc951' }}
            />
          )}
          {user.licenseLevel === 2 && user.isActive && (
            <FontAwesomeIcon
              icon={solid('circle')}
              style={{ fontSize: '9px', color: '#528ccd' }}
            />
          )}
          {user.licenseLevel === 1 && user.isActive && (
            <FontAwesomeIcon
              icon={solid('circle')}
              style={{ fontSize: '9px', color: '#f1d951' }}
            />
          )}
          {user.licenseLevel === 4 && !user.isActive && (
            <FontAwesomeIcon
              icon={regular('circle')}
              style={{ fontSize: '9px', color: '#cd5c5c' }}
            />
          )}
          {user.licenseLevel === 1 && !user.isActive && (
            <FontAwesomeIcon
              icon={regular('circle')}
              style={{ fontSize: '9px', color: '#f1d951' }}
            />
          )}{' '}
          <Link
            className='sidebar-link'
            to={'/Admin/UsersView/' + user.userId}
            //onClick={() => this.handleBPClick(bp)}
            style={{ cursor: 'Pointer', wordWrap: 'break-word' }}
          >
            {user.userName}
          </Link>{' '}
          {user.licenseLevel === 5 && (
            <FontAwesomeIcon
              icon={solid('lock')}
              style={{ fontSize: '11px', color: '#888' }}
            />
          )}
        </div>
      ));
    } else {
      listOfUsers = <div />;
    }
    return (
      <div
        className='d-flex flex-column p-3 text-white bg-dark'
        style={{ height: '100%', width: '245px' }}
      >
        <Link
          to='/v/administration/directories'
          className='d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none'
        >
          <span className='fs-4'>
            <FontAwesomeIcon icon={solid('user')} />
            {'   '}
            Users
          </span>
        </Link>
        <hr />
        <div className='input-group rounded' style={{ marginBottom: '16px' }}>
          <input
            type='search'
            value={this.state.searchText}
            className='form-control'
            placeholder='Search'
            aria-label='Search'
            onChange={this.onSearchChange}
            aria-describedby='search-addon'
          />
          <span className='input-group-text border-0' id='search-addon'>
            <FontAwesomeIcon icon={solid('search')} />
          </span>
        </div>

        <Link className='sidebar-link' to='/Admin/UsersView/0'>
          New User
        </Link>
        <hr />
        <div className='form-check form-switch'>
          <input
            type='checkbox'
            className='form-check-input'
            checked={this.state.showInactive}
            onChange={this.changeShowInactive}
            id='chkActive'
          />
          <label
            className='form-check-label'
            style={{ fontSize: '8pt' }}
            htmlFor='chkActive'
          >
            Show Inactive
          </label>
        </div>
        <div>{listOfUsers}</div>

      </div>
    );
  }
}

export default UsersSidebar;
