import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

class ActionButton extends React.Component {
  //isList: Array<BPModel>;
  constructor() {
    super();
    this.state = {
      mouseOver: false,
      mouseDown: false,
    };
  }

  render() {
    return (
      <button
        className='btn float-start'
        style={{
          marginTop: '6px',
          marginBottom: '6px',
          position: 'relative',
          marginLeft: '6px',
          backgroundColor: this.props.isDisabled
            ? '#bbb'
            : this.props.iconColor,
          color: '#FFF',
        }}
        title={this.props.toolTip}
        disabled={this.props.isDisabled}
        onClick={this.props.onClick}
      >
        {/*<FontAwesomeIcon icon={solid(this.props.iconClass)} />*/}
        {this.props.iconClass === 'thumbs-up' && !this.props.waiting && (
          <FontAwesomeIcon icon={solid('thumbs-up')} />
        )}
        {this.props.iconClass === 'thumbs-down' && !this.props.waiting && (
          <FontAwesomeIcon icon={solid('thumbs-down')} />
        )}
        {this.props.waiting && <FontAwesomeIcon icon={solid('spinner')} spin />}
        {this.props.iconClass === 'save' && (
          <FontAwesomeIcon icon={regular('save')} />
        )}
        {this.props.iconClass === 'saving' && (
          <FontAwesomeIcon
            style={{ color: '#000' }}
            icon={solid('spinner')}
            spin
          />
        )}
        {this.props.iconClass === 'arrow-left' && (
          <FontAwesomeIcon icon={solid('arrow-left')} />
        )}
        {this.props.iconClass === 'arrow-right' && (
          <FontAwesomeIcon icon={solid('arrow-right')} />
        )}
        {this.props.iconClass === 'paperclip' && (
          <FontAwesomeIcon icon={solid('paperclip')} />
        )}
        {this.props.iconClass === 'trash-alt' && (
          <FontAwesomeIcon icon={solid('trash-alt')} />
        )}
        {this.props.iconClass === 'pdf' && (
          <FontAwesomeIcon icon={regular('file-pdf')} />
        )}
        {this.props.iconClass === 'file' && (
          <FontAwesomeIcon icon={solid('file-image')} />
        )}
        {this.props.iconClass === 'undo' && (
          <FontAwesomeIcon icon={solid('undo')} />
        )}
        {this.props.iconClass === 'stamp' && (
          <FontAwesomeIcon icon={solid('stamp')} />
        )}{' '}
        {this.props.showBadge && (
          <span className='badge rounded-pill bg-secondary'>
            {this.props.badgeValue}
          </span>
        )}
      </button>
    );
  }
}

export default ActionButton;
