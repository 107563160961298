import * as React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import MailboxSidebar from './MailboxSidebar';
import MailboxStore from '../../../../stores/MailboxStore';
import actionTypes from '../../../../actions/ActionTypes';
import {
  CreateMailbox,
  GetMailboxList,
  UpdateMailbox,
  LoadMailbox,
  TestMailbox,
  AuthenticateOAuth,
  InstructionCheck,
  DeleteMailbox,
  UpdateMailboxPassword,
} from '../../../../actions/MailboxActions';

class MailboxView extends React.Component {
  constructor(props) {
    super(props);
    // state
    this.interval = undefined;
    this.state = {
      currentMailbox: {
        name: '',
        server: '',
        serverType: 0,
        port: 0,
        authenticationMethod: 0,
        username: '',
        domain: '',
        maxMessageSizeMb: 10,
        emailAddress: '',
        newMbId: 0,
        cilentId: '',
        useSystemClientId: false,
        passWord: '',
      },

      isDirty: false,
      error: '',
      isTesting: false,
      isSaving: false,
      changingPassword: false,
      newPass1: '',
      newPass2: '',
      passwordErrors: [
        { key: 1, error: 'Password and confirm password do not match' },
        { key: 2, error: 'Password must be at least 6 characters long' },
      ],
      showPasswordError: false,
      testResult: undefined,
      isAuthenticating: false,
      oauthInstr: undefined,
      authResult: undefined,
    };

    this.updateMailbox = this.updateMailbox.bind(this);

    this.editMailboxName = this.editMailboxName.bind(this);
    this.editServerSettings = this.editServerSettings.bind(this);
    this.toggleChangePassword = this.toggleChangePassword.bind(this);
    this.UpdateChangePassword2 = this.UpdateChangePassword2.bind(this);
    this.editNewPass = this.editNewPass.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onStoreUpdate = this.onStoreUpdate.bind(this);
    this.toggleUseSystemClientId = this.toggleUseSystemClientId.bind(this);
    this.editClientId = this.editClientId.bind(this);
    this.startMailboxTest = this.startMailboxTest.bind(this);
    this.closeTestResult = this.closeTestResult.bind(this);
    this.startAuthentication = this.startAuthentication.bind(this);
    this.checkInstructions = this.checkInstructions.bind(this);
    this.closeInstructions = this.closeInstructions.bind(this);
    this.deleteMailbox = this.deleteMailbox.bind(this);
  }

  componentDidMount() {
    MailboxStore.addChangeListener(this.onStoreUpdate);
    this.updateMailbox();
  }

  componentWillUnmount() {
    MailboxStore.removeChangeListener(this.onStoreUpdate);
  }

  componentDidUpdate() {
    this.updateMailbox();
  }

  updateMailbox() {
    let loc = window.location.pathname;
    let pathParts = loc.split('/');
    let mailboxId = parseInt(pathParts[pathParts.length - 1], 10);
    if (
      mailboxId !== 0 &&
      (this.state.currentMailbox === undefined ||
        this.state.currentMailbox.mailboxId !== mailboxId)
    ) {
      LoadMailbox(mailboxId);
    } else if (
      mailboxId === 0 &&
      this.state.currentMailbox !== undefined &&
      this.state.currentMailbox.mailboxId !== 0
    ) {
      let currentMailbox = {};
      currentMailbox.mailboxId = 0;
      currentMailbox.name = '';
      currentMailbox.server = '';
      currentMailbox.mailboxType = 0;
      currentMailbox.port = 0;
      currentMailbox.authenticationMethod = 0;
      currentMailbox.username = '';
      currentMailbox.domain = '';
      currentMailbox.maxMessageSizeMb = 10;
      currentMailbox.emailAddress = '';

      this.setState({
        currentMailbox: currentMailbox,
      });
    }
  }

  onStoreUpdate() {
    let lastAction = MailboxStore.lastChange();

    if (lastAction === 'MailboxLoadOne') {
      let mb = MailboxStore.getCurrentMailbox();
      this.setState({ currentMailbox: mb, isSaving: false, isDirty: false });
    }

    if (lastAction === actionTypes.MailboxUpdateOne) {
      let mb = MailboxStore.getCurrentMailbox();
      this.setState({ currentMailbox: mb, isSaving: false, isDirty: false });
      GetMailboxList();
      if (this.state.isTesting) {
        console.log(this.state.currentMailbox);
        TestMailbox(this.state.currentMailbox);
      }
      if (this.state.isAuthenticating) {
        AuthenticateOAuth(this.state.currentMailbox.mailboxId);
        this.interval = setInterval(this.checkInstructions, 1000);
      }
    }
    if (lastAction === actionTypes.MailboxCreateOne) {
      let mb = MailboxStore.getCurrentMailbox();
      this.setState({ newMbId: mb.mailboxId }, () => {
        let link = document.getElementById('newMbLink');
        link.click();
      });
      this.setState({ currentMailbox: mb, isSaving: false, isDirty: false });
      GetMailboxList();
      if (this.state.isAuthenticating) {
        AuthenticateOAuth(this.state.currentMailbox.mailboxId);
        this.interval = setInterval(this.checkInstructions, 1000);
      }
    }

    if (lastAction === actionTypes.MailboxText) {
      let result = MailboxStore.getTestResult();
      if (result.message.includes('Authentication unsuccessful')) {
        result.message = 'Authentication unsuccessful. ' + result.message;
      }
      this.setState({ isTesting: false, testResult: result });
    }

    if (lastAction === actionTypes.MailboxDelete) {
      let x = MailboxStore.getLastError();
      if (x === '') {
        let link = document.getElementById('mailboxHome');
        link.click();
      } else {
        //PostAlert('error', x);
      }
    }
    if (lastAction === actionTypes.MailboxInstructions) {
      let instr = MailboxStore.getInstructions();
      this.setState({ oauthInstr: instr });
      clearInterval(this.interval);
    }

    if (lastAction === actionTypes.MailboxAuthenticate) {
      let result = MailboxStore.getAuthResult();
      this.setState({ oauthInstr: undefined, authResult: result });
    }
  }

  editMailboxName(e) {
    let mb = this.state.currentMailbox;
    mb.name = e.target.value;
    this.setState({ currentMailbox: mb, isDirty: true });
  }

  editServerSettings(e, setting) {
    let mb = this.state.currentMailbox;
    switch (setting) {
      case 'server':
        mb.server = e.target.value;
        break;
      case 'serverType':
        mb.mailboxType = parseInt(e.target.value, 10);
        break;
      case 'port':
        if (e.target.value === '') {
          mb.port = 0;
        } else {
          mb.port = parseInt(e.target.value, 10);
        }
        break;
      case 'authMethod':
        mb.authenticationMethod = parseInt(e.target.value, 10);
        break;
      default:
        break;
    }
    this.setState({ currentMailbox: mb, isDirty: true });
  }

  editEmailSettings(e, setting) {
    let mb = this.state.currentMailbox;
    switch (setting) {
      case 'emailAddress':
        mb.emailAddress = e.target.value;
        break;
      case 'maxMessageSizeMb':
        if (e.target.value === '') {
          mb.maxMessageSizeMb = 0;
        } else {
          mb.maxMessageSizeMb = parseInt(e.target.value, 10);
        }
        break;
      case 'domain':
        mb.domain = e.target.value;
        break;
      case 'username':
        mb.username = e.target.value;
        break;
      default:
        break;
    }
    this.setState({ currentMailbox: mb, isDirty: true });
  }

  toggleChangePassword() {
    let chPass = this.state.changingPassword;
    this.setState({ changingPassword: !chPass });
  }

  UpdateChangePassword2() {
    let mb = this.state.currentMailbox;
    mb.passWord = this.state.newPass1;
    this.setState({ currentMailbox: mb });

    UpdateMailboxPassword(this.state.currentMailbox, this.state.newPass1);

    let chPass = this.state.changingPassword;
    this.setState({ changingPassword: !chPass });
  }

  editNewPass(e, passwordtype) {
    let pass1 = this.state.newPass1;
    let pass2 = this.state.newPass2;

    switch (passwordtype) {
      case 'newPass1':
        this.setState({ newPass1: e.target.value });
        pass1 = e.target.value;
        break;
      case 'newPass2':
        this.setState({ newPass2: e.target.value });
        pass2 = e.target.value;
        break;
      default:
        break;
    }

    if (pass1 === '' && pass2 === '') {
      this.setState({ showPasswordError: false });
    } else {
      let errors = [];
      if (pass1.length < 6 || pass2.length < 6) {
        errors.push({
          key: 1,
          error: 'Password must be at least 6 characters long',
        });
      }
      if (pass1 !== pass2) {
        errors.push({
          key: 2,
          error: 'Password and confirm password do not match',
        });
      }
      if (errors.length > 0) {
        this.setState({ showPasswordErrors: true, passwordErrors: errors });
      } else {
        this.setState({ showPasswordErrors: false, passwordErrors: [] });
      }
    }
  }

  onSave() {
    // validate mailbox
    if (this.state.currentMailbox.mailboxId === 0) {
      CreateMailbox(this.state.currentMailbox);
      this.setState({ isSaving: true });
    } else {
      UpdateMailbox(this.state.currentMailbox);
      this.setState({ isSaving: true });
    }
  }

  toggleUseSystemClientId(e) {
    let mb = this.state.currentMailbox;
    mb.useSystemClientId = e.target.checked;
    this.setState({ currentMailbox: mb });
  }

  editClientId(e) {
    let mb = this.state.currentMailbox;
    mb.clientId = e.target.value;
    this.setState({ currentMailbox: mb });
    this.setState({ isDirty: true });
  }

  startMailboxTest() {
    this.setState({ isTesting: true });
    // validate mailbox
    if (this.state.currentMailbox.mailboxId === 0) {
      CreateMailbox(this.state.currentMailbox);
      this.setState({ isSaving: true });
    } else {
      UpdateMailbox(this.state.currentMailbox);
      this.setState({ isSaving: true });
    }
  }
  deleteMailbox() {
    let mb = this.state.currentMailbox;
    this.setState({ currentMailbox: mb });

    DeleteMailbox(this.state.currentMailbox.mailboxId);
  }

  closeTestResult() {
    this.setState({ isTesing: false, testResult: undefined });
  }

  startAuthentication() {
    this.setState({ isAuthenticating: true });
    if (this.state.currentMailbox.mailboxId === 0) {
      CreateMailbox(this.state.currentMailbox);
      //this.setState({ isSaving: true });
    } else {
      UpdateMailbox(this.state.currentMailbox);
      //this.setState({ isSaving: true });
    }

    //UpdateMailbox(this.state.currentMailbox);
  }

  checkInstructions() {
    console.log(this.state.currentMailbox);
    InstructionCheck(this.state.currentMailbox.mailboxId);
  }

  closeInstructions() {
    this.setState({
      oauthInstr: undefined,
      authResult: undefined,
      isAuthenticating: false,
    });
  }

  render() {
    return (
      <div className='container-fluid' style={{ height: '100%' }}>
        <div className='row flex-nowrap' style={{ height: '100%' }}>
          <MailboxSidebar />
          <Link
            id='mailboxHome'
            className='nav-link'
            to={`/Admin/Mailbox`}
            style={{ display: 'none' }}
          ></Link>
          {this.state.currentMailbox !== undefined && (
            <div
              className='flex-fill p-4'
              style={{ backgroundColor: '#efefef', overflow: 'scroll' }}
            >
              <div
                className='card'
                style={{
                  maxHeight: '620px',
                }}
              >
                <div className='card-header'>
                  <h4>Mailbox Name</h4>
                </div>
                <div className='card-body row'>
                  <div className='col-6'>
                    <label>Name:</label>
                    <input
                      type='text'
                      className='form-control'
                      value={this.state.currentMailbox.name}
                      onChange={this.editMailboxName}
                    ></input>
                  </div>
                </div>
              </div>

              {this.state.isDirty && (
                <div>
                  <button
                    className='btn btn-success mt-2'
                    onClick={this.onSave}
                  >
                    <FontAwesomeIcon icon={regular('save')} /> Save
                  </button>
                </div>
              )}

              <div className='card mt-2'>
                <div className='card-header'>
                  <h4>Server settings</h4>
                </div>
                <div className='card-body row'>
                  <div className='col-6'>
                    <label>Server:</label>
                    <input
                      type='text'
                      className='form-control'
                      value={this.state.currentMailbox.server}
                      onChange={(e) => this.editServerSettings(e, 'server')}
                    ></input>
                  </div>
                  <div className='col-6'>
                    <label>Server Type:</label>
                    <select
                      className='form-select'
                      value={this.state.currentMailbox.mailboxType}
                      onChange={(e) => this.editServerSettings(e, 'serverType')}
                    >
                      <option value='0'></option>
                      <option value='11'>InboundAutomation (IMAP)</option>
                      <option value='12'>Notification (SMTP)</option>
                    </select>
                  </div>
                  <div className='col-6 mt-3'>
                    <label>Port:</label>
                    <input
                      type='text'
                      className='form-control'
                      value={this.state.currentMailbox.port}
                      onChange={(e) => this.editServerSettings(e, 'port')}
                    ></input>
                    {this.state.currentMailbox.mailboxId !== 0 &&
                      !this.state.isTesting && (
                        <button
                          className='btn btn-success mt-3'
                          onClick={this.startMailboxTest}
                        >
                          Test Mailbox
                        </button>
                      )}
                    {this.state.currentMailbox.mailboxId !== 0 &&
                      this.state.isTesting && (
                        <button className='btn btn-success mt-3'>
                          <FontAwesomeIcon
                            icon={solid('spinner')}
                            className='fa-spin'
                          />
                        </button>
                      )}
                    {this.state.currentMailbox.mailboxId !== 0 && (
                      <button
                        className='btn btn-danger mt-3 ms-2'
                        onClick={this.deleteMailbox}
                      >
                        Delete Mailbox
                      </button>
                    )}
                  </div>
                  <div className='col-6 mt-3'>
                    <label>Authentication Method:</label>
                    <select
                      className='form-select'
                      value={this.state.currentMailbox.authenticationMethod}
                      onChange={(e) => this.editServerSettings(e, 'authMethod')}
                    >
                      <option value='0'>Email Address and Password</option>
                      <option value='1'>Domain\User Name and Password</option>
                      <option value='3'>MS OAuth (Device Credentials)</option>
                      <option value='4'>MS OAuth (Client Credentials)</option>
                      <option value='2'>No Authentication</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className='card mt-2'>
                <div className='card-header'>
                  <h4>Email settings</h4>
                </div>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-6'>
                      <label>Email Address:</label>
                      <input
                        type='text'
                        className='form-control'
                        value={this.state.currentMailbox.emailAddress}
                        onChange={(e) =>
                          this.editEmailSettings(e, 'emailAddress')
                        }
                      ></input>
                    </div>
                    <div className='col-6'>
                      <label>Max Email Size (Mb):</label>
                      <input
                        type='text'
                        className='form-control'
                        value={this.state.currentMailbox.maxMessageSizeMb}
                        onChange={(e) =>
                          this.editEmailSettings(e, 'maxMessageSizeMb')
                        }
                      ></input>
                    </div>
                  </div>
                  {(this.state.currentMailbox.authenticationMethod === 0 ||
                    this.state.currentMailbox.authenticationMethod === 1) && (
                    <>
                      {this.state.currentMailbox.authenticationMethod === 1 && (
                        <div className='row mt-3'>
                          <div className='col-6'>
                            <label>Domain:</label>
                            <input
                              type='text'
                              className='form-control'
                              value={this.state.currentMailbox.domain}
                              onChange={(e) =>
                                this.editEmailSettings(e, 'domain')
                              }
                            ></input>
                          </div>

                          <div className='col-6'>
                            <label>Username:</label>
                            <input
                              type='text'
                              className='form-control'
                              value={this.state.currentMailbox.username}
                              onChange={(e) =>
                                this.editEmailSettings(e, 'username')
                              }
                            ></input>
                          </div>
                        </div>
                      )}
                      <div>
                        {!this.state.changingPassword &&
                          this.state.currentMailbox.mailboxId !== 0 && (
                            <div className='col-4 mt-3'>
                              <button
                                onClick={this.toggleChangePassword}
                                className='btn btn-primary btn-sm mt-2'
                              >
                                Change password
                              </button>
                            </div>
                          )}
                        {this.state.changingPassword && (
                          <>
                            <div className='row mt-3'>
                              <div className='col-4'>
                                <label>New Password:</label>
                                <input
                                  type='password'
                                  className='form-control'
                                  value={this.state.newPass1}
                                  onChange={(e) =>
                                    this.editNewPass(e, 'newPass1')
                                  }
                                ></input>
                              </div>
                              <div className='col-4'>
                                <label>Confirm New Password:</label>
                                <input
                                  type='password'
                                  className='form-control'
                                  value={this.state.newPass2}
                                  onChange={(e) =>
                                    this.editNewPass(e, 'newPass2')
                                  }
                                ></input>
                              </div>
                            </div>
                            <div>
                              {!this.state.showPasswordErrors && (
                                <button
                                  onClick={this.UpdateChangePassword2}
                                  className='btn btn-success mt-1'
                                >
                                  Update Password
                                </button>
                              )}
                              <button
                                onClick={this.toggleChangePassword}
                                className='btn btn-secondary mt-1 ms-2'
                              >
                                Cancel
                              </button>
                            </div>
                          </>
                        )}
                        {this.state.changingPassword &&
                          this.state.showPasswordErrors && (
                            <div
                              className='alert alert-warning mt-3 ms-2 p-2'
                              style={{ maxWidth: '500px' }}
                            >
                              <ul>
                                {this.state.passwordErrors.map((error) => (
                                  <li key={error.key}>{error.error}</li>
                                ))}
                              </ul>
                            </div>
                          )}
                      </div>
                    </>
                  )}
                  {this.state.currentMailbox.authenticationMethod === 3 && (
                    <div>
                      <div className='form-check form-switch mt-3'>
                        <input
                          className='form-check-input'
                          checked={this.state.currentMailbox.useSystemClientId}
                          onChange={this.toggleUseSystemClientId}
                          type='checkbox'
                        />
                        <label className='form-check-label'>
                          Use System Client Id
                        </label>
                      </div>
                      {!this.state.currentMailbox.useSystemClientId && (
                        <div className='mt-2'>
                          <label className='form-label'>Client Id</label>
                          <input
                            type='text'
                            value={this.state.currentMailbox.clientId}
                            onChange={this.editClientId}
                            className='form-control'
                          />
                        </div>
                      )}
                      {!this.state.isAuthenticating && (
                        <button
                          onClick={this.startAuthentication}
                          className='mt-2 btn btn-primary'
                        >
                          Authenticate
                        </button>
                      )}
                      {this.state.isAuthenticating && (
                        <button className='mt-2 btn btn-primary'>
                          <FontAwesomeIcon
                            icon={solid('spinner')}
                            className='fa-spin'
                          />{' '}
                          Authenticating...
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <Link
                id='newMbLink'
                to={'/Admin/MailboxView/' + this.state.newMbId}
                style={{ display: 'none' }}
              >
                HiddenLink
              </Link>
            </div>
          )}
        </div>
        {this.state.testResult !== undefined && (
          <div className='td-dialogue-container'>
            <div
              className='td-dialogue card'
              style={{ width: '400px', zIndex: 300 }}
            >
              <div className='card-header'>
                Mailbox test result{' '}
                <button
                  type='button'
                  className='btn-close'
                  onClick={this.closeTestResult}
                />
              </div>
              <div className='card-body'>
                {this.state.testResult.result === 'Success' && (
                  <div style={{ color: '#3D9E31' }}>
                    <h4>Success</h4>
                  </div>
                )}
                {this.state.testResult.result === 'Error' && (
                  <div style={{ color: '#9E3131' }}>
                    <h4>Error</h4>
                  </div>
                )}
                <div>{this.state.testResult.message}</div>
              </div>
            </div>
          </div>
        )}
        {this.state.oauthInstr !== undefined && (
          <div className='td-dialogue-container'>
            <div
              className='td-dialogue card'
              style={{ width: '600px', zIndex: 300 }}
            >
              <div className='card-header'>
                To authenticate your account:{' '}
                <button
                  type='button'
                  className='btn-close'
                  onClick={this.closeInstructions}
                />
              </div>
              <div className='card-body'>
                <div>{this.state.oauthInstr.authMessage}</div>
              </div>
            </div>
          </div>
        )}
        {this.state.authResult !== undefined && (
          <div className='td-dialogue-container'>
            <div
              className='td-dialogue card'
              style={{ width: '600px', zIndex: 300 }}
            >
              <div className='card-header'>
                Authentication:{' '}
                <button
                  type='button'
                  className='btn-close'
                  onClick={this.closeInstructions}
                />
              </div>
              <div className='card-body'>
                {this.state.authResult.status === 'Success' && (
                  <div style={{ color: '#3D9E31' }}>
                    <h4>Success</h4>
                  </div>
                )}
                {this.state.authResult.status === 'Error' && (
                  <div style={{ color: '#9E3131' }}>
                    <h4>Error</h4>
                  </div>
                )}
                <div>{this.state.authResult.authMessage}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default MailboxView;
