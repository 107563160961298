import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';


const CHANGE_EVENT = 'workflowStepRolePermissionChange';
let _userRoleList = [];
let _currentRole = {};
let _lastChange = '';

class UserInRoleStore extends EventEmitter {
   addChangeListener(callback) {
      this.on(CHANGE_EVENT, callback);
   }

   removeChangeListener(callback) {
      this.removeListener(CHANGE_EVENT, callback);
   }

   emitChange() {
      this.emit(CHANGE_EVENT);
   }

   getUserRoleList() {

      return _userRoleList;
   }

   getLastChange() {
       return _lastChange;
   }

   getCurrentRole() {
      return _currentRole;
   }

}

const store = new UserInRoleStore();

Dispatcher.register((action) => {
   switch (action.actionType) {
      case actionTypes.UserInRoleGetList:
        _userRoleList = action.Result;
         _lastChange = action.actionType;
         store.emitChange();
         break;
      default:
   }
});

export default store;
