import * as React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import actionTypes from '../../../actions/ActionTypes';
import SearchStore from '../../../stores/SearchStore';

class SearchSidebar extends React.Component {
  //isList: Array<BPModel>;
  constructor(props) {
    super(props);
    this.state = {
      nodeTree: [],
      h: window.innerHeight - 142,
      favorites: [],
    };

    //this.onReceiveActiveDocChange = this.onReceiveActiveDocChange.bind(this);
    this.toggleNode = this.toggleNode.bind(this);
    this.resizeH = this.resizeH.bind(this);
    //this.toggleFavNode = this.toggleFavNode.bind(this);
    this.onSearchUpdate = this.onSearchUpdate.bind(this);
    this.applyTree = this.applyTree.bind(this);
    // this.onSearchChange = this.onSearchChange.bind(this);
  }

  componentDidMount() {
    //ActiveDocumentStore.addChangeListener(this.onReceiveActiveDocChange);
    SearchStore.addChangeListener(this.onSearchUpdate);
    this.resizeH();
    window.addEventListener('resize', this.resizeH);

    // look for qucksearch results
    let adTree = SearchStore.getAdTree();
    if(adTree !== undefined)
      {
        this.applyTree();
      }
  }

  componentWillUnmount() {
    //ActiveDocumentStore.removeChangeListener(this.onReceiveActiveDocChange);
    SearchStore.removeChangeListener(this.onSearchUpdate);
    window.removeEventListener('resize', this.resizeH);
  }

  applyTree() {
    let docCount = SearchStore.getDocCount();
      if (docCount !== 1) {
        let adTree = SearchStore.getAdTree();
        let settings = {
          fav: [],
          favOpen: [],
          ouOpen: [],
          bpOpen: [],
        };
        if (settings !== null) {
          settings.fav.forEach((id) => {
            var node1 = adTree.find((n1) => n1.type === 'bp' && n1.id === id);
            if (node1) {
              node1.isFav = true;
              if (settings.favOpen.indexOf(node1.id) > -1) {
                node1.isOpen = true;
              }

              //favTree.push(node1);
            } else {
              // search children
              adTree.forEach((ou) => {
                if (ou.type === 'ou') {
                  var node2 = ou.children.find(
                    (n2) => n2.type === 'bp' && n2.id === id
                  );
                  if (node2) {
                    node2.isFav = true;
                    if (settings.favOpen.indexOf(node2.id) > -1) {
                      node2.isOpen = true;
                    }
                    //favTree.push(node2);
                  }
                }
              });
            }
          });

          settings.ouOpen.forEach((id) => {
            adTree.forEach((ou) => {
              if (ou.type === 'ou') {
                ou.isOpen = true;
              }
            });
          });

          this.setState({
            nodeTree: adTree, //, favorites: favTree
          });

          this.loadFirstStep(adTree);
        }
      }
  }

  onSearchUpdate() {
    let lastAction = SearchStore.getLastAction();

    if (lastAction === actionTypes.SearchByValues || lastAction === actionTypes.SearchQuickSearch) {
      let docCount = SearchStore.getDocCount();
      if (docCount !== 1) {
        let adTree = SearchStore.getAdTree();
        let settings = {
          fav: [],
          favOpen: [],
          ouOpen: [],
          bpOpen: [],
        };
        if (settings !== null) {
          settings.fav.forEach((id) => {
            var node1 = adTree.find((n1) => n1.type === 'bp' && n1.id === id);
            if (node1) {
              node1.isFav = true;
              if (settings.favOpen.indexOf(node1.id) > -1) {
                node1.isOpen = true;
              }

              //favTree.push(node1);
            } else {
              // search children
              adTree.forEach((ou) => {
                if (ou.type === 'ou') {
                  var node2 = ou.children.find(
                    (n2) => n2.type === 'bp' && n2.id === id
                  );
                  if (node2) {
                    node2.isFav = true;
                    if (settings.favOpen.indexOf(node2.id) > -1) {
                      node2.isOpen = true;
                    }
                    //favTree.push(node2);
                  }
                }
              });
            }
          });

          settings.ouOpen.forEach((id) => {
            adTree.forEach((ou) => {
              if (ou.type === 'ou') {
                ou.isOpen = true;
              }
            });
          });

          this.setState({
            nodeTree: adTree, //, favorites: favTree
          });

          this.loadFirstStep(adTree);
        }
      }
    }



    if (lastAction === actionTypes.SearchByFieldValue) {
      let docCount = SearchStore.getDocCount();
      if (docCount !== 1) {
        let adTree = SearchStore.getAdTree();
        let settings = {
          fav: [],
          favOpen: [],
          ouOpen: [],
          bpOpen: [],
        };
        if (settings !== null) {
          settings.fav.forEach((id) => {
            var node1 = adTree.find((n1) => n1.type === 'bp' && n1.id === id);
            if (node1) {
              node1.isFav = true;
              if (settings.favOpen.indexOf(node1.id) > -1) {
                node1.isOpen = true;
              }

              //favTree.push(node1);
            } else {
              // search children
              adTree.forEach((ou) => {
                if (ou.type === 'ou') {
                  var node2 = ou.children.find(
                    (n2) => n2.type === 'bp' && n2.id === id
                  );
                  if (node2) {
                    node2.isFav = true;
                    if (settings.favOpen.indexOf(node2.id) > -1) {
                      node2.isOpen = true;
                    }
                    //favTree.push(node2);
                  }
                }
              });
            }
          });

          settings.ouOpen.forEach((id) => {
            adTree.forEach((ou) => {
              if (ou.type === 'ou') {
                ou.isOpen = true;
              }
            });
          });

          this.setState({
            nodeTree: adTree, //, favorites: favTree
          });

          this.loadFirstStep(adTree);

        }
      }
    }

    if (lastAction === actionTypes.SearchByUserAudit) {
      console.log('AuditSearch');
      let docCount = SearchStore.getDocCount();
      if (docCount !== 1) {
        let adTree = SearchStore.getAdTree();
        let settings = {
          fav: [],
          favOpen: [],
          ouOpen: [],
          bpOpen: [],
        };
        if (settings !== null) {
          settings.fav.forEach((id) => {
            var node1 = adTree.find((n1) => n1.type === 'bp' && n1.id === id);
            if (node1) {
              node1.isFav = true;
              if (settings.favOpen.indexOf(node1.id) > -1) {
                node1.isOpen = true;
              }

              //favTree.push(node1);
            } else {
              // search children
              adTree.forEach((ou) => {
                if (ou.type === 'ou') {
                  var node2 = ou.children.find(
                    (n2) => n2.type === 'bp' && n2.id === id
                  );
                  if (node2) {
                    node2.isFav = true;
                    if (settings.favOpen.indexOf(node2.id) > -1) {
                      node2.isOpen = true;
                    }
                    //favTree.push(node2);
                  }
                }
              });
            }
          });

          settings.ouOpen.forEach((id) => {
            adTree.forEach((ou) => {
              if (ou.type === 'ou') {
                ou.isOpen = true;
              }
            });
          });

          this.setState({
            nodeTree: adTree, //, favorites: favTree
          });

          this.loadFirstStep(adTree);
        }
      }
    }

    if (lastAction === actionTypes.SearchByUserQueue) {
      let docCount = SearchStore.getDocCount();
      if (docCount !== 1) {
        let adTree = SearchStore.getAdTree();
        let settings = {
          fav: [],
          favOpen: [],
          ouOpen: [],
          bpOpen: [],
        };
        if (settings !== null) {
          settings.fav.forEach((id) => {
            var node1 = adTree.find((n1) => n1.type === 'bp' && n1.id === id);
            if (node1) {
              node1.isFav = true;
              if (settings.favOpen.indexOf(node1.id) > -1) {
                node1.isOpen = true;
              }

              //favTree.push(node1);
            } else {
              // search children
              adTree.forEach((ou) => {
                if (ou.type === 'ou') {
                  var node2 = ou.children.find(
                    (n2) => n2.type === 'bp' && n2.id === id
                  );
                  if (node2) {
                    node2.isFav = true;
                    if (settings.favOpen.indexOf(node2.id) > -1) {
                      node2.isOpen = true;
                    }
                    //favTree.push(node2);
                  }
                }
              });
            }
          });

          settings.ouOpen.forEach((id) => {
            adTree.forEach((ou) => {
              if (ou.type === 'ou') {
                ou.isOpen = true;
              }
            });
          });

          this.setState({
            nodeTree: adTree, //, favorites: favTree
          });

          this.loadFirstStep(adTree);

        }
      }
    }

    if (
      lastAction === actionTypes.SearchByNotes ||
      lastAction === actionTypes.SearchByProperties
    ) {
      let docCount = SearchStore.getDocCount();
      if (docCount !== 1) {
        let adTree = SearchStore.getAdTree();
        let settings = {
          fav: [],
          favOpen: [],
          ouOpen: [],
          bpOpen: [],
        };
        if (settings !== null) {
          settings.fav.forEach((id) => {
            var node1 = adTree.find((n1) => n1.type === 'bp' && n1.id === id);
            if (node1) {
              node1.isFav = true;
              if (settings.favOpen.indexOf(node1.id) > -1) {
                node1.isOpen = true;
              }

              //favTree.push(node1);
            } else {
              // search children
              adTree.forEach((ou) => {
                if (ou.type === 'ou') {
                  var node2 = ou.children.find(
                    (n2) => n2.type === 'bp' && n2.id === id
                  );
                  if (node2) {
                    node2.isFav = true;
                    if (settings.favOpen.indexOf(node2.id) > -1) {
                      node2.isOpen = true;
                    }
                    //favTree.push(node2);
                  }
                }
              });
            }
          });

          settings.ouOpen.forEach((id) => {
            adTree.forEach((ou) => {
              if (ou.type === 'ou') {
                ou.isOpen = true;
              }
            });
          });

          this.setState({
            nodeTree: adTree, //, favorites: favTree
          });

          this.loadFirstStep(adTree);

        }
      }
    }
  }

  //   onReceiveActiveDocChange() {
  //     let lastChange = ActiveDocumentStore.lastChange();

  //     if (lastChange === actionTypes.ADGetADTree) {
  //       let adTree = ActiveDocumentStore.getADTree();
  //       var settings = this.getADSettings();
  //       var favTree = this.state.favorites;
  //       if (settings !== null) {
  //         settings.fav.forEach((id) => {
  //           var node1 = adTree.find((n1) => n1.type === 'bp' && n1.id === id);
  //           if (node1) {
  //             node1.isFav = true;
  //             if (settings.favOpen.indexOf(node1.id) > -1) {
  //               node1.isOpen = true;
  //             }

  //             favTree.push(node1);
  //           } else {
  //             // search children
  //             adTree.forEach((ou) => {
  //               if (ou.type === 'ou') {
  //                 var node2 = ou.children.find(
  //                   (n2) => n2.type === 'bp' && n2.id === id
  //                 );
  //                 if (node2) {
  //                   node2.isFav = true;
  //                   if (settings.favOpen.indexOf(node2.id) > -1) {
  //                     node2.isOpen = true;
  //                   }
  //                   favTree.push(node2);
  //                 }
  //               }
  //             });
  //           }
  //         });

  //         settings.ouOpen.forEach((id) => {
  //           adTree.forEach((ou) => {
  //             if (ou.type === 'ou') {
  //               ou.isOpen = true;
  //             }
  //           });
  //         });

  //         this.setState({
  //           nodeTree: adTree,
  //           favorites: favTree,
  //         });
  //       }
  //     }
  //   }

  refreshNode(node) {}

  loadFirstStep(adTree) {
      // load the results of the very first step
      if (adTree.length > 0 && adTree[0].type === 'bp') {
      var bpId = adTree[0].id;
      if (adTree[0].children.length > 0) {
        var id = adTree[0].children[0].id;
        adTree[0].children[0].isOpen = true;
        //this.props.LoadStepClick(id, bpId);
      }
    }
    if (adTree.length > 0 && adTree[0].type === 'ou') {
      if (
        adTree[0].children.length > 0 &&
        adTree[0].children[0].type === 'bp'
      ) {
        var bpId = adTree[0].children[0].id;
        if (adTree[0].children.length > 0 && adTree[0].children[0].children.length > 0) {
          var id = adTree[0].children[0].children[0].id;
          adTree[0].children[0].children[0].isOpen = true;
          adTree[0].children[0].isOpen = true;
          adTree[0].isOpen = true;
          //this.props.LoadStepClick(id, bpId);
        }
      }
    }
  }

  resizeH() {
    this.setState({ h: window.innerHeight - 142 });
  }

  copyNode(node) {
    var newNode = {
      id: node.id,
      type: node.type,
      name: node.name,
      isOpen: false,
      isFav: node.isFav,
      count: node.count,
      children: [],
    };
    node.children.forEach((n) => {
      newNode.children.push(this.copyNode(n));
    });
    return newNode;
  }

  getADSettings() {
    var settings = JSON.parse(localStorage.getItem('ADSettings'));

    if (settings === null) {
      settings = {
        fav: [],
        favOpen: [],
        ouOpen: [],
        bpOpen: [],
      };
    }
    return settings;
  }

  toggleNode(node) {
    //if (node.type === 'bp') {
      this.props.LoadBPClick(node.id);
    //}
    var settings = this.getADSettings();
    if (node.type === 'bp') {
      let idx = settings.bpOpen.indexOf(node.id);
      if (idx === -1 && !node.isOpen) {
        settings.bpOpen.push(node.id);
      } else if (idx > -1 && node.isOpen) {
        settings.bpOpen.splice(idx, 1);
      }
    }
    if (node.type === 'ou') {
      let idx = settings.ouOpen.indexOf(node.id);
      if (idx === -1 && !node.isOpen) {
        settings.ouOpen.push(node.id);
      } else if (idx > -1 && node.isOpen) {
        settings.ouOpen.splice(idx, 1);
      }
    }
    localStorage.setItem('ADSettings', JSON.stringify(settings));

    var tree = this.state.nodeTree;
    var node1 = tree.find((n1) => n1.type === node.type && n1.id === node.id);
    if (node1) {
      node1.isOpen = !node1.isOpen;
    } else {
      // search children
      tree.forEach((ou) => {
        if (ou.type === 'ou') {
          var node2 = ou.children.find(
            (n2) => n2.type === node.type && n2.id === node.id
          );
          if (node2) {
            node2.isOpen = !node2.isOpen;
          }
        }
      });
    }
    this.setState({ nodeTree: tree });
  }

  toggleFavNode(node) {
    var tree = this.state.favorites;
    var node1 = tree.find((n1) => n1.type === node.type && n1.id === node.id);
    var settings = this.getADSettings();
    var idx = settings.favOpen.indexOf(node.id);
    if (idx === -1 && !node.isOpen) {
      settings.favOpen.push(node.id);
    } else if (idx > -1 && node.isOpen) {
      settings.favOpen.splice(idx, 1);
    }
    localStorage.setItem('ADSettings', JSON.stringify(settings));
    if (node1) {
      node1.isOpen = !node1.isOpen;
    } else {
      // search children
      tree.forEach((ou) => {
        if (ou.type === 'ou') {
          var node2 = ou.children.find(
            (n2) => n2.type === node.type && n2.id === node.id
          );
          if (node2) {
            node2.isOpen = !node2.isOpen;
          }
        }
      });
    }
    this.setState({ favorites: tree });
  }

  nodeMouseUp(e) {}

  render() {
    return (
      <div
        className='d-flex flex-column pt-3 ps-3 text-white bg-dark'
        style={{ maxHeight: '100%', width: '320px' }}
      >
        <Link
          to='/Search'
          className='d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none'
        >
          <span className='fs-4'>
            <FontAwesomeIcon
              style={{ color: '#4BB5CF' }}
              icon={solid('search')}
            />
            {'   '}
            Advanced Search
          </span>
        </Link>
        <hr />
        {this.props.Loading &&
          <div className='h-100 d-flex flex-column align-items-center justify-content-center'>
          <div style={{color: '#8DF5F5', marginBottom: '8px'}}><FontAwesomeIcon icon={solid('circle-notch')} size='3x' spin /></div>
          <div style={{color: '#8DF5F5', fontSize: '14pt', fontFamily: 'Tilt Neon, "Courier New", monospace'}}>Running Search...</div>
          </div>
        }
        {!this.props.Loading &&
        <div
          className='pe-3 coolScroll'
          style={{ maxHeight: `${this.state.h}px`, overflow: 'auto' }}
        >
          <ul className='list-group active-documents'>
            <li className='list-group-item d-flex justify-content-between aligh-items-start'>
              <div className='me-auto'>
                <FontAwesomeIcon icon={solid('search')} />{' '}
                <strong>Search Results</strong>{' '}
                <FontAwesomeIcon className='redo-icon' icon={solid('redo')} />
              </div>
            </li>
            {this.state.nodeTree.map((node) => (
              <React.Fragment key={node.id + 100}>
                {node.type === 'ou' && (
                  <React.Fragment key={node.id}>
                    <li className='list-group-item d-flex justify-content-between aligh-items-start'>
                      <div
                        className='me-auto'
                        onClick={() => this.toggleNode(node)}
                      >
                        {node.isOpen && (
                          <FontAwesomeIcon icon={solid('caret-down')} />
                        )}
                        {!node.isOpen && (
                          <FontAwesomeIcon icon={solid('caret-right')} />
                        )}{' '}
                        <FontAwesomeIcon
                          className='orgUnit-icon'
                          icon={solid('folder')}
                        />{' '}
                        {node.name}
                      </div>
                      <span className='badge bg-success rounded-pill'>
                        {node.count}
                      </span>
                    </li>
                    {node.isOpen && (
                      <div className='ms-3'>
                        {node.children.map((child) => (
                          <React.Fragment key={child.id}>
                            <li className='list-group-item d-flex justify-content-between aligh-items-start'>
                              <div
                                className='me-auto'
                                onClick={() => this.toggleNode(child)}
                              >
                                {child.isOpen && (
                                  <FontAwesomeIcon icon={solid('caret-down')} />
                                )}
                                {!child.isOpen && (
                                  <FontAwesomeIcon
                                    icon={solid('caret-right')}
                                  />
                                )}{' '}
                                {child.name}
                              </div>
                              <span>
                                <span className='badge bg-success rounded-pill'>
                                  {child.count}
                                </span>
                              </span>
                            </li>
                            {child.isOpen && (
                              <div className='ms-3'>
                                <ul className='list-group active-documents'>
                                  {child.children.map((step) => (
                                    <li
                                      key={step.id}
                                      className='list-group-item d-flex justify-content-between aligh-items-start'
                                      onClick={(s) =>
                                        this.props.LoadStepClick(
                                          step.id,
                                          child.id
                                        )
                                      }
                                    >
                                      <div
                                        className='me-auto'
                                        style={{
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                        }}
                                      >
                                        {step.stepType === 'User' && (
                                          <span>
                                            <FontAwesomeIcon
                                              icon={solid('user')}
                                            />{' '}
                                            {step.name}
                                          </span>
                                        )}
                                        {step.stepType !== 'User' && (
                                          <span style={{ color: '#C1C1C1' }}>
                                            <FontAwesomeIcon
                                              icon={solid('cog')}
                                            />{' '}
                                            {step.name}
                                          </span>
                                        )}
                                      </div>
                                      {step.count > 0 && (
                                        <span className='badge bg-success rounded-pill'>
                                          {step.count}
                                        </span>
                                      )}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </React.Fragment>
                        ))}
                      </div>
                    )}
                  </React.Fragment>
                )}

                {node.type === 'bp' && (
                  <React.Fragment key={node.id}>
                    <li className='list-group-item d-flex justify-content-between aligh-items-start'>
                      <div
                        className='me-auto'
                        onClick={() => this.toggleNode(node)}
                      >
                        {node.isOpen && (
                          <FontAwesomeIcon icon={solid('caret-down')} />
                        )}
                        {!node.isOpen && (
                          <FontAwesomeIcon icon={solid('caret-right')} />
                        )}{' '}
                        {node.name}
                      </div>
                      <span>
                        <span className='badge bg-success rounded-pill'>
                          {node.count}
                        </span>
                      </span>
                    </li>
                    {node.isOpen && (
                      <div className='ms-3'>
                        <ul className='list-group active-documents'>
                          {node.children.map((step) => (
                            <li
                              key={step.id}
                              className='list-group-item d-flex justify-content-between aligh-items-start'
                              onClick={() =>
                                this.props.LoadStepClick(step.id, node.id)
                              }
                            >
                              <div
                                className='me-auto'
                                style={{ overflow: 'hidden' }}
                              >
                                {step.stepType === 'User' && (
                                  <span>
                                    <FontAwesomeIcon icon={solid('user')} />{' '}
                                    {step.name}
                                  </span>
                                )}
                                {step.stepType !== 'User' && (
                                  <span style={{ color: '#C1C1C1' }}>
                                    <FontAwesomeIcon icon={solid('cog')} />{' '}
                                    {step.name}
                                  </span>
                                )}
                              </div>
                              {step.count > 0 && (
                                <span className='badge bg-success rounded-pill'>
                                  {step.count}
                                </span>
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            ))}
          </ul>
        </div>
        }
      </div>
    );
  }
}

export default SearchSidebar;
