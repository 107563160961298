import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { createBrowserHistory } from 'history';
import { Link } from 'react-router-dom';
import ImageReviewSidebar from './ImageReviewSidebar';
import IRStore from '../../../stores/ImageReviewStore';
import WorkflowStore from '../../../stores/WorkflowStore';
import { GetWorkflowFromStep } from '../../../actions/WorkflowActions';
import { GetIRStep, GetIRBP } from '../../../actions/ImageReviewActions.js';
import { UpdateUserHeaderSettings } from '../../../actions/ActiveDocumentActions';
import actionTypes from '../../../actions/ActionTypes';

const customHistory = createBrowserHistory();

var isDraggingColumn = false;
var startColX = 0;
var dragColIndex = 0;
var startColWidth = 0;

class ImageReviewHome extends React.Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      currentDocs: [],
      docCount: 0,
      columns: [],
      scrollWidth: window.innerWidth - 320,
      scrollHeight: window.innerHeight - 170,
      colSizes: [180, 150, 150, 150, 150, 150],
      colOffsets: [0, 180, 330, 480, 630, 780],
      downloadConfirmation: false,
      formatDocumentIdAsTDLink: false,
      currentWfName: '',
      showFields: false,
      checkAll: false,
      checkCount: 0,
    };

    this.LoadStep = this.LoadStep.bind(this);
    this.loadBP = this.loadBP.bind(this);
    this.onWfChange = this.onWfChange.bind(this);
    this.checkParams = this.checkParams.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);
    this.goToDoc = this.goToDoc.bind(this);
    this.toggleDownloadConfirmation =
      this.toggleDownloadConfirmation.bind(this);
    this.toggleFormatDocIdasTDLink = this.toggleFormatDocIdasTDLink.bind(this);
    this.DragColumnSize = this.DragColumnSize.bind(this);
    this.stopDraggingColSize = this.stopDraggingColSize.bind(this);
    this.onIRChange = this.onIRChange.bind(this);
    this.toggleFields = this.toggleFields.bind(this);
    this.toggleFieldDisplay = this.toggleFieldDisplay.bind(this);
    this.updateColumnSettings = this.updateColumnSettings.bind(this);
    this.toggleCheckAll = this.toggleCheckAll.bind(this);
    this.toggleDocument = this.toggleDocument.bind(this);
    this.beginEdit = this.beginEdit.bind(this);
  }

  componentDidMount() {
    IRStore.addChangeListener(this.onIRChange);
    WorkflowStore.addChangeListener(this.onWfChange);
    window.onresize = this.onWindowResize;
    this.checkParams();
    document.title = 'TrinDocs - Image Review'
  }

  componentWillUnmount() {
    IRStore.removeChangeListener(this.onIRChange);
    WorkflowStore.removeChangeListener(this.onWfChange);
  }

  LoadStep(id, bpId) {
    customHistory.push(
      `/ImageReview?Step=${id.toString()}&BP=${bpId.toString()}`
    );
    GetIRStep(id, bpId);
    GetWorkflowFromStep(id);
  }

  goToDoc(docId) {
    if (window.location.pathname === '/ImageReview') {
      var docLink = document.getElementById(`docLink${docId}`);
      var returnPath = window.location.pathname + window.location.search;
      localStorage.setItem('docReturn', returnPath);

      docLink.click();
    }
  }

  onWindowResize(e) {
    //setViewWidth
    this.setState({ scrollWidth: window.innerWidth - 322 });
    this.setState({ scrollHeight: window.innerHeight - 175 });
  }

  startDraggingColumn(e, colIndex) {
    if (colIndex >= 0) {
      isDraggingColumn = true;
      startColX = e.clientX;

      let colInfo = this.state.columns[colIndex];

      dragColIndex = colIndex;
      if (colInfo) {
        startColWidth = colInfo.colWidth;
      }
    }
  }

  DragColumnSize(e) {
    if (isDraggingColumn) {
      var delta = e.clientX - startColX;
      if (
        startColWidth + delta > 20 &&
        !(dragColIndex === 0 && startColWidth + delta < 130)
      ) {
        let info = this.state.columns;
        let colInfo = info[dragColIndex];
        colInfo.colWidth = startColWidth + delta;

        this.setState({ columns: info });
      }
    }
  }

  updateColumnSettings() {
    var settings = {
      headerSettings: [],
    };
    this.state.columns.forEach((col) => {
      let newCol = {
        colName: col.colName,
        display: col.show,
        colWidth: col.colWidth,
      };
      settings.headerSettings.push(newCol);
    });
    var summary = IRStore.getSummary();
    UpdateUserHeaderSettings(summary.formTypeId, settings.headerSettings);
  }

  stopDraggingColSize() {
    if (isDraggingColumn) {
      isDraggingColumn = false;
      this.updateColumnSettings();
    }
  }
  toggleFields() {
    let f = this.state.showFields;
    this.setState({ showFields: !f });
  }

  onIRChange() {
    let lastChange = IRStore.lastChange();
    switch (lastChange) {
      case actionTypes.IRGetStep:
        let docs = IRStore.getDocs();
        let count = IRStore.getDocCount();
        let cols = IRStore.getColumns();
        this.setState({ currentDocs: docs, docCount: count, columns: cols });
        break;
      case actionTypes.IRGetBP:
        let docs2 = IRStore.getDocs();
        let count2 = IRStore.getDocCount();
        let cols2 = IRStore.getColumns();
        this.setState({ currentDocs: docs2, docCount: count2, columns: cols2 });
        break;
      default:
        break;
    }
  }

  onWfChange() {
    var lastChange = WorkflowStore.getLastAction();
    if (lastChange === actionTypes.WorkflowGetFromStep) {
      var currentWfName = WorkflowStore.getCurrentWorkflowName();
      this.setState({ currentWfName: currentWfName.name });
    }
  }

  toggleDownloadConfirmation() {
    if (this.state.downloadConfirmation === true) {
      this.setState({ downloadConfirmation: false });
    } else {
      this.setState({ downloadConfirmation: true });
    }
  }

  toggleFormatDocIdasTDLink() {
    if (this.state.formatDocumentIdAsTDLink === false) {
      this.setState({ formatDocumentIdAsTDLink: true });
    } else {
      this.setState({ formatDocumentIdAsTDLink: false });
    }
  }

  toggleCheckAll() {
  //dont do anything
  return;

    let checkAll = !this.state.checkAll;
    this.setState({ checkAll: checkAll });
    let checkCount = 0;
    let docs = this.state.currentDocs;
    docs.forEach((d) => {
      d.isChecked = checkAll;
      if (checkAll) checkCount++;
    });
    this.setState({ currentDocs: docs, checkCount: checkCount });

    // set same check to all others.
  }

  toggleDocument(docId) {
    let docs = this.state.currentDocs;
    let doc = docs.find((d) => d.documentId === docId);
    if (doc) {
      doc.isChecked = !doc.isChecked;
      let checkCount = 0;
      docs.forEach((d) => {
        if (d.isChecked) checkCount++;
      });

      this.setState({ currentDocs: docs, checkCount: checkCount });
    }
  }

  beginEdit() {
    let docs = this.state.currentDocs;
    let docIds = [];
    docs.forEach((d) => {
      if (d.isChecked) {
        docIds.push(d.documentId);
      }
    });

    IRStore.setReviewDocIds(docIds);
    let link = document.getElementById('imageReviewEditLink');
    link.click();
  }

  checkParams() {
    let params = new URLSearchParams(window.location.search);
    var stepParam = params.get('Step');
    var bpParam = params.get('BP');

    if (stepParam) {
      this.LoadStep(stepParam, bpParam);
    }

    if (stepParam === null && bpParam !== null) {
      this.loadBP(bpParam);
    }
  }

  loadBP(nodeId) {
    GetIRBP(nodeId);
    customHistory.push(`/ImageReview?BP=${nodeId.toString()}`);
  }

  toggleFieldDisplay(col) {
    let columns = this.state.columns;
    let editCol = columns.find((f) => f.colId === col.colId);
    if (editCol) {
      editCol.show = !editCol.show;
      this.setState({ columns: columns }, this.updateColumnSettings);
    }
  }

  render() {
    return (
      <div className='sidebarLayout d-flex flex-row flex-fill'>
        <ImageReviewSidebar
          LoadStepClick={this.LoadStep}
          LoadBPClick={this.loadBP}
        />
        {/*<div className='h-100 d-flex flex-fill align-items-center justify-content-center'  style={{textAlign: 'center'}}>
                <div>
              <FontAwesomeIcon icon={solid('cubes')} size='6x' />

              <h2 style={{ fontWeight: 'bolder', fontStyle: 'italic' }}>
                Active Documents
              </h2>
              </div>

    </div>*/}
        <div className='h-100 d-flex flex-column flex-fill'>
          <div
            className='d-flex justify-content-end'
            style={{ background: '#EFEFEF', borderBottom: 'solid 1px #BBB' }}
          >
            <div>
              {this.state.checkCount > 0 && (
                <button
                  className='btn btn-success btn-sm mt-1 me-3'
                  onClick={this.beginEdit}
                >
                  Begin Edit
                </button>
              )}
            </div>
            <div
              onClick={this.checkParams}
              className='p-2 me-3'
              style={{ cursor: 'pointer' }}
            >
              <FontAwesomeIcon icon={solid('redo')} /> Refresh
            </div>

            <div
              className='p-2 me-3'
              style={{ cursor: 'pointer' }}
              onClick={this.toggleFields}
            >
              <FontAwesomeIcon icon={solid('th-list')} /> Fields
            </div>
            <Link id='docLink' to='/Document' style={{ display: 'None' }} />
          </div>
          {this.state.docCount > 0 && (
            <div
              className='coolScroll flex-fill'
              style={{
                maxWidth: `${this.state.scrollWidth}px`,
                overflowX: 'auto',
              }}
            >
              <div>
                <table>
                  <tbody>
                    <tr
                      onMouseMove={this.DragColumnSize}
                      onMouseUp={this.stopDraggingColSize}
                      onMouseLeave={this.stopDraggingColSize}
                    >
                      <td className='adc-col-header2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          checked={this.state.checkAll}
                          onChange={this.toggleCheckAll}
                        />
                      </td>
                      {this.state.columns.map(
                        (col) =>
                          (col.show || col.colId === 0) && (
                            <React.Fragment key={col.colId}>
                              <td
                                className='adc-col-header2'
                                style={{
                                  maxWidth: `${col.colWidth - 8}px`,
                                  minWidth: `${col.colWidth - 8}px`,
                                }}
                              >
                                {col.colName}
                              </td>
                              <td
                                className='document-grider-header-right'
                                onMouseDown={(e) =>
                                  this.startDraggingColumn(e, col.colId)
                                }
                                style={{ maxWidth: '8px', minWidth: '8px' }}
                              >
                                {' '}
                              </td>
                            </React.Fragment>
                          )
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                className='coolScroll flex-fill'
                style={{
                  maxHeight: `${this.state.scrollHeight}px`,
                  overflowX: 'initial',
                }}
              >
                <table className='adc-table'>
                  <tbody>
                    {this.state.currentDocs.map((doc) => (
                      <tr key={doc.documentId} className='adc-row'>
                        <td
                          style={{
                            width: '30px',
                            minWidth: '30px',
                            paddingLeft: '6px',
                            backgroundColor: '#B98CC7',
                          }}
                        >
                          <input
                            className='form-check-input'
                            type='checkbox'
                            checked={doc.isChecked}
                            onChange={() => this.toggleDocument(doc.documentId)}
                          />
                        </td>
                        {doc.status === 0 && (
                          <td
                            className='col-one'
                            width={`${this.state.columns[0].colWidth}px`}
                            style={{
                              maxWidth: `${this.state.columns[0].colWidth}px`,
                              minWidth: `${this.state.columns[0].colWidth}px`,
                            }}
                            onClick={() => this.goToDoc(doc.documentId)}
                          >
                            <div className='row'>
                              <div className='col-auto col-ready'>
                                <FontAwesomeIcon icon={solid('check')} />
                              </div>
                              <div className='col-auto trans'>
                                <svg width='8' height='30'>
                                  <polygon
                                    points='0,0 8,15, 0,30'
                                    fill='#3FAD42'
                                  />
                                </svg>
                              </div>
                              <div className='col-auto col-id'>
                                {doc.documentId}
                                <Link
                                  id={`docLink${doc.documentId}`}
                                  to={`/Document/${doc.documentId}`}
                                  style={{ display: 'none' }}
                                />
                              </div>
                            </div>
                          </td>
                        )}
                        {doc.status === 1 && (
                          <td
                            className='col-one'
                            style={{
                              maxWidth: `${this.state.columns[0].colWidth}px`,
                              minWidth: `${this.state.columns[0].colWidth}px`,
                            }}
                            onClick={() => this.goToDoc(doc.documentId)}
                          >
                            <div className='row'>
                              <div className='col-auto col-lock'>
                                <FontAwesomeIcon icon={solid('user-lock')} />{' '}
                                {doc.checkOutUserName}
                              </div>
                              <div className='col-auto trans'>
                                <svg width='8' height='30'>
                                  <polygon
                                    points='0,0 8,15, 0,30'
                                    fill='#DDC562'
                                  />
                                </svg>
                              </div>
                              <div className='col-auto col-id'>
                                {doc.documentId}
                                <Link
                                  id={`docLink${doc.documentId}`}
                                  to={`/Document/${doc.documentId}`}
                                  style={{ display: 'none' }}
                                />
                              </div>
                            </div>
                          </td>
                        )}
                        {doc.status === 5 && (
                          <td
                            className='col-one'
                            style={{
                              maxWidth: `${this.state.columns[0].colWidth}px`,
                              minWidth: `${this.state.columns[0].colWidth}px`,
                            }}
                          >
                            <div className='row'>
                              <div className='col-auto col-review'>
                                <FontAwesomeIcon icon={solid('file-image')} />
                              </div>
                              <div className='col-auto trans'>
                                <svg width='8' height='30'>
                                  <polygon
                                    points='0,0 8,15, 0,30'
                                    fill='#be4cdd'
                                  />
                                </svg>
                              </div>
                              <div className='col-auto col-id'>
                                {doc.documentId}
                              </div>
                            </div>
                          </td>
                        )}

                        {this.state.columns[1].show && (
                          <td
                            className='adc-col-value'
                            style={{
                              maxWidth: `${this.state.columns[1].colWidth}px`,
                              minWidth: `${this.state.columns[1].colWidth}px`,
                            }}
                          >
                            {doc.stepName}
                          </td>
                        )}
                        {this.state.columns[2].show && (
                          <td
                            className='adc-col-value'
                            style={{
                              maxWidth: `${this.state.columns[2].colWidth}px`,
                              minWidth: `${this.state.columns[2].colWidth}px`,
                            }}
                          >
                            {doc.pageCount}
                          </td>
                        )}
                        {this.state.columns[3].show && (
                          <td
                            className='adc-col-value'
                            style={{
                              maxWidth: `${this.state.columns[3].colWidth}px`,
                              minWidth: `${this.state.columns[3].colWidth}px`,
                            }}
                          ></td>
                        )}
                        {doc.headers.map(
                          (head) =>
                            this.state.columns[head.colId].show && (
                              <td
                                className='adc-col-value'
                                key={head.fieldCol}
                                style={{
                                  maxWidth: `${
                                    this.state.columns[head.colId].colWidth
                                  }px`,
                                  minWidth: `${
                                    this.state.columns[head.colId].colWidth
                                  }px`,
                                }}
                              >
                                {head.value}
                              </td>
                            )
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {this.state.docCount === 0 && (
            <div className='flex-fill'>
              <div className='h-100 d-flex flex-column align-items-center justify-content-center'>
                <FontAwesomeIcon icon={solid('file-image')} size='4x' />
                <h3 style={{ color: '#000' }}>Image Review</h3>
              </div>
            </div>
          )}

          <div
            className='d-flex'
            style={{ background: '#FDFED8', borderTop: 'solid 1px #222' }}
          >
            <div className='p-2 me-auto'>
              <strong>
                {this.state.docCount} Document
                {this.state.docCount !== 1 && <span>s</span>}
              </strong>
            </div>
            <div className='p-2' style={{ borderLeft: 'solid 1px #CCC' }}>
              Payables
            </div>
            <div className='p-2 me-2' style={{ borderLeft: 'solid 1px #CCC' }}>
              {' '}
              Step 1
            </div>
          </div>
        </div>
        {this.state.showFields && (
          <div className='td-dialogue-container'>
            <div className='td-dialogue card' style={{ width: '400px' }}>
              <div className='card-header'>
                Fields
                <button
                  type='button'
                  className='btn-close'
                  onClick={this.toggleFields}
                />
              </div>
              <div
                className='card-body ps-4'
                style={{ height: '600px', overflow: 'auto' }}
              >
                Fields:
                <div>
                  {this.state.columns.map((col) => (
                    <div key={col.colId} className='form-check'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        checked={col.show}
                        onChange={() => this.toggleFieldDisplay(col)}
                      />
                      <label className='form-check-label'>{col.colName}</label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
        <Link
          id='imageReviewEditLink'
          to={'/ImageReviewEdit'}
          style={{ display: 'none' }}
        >
          HiddenLink
        </Link>
      </div>
    );
  }
}

export default ImageReviewHome;
