import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  GetLicenseSummary,
  UpdateLicense,
} from '../../../actions/LicenseActions';
import LicenseStore from '../../../stores/LicenseStore';

class LicenseView extends React.Component {
  constructor() {
    // state
    super();

    this.state = {
      licenseSummary: undefined,
    };

    this.onLicenseUpdate = this.onLicenseUpdate.bind(this);
    this.onUploadLicense = this.onUploadLicense.bind(this);
  }

  componentDidMount() {
    LicenseStore.addChangeListener(this.onLicenseUpdate);
    GetLicenseSummary();
    document.title = 'TrinDocs - Licenses'
  }

  componentWillUnmount() {
    LicenseStore.removeChangeListener(this.onLicenseUpdate);
  }

  componentDidUpdate() {}

  onLicenseUpdate() {
    let ls = LicenseStore.getLicenseSummary();
    //ls.sysManagerUsed = ls.sysManagerUsed - 2;
    //ls.sysManagerCount = ls.sysManagerCount - 2;
    ls.expDateString = this.convertDate(ls.expDate);
    console.log(ls);
    this.setState({ licenseSummary: ls });
  }

  onUploadLicenseClick() {
    var inputFile = document.getElementById('licenseInput');
    inputFile.click();
  }

  onUploadLicense(e) {
    console.log('upload');
    var inputFile = document.getElementById('licenseInput');
    console.log(inputFile.files);
    UpdateLicense(inputFile.files[0]);
  }

  render() {
    return (
      <div className='sidebarLayout d-flex flex-row flex-fill'>
        <div className='sidebar-container'></div>
        <div className='flex-fill'>
          <div className='card' style={{ margin: '16px', maxWidth: '500px' }}>
            <div className='card-header'>License</div>
            <div className='card-body'>
              <h3 style={{ marginBottom: '18px' }}>
                <FontAwesomeIcon icon={solid('file-signature')} /> TrinDocs
                License
              </h3>
              {this.state.licenseSummary !== undefined &&
                this.state.licenseSummary.companyName !== null && (
                  <div className='row'>
                    <div className='col-6'>
                      <div className='license-header'>License To:</div>
                      <div className='license-item'>
                        {this.state.licenseSummary.companyName}
                      </div>
                      <div className='license-header'>
                        TrinDocs Instance Name:
                      </div>
                      <div className='license-item'>
                        {this.state.licenseSummary.companyInstance}
                      </div>
                      <div className='license-header'>Expiration Date:</div>
                      <div className='license-item'>
                        {this.state.licenseSummary.expDateString}
                      </div>
                    </div>

                    <div className='col-6'>
                      <table>
                        <tbody>
                          <tr>
                            <td className='license-header'>System Managers:</td>
                            <td className='license-count'>
                              {this.state.licenseSummary.sysManagerUsed - 2}/
                              {this.state.licenseSummary.sysManagerCount - 2}
                            </td>
                          </tr>

                          <tr>
                            <td className='license-header'>Admins:</td>
                            <td className='license-count'>
                              {this.state.licenseSummary.adminUsed}/
                              {this.state.licenseSummary.adminCount}
                            </td>
                          </tr>
                          <tr>
                            <td className='license-header'>Approvers:</td>
                            <td className='license-count'>
                              {this.state.licenseSummary.approverUsed}/
                              {this.state.licenseSummary.approverCount}
                            </td>
                          </tr>
                          <tr>
                            <td className='license-header'>Viewers:</td>
                            <td className='license-count'>
                              {this.state.licenseSummary.viewerUsed}/
                              {this.state.licenseSummary.viewerCount}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              {this.state.licenseSummary !== undefined &&
                this.state.licenseSummary.companyName === null && (
                  <div style={{ margin: '18px' }}>
                    {' '}
                    You do not have an active license
                  </div>
                )}
              <button
                className='btn btn-success'
                onClick={this.onUploadLicenseClick}
              >
                Upload new license
              </button>
              <input
                id='licenseInput'
                style={{ display: 'none' }}
                // accept=".zip,.rar"

                onChange={this.onUploadLicense}
                type='file'
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  convertDate(strDate) {
    let strDate2 = strDate.toString();
    //if (!strDate2.endsWith('Z')) {
    //  strDate2 = strDate2 + 'Z';
    //}
    let date = new Date(strDate2);
    console.log(date);
    let d1 = date.getDate();
    let day = d1.toString();
    let m1 = date.getMonth();
    m1 = m1 + 1;
    let mon = m1.toString();
    let year = date.getFullYear().toString();

    let fulldtime = mon + '/' + day + '/' + year;
    return fulldtime;
  }
}

export default LicenseView;
