//@flow
import dispatcher from '../Dispatcher';
import actionTypes from './ActionTypes';
import NavStore from '../stores/NavStore';

export function RequestNav(navDestination, element) {
  let list = NavStore.getListenCount();

  if (list === 0) {
    // no listeners, go ahead and link...
    let link = document.getElementById(element);

    //link.href = navPath;
    link.click();
  }
  dispatcher.dispatch({
    actionType: actionTypes.NavRequest,
    Result: {
      dest: navDestination,
      el: element,
    },
  });
}

export function AcceptNav() {
  let el = NavStore.getElement();
  let link = document.getElementById(el);
  console.log(el);
  //link.href = navPath;
  link.click();
}
