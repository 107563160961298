import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';
import actionTypes from '../actions/ActionTypes';

const CHANGE_EVENT = 'archiveChange';
let _allArchives = undefined;
let _currentArchive = undefined;
let _lastChange = '';
let _lastError = '';

class ArchiveStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getArchives() {
    return _allArchives;
  }

  getCurrentArchive() {
    return _currentArchive;
  }

  lastChange() {
    return _lastChange;
  }

  getLastError() {
    return _lastError;
  }
}

const store = new ArchiveStore();

Dispatcher.register((action) => {
  _lastChange = action.actionType;
  switch (action.actionType) {
    // loading the full list of Companion schemas
    case actionTypes.ArchiveList:
      _lastChange = 'ArchiveList';
      _allArchives = action.Result;
      store.emitChange();
      break;
    case actionTypes.ArchiveLoadOne:
      _lastChange = 'ArchiveLoadOne';
      _currentArchive = action.Result;
      store.emitChange();
      break;
    case actionTypes.ArchiveUpdateOne:
      _lastChange = 'ArchiveUpdateOne';
      if (action.Result.status === 'Success') {
        _lastError = '';
        _currentArchive = action.Result.data;
      } else {
        _lastError = action.Result.updateMessage;
      }
      store.emitChange();
      break;
    case actionTypes.ArchiveDelete:
      _lastChange = 'ArchiveDelete';
      if (action.Result.updateStatus === 'Success') {
        _lastError = '';
        _currentArchive = action.Result.result;
      } else {
        _lastError = action.Result.errorMessage;
      }
      store.emitChange();
      break;
    default:
  }
});

export default store;
