import * as React from 'react';
import SecretSidebar from '../Secret/SecretSidebar';
import { createBrowserHistory } from 'history';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  LoadSecret,
  GetSecretList,
  CreateSecret,
  UpdateSecret,
  DeleteSecret,
} from '../../../../actions/SecretActions';
import SecretStore from '../../../../stores/SecretStore';
import { Link } from 'react-router-dom';

const customHistory = createBrowserHistory();

class SecretView extends React.Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      currentSecret: {
        id: 0,
        name: '',
        value: '',
      },
      secretId: 0,
      isDirty: false,
      error: '',
      isSaving: false,
      deleteStatus: 0,
      isEncrypted: false,
    };

    this.updateSecret = this.updateSecret.bind(this);
    this.onStoreUpdate = this.onStoreUpdate.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);

    this.startDelete = this.startDelete.bind(this);
    this.deleteSecret = this.deleteSecret.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    this.handleSaveClick2 = this.handleSaveClick2.bind(this);
  }

  componentDidMount() {
    SecretStore.addChangeListener(this.onStoreUpdate);
    this.updateSecret();
  }

  componentWillUnmount() {
    SecretStore.removeChangeListener(this.onStoreUpdate);
  }

  componentDidUpdate() {
    this.updateSecret();
  }

  updateSecret() {
    let loc = window.location.pathname;
    let pathParts = loc.split('/');
    let secretId = parseInt(pathParts[pathParts.length - 1], 10);
    if (
      secretId !== 0 &&
      (this.state.currentSecret === undefined ||
        this.state.currentSecret.id !== secretId)
    ) {
      LoadSecret(secretId);
    } else if (
      secretId === 0 &&
      this.state.currentSecret !== undefined &&
      this.state.currentSecret.id !== 0
    ) {
      let currentSecret = {};
      currentSecret.id = 0;
      currentSecret.name = '';
      currentSecret.value = '';
      this.setState({
        currentSecret: currentSecret,
        secretName: '',
      });
    }
  }

  onStoreUpdate() {
    let lastAction = SecretStore.lastChange();
    if (lastAction === 'SecretLoadOne') {
      let secret = SecretStore.getCurrentSecret();
      this.setState({
        currentSecret: secret,
        secretName: secret.name,
        isDirty: false,
        isSaving: false,
      });
    }
    if (lastAction === 'SecretCreateOne') {
      let lastError = SecretStore.getLastError();
      if (lastError === '' || lastError === undefined) {
        let newSecret = SecretStore.getCurrentSecret();
        if (newSecret.id !== this.state.currentSecret.id) {
          customHistory.push(`/Admin/SecretView/${newSecret.data.id}`);
        }

        this.setState({
          error: '',
          currentSecret: newSecret,
          currentName: newSecret.name,
          isSaving: false,
          isDirty: false,
        });
        GetSecretList();
      } else {
        this.setState({ isSaving: false, error: lastError });
      }
    }
    if (lastAction === 'SecretUpdateOne') {
      let lastError = SecretStore.getLastError();
      if (lastError === '' || lastError === undefined) {
        let newSecret = SecretStore.getCurrentSecret();
        if (newSecret.data.id !== this.state.currentSecret.id) {
          customHistory.push(`/Admin/SecretView/${newSecret.data.id}`);
        }

        this.setState({
          error: '',
          currentSecret: newSecret,
          currentName: newSecret.name,
          isSaving: false,
          isDirty: false,
        });
        GetSecretList();
      } else {
        this.setState({ isSaving: false, error: lastError });
      }
    }
    if (lastAction === 'SecretDelete') {
      let lastError = SecretStore.getLastError();
      if (lastError === '') {
        let link = document.getElementById('secretHome');
        link.click();
      } else {
        this.setState({ isSaving: false, error: lastError });
      }
    }
  }

  handleNameChange(e) {
    let currentSecret = this.state.currentSecret;
    currentSecret.name = e.target.value;
    this.setState({ currentSecret: currentSecret, isDirty: true });
  }

  handleValueChange(e) {
    let currentSecret = this.state.currentSecret;
    currentSecret.value = e.target.value;
    this.setState({ currentSecret: currentSecret, isDirty: true });
  }

  handleSaveClick(e) {
    this.setState({ isSaving: true, isEncrypted : false });
    if (this.state.currentSecret.id === 0) {
      CreateSecret(this.state.currentSecret);
    } else {
      UpdateSecret(this.state.currentSecret);
    }
  }

  handleSaveClick2(e) {
    this.setState({ isSaving: true, isEncrypted : true });
    if (this.state.currentSecret.id === 0) {
      CreateSecret(this.state.currentSecret, this.state.isEncrypted);
    } else {
      UpdateSecret(this.state.currentSecret, this.state.isEncrypted);
    }
  }


  startDelete() {
    this.setState({ deleteStatus: 1 });
  }

  deleteSecret() {
    DeleteSecret(this.state.currentSecret.id);
    this.setState({ deleteStatus: 0 });
  }

  cancelDelete() {
    this.setState({ deleteStatus: 0 });
  }

  render() {
    return (
      <div className='container-fluid' style={{ height: '100%' }}>
        <div className='row flex-nowrap' style={{ height: '100%' }}>
          <SecretSidebar />
          <Link
              id='secretHome'
              className='nav-link'
              to={`/Admin/Secret`}
              style={{ display: 'none' }}
            >
            </Link>
            
          {this.state.currentSecret !== undefined && (
            <div
              className='flex-fill'
              style={{ backgroundColor: '#efefef', overflow: 'scroll' }}
            >
              <div
                className='card'
                style={{
                  marginTop: '20px',
                  marginLeft: '30px',
                  marginRight: '30px',
                  maxHeight: '620px',
                }}
              >
                <div className='card-header'>
                  <h4>
                    <FontAwesomeIcon icon={solid('key')} />
                    {'  '}
                    {this.state.currentSecret.name}
                  </h4>
                </div>
                <div className='card-body'>
                  <div className='mb-3'>
                    <label htmlFor='nameTxt' className='form-label'>
                      Name:
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      id='nameTxt'
                      value={this.state.currentSecret.name}
                      onChange={this.handleNameChange}
                    />
                  </div>

                  <div className='mb-3'>
                    <label htmlFor='valueTxt' className='form-label'>
                      Value:
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      id='valueTxt'
                      value={this.state.currentSecret.value}
                      onChange={this.handleValueChange}
                    />
                  </div>

                  <div className='mb-3' style={{ marginTop: '8px' }}>
                    {this.state.isDirty && !this.state.isSaving && (
                      <button
                        className='btn btn-primary'
                        onClick={this.handleSaveClick}
                      >
                        Save
                      </button>
                        )}
                        {this.state.isDirty && !this.state.isSaving && (
                        <button
                      className='btn btn-primary' style={{marginLeft:'8px'}}
                      onClick={this.handleSaveClick2}
                    >
                      Save Encrypted
                    </button>
                    )}
                    {!this.state.isDirty && !this.state.isSaving && (
                      <div>
                        <button className='btn btn-primary disabled'>
                          Save
                        </button><button
                      className='btn btn-primary disabled' style={{marginLeft:'8px'}}
                      onClick={this.handleSaveClick2}
                    >
                      Save Encrypted
                    </button>
                        <button
                          className='btn btn-danger'
                          style={{ marginLeft: '6px' }}
                          onClick={this.startDelete}
                        >
                          Delete
                        </button>
                      </div>
                    )}
                    {this.state.isSaving && (
                      <FontAwesomeIcon icon={solid('spinner')} size='2x' spin />
                    )}
                    {this.state.deleteStatus === 1 && (
                      <div
                        className='alert alert-danger'
                        style={{ marginTop: '6px' }}
                        role='alert'
                      >
                        <p>Are you sure you want to delete this secret?</p>

                        <button
                          className='btn btn-danger'
                          onClick={this.deleteSecret}
                        >
                          Yes - Delete
                        </button>
                        <button
                          className='btn btn-secondary'
                          style={{ marginLeft: '6px' }}
                          onClick={this.cancelDelete}
                        >
                          Cancel
                        </button>
                      </div>
                    )}

                    {this.state.error !== '' && (
                      <div
                        className='alert alert-danger'
                        style={{ marginTop: '6px' }}
                        role='alert'
                      >
                        {this.state.error}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default SecretView;
