import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import InboundAutomationSidebar from './InboundAutomationSidebar';

import { LoadIA, UpdateIA, DeleteIA } from '../../../../actions/IAActions';
import { GetMailboxList } from '../../../../actions/MailboxActions';
import { GetBPList } from '../../../../actions/BPActions';
import { LoadIntakeStrategies } from '../../../../actions/ISActions';
import { GetTemplateList } from '../../../../actions/TemplateActions';

import IAStore from '../../../../stores/IAStore';
import MailboxStore from '../../../../stores/MailboxStore';
import BPStore from '../../../../stores/BPStore';
import ISStore from '../../../../stores/IntakeStrategyStore';
import TemplateStore from '../../../../stores/TemplateStore';
import { Link } from 'react-router-dom';

class InboundAutomationView extends React.Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      currentIA: undefined,
      mailboxes: [],
      bps: [],
      intakeStrats: [],
      isRearranging: false,
      isDirty: false,
      templates: [],
      filteredTemplates: [],
    };

    this.onIAChange = this.onIAChange.bind(this);
    this.onMailboxSelection = this.onMailboxSelection.bind(this);
    this.onISUpdate = this.onISUpdate.bind(this);
    this.onSendAdminEmailChange = this.onSendAdminEmailChange.bind(this);
    this.onAttachCopyChange = this.onAttachCopyChange.bind(this);
    this.onAppendEmailChange = this.onAppendEmailChange.bind(this);
    this.onMBChange = this.onMBChange.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.onAdminEmailChange = this.onAdminEmailChange.bind(this);
    this.ftpChange = this.ftpChange.bind(this);
    this.handleDefaultFtpChange = this.handleDefaultFtpChange.bind(this);
    this.onBPUpdate = this.onBPUpdate.bind(this);
    this.handleChangeRouting = this.handleChangeRouting.bind(this);
    this.handleIsChange = this.handleIsChange.bind(this);
    this.AddRule = this.AddRule.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.handleChangeRuleCode = this.handleChangeRuleCode.bind(this);
    this.handleChangeRuleParam = this.handleChangeRuleParam.bind(this);
    this.handleChangeIntakeStrategy =
      this.handleChangeIntakeStrategy.bind(this);
    this.handleRearranging = this.handleRearranging.bind(this);
    this.onMoveTargetUp = this.onMoveTargetUp.bind(this);
    this.onMoveTargetDown = this.onMoveTargetDown.bind(this);
    this.updateActionDisplays = this.updateActionDisplays.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onIsActiveChange = this.onIsActiveChange.bind(this);
    this.onTemplateUpdate = this.onTemplateUpdate.bind(this);
    this.onDeleteEmailChange = this.onDeleteEmailChange.bind(this);
    this.handleDefaultTemplateChange =
      this.handleDefaultTemplateChange.bind(this);
    this.updateIAView = this.updateIAView.bind(this);
    this.deleteRule = this.deleteRule.bind(this);
  }

  componentDidMount() {
    IAStore.addChangeListener(this.onIAChange);
    BPStore.addChangeListener(this.onBPUpdate);
    MailboxStore.addChangeListener(this.onMBChange);
    ISStore.addChangeListener(this.onISUpdate);
    TemplateStore.addChangeListener(this.onTemplateUpdate);
    this.updateIA();
    GetMailboxList();
    GetBPList();
    LoadIntakeStrategies();
    GetTemplateList();
  }

  componentWillUnmount() {
    IAStore.removeChangeListener(this.onIAChange);
    BPStore.removeChangeListener(this.onBPUpdate);
    MailboxStore.removeChangeListener(this.onMBChange);
    ISStore.removeChangeListener(this.onISUpdate);
    TemplateStore.removeChangeListener(this.onTemplateUpdate);
  }

  componentDidUpdate() {
    this.updateIAView();
  }

  updateIAView() {
    let loc = window.location.pathname;
    let pathParts = loc.split('/');
    let id = parseInt(pathParts[pathParts.length - 1], 10);

    if (this.state.currentIA === undefined) {
      if (id !== 0) {
        LoadIA(id);
      }
    } else if (this.state.currentIA.inboundAutomationId !== id) {
      LoadIA(id);
    }
  }

  onIAChange() {
    let ia = IAStore.getCurrentIA();
    let lastChange = IAStore.lastChange();

    if (lastChange === 'IADelete') {
      let link = document.getElementById('inboundAutomationHome');
      link.click();
    } else {
      this.setState(
        { currentIA: ia, isDirty: false },
        this.updateActionDisplays
      );
    }
    if (lastChange === 'IALoadOne') {
      if (ia.routingType === 1) {
        if (this.state.templates.length > 0) {
          let filter = [];
          let defaultBP = this.state.bps.find(
            (bp) => bp.bpId === this.state.currentIA.defaultFtp
          );

          let defaultFormType = this.state.templates.find(
            (ft) => ft.formTypeId === defaultBP.formTypeId
          );
          defaultFormType.templates.forEach((tem) => {
            filter.push(tem);
          });
          //   if (defaultBP !== undefined) {
          //     templates.forEach((t) => {
          //       if (t.formTypeId === defaultBP.formTypeId) {
          //         filter.push(t);
          //       }
          //     });
          //   }
          this.setState({ filteredTemplates: filter });
        }
      }
    }
  }

  updateIA() {
    let loc = window.location.pathname;
    let pathParts = loc.split('/');
    let isId = parseInt(pathParts[pathParts.length - 1], 10);
    LoadIA(isId);
  }

  onISUpdate() {
    let intakes = ISStore.getIntakeStrategies();
    this.setState({ intakeStrats: intakes }, this.updateActionDisplays);
  }

  onMBChange() {
    let mbs = MailboxStore.getMailboxes();
    this.setState({ mailboxes: mbs });
  }

  onBPUpdate() {
    let bps = BPStore.getBPList();
    this.setState({ bps: bps });
  }

  onTemplateUpdate() {
    let formTypes = TemplateStore.getTemplateList();
    this.setState({ templates: formTypes });
    if (this.state.currentIA !== undefined) {
      if (this.state.currentIA.routingType === 1) {
        let filter = [];
        let defaultBP = this.state.bps.find(
          (bp) => bp.bpId === this.state.currentIA.defaultFtp
        );
        let defaultFormType = formTypes.find(
          (ft) => ft.formTypeId === defaultBP.formTypeId
        );
        defaultFormType.templates.forEach((tem) => {
          filter.push(tem);
        });
        this.setState({ filteredTemplates: filter });
      }
    }
  }

  onMailboxSelection(e) {
    let ia = this.state.currentIA;
    ia.mailBoxId = e.target.value;
    this.setState({ currentIA: ia, isDirty: true });
  }
  onSendAdminEmailChange(e) {
    let ia = this.state.currentIA;
    ia.sendAdminEmail = e.target.checked;
    this.setState({ currentIA: ia, isDirty: true });
  }
  onAttachCopyChange(e) {
    let ia = this.state.currentIA;
    ia.attachCopyOfEmail = e.target.checked;
    this.setState({ currentIA: ia, isDirty: true });
  }
  onAppendEmailChange(e) {
    let ia = this.state.currentIA;
    ia.appendEmailBody = e.target.checked;
    this.setState({ currentIA: ia, isDirty: true });
  }

  onAdminEmailChange(e) {
    let ia = this.state.currentIA;
    ia.adminEmail = e.target.value;
    this.setState({ currentIA: ia, isDirty: true });
  }

  onDeleteEmailChange(e) {
    let ia = this.state.currentIA;
    ia.deleteEmail = e.target.checked;
    this.setState({ currentIA: ia, isDirty: true });
  }

  ftpChange(e) {
    let ftps = this.state.currentIA.ftps;
    let targetBp = parseInt(e.target.value, 10);
    if (e.target.checked && ftps.indexOf(targetBp) === -1) {
      // add to ftps
      ftps.push(targetBp);
    }
    if (!e.target.checked && ftps.indexOf(targetBp) !== -1) {
      // remove from ftps
      let idx = ftps.indexOf(targetBp);
      ftps.splice(idx, 1);
    }
    let ia = this.state.currentIA;
    ia.ftps = ftps;

    this.setState({ isDirty: true, currentIA: ia });
  }

  handleDefaultFtpChange(e) {
    let ia = this.state.currentIA;
    ia.defaultFtp = parseInt(e.target.value, 10);

    this.setState(
      {
        isDirty: true,
        currentIA: ia,
      },
      () => {
        console.log('Adjust filter');
        let filter = [];
        let defaultBP = this.state.bps.find(
          (bp) => bp.bpId === this.state.currentIA.defaultFtp
        );
        console.log(defaultBP);
        console.log(this.state.templates);
        this.state.templates.forEach((t) => {
          if (t.formTypeId === defaultBP.formTypeId) {
            filter.push(...t.templates);
          }
        });
        this.setState({ filteredTemplates: filter });
      }
    );
  }

  handleDefaultTemplateChange(e) {
    let ia = this.state.currentIA;
    ia.defaultTemplateId = parseInt(e.target.value, 10);
    this.setState({
      isDirty: true,
      currentIA: ia,
    });
  }

  handleChangeRouting(e) {
    let ia = this.state.currentIA;
    ia.routingType = parseInt(e.target.value, 10);
    this.setState({
      currentIA: ia,
      isDirty: true,
    });
    if (ia.routingType === 1) {
      let filter = [];
      let defaultBP = this.state.bps.find(
        (bp) => bp.bpId === this.state.currentIA.defaultFtp
      );
      this.state.templates.forEach((t) => {
        if (t.formTypeId === defaultBP.formTypeId) {
          filter.push(t);
        }
      });
      this.setState({ filteredTemplates: filter });
    }
  }

  handleIsChange(e) {
    let ia = this.state.currentIA;
    ia.intakeStrategyId = e.target.value;
    this.setState({
      currentIA: ia,
      isDirty: true,
    });
  }

  AddRule() {
    let ia = this.state.currentIA;
    let rulePrio = 1;
    ia.routingRules.forEach((rule) => {
      if (rule.rulePriority >= rulePrio) {
        rulePrio = rule.rulePriority + 1;
      }
    });
    let newRule = {
      rulePriority: rulePrio,
      ruleCode: 'ANY',
      ruleParam: '',
      IsEditing: true,
      IsDeleting: false,
      intakeStrategyId: '0',
      actionType: 2,
      actionDisplay: 'Skip email',
    };
    ia.routingRules.push(newRule);
    this.setState({ currentIA: ia, isDirty: true });
  }

  toggleEdit(rule) {
    let ia = this.state.currentIA;
    let r = ia.routingRules.find((i) => i.rulePriority === rule.rulePriority);
    if (r !== undefined) {
      r.IsEditing = !r.IsEditing;
    }
    this.setState({ currentIA: ia, isDirty: true });
  }

  handleChangeRuleCode(e, rule) {
    let ia = this.state.currentIA;
    let r = ia.routingRules.find((i) => i.rulePriority === rule.rulePriority);
    if (r !== undefined) {
      r.ruleCode = e.target.value;
    }
    this.setState({ currentIA: ia, isDirty: true });
  }

  handleChangeRuleParam(e, rule) {
    let ia = this.state.currentIA;
    let r = ia.routingRules.find((i) => i.rulePriority === rule.rulePriority);
    if (r !== undefined) {
      r.ruleParam = e.target.value;
    }
    this.setState({ currentIA: ia, isDirty: true });
  }

  updateActionDisplays() {
    let ia = this.state.currentIA;
    if (ia !== undefined) {
      ia.routingRules.forEach((rule) => {
        if (rule.actionType === 2) {
          rule.actionDisplay = 'Skip email';
        }
        if (rule.actionType === 1) {
          let is = this.state.intakeStrats.find(
            (i) => i.objectId === rule.intakeStrategyId
          );
          if (is !== undefined) {
            rule.actionDisplay = is.isName;
          }
        }
      });
    }
    this.setState({ currentIA: ia });
  }

  handleChangeIntakeStrategy(e, rule) {
    let ia = this.state.currentIA;
    let r = ia.routingRules.find((i) => i.rulePriority === rule.rulePriority);
    if (r !== undefined) {
      r.intakeStrategyId = e.target.value;
      if (e.target.value === '0') {
        r.actionType = 2;
        r.actionDisplay = 'Skip email';
      } else {
        let is = this.state.intakeStrats.find(
          (i) => i.objectId === e.target.value
        );
        if (is !== undefined) {
          r.actionType = 1;
          r.actionDisplay = is.isName;
        }
      }
    }
    this.setState({ currentIA: ia, isDirty: true });
  }

  handleRearranging() {
    let rearrange = this.state.isRearranging;
    rearrange = !rearrange;
    this.setState({ isRearranging: rearrange });
  }

  onMoveTargetUp(rule) {
    let ia = this.state.currentIA;
    let r1 = ia.routingRules.find((r) => r.rulePriority === rule.rulePriority);
    let r2 = ia.routingRules.find(
      (r) => r.rulePriority === rule.rulePriority - 1
    );

    if (r1 !== undefined && r2 !== undefined) {
      let t = r1.rulePriority;
      r1.rulePriority = r2.rulePriority;
      r2.rulePriority = t;
    }
    ia.routingRules = ia.routingRules.sort(
      (a, b) => a.rulePriority - b.rulePriority
    );
    this.setState({ currentIA: ia, isDirty: true });
  }
  onMoveTargetDown(rule) {
    let ia = this.state.currentIA;
    let r1 = ia.routingRules.find((r) => r.rulePriority === rule.rulePriority);
    let r2 = ia.routingRules.find(
      (r) => r.rulePriority === rule.rulePriority + 1
    );

    if (r1 !== undefined && r2 !== undefined) {
      let t = r1.rulePriority;
      r1.rulePriority = r2.rulePriority;
      r2.rulePriority = t;
    }
    ia.routingRules = ia.routingRules.sort(
      (a, b) => a.rulePriority - b.rulePriority
    );
    this.setState({ currentIA: ia, isDirty: true });
  }

  onSaveClick() {
    UpdateIA(this.state.currentIA.inboundAutomationId, this.state.currentIA);
  }

  onDeleteClick() {
    DeleteIA(this.state.currentIA.inboundAutomationId);
  }

  onIsActiveChange(e) {
    let ia = this.state.currentIA;
    ia.isActive = e.target.checked;
    this.setState({ currentIA: ia, isDirty: true });
  }

  deleteRule(rule) {
    console.log('deleteRule');
    let ia = this.state.currentIA;
    let rules = ia.routingRules;
    let idx = ia.routingRules.indexOf(rule);
    if (idx > -1) {
      rules.splice(idx, 1);
    }
    this.setState({ currentIA: ia });
  }

  render() {
    return (
      <div className='container-fluid' style={{ height: '100%' }}>
        <div className='row flex-nowrap' style={{ height: '100%' }}>
          <InboundAutomationSidebar />
          <Link
            id='inboundAutomationHome'
            className='nav-link'
            to={`/Admin/InboundAutomationHome`}
            style={{ display: 'none' }}
          ></Link>
          {this.state.currentIA !== undefined && (
            <div
              className='flex-fill'
              style={{
                backgroundColor: '#efefef',
                padding: '15px',
                overflow: 'auto',
              }}
            >
              <div
                className='card'
                style={{
                  marginTop: '20px',
                }}
              >
                <div className='card-header'>
                  <h4>InboundAutomation</h4>
                </div>
                <div className='card-body'>
                  <h3>{this.state.currentIA.mailBoxName}</h3>
                </div>
              </div>

              <div
                className='card'
                style={{
                  marginTop: '20px',
                }}
              >
                <div className='card-header'>
                  <h4>Mailbox Configuration</h4>
                </div>
                <div className='card-body'>
                  <div className='mb-3' style={{ marginTop: '8px' }}>
                    <label
                      className='form-label'
                      htmlFor='flexSwitchCheckChecked'
                    >
                      <FontAwesomeIcon icon={solid('envelope-square')} />{' '}
                      Mailbox:
                    </label>
                    <select
                      className='form-control'
                      aria-label='Select a mailbox'
                      value={this.state.currentIA.mailBoxId}
                      onChange={this.onMailboxSelection}
                    >
                      {this.state.mailboxes.map(
                        (mb) =>
                          mb.mailboxType === 11 && (
                            <option value={mb.mailboxId} key={mb.mailboxId}>
                              {mb.name}
                            </option>
                          )
                      )}
                    </select>
                  </div>
                  <div
                    className='form-check form-switch'
                    style={{ marginTop: '10px' }}
                  >
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='switchSendAdminEmail'
                      onChange={this.onSendAdminEmailChange}
                      checked={this.state.currentIA.sendAdminEmail}
                    />
                    <label
                      className='form-check-label'
                      htmlFor='switchSendAdminEmail'
                    >
                      Send alert notifications to administrator
                    </label>
                  </div>
                  {this.state.currentIA.sendAdminEmail && (
                    <div className='row' style={{ marginLeft: '20px' }}>
                      <label
                        htmlFor='uploadPathTxt'
                        className='col-form-label col-auto'
                      >
                        Email to:
                      </label>
                      <div className='col-auto'>
                        <input
                          type='text'
                          className='form-control'
                          id='uploadPathTxt'
                          value={this.state.currentIA.adminEmail}
                          style={{ width: '300px' }}
                          onChange={this.onAdminEmailChange}
                        />
                      </div>
                    </div>
                  )}
                  <div
                    className='form-check form-switch'
                    style={{ marginTop: '10px' }}
                  >
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='switchAttachCopyOfEmail'
                      onChange={this.onAttachCopyChange}
                      checked={this.state.currentIA.attachCopyOfEmail}
                    />
                    <label
                      className='form-check-label'
                      htmlFor='switchAttachCopyOfEmail'
                    >
                      Attach a copy of the email
                    </label>
                  </div>
                  <div
                    className='form-check form-switch'
                    style={{ marginTop: '10px' }}
                  >
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='switchAppendEmailBody'
                      onChange={this.onAppendEmailChange}
                      checked={this.state.currentIA.appendEmailBody}
                    />
                    <label
                      className='form-check-label'
                      htmlFor='switchAppendEmailBody'
                    >
                      Append email body to PDF
                    </label>
                  </div>
                  <div
                    className='form-check form-switch'
                    style={{ marginTop: '10px' }}
                  >
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='switchDeleteEmail'
                      onChange={this.onDeleteEmailChange}
                      checked={this.state.currentIA.deleteEmail}
                    />
                    <label
                      className='form-check-label'
                      htmlFor='switchAppendEmailBody'
                    >
                      Delete email after processing
                    </label>
                  </div>
                  <div
                    className='form-check form-switch'
                    style={{ marginTop: '10px' }}
                  >
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='switchIsActove'
                      onChange={this.onIsActiveChange}
                      checked={this.state.currentIA.isActive}
                    />
                    <label
                      className='form-check-label'
                      htmlFor='switchAppendEmailBody'
                    >
                      Active
                    </label>
                  </div>
                </div>
              </div>
              <div
                className='card'
                style={{
                  marginTop: '20px',
                }}
              >
                <div className='card-header'>
                  <h4>Routing</h4>
                </div>
                <div className='card-body'>
                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='rdoLegacyRouting'
                      id='rdoLegacyRouting'
                      value='1'
                      checked={this.state.currentIA.routingType === 1}
                      onChange={this.handleChangeRouting}
                    />
                    <label
                      className='form-check-label'
                      htmlFor='rdoLegacyRouting'
                    >
                      Legacy
                    </label>
                  </div>
                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='rdoIntakeStrategy'
                      id='rdoIntakeStrategy'
                      value='2'
                      checked={this.state.currentIA.routingType === 2}
                      onChange={this.handleChangeRouting}
                    />
                    <label
                      className='form-check-label'
                      htmlFor='rdoIntakeStrategy'
                    >
                      Intake Strategy
                    </label>
                  </div>
                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='rdoMultiStrategy'
                      id='rdoMultiStrategy'
                      value='3'
                      checked={this.state.currentIA.routingType === 3}
                      onChange={this.handleChangeRouting}
                    />
                    <label
                      className='form-check-label'
                      htmlFor='rdoMultiStrategy'
                    >
                      Multiple Rules
                    </label>
                  </div>
                  <hr />
                  {this.state.currentIA.routingType === 1 && (
                    <div>
                      <div className='h5' style={{ marginTop: '10px' }}>
                        Try these business processes:
                      </div>
                      {this.state.bps.map((bp) => (
                        <div
                          className='form-check form-switch'
                          style={{ marginTop: '5px' }}
                          key={bp.bpId}
                        >
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value={bp.bpId}
                            onChange={this.ftpChange}
                            //onChange={this.onFastPassChange}
                            checked={
                              this.state.currentIA.ftps.indexOf(bp.bpId) !== -1
                            }
                          />
                          <label
                            className='form-check-label'
                            htmlFor='flexSwitchCheckChecked'
                          >
                            {bp.bpName}
                          </label>
                        </div>
                      ))}

                      <div className='h5' style={{ marginTop: '10px' }}>
                        If none match, use this:
                      </div>
                      <div className='row'>
                        <div className='col-6'>
                          <select
                            className='form-select'
                            aria-label='Select default business process'
                            value={this.state.currentIA.defaultFtp}
                            onChange={this.handleDefaultFtpChange}
                          >
                            <option value='0'>Select a Business Process</option>
                            {this.state.bps.map((bp) => (
                              <option key={bp.bpId} value={bp.bpId}>
                                {bp.bpName}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className='col-6'>
                          <select
                            className='form-select'
                            aria-label='Select default business process'
                            value={this.state.currentIA.defaultTemplateId}
                            onChange={this.handleDefaultTemplateChange}
                          >
                            <option>No default template</option>
                            {this.state.filteredTemplates.map((t) => (
                              <option key={t.templateId} value={t.templateId}>
                                {t.name}
                              </option>
                            ))}
                            ;
                          </select>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.currentIA.routingType === 2 && (
                    <div>
                      <div className='h5' style={{ marginTop: '10px' }}>
                        Select an Intake Strategy:
                      </div>
                      <hr />

                      <select
                        className='form-select'
                        value={this.state.currentIA.intakeStrategyId}
                        onChange={this.handleIsChange}
                      >
                        <option value='0'>Select an intake strategy</option>
                        {this.state.intakeStrats.map((intake) => (
                          <option key={intake.id} value={intake.objectId}>
                            {intake.isName}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {this.state.currentIA.routingType === 3 && (
                    <div>
                      <div>Rules:</div>
                      <hr />
                      {this.state.currentIA.routingRules.map((rule) => (
                        <div
                          key={rule.rulePriority}
                          className='row'
                          style={{
                            marginTop: '6px',
                            marginBottom: '6px',
                          }}
                        >
                          {!rule.IsEditing && (
                            <div
                              className='lead2'
                              style={{
                                width: '200px',
                                paddingTop: '6px',
                              }}
                            >
                              {rule.ruleCode === 'ANY' && 'Anything Else:'}
                              {rule.ruleCode === 'SUBJCONT' &&
                                'if Subject Contains:'}
                              {rule.ruleCode === 'SUBJMATCH' &&
                                'if Subject Matches:'}
                              {rule.ruleCode === 'EMAILFROM' &&
                                'if Email From:'}
                              {rule.ruleCode === 'EMAILDOMAIN' &&
                                'if EmailFrom (Domain):'}
                            </div>
                          )}
                          {rule.IsEditing && (
                            <div
                              className='lead2'
                              style={{
                                width: '200px',
                                paddingTop: '6px',
                              }}
                            >
                              <select
                                className='form-select'
                                value={rule.ruleCode}
                                onChange={(e) =>
                                  this.handleChangeRuleCode(e, rule)
                                }
                              >
                                <option value='SUBJCONT'>
                                  Subject Contains
                                </option>
                                <option value='SUBJMATCH'>
                                  Subject Matches
                                </option>
                                <option value='EMAILFROM'>Email From</option>
                                <option value='EMAILDOMAIN'>
                                  Email Domain
                                </option>
                                <option value='ANY'>Anything else</option>
                              </select>
                            </div>
                          )}
                          <div
                            className='lead2'
                            style={{
                              width: '230px',
                              paddingTop: '6px',
                            }}
                          >
                            {rule.ruleCode !== 'ANY' && rule.IsEditing && (
                              <input
                                type='text'
                                className='form-control'
                                value={rule.ruleParam}
                                onChange={(e) =>
                                  this.handleChangeRuleParam(e, rule)
                                }
                              />
                            )}
                            {!rule.IsEditing && (
                              <span
                                style={{
                                  color: '#148F77',
                                }}
                              >
                                {rule.ruleParam}
                              </span>
                            )}
                          </div>
                          <div
                            className='lead2'
                            style={{
                              width: '55px',
                              paddingTop: '6px',
                            }}
                          >
                            use:
                          </div>
                          <div
                            style={{
                              width: '230px',
                              paddingTop: '6px',
                            }}
                          >
                            {!rule.IsEditing && (
                              <span>
                                {rule.actionType === 2 && (
                                  <i
                                    className='fas fa-ban'
                                    style={{
                                      color: 'red',
                                    }}
                                  />
                                )}
                                {rule.actionType === 1 && (
                                  <i
                                    className='fas fa-file-import'
                                    style={{
                                      color: '#0d6efd',
                                    }}
                                  />
                                )}

                                {rule.actionDisplay}
                              </span>
                            )}
                            {rule.IsEditing && (
                              <select
                                className='form-select'
                                value={rule.intakeStrategyId}
                                onChange={(e) =>
                                  this.handleChangeIntakeStrategy(e, rule)
                                }
                              >
                                {this.state.intakeStrats.map((intake) => (
                                  <option
                                    key={intake.id}
                                    value={intake.objectId}
                                  >
                                    {intake.isName}
                                  </option>
                                ))}
                                <option value='0'>Skip email</option>
                              </select>
                            )}
                          </div>
                          {!rule.IsEditing && (
                            <div
                              style={{
                                width: '30px',
                                padding: '0px',
                              }}
                            >
                              <button
                                className='btn btn-primary btn-sm'
                                onClick={() => this.toggleEdit(rule)}
                              >
                                <FontAwesomeIcon icon={solid('pencil-alt')} />
                              </button>
                            </div>
                          )}
                          {!rule.IsEditing && (
                            <div
                              style={{
                                width: '30px',
                                padding: '0px',
                              }}
                            >
                              <button
                                className='btn btn-danger btn-sm'
                                onClick={() => this.deleteRule(rule)}
                              >
                                <FontAwesomeIcon icon={solid('times')} />
                              </button>
                            </div>
                          )}
                          {!rule.IsEditing && this.state.isRearranging && (
                            <div
                              style={{
                                width: '50px',
                                padding: '0px',
                              }}
                            >
                              <button
                                className='btn btn-primary round-button'
                                onClick={() => this.onMoveTargetUp(rule)}
                              >
                                <FontAwesomeIcon icon={solid('arrow-up')} />
                              </button>

                              <button
                                className='btn btn-primary round-button'
                                onClick={() => this.onMoveTargetDown(rule)}
                              >
                                <FontAwesomeIcon icon={solid('arrow-down')} />
                              </button>
                            </div>
                          )}
                          {rule.IsEditing && (
                            <div
                              style={{
                                width: '30px',
                                padding: '9px',
                              }}
                            >
                              <button
                                className='btn btn-success btn-sm'
                                onClick={() => this.toggleEdit(rule)}
                              >
                                <FontAwesomeIcon icon={solid('check')} />
                              </button>
                            </div>
                          )}
                        </div>
                      ))}

                      <hr />
                      <button
                        className='btn btn-success'
                        onClick={this.AddRule}
                      >
                        <FontAwesomeIcon icon={solid('plus')} />
                        {'  '}Add Rule
                      </button>
                      <button
                        className='btn btn-primary'
                        style={{ marginLeft: '4px' }}
                        onClick={this.handleRearranging}
                      >
                        Rearrange
                      </button>
                    </div>
                  )}
                </div>
              </div>

              <div className='clearfix'>
                {this.state.isDirty && (
                  <button
                    style={{ marginTop: '8px', padding: '12px' }}
                    className='btn btn-success btn-lg float-start'
                    onClick={this.onSaveClick}
                  >
                    <i
                      className='fas fa-save'
                      style={{ marginRight: '8px' }}
                    ></i>
                    {'   '}
                    Save changes
                  </button>
                )}
                <button
                  style={{
                    marginTop: '8px',
                    padding: '12px',
                    marginLeft: '6px',
                  }}
                  className='btn btn-danger btn-lg float-end'
                  onClick={this.onDeleteClick}
                >
                  <i
                    className='fas fa-times'
                    style={{ marginRight: '8px' }}
                  ></i>
                  {'   '}
                  Delete IA
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default InboundAutomationView;
