import * as React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import actionTypes from '../../../actions/ActionTypes';
import ImageReviewStore from '../../../stores/ImageReviewStore';
import { GetIRTree } from '../../../actions/ImageReviewActions';

class ImageReviewSidebar extends React.Component {
  //isList: Array<BPModel>;
  constructor(props) {
    super(props);
    this.state = {
      nodeTree: [],
      h: window.innerHeight - 142,
    };

     this.onReceiveImageReviewChange = this.onReceiveImageReviewChange.bind(this);
     this.toggleNode = this.toggleNode.bind(this);
     this.resizeH = this.resizeH.bind(this);

    // this.onSearchChange = this.onSearchChange.bind(this);
  }

  componentDidMount() {
    ImageReviewStore.addChangeListener(this.onReceiveImageReviewChange);
     GetIRTree();
     this.resizeH();
     window.addEventListener('resize',this.resizeH);
   }

  componentWillUnmount() {
    ImageReviewStore.removeChangeListener(this.onReceiveImageReviewChange);
    window.removeEventListener('resize',this.resizeH);
  }

  onReceiveImageReviewChange() {
    let lastChange = ImageReviewStore.lastChange();

    if (lastChange === actionTypes.IRGetADTree) {
       let adTree = ImageReviewStore.getIRTree();




       this.setState({
        nodeTree: adTree
       });
     }
    }


    refreshNode(node) {

    }

    resizeH() {
        this.setState({h: window.innerHeight - 142});

    }

    copyNode(node) {
        var newNode = {
            id: node.id,
            type: node.type,
            name: node.name,
            isOpen: false,
            isFav: node.isFav,
            count: node.count,
            children: [],
        }
        node.children.forEach((n) => {
            newNode.children.push(this.copyNode(n));
        });
        return newNode;
    }

    toggleNode(node) {
        if (node.type === 'bp') {
            this.props.LoadBPClick(node.id);
    }

    var tree= this.state.nodeTree;
    var node1 = tree.find(n1 => n1.type === node.type && n1.id === node.id);
    if(node1)
    {

        node1.isOpen = !node1.isOpen;
    }
    else {
        // search children
        tree.forEach((ou) => {
            if(ou.type === 'ou'){
                var node2 = ou.children.find(n2 => n2.type === node.type && n2.id === node.id);
                if(node2)
                {
                    node2.isOpen = !node2.isOpen;
                }
            }
        });
    }
    this.setState({nodeTree: tree});
  }



  nodeMouseUp(e) {

  }


  render() {

    return (
      <div
        className='d-flex flex-column pt-3 ps-3 text-white bg-dark'
        style={{ maxHeight: '100%', width: '320px'  }}
      >
        <Link
          to='/ImageReview'
          className='d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none'
        >
          <span className='fs-4'>
            <FontAwesomeIcon style={{ color: '#AE4BCF'}} icon={solid('file-image')} />
            {'   '}
            Image Review
          </span>
        </Link>
        <hr />
        <div className='pe-3 coolScroll' style={{maxHeight: `${this.state.h}px`, overflow: 'auto'}}>
            <ul className='list-group active-documents'>
                <li className='list-group-item d-flex justify-content-between aligh-items-start'>
                    <div className='me-auto'>
                        <strong>All Documents</strong>{' '}
                        <FontAwesomeIcon className='redo-icon' icon={solid('redo')} />
                    </div>
                </li>
            {this.state.nodeTree.map((node) => (
                <React.Fragment key={node.id + 100}>
                {node.type === 'ou' && (
                    <React.Fragment key={node.id}>
                    <li className='list-group-item d-flex justify-content-between aligh-items-start'>
                            <div className='me-auto' onClick={() => this.toggleNode(node)} >
                                {node.isOpen && <FontAwesomeIcon icon={solid('caret-down')}/>}
                                {!node.isOpen && <FontAwesomeIcon icon={solid('caret-right')}/>}
                            {' '}<FontAwesomeIcon className='orgUnit-icon' icon={solid('folder')} />{' '}{node.name}
                            </div>
                            <span className='badge bg-imageRev rounded-pill'>{node.count}</span>
                        </li>
                        {node.isOpen && (
                            <div className='ms-3'>
                                {node.children.map((child) => (
                                    <React.Fragment key={child.id}>
                                    <li className='list-group-item d-flex justify-content-between aligh-items-start'>
                                        <div className='me-auto' onClick={() => this.toggleNode(child)}>
                                            {child.isOpen && <FontAwesomeIcon icon={solid('caret-down')}/>}
                                            {!child.isOpen && <FontAwesomeIcon icon={solid('caret-right')}/>}
                                            {' '}
                                            <FontAwesomeIcon className='businessProcess-icon' icon={solid('building')} />{' '}{child.name}
                                        </div>
                                        <span><span className='badge bg-imageRev rounded-pill'>{child.count}</span></span>
                                    </li>
                                    {child.isOpen && (
                                        <div className='ms-3'>
                                            <ul className='list-group active-documents'>
                                            {child.children.map((step) => (
                                                <li key={step.id} className='list-group-item d-flex justify-content-between aligh-items-start' onClick={(s) => this.props.LoadStepClick(step.id, child.id )}>
                                                <div className='me-auto'  style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                                    {step.stepType === 'User' && <span ><FontAwesomeIcon icon={solid('user')} />{' '}{step.name}</span>}
                                                    {step.stepType !== 'User' && <span style={{color: '#C1C1C1'}}><FontAwesomeIcon icon={solid('cog')} />{' '}{step.name}</span>}
                                                </div>
                                                {step.count > 0 &&
                                                    <span className='badge bg-imageRev rounded-pill'>{step.count}</span>
                                                }
                                                </li>
                                            ))}
                                            </ul>
                                        </div>
                                    )}
                                    </React.Fragment>
                                ))}
                            </div>
                        )}
                    </React.Fragment>
                )}

                {node.type === 'bp' && (
                    <React.Fragment key={node.id}>
                    <li className='list-group-item d-flex justify-content-between aligh-items-start'>
                        <div className='me-auto' onClick={() => this.toggleNode(node)}>
                            {node.isOpen && <FontAwesomeIcon icon={solid('caret-down')}/>}
                            {!node.isOpen && <FontAwesomeIcon icon={solid('caret-right')}/>}

                            {' '}<FontAwesomeIcon className='businessProcess-icon' icon={solid('building')} />{' '}{node.name}
                        </div>
                        <span> <span className='badge bg-imageRev rounded-pill'>{node.count}</span></span>
                    </li>
                    {node.isOpen && (
                        <div className='ms-3'>
                            <ul className='list-group active-documents'>
                            {node.children.map((step) => (
                                <li key={step.id} className='list-group-item d-flex justify-content-between aligh-items-start' onClick={() => this.props.LoadStepClick(step.id, node.id)}>
                                <div className='me-auto' style={{overflow: 'hidden'}}>
                                    {step.stepType === 'User' && <span ><FontAwesomeIcon icon={solid('user')} />{' '}{step.name}</span>}
                                    {step.stepType !== 'User' && <span style={{color: '#C1C1C1'}}><FontAwesomeIcon icon={solid('cog')} />{' '}{step.name}</span>}
                                </div>
                                {step.count > 0 &&
                                    <span className='badge bg-success rounded-pill'>{step.count}</span>
                                }
                                </li>
                            ))}
                            </ul>
                        </div>
                    )}
                    </React.Fragment>
                )}

                </React.Fragment>
            ))}
            </ul>
        </div>

      </div>
    );
  }
}

export default ImageReviewSidebar;
